import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import { InfiniteQueryResult } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1),
  },
}))

interface EntityResults<T> {
  readonly nextResultsToken: string | undefined | null
  readonly results: readonly T[]
}

type InfiniteListFooterProps = InfiniteQueryResult<EntityResults<unknown>>

const InfiniteListFooter: FC<InfiniteListFooterProps> = ({
  canFetchMore,
  fetchMore,
  isFetchingMore,
  data,
}) => {
  const classes = useStyles()

  const allResults = data?.flatMap((x) => x.results) ?? []

  let text: string

  if (isFetchingMore) {
    text = 'Loading more...'
  } else if (canFetchMore) {
    text = 'Load more'
  } else if (!canFetchMore && allResults.length === 0) {
    text = 'No results'
  } else {
    text = 'No more results'
  }

  return (
    <div className={classes.footer}>
      <Button
        color="primary"
        disabled={!!isFetchingMore || !canFetchMore}
        onClick={() => fetchMore()}
      >
        {text}
      </Button>
    </div>
  )
}

export default InfiniteListFooter
