import { OtaUpdateSession } from './data/OtaUpdateSession'
import * as ApiError from './data/ApiError'
import { Firmware } from './data/Firmware'
import { FirmwareFamily } from './data/FirmwareFamily'
import { Thing } from './data/Thing'
import { delayed } from './delayed'
import { UserDetails } from './data/UserDetails'
import { User } from './data/User'
import { ThingState } from './data/ThingState'

export interface OtaUpdateSessionResults {
  readonly nextResultsToken: string | undefined
  readonly results: OtaUpdateSession[]
}

async function makeHeaders(
  jwtToken: () => Promise<string>,
): Promise<Record<string, string>> {
  return {
    Authorization: `Bearer ${await jwtToken()}`,
  }
}

export async function fetchOtaUpdateSessions(
  key: string,
  jwtToken: () => Promise<string>,
  states: OtaUpdateSession['state'][],
  thingId: string | undefined,
  version: string | undefined,
  limit: number,
  nextResultsToken: string | undefined,
): Promise<OtaUpdateSessionResults> {
  const headers = await makeHeaders(jwtToken)

  const query = [
    ['thingId', thingId],
    ['version', version],
    ['limit', limit],
    ['nextResultsToken', nextResultsToken],
    ...(states?.map((state) => ['state', state]) ?? []),
  ]
    .filter((x) => x[1] !== undefined && x[1] !== null)
    .map((x) => `${x[0]}=${x[1]}`)
    .join('&')

  return fetch(`/mobile-admin/otaupdate?${query}`, { headers }).then(
    ApiError.rejectOrJson,
  )
}

export type SessionIdAndThingId = { sessionId: number; thingId: string }

export async function startOtaUpdateSession(
  jwtToken: () => Promise<string>,
  { sessionId, thingId }: SessionIdAndThingId,
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)
  return fetch(`/mobile-admin/otaupdate/${thingId}/${sessionId}/start`, {
    method: 'POST',
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function deleteOtaUpdateSession(
  jwtToken: () => Promise<string>,
  { sessionId, thingId }: SessionIdAndThingId,
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)
  return fetch(`/mobile-admin/otaupdate/${thingId}/${sessionId}`, {
    method: 'DELETE',
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function createOtaUpdateSession(
  jwtToken: () => Promise<string>,
  { firmwareId, thingId }: { firmwareId: string; thingId: string },
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)
  return fetch(`/mobile-admin/otaupdate`, {
    method: 'POST',
    body: JSON.stringify({ firmware: firmwareId, thing: thingId }),
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function fetchFirmware(
  jwtToken: () => Promise<string>,
): Promise<Firmware[]> {
  const headers = await makeHeaders(jwtToken)

  return fetch(`/mobile-admin/firmware`, { headers }).then(
    ApiError.rejectOrJson,
  )
}

export type ThingExportItem = {
  thingId: string
  metadata?: Record<string, string>
}

export async function exportMetricsCsv(
  jwtToken: () => Promise<string>,
  {
    thingId,
    startTime,
    endTime,
    zoneId,
  }: {
    thingId: string
    startTime: string
    endTime: string | undefined
    zoneId: string | undefined
  },
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)

  const params = new URLSearchParams()
  params.set('thingId', thingId)
  params.set('startTime', startTime)
  if (endTime) {
    params.set('endTime', endTime)
  }
  if (zoneId) {
    params.set('zoneId', zoneId)
  }
  params.set('limit', '' + 1000000)

  return fetch(`/metrics/export?${params.toString()}`, {
    method: 'GET',
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function exportAlarmsCsv(
  jwtToken: () => Promise<string>,
  {
    thingId,
    startTime,
    endTime,
    zoneId,
  }: {
    thingId: string
    startTime: string
    endTime: string | undefined
    zoneId: string | undefined
  },
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)

  const params = new URLSearchParams()
  params.set('startTime', startTime)
  if (endTime) {
    params.set('endTime', endTime)
  }
  if (zoneId) {
    params.set('zoneId', zoneId)
  }
  params.set('limit', '' + 10_000)

  return fetch(
    `/mobile-admin/thing/${thingId}/alarms/export?${params.toString()}`,
    {
      headers,
    },
  ).then(ApiError.rejectOrResponse)
}

export async function exportAlarmsCsvFromMultipleThings(
  jwtToken: () => Promise<string>,
  {
    thingIds,
    startTime,
    endTime,
    zoneId,
  }: {
    thingIds: [string, ...string[]]
    startTime: string
    endTime: string | undefined
    zoneId: string | undefined
  },
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)

  const params = new URLSearchParams()
  thingIds.forEach((thingId) => params.append('thingId', thingId))
  params.set('startTime', startTime)
  if (endTime) {
    params.set('endTime', endTime)
  }
  if (zoneId) {
    params.set('zoneId', zoneId)
  }
  params.set('limit', '' + 1_000_000)

  return fetch(`/mobile-admin/alarms/export?${params.toString()}`, {
    headers,
  }).then(ApiError.rejectOrResponse)
}

export interface LatestUpdateInMetricsResult {
  readonly thingId: string
  readonly time: string
}

export async function latestUpdateInMetrics(
  jwtToken: () => Promise<string>,
  { thingIds }: { thingIds: string[] },
): Promise<LatestUpdateInMetricsResult[]> {
  if (thingIds.length === 0 || thingIds.length > 20) {
    throw new Error(
      `Cannot fetch metrics update details for ${thingIds.length} things at once. (min: 1, max: 20)`,
    )
  }
  const headers = await makeHeaders(jwtToken)
  const params = new URLSearchParams()
  thingIds.forEach((thingId) => params.append('thingId', thingId))
  return fetch(`/metrics/latest_update?${params.toString()}`, { headers }).then(
    ApiError.rejectOrJson,
  )
}

export async function readRegisters(
  jwtToken: () => Promise<string>,
  thingId: string,
  registers: Set<number>,
): Promise<Record<number, number>> {
  const headers = await makeHeaders(jwtToken)
  const params = new URLSearchParams()
  registers.forEach((register) => {
    params.append('r', register.toString())
  })
  return fetch(
    `/mobile-admin/thing/${thingId}/registers?${params.toString()}`,
    {
      headers,
    },
  ).then(ApiError.rejectOrJson)
}

export async function writeRegisters(
  jwtToken: () => Promise<string>,
  thingId: string,
  registersAndValues: Record<number, number>,
): Promise<void> {
  const headers = await makeHeaders(jwtToken)
  return fetch(`/mobile-admin/thing/${thingId}/registers`, {
    method: 'PUT',
    body: JSON.stringify(registersAndValues),
    headers,
  })
    .then(ApiError.rejectOrResponse)
    .then(() => undefined)
}

export async function fetchThingById(
  jwtToken: () => Promise<string>,
  thingId: string,
): Promise<Thing> {
  const headers = await makeHeaders(jwtToken)

  return fetch(`/mobile-admin/thing/${thingId}`, {
    headers,
  }).then(ApiError.rejectOrJson)
}

export type AddFirmwareRequest = {
  family: FirmwareFamily
  version: string
  file: File
  serialNumberRegexes: string[]
}

export async function fetchFirmwareDownloadUrl(
  jwtToken: () => Promise<string>,
  firmwareId: string,
): Promise<string> {
  const headers = await makeHeaders(jwtToken)

  return fetch(`/mobile-admin/firmware/${firmwareId}/download`, {
    headers,
  }).then(ApiError.rejectOrString)
}

export async function deleteFirmware(
  jwtToken: () => Promise<string>,
  firmwareId: string,
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)

  return fetch(`/mobile-admin/firmware/${firmwareId}`, {
    method: 'DELETE',
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function updateFirmware(
  jwtToken: () => Promise<string>,
  firmwareId: string,
  serialNumberRegexes: string[],
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)

  return fetch(`/mobile-admin/firmware/${firmwareId}`, {
    method: 'PATCH',
    body: JSON.stringify({ serialNumberRegexes }),
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function publishFirmware(
  jwtToken: () => Promise<string>,
  firmwareId: string,
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)

  return fetch(`/mobile-admin/firmware/${firmwareId}/published/true`, {
    method: 'PUT',
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function unpublishFirmware(
  jwtToken: () => Promise<string>,
  firmwareId: string,
): Promise<Response> {
  const headers = await makeHeaders(jwtToken)

  return fetch(`/mobile-admin/firmware/${firmwareId}/published/false`, {
    method: 'PUT',
    headers,
  }).then(ApiError.rejectOrResponse)
}

export async function addFirmware(
  jwtToken: () => Promise<string>,
  { version, family, file, serialNumberRegexes }: AddFirmwareRequest,
): Promise<Response> {
  const formData = new FormData()
  formData.set('version', version)
  formData.set('family', family)
  formData.set('file', file)
  serialNumberRegexes.forEach((regex) =>
    formData.append('serialNumberRegex', regex),
  )
  const headers = await makeHeaders(jwtToken)
  return fetch('/mobile-admin/firmware', {
    method: 'POST',
    body: formData,
    headers,
  }).then(ApiError.rejectOrResponse)
}

export type UpdateFirmwareMetadataRequest = {
  family: FirmwareFamily
  version: string
  serialNumberRegexes: string[]
}[]

export async function updateFirmwareMetadata(
  jwtToken: () => Promise<string>,
  request: UpdateFirmwareMetadataRequest,
): Promise<{ updatedCount: number }> {
  const headers = await makeHeaders(jwtToken)
  return fetch('/mobile-admin/firmware/metadata', {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  })
    .then(ApiError.rejectOrResponse)
    .then((response) => response.json())
}

interface UserResults {
  readonly nextResultsToken: string | undefined | null
  readonly results: User[]
}

export async function fetchUsers(
  jwtToken: () => Promise<string>,
  searchTerm: string,
  nextResultsToken: string | undefined,
): Promise<UserResults> {
  const headers = await makeHeaders(jwtToken)
  const query = [
    ['searchTerm', searchTerm.length === 0 ? undefined : searchTerm],
    ['limit', 10],
    ['nextResultsToken', nextResultsToken],
  ]
    .filter((x) => x[1] !== undefined)
    .map((x) => `${x[0]}=${x[1]}`)
    .join('&')
  return fetch(`/mobile-admin/user?${query}`, { headers }).then(
    ApiError.rejectOrJson,
  )
}

export async function fetchUserDetails(
  jwtToken: () => Promise<string>,
  id: string,
): Promise<UserDetails> {
  const headers = await makeHeaders(jwtToken)
  return delayed(
    fetch(`mobile-admin/user/${id}`, { headers }).then(ApiError.rejectOrJson),
  )
}

interface ThingResults {
  readonly nextResultsToken: string | undefined
  readonly results: Thing[]
}

export async function getThings(
  jwtToken: () => Promise<string>,
  searchTerm: string,
  searchTermRegex: boolean,
  states: ThingState[] | undefined,
  setupFinished: boolean,
  ahuSwVersion: string | undefined,
  nextResultsToken: string | undefined,
): Promise<ThingResults> {
  const headers = await makeHeaders(jwtToken)

  const query = [
    [
      'searchTerm',
      searchTerm.length === 0
        ? undefined
        : encodeURIComponent(searchTermRegex ? searchTerm : `%${searchTerm}%`),
    ],
    ['searchTermRegex', searchTermRegex],
    [
      'ahuSwVersion',
      ahuSwVersion !== undefined
        ? encodeURIComponent(
            ahuSwVersion.includes('%') || ahuSwVersion.includes('_')
              ? ahuSwVersion
              : `%${ahuSwVersion}%`,
          )
        : undefined,
    ],
    ['setupFinished', `${setupFinished}`],
    ['limit', 50],
    ['nextResultsToken', nextResultsToken],
    ...(states?.map((state) => ['state', state]) ?? []),
  ]
    .filter((x) => x[1] !== undefined && x[1] !== null)
    .map((x) => `${x[0]}=${x[1]}`)
    .join('&')
  return fetch(`/mobile-admin/thing?${query}`, { headers }).then(
    ApiError.rejectOrJson,
  )
}
