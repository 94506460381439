import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MutationResult, QueryStatus, useQuery } from 'react-query'
import * as ApiError from '../data/ApiError'
import { FirmwareFamily } from '../data/FirmwareFamily'
import * as api from '../api'

type CreateOtaUpdateSessionDialogProps = {
  readonly open: boolean
  readonly jwtToken: () => Promise<string>
  readonly onUpdate: (request: { firmwareId: string }) => void
  readonly onClose: () => void
  readonly updateResult: MutationResult<Response, unknown>
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginBottom: theme.spacing(2),
    width: '150px',
  },
  versionInputField: {
    marginLeft: theme.spacing(1),
  },
}))

const CreateOtaUpdateSessionDialog: FC<CreateOtaUpdateSessionDialogProps> = (
  props,
) => {
  const classes = useStyles()
  const [firmware, setFirmware] = React.useState('')
  const [family, setFamily] = React.useState<FirmwareFamily>('ahu2020_hmi')
  const firmwareQuery = useQuery(
    ['firmware'],
    () => api.fetchFirmware(props.jwtToken),
    {
      retry: false,
      enabled: props.jwtToken !== undefined,
      staleTime: 1000 * 60,
    },
  )
  const { open, onClose, onUpdate } = props

  React.useEffect(() => {
    if (!open && firmware !== '') {
      setFirmware('')
      setFamily('ahu2020_hmi')
    }
  }, [open, firmware, setFirmware, setFamily])

  const update = React.useCallback(() => {
    if (firmware.length > 0) {
      onUpdate({ firmwareId: firmware })
    }
  }, [firmware, onUpdate])

  const { status, error } = props.updateResult

  const close = React.useCallback(() => {
    if (status !== QueryStatus.Loading) {
      onClose()
    }
  }, [status, onClose])

  return (
    <div>
      <Dialog open={props.open} onClose={close} aria-labelledby="add-firmware">
        <DialogTitle id="add-firmware">Update firmware</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the firmware family and version to update to.
          </DialogContentText>

          <FormControl className={classes.inputField}>
            <InputLabel id="family-select-label">Family</InputLabel>
            <Select
              labelId="family-select-label"
              value={family}
              onChange={(e) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setFamily(e.target.value as any as FirmwareFamily)
              }
            >
              <MenuItem value={'ahu2020_hmi'}>AHU2020 HMI</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            className={`${classes.inputField} ${classes.versionInputField}`}
          >
            <InputLabel id="version-select-label">Version</InputLabel>
            <Select
              labelId="version-select-label"
              value={firmware}
              onChange={(e) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setFirmware(e.target.value as any as string)
              }
            >
              {firmwareQuery.data
                ?.filter((firmware) => firmware.family === family)
                .map((firmware) => (
                  <MenuItem key={firmware.id} value={firmware.id}>
                    {firmware.version}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {status === QueryStatus.Error && (
            <DialogContentText color="error" style={{ marginTop: '8px' }}>
              {ApiError.unknownErrorToMessage(error)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            color="primary"
            disabled={status === QueryStatus.Loading}
          >
            Cancel
          </Button>
          <Button
            onClick={update}
            disabled={
              status === QueryStatus.Loading ||
              firmware.length === 0 ||
              family.length === 0
            }
            color="primary"
          >
            {status === QueryStatus.Loading && <CircularProgress size={18} />}
            {status !== QueryStatus.Loading && 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateOtaUpdateSessionDialog
