import React, { FC } from 'react'
import KeyValueData from './KeyValueData'
import { Thing } from '../data/Thing'
import { makeStyles } from '@material-ui/core/styles'
import * as ThingState from '../data/ThingState'
import { dateTimeFormat } from '../dateTimeFormat'
import StateLabel from './StateLabel'
import * as color from '../color'

const useStyles = makeStyles((theme) => ({
  container: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}))

type Props = {
  thing: Thing
}

const ThingDetails: FC<Props> = ({ thing }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <KeyValueData field="Thing ID" value={thing.id} />
      <KeyValueData
        field="HMI serial number"
        value={thing.serialNumber ?? 'n/a'}
      />
      <KeyValueData
        field="Setup finished"
        value={
          <StateLabel
            label={thing.setupFinished ? 'Yes' : 'No'}
            circleColor={thing.setupFinished ? color.swegonGreen : color.gray}
          />
        }
      />
      <KeyValueData
        field="State"
        value={
          <StateLabel
            label={ThingState.translate(thing.state)}
            circleColor={color.thingStateToColor(thing.state)}
          />
        }
      />
      <KeyValueData
        field="AHU serial number"
        value={thing.ahuSerialNumber ?? '-'}
      />
      <KeyValueData field="AHU description" value={thing.ahuName ?? '-'} />
      <KeyValueData
        field="AHU software version"
        value={thing.ahuSwVersion ?? '-'}
      />

      <KeyValueData
        field="Latest handshake at"
        value={
          thing.latestHandshake
            ? dateTimeFormat.format(Date.parse(thing.latestHandshake))
            : 'Never'
        }
      />
      <KeyValueData
        field="Created at"
        value={dateTimeFormat.format(Date.parse(thing.createdAt))}
      />
    </div>
  )
}

export default ThingDetails
