export type ThingState = 'registered' | 'in_use' | 'deactivated'

export function translate(thingState: ThingState): string {
  switch (thingState) {
    case 'registered':
      return 'Registered'
    case 'in_use':
      return 'In use'
    case 'deactivated':
      return 'Deactivated'
  }
}
