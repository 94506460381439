import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  QueryStatus,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import * as ApiError from '../data/ApiError'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/RefreshOutlined'
import { dateTimeFormat } from '../dateTimeFormat'
import * as color from '../color'
import StateLabel from '../components/StateLabel'
import * as api from '../api'
import { Link, useRouteMatch } from 'react-router-dom'
import { RoutePath } from '../RoutePath'
import CreateOtaUpdateSessionDialog from '../components/CreateOtaUpdateSessionDialog'
import OtaUpdateTable from '../components/OtaUpdateTable'
import KeyValueData from '../components/KeyValueData'
import ExportCsv from '../components/ExportCsv'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  exportCsvError: {
    marginBottom: theme.spacing(3),
  },
}))

const OtaUpdateSection = (props: {
  jwtToken: () => Promise<string>
  thingId: string
  updateDisabled: boolean
  onUpdateFirmwareClicked: () => void
}) => {
  return (
    <Box margin={0}>
      <div style={{ display: 'flex' }}>
        <Typography variant="h6" gutterBottom component="span">
          OTA Updates{' '}
        </Typography>

        <Button
          color="primary"
          style={{ marginLeft: 'auto' }}
          disabled={props.updateDisabled}
          onClick={props.onUpdateFirmwareClicked}
        >
          Create update
        </Button>

        <Button
          color="primary"
          component={Link}
          to={`${RoutePath.otaUpdate}?thingId=${props.thingId}`}
          style={{ marginLeft: '16px' }}
        >
          View all
        </Button>
      </div>
      <OtaUpdateTable
        tableComponent="div"
        tableSize="small"
        jwtToken={props.jwtToken}
        thingId={props.thingId}
        version={undefined}
        states={[]}
        limit={5}
        hideColumns={['thingId']}
      />
    </Box>
  )
}

function formatOptionalString(str: string | undefined | null): string {
  return str && str.length > 0 ? str : 'n/a'
}

function LatestTelemetryView({
  data,
  isLoading,
  error,
}: {
  data: string | undefined
  isLoading: boolean
  error: unknown
}) {
  if (data) {
    return dateTimeFormat.format(Date.parse(data))
  } else if (isLoading) {
    return <CircularProgress size={14} />
  } else if (error) {
    return ApiError.unknownErrorToMessage(error)
  } else {
    return '-'
  }
}

const ThingDetails: FC<{ jwtToken: () => Promise<string> }> = ({
  jwtToken,
}) => {
  const routeMatch = useRouteMatch<{ thingId: string }>()
  const thingId = routeMatch.params.thingId
  const thingQuery = useQuery(
    ['thing', thingId],
    (key: string, thingId: string) => api.fetchThingById(jwtToken, thingId),
  )

  const [updateFirmwareDialogOpen, setUpdateFirmwareDialogOpen] =
    React.useState<boolean>(false)
  const cache = useQueryCache()

  const [mutateUpdateFirmwareVersion, mutateUpdateFirmwareVersionResult] =
    useMutation(
      (request: { firmwareId: string; thingId: string }) =>
        api.createOtaUpdateSession(jwtToken, request),
      {
        onSuccess: () => {
          setUpdateFirmwareDialogOpen(false)
        },
        onSettled: () => {
          cache.invalidateQueries('otaUpdate')
        },
      },
    )

  const otaUpdateQuery = useInfiniteQuery(
    ['otaUpdate', thingId],
    (key: string, thingId: string, nextResultsToken: string | undefined) =>
      api.fetchOtaUpdateSessions(
        key,
        jwtToken,
        [],
        thingId,
        undefined,
        5,
        nextResultsToken,
      ),
    {
      retry: false,
      enabled: thingId !== undefined,
      getFetchMore: (last) => last?.nextResultsToken,
    },
  )

  const latestTelemetryUpdate = useQuery(
    ['latestUpdate', thingId],
    (key: string, thingId: string) =>
      api
        .latestUpdateInMetrics(jwtToken, { thingIds: [thingId] })
        .then((x) => (x.length === 1 ? x[0].time : undefined)),
    {
      enabled: thingId !== undefined,
      retry: false,
    },
  )

  const classes = useStyles()

  const thing = thingQuery.data

  if (thingQuery.status === QueryStatus.Error) {
    return <div>{ApiError.unknownErrorToMessage(thingQuery.error)}</div>
  }

  if (!thing || thingQuery.isLoading) {
    return <CircularProgress />
  }

  const otaUpdateDisabled = ['started', 'idle', 'in_progress'].some((state) =>
    otaUpdateQuery.data
      ?.flatMap((x) => x.results)
      ?.some((y) => y.state === state),
  )

  const exportFilePrefix = `${
    thing.ahuSerialNumber ? thing.ahuSerialNumber : 'unknown'
  }_${thing.ahuSwVersion ? thing.ahuSwVersion : 'unknown'}`

  return (
    <div>
      <div>
        <Typography variant="h6" gutterBottom component="div">
          General
        </Typography>

        <KeyValueData field="Thing ID" value={thing.id} />

        <KeyValueData field="Image version" value={thing.imageVersion ?? '-'} />

        <KeyValueData
          field="Latest handshake (Reboot and subsequent successful connection)"
          value={
            thing.latestHandshake
              ? dateTimeFormat.format(Date.parse(thing.latestHandshake))
              : 'n/a'
          }
        />

        <KeyValueData
          field="Latest metrics received"
          value={LatestTelemetryView(latestTelemetryUpdate)}
        />

        <KeyValueData
          field="Remote debugging"
          value={
            <div>
              <StateLabel
                label={
                  thing.allowRemoteDebugging ? (
                    'Allowed'
                  ) : (
                    <div style={{ display: 'inline-block' }}>
                      Forbidden
                      {!thing.allowRemoteDebugging && (
                        <IconButton
                          onClick={() => {
                            thingQuery.refetch()
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      )}
                    </div>
                  )
                }
                circleColor={
                  thing.allowRemoteDebugging ? color.swegonGreen : color.warning
                }
              />

              {thing.allowRemoteDebugging && (
                <Button
                  size="small"
                  component={Link}
                  color="primary"
                  to={`${RoutePath.thingRemoteDebugging.replace(
                    ':thingId',
                    thingId ?? '',
                  )}`}
                >
                  Connect to device
                </Button>
              )}
            </div>
          }
        />

        <Typography
          variant="h6"
          gutterBottom
          component="div"
          className={classes.title}
        >
          AHU details
        </Typography>

        <KeyValueData
          field="AHU serial number"
          value={formatOptionalString(thing.ahuSerialNumber)}
        />

        <KeyValueData
          field="AHU description"
          value={formatOptionalString(thing.ahuName)}
        />

        <KeyValueData
          field="AHU software version"
          value={formatOptionalString(thing.ahuSwVersion)}
        />

        <Typography
          variant="h6"
          gutterBottom
          component="div"
          className={classes.title}
        >
          HMI details
        </Typography>

        <KeyValueData
          field="HMI serial number"
          value={formatOptionalString(thing.serialNumber)}
        />

        <KeyValueData
          field="HMI software version"
          value={thing.swVersion ?? '-'}
        />
        <KeyValueData
          field="HMI hardware version"
          value={thing.hwVersion ?? '-'}
        />
      </div>

      <ExportCsv
        title="Export metrics"
        filePrefix={`${exportFilePrefix}_metrics`}
        doExport={(args) => {
          return api.exportMetricsCsv(jwtToken, {
            thingId: thing.id,
            startTime: args.startTime,
            endTime: args.endTime,
            zoneId: args.zoneId,
          })
        }}
      />

      <ExportCsv
        title="Export alarms"
        filePrefix={`${exportFilePrefix}_alarms`}
        doExport={(args) =>
          api.exportAlarmsCsv(jwtToken, {
            thingId: thing.id,
            startTime: args.startTime,
            endTime: args.endTime,
            zoneId: args.zoneId,
          })
        }
      />

      <OtaUpdateSection
        jwtToken={jwtToken}
        thingId={thing.id}
        updateDisabled={otaUpdateDisabled}
        onUpdateFirmwareClicked={() => setUpdateFirmwareDialogOpen(true)}
        {...otaUpdateQuery}
      />

      <CreateOtaUpdateSessionDialog
        jwtToken={jwtToken}
        open={updateFirmwareDialogOpen}
        onUpdate={({ firmwareId }) => {
          mutateUpdateFirmwareVersion({
            firmwareId,
            thingId,
          })
        }}
        updateResult={mutateUpdateFirmwareVersionResult}
        onClose={() => {
          setUpdateFirmwareDialogOpen(false)
        }}
      />
    </div>
  )
}

export default ThingDetails
