import React, { FC, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDropzone } from 'react-dropzone'
import CircularProgress from '@material-ui/core/CircularProgress'
import { QueryStatus, useMutation, useQueryCache } from 'react-query'
import * as ApiError from '../data/ApiError'
import * as api from '../api'

type UpdateFirmwareRegExprsDialogProps = {
  readonly open: boolean
  readonly onClose: () => void
  jwtToken: () => Promise<string>
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  height: '120px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  padding: 12,
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function StyledDropzone({
  selected,
  onSelect,
  className,
}: {
  selected: File | undefined
  onSelect: (file: File) => void
  className?: string
}) {
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      onSelect(acceptedFiles[0])
    },
    [onSelect],
  )
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ multiple: false, onDrop, accept: '.json' })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  return (
    <div className={['container', className ?? ''].join(' ')}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />

        {!selected && (
          <p>Drag and drop a firmware RegExpr file here, or click to select.</p>
        )}
        {selected && (
          <strong>
            {selected.name} ({(selected.size / 1000).toFixed(3)} kB)
          </strong>
        )}
      </div>
    </div>
  )
}

const UpdateFirmwareRegExprsDialog: FC<UpdateFirmwareRegExprsDialogProps> = (
  props,
) => {
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>(
    undefined,
  )
  const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined)

  const cache = useQueryCache()

  const [mutateUpdateFirmware, mutateUpdateFirmwareResult] = useMutation(
    (request: api.UpdateFirmwareMetadataRequest) =>
      api.updateFirmwareMetadata(props.jwtToken, request),
    {
      onSuccess: () => {
        setSelectedFile(undefined)
        setErrorMsg(undefined)
      },
      onSettled: () => {
        cache.invalidateQueries('firmware')
      },
    },
  )

  const { open, onClose } = props

  React.useEffect(() => {
    if (!open) {
      setSelectedFile(undefined)
      setErrorMsg(undefined)
      mutateUpdateFirmwareResult.reset()
    }
  }, [open, setSelectedFile])
  const status = undefined

  const add = React.useCallback(async () => {
    if (!selectedFile) {
      return
    }
    setErrorMsg(undefined)
    mutateUpdateFirmwareResult.reset()
    try {
      const file = await selectedFile.arrayBuffer()
      const decoder = new TextDecoder('utf-8')
      const decoded = JSON.parse(decoder.decode(file))
      await mutateUpdateFirmware(decoded)
    } catch (err) {
      setErrorMsg('Failed to read / parse JSON.')
    }
  }, [selectedFile])

  const close = React.useCallback(() => {
    if (status !== QueryStatus.Loading) {
      onClose()
    }
  }, [status, onClose])

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="update-firmware-metadata"
      >
        <DialogTitle id="update-firmware-metadata">
          Update firmware RegExprs
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the firmware serial number regex (.json) file.
          </DialogContentText>

          <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>
            <strong>Example:</strong>
            <br />
            {JSON.stringify(
              [
                {
                  family: 'ahu2020_hmi',
                  version: '1.0.0',
                  serialNumberRegexes: ['^.*$'],
                },
              ],
              undefined,
              3,
            )}
          </DialogContentText>

          <StyledDropzone selected={selectedFile} onSelect={setSelectedFile} />

          {mutateUpdateFirmwareResult.error || errorMsg ? (
            <DialogContentText color="error" style={{ marginTop: '8px' }}>
              <>
                {mutateUpdateFirmwareResult.error
                  ? ApiError.unknownErrorToMessage(
                      mutateUpdateFirmwareResult.error,
                    )
                  : null}
                {errorMsg ? errorMsg : null}
              </>
            </DialogContentText>
          ) : null}

          {mutateUpdateFirmwareResult.data && (
            <DialogContentText color="primary" style={{ marginTop: '8px' }}>
              Successfully updated{' '}
              {mutateUpdateFirmwareResult.data.updatedCount} firmwares.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            color="primary"
            disabled={status === QueryStatus.Loading}
          >
            Cancel
          </Button>
          <Button
            onClick={add}
            disabled={
              selectedFile === undefined || status === QueryStatus.Loading
            }
            color="primary"
          >
            {status === QueryStatus.Loading && <CircularProgress size={18} />}
            {status !== QueryStatus.Loading && 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UpdateFirmwareRegExprsDialog
