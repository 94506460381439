import React, { ElementType, FC } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import { dateTimeFormat } from '../dateTimeFormat'
import {
  QueryStatus,
  useInfiniteQuery,
  useMutation,
  useQueryCache,
} from 'react-query'
import * as FirmwareFamily from '../data/FirmwareFamily'
import * as ApiError from '../data/ApiError'
import CircularProgress from '@material-ui/core/CircularProgress'
import { OtaUpdateSession } from '../data/OtaUpdateSession'
import InfiniteListFooter from './InfiniteListFooter'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import { delayed } from '../delayed'
import Snackbar from '@material-ui/core/Snackbar'
import * as api from '../api'
import OtaStateLabel from './OtaStateLabel'
import DeleteIcon from '@material-ui/icons/Delete'
import RefreshIcon from '@material-ui/icons/RefreshOutlined'
import DeleteDialog from './DeleteDialog'

interface OtaUpdateTableComponentProps {
  readonly jwtToken: () => Promise<string>
  readonly thingId: string | undefined
  readonly version: string | undefined
  readonly states: readonly OtaUpdateSession['state'][]
  readonly limit: number
  readonly tableComponent: ElementType<unknown> | 'div'
  readonly tableSize?: 'small' | 'medium'
  readonly hideColumns?: 'thingId'[]
}

const OtaUpdateTableComponent: FC<OtaUpdateTableComponentProps> = ({
  jwtToken,
  thingId,
  version,
  states,
  limit,
  tableComponent,
  tableSize,
  hideColumns,
}) => {
  const [starting, setStarting] = React.useState<api.SessionIdAndThingId[]>([])
  const [startError, setStartError] = React.useState<boolean>(false)
  const [sessionToDelete, setSessionToDelete] = React.useState<
    OtaUpdateSession | undefined
  >(undefined)

  const cache = useQueryCache()

  const isStarting = React.useCallback(
    (session: OtaUpdateSession) => {
      return starting.some(
        (x) =>
          x.thingId === session.thingId && x.sessionId === session.sessionId,
      )
    },
    [starting],
  )

  const otaUpdateSessionsQuery = useInfiniteQuery(
    ['otaUpdate', states, thingId, version, limit],
    (
      key: string,
      states: OtaUpdateSession['state'][],
      thingId: string | undefined,
      version: string | undefined,
      limit: number,
      nextResultsToken: string | undefined,
    ) =>
      api.fetchOtaUpdateSessions(
        key,
        jwtToken,
        states,
        thingId,
        version,
        limit,
        nextResultsToken,
      ),
    {
      retry: false,
      enabled: true,
      getFetchMore: (last) => last?.nextResultsToken,
      staleTime: 1000 * 15,
    },
  )

  const [mutateDeleteSession, mutateDeleteSessionResult] = useMutation(
    (request: { sessionId: number; thingId: string }) =>
      api.deleteOtaUpdateSession(jwtToken ?? '', request),
    {
      onSuccess: () => {
        setSessionToDelete(undefined)
      },
      onSettled: () => {
        cache.invalidateQueries('otaUpdate')
      },
    },
  )

  const { status, data, error } = otaUpdateSessionsQuery

  let content: JSX.Element
  if (status === QueryStatus.Idle || status === QueryStatus.Loading) {
    content = <CircularProgress />
  } else if (status === QueryStatus.Error) {
    content = <div>{ApiError.unknownErrorToMessage(error)}</div>
  } else {
    content = (
      <React.Fragment>
        <TableContainer component={tableComponent}>
          <Table size={tableSize}>
            <TableHead>
              <TableRow>
                {hideColumns?.includes('thingId') !== true && (
                  <TableCell>Thing ID</TableCell>
                )}
                <TableCell>Session ID</TableCell>
                <TableCell>Firmware family</TableCell>
                <TableCell>Firmware version</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Created in</TableCell>
                <TableCell>Updated at</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.flatMap((x) => x.results)
                ?.map((session) => (
                  <TableRow key={`${session.thingId}-${session.sessionId}`}>
                    {hideColumns?.includes('thingId') !== true && (
                      <TableCell>{session.thingId}</TableCell>
                    )}
                    <TableCell>{session.sessionId}</TableCell>
                    <TableCell>
                      {FirmwareFamily.translate(session.firmwareFamily)}
                    </TableCell>
                    <TableCell>{session.firmwareVersion}</TableCell>
                    <TableCell>
                      <OtaStateLabel session={session} />
                    </TableCell>
                    <TableCell>
                      {session.createdByUserId ? 'Mobile app' : 'Admin app'}
                    </TableCell>
                    <TableCell>
                      {session.updatedAt
                        ? dateTimeFormat.format(Date.parse(session.updatedAt))
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {dateTimeFormat.format(Date.parse(session.createdAt))}
                    </TableCell>
                    <TableCell align="right" width={180}>
                      {['started', 'idle', 'in_progress'].includes(
                        session.state,
                      ) && (
                        <IconButton
                          onClick={() => otaUpdateSessionsQuery.refetch()}
                          disabled={otaUpdateSessionsQuery.isLoading}
                        >
                          <RefreshIcon />
                        </IconButton>
                      )}
                      <IconButton
                        disabled={
                          session.state !== 'created' || isStarting(session)
                        }
                        onClick={() => setSessionToDelete(session)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Tooltip title="Start update">
                        <span>
                          <IconButton
                            onClick={() => {
                              setStarting([...starting, session])
                              const promise = api.startOtaUpdateSession(
                                jwtToken ?? '',
                                {
                                  sessionId: session.sessionId,
                                  thingId: session.thingId,
                                },
                              )

                              delayed(promise, 8000)
                                .catch(() => {
                                  setStartError(true)
                                })
                                .finally(() => {
                                  setStarting(
                                    starting.filter(
                                      (x) =>
                                        x.thingId !== session.thingId &&
                                        x.sessionId !== session.sessionId,
                                    ),
                                  )
                                  otaUpdateSessionsQuery.refetch()
                                })
                            }}
                            disabled={
                              session.state !== 'created' || isStarting(session)
                            }
                          >
                            {isStarting(session) ? (
                              <CircularProgress size={24} />
                            ) : (
                              <PlayIcon />
                            )}
                            {}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <InfiniteListFooter {...otaUpdateSessionsQuery} />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={startError}
          autoHideDuration={3000}
          onClose={() => setStartError(false)}
          message={<span>Failed to start update</span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={() => setStartError(false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <DeleteDialog
          title={`Delete session ${
            sessionToDelete ? sessionToDelete.sessionId : ''
          } with version ${sessionToDelete?.firmwareVersion}?`}
          description={'The session will be deleted permanently.'}
          open={sessionToDelete !== undefined}
          deleteResult={mutateDeleteSessionResult}
          onDelete={() =>
            sessionToDelete && mutateDeleteSession(sessionToDelete)
          }
          onClose={() => setSessionToDelete(undefined)}
        />
      </React.Fragment>
    )
  }

  return <div>{content}</div>
}

export default OtaUpdateTableComponent
