import React, { FC } from 'react'
import StateLabel from './StateLabel'
import * as color from '../color'
import { OtaUpdateSession, translateState } from '../data/OtaUpdateSession'

function otaSessionStateColor(state: OtaUpdateSession['state']): string {
  switch (state) {
    case 'created':
      return color.gray
    case 'started':
      return color.swegonBlue
    case 'idle':
      return color.swegonBlue
    case 'in_progress':
      return color.swegonOrange
    case 'success':
      return color.swegonGreen
    case 'failure':
      return color.warning
  }
}

type Props = { session: OtaUpdateSession }

const OtaStateLabel: FC<Props> = ({ session }) => (
  <StateLabel
    circleColor={otaSessionStateColor(session.state)}
    label={`${translateState(session.state)}${
      session.state === 'failure' ? ` (${session.error})` : ''
    }${session.state === 'in_progress' ? ` (${session.progress}%)` : ''}`}
  />
)

export default OtaStateLabel
