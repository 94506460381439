import React, { FC, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MutationResult, QueryStatus } from 'react-query'
import * as ApiError from '../data/ApiError'
import { versionRegex } from '../data/Firmware'
import { FirmwareFamily } from '../data/FirmwareFamily'
import RegExprsInputList from './RegExprsInputList'
import { Typography } from '@material-ui/core'

type AddFirmwareDialogProps = {
  readonly open: boolean
  readonly onAdd: (request: {
    family: FirmwareFamily
    version: string
    file: File
    serialNumberRegexes: string[]
  }) => void
  readonly onClose: () => void
  readonly addResult: MutationResult<Response, unknown>
  readonly existingVersions: Set<string>
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginBottom: theme.spacing(2),
    width: '150px',
  },
  versionInputField: {
    marginLeft: theme.spacing(1),
  },
  serialNumberRegexTitle: {
    marginTop: theme.spacing(6),
  },
  space: {
    padding: theme.spacing(4),
  },
}))

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  height: '120px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function StyledDropzone({
  selected,
  onSelect,
  className,
}: {
  selected: File | undefined
  onSelect: (file: File) => void
  className?: string
}) {
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      onSelect(acceptedFiles[0])
    },
    [onSelect],
  )
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ multiple: false, onDrop, accept: '.bin' })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  return (
    <div className={['container', className ?? ''].join(' ')}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />

        {!selected && (
          <p>Drag and drop a firmware file here, or click to select.</p>
        )}
        {selected && (
          <strong>
            {selected.name} ({(selected.size / 1000).toFixed(3)} kB)
          </strong>
        )}
      </div>
    </div>
  )
}

const AddFirmwareDialog: FC<AddFirmwareDialogProps> = (props) => {
  const classes = useStyles()
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>(
    undefined,
  )
  const [versionBlurred, setVersionBlurred] = React.useState(false)
  const [version, setVersion] = React.useState('')
  const [family, setFamily] = React.useState<FirmwareFamily>('ahu2020_hmi')
  const [serialNumberRegexes, setSerialNumberRegexes] = React.useState<
    string[]
  >([''])
  const { open, onClose, onAdd } = props

  React.useEffect(() => {
    if (!open && version !== '') {
      setVersion('')
      setFamily('ahu2020_hmi')
      setSelectedFile(undefined)
      setVersionBlurred(false)
      setSerialNumberRegexes([''])
    }
  }, [open, version, setVersion, setFamily, setSelectedFile, setVersionBlurred])

  const add = React.useCallback(() => {
    if (version.length > 0 && family.length > 0 && selectedFile !== undefined) {
      onAdd({
        version,
        family,
        file: selectedFile,
        serialNumberRegexes: serialNumberRegexes
          .map((regex) => regex.trim())
          .filter((regex) => regex.length > 0),
      })
    }
  }, [version, family, selectedFile, onAdd])

  const { status, error } = props.addResult

  const close = React.useCallback(() => {
    if (status !== QueryStatus.Loading) {
      onClose()
    }
  }, [status, onClose])

  const versionAlreadyExists = props.existingVersions.has(version)
  const versionValid = version.length === 0 || versionRegex.test(version)

  let versionError: string | undefined
  if (!versionBlurred) {
    versionError = undefined
  } else if (versionAlreadyExists) {
    versionError = 'Version already exists'
  } else if (!versionValid) {
    versionError = 'Invalid version. Should follow Semantic Versioning 2.0.0.'
  }

  return (
    <div>
      <Dialog open={props.open} onClose={close} aria-labelledby="add-firmware">
        <DialogTitle id="add-firmware">Add firmware</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add firmware enter the version number and select the firmware
            file.
          </DialogContentText>

          <FormControl className={classes.inputField}>
            <InputLabel id="family-select-label">Family *</InputLabel>
            <Select
              labelId="family-select-label"
              value={family}
              onChange={(e) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setFamily(e.target.value as any as FirmwareFamily)
              }
            >
              <MenuItem value={'ahu2020_hmi'}>AHU2020 HMI</MenuItem>
            </Select>
          </FormControl>

          <TextField
            className={`${classes.inputField} ${classes.versionInputField}`}
            autoFocus
            id="version"
            label="Version *"
            onBlur={() => setVersionBlurred(true)}
            error={versionError !== undefined}
            helperText={versionError ?? ''}
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />

          <Typography
            variant="body1"
            className={classes.serialNumberRegexTitle}
          >
            Serial number RegExprs *
          </Typography>

          <RegExprsInputList
            value={serialNumberRegexes}
            onChange={setSerialNumberRegexes}
          />

          <div className={classes.space} />

          <StyledDropzone selected={selectedFile} onSelect={setSelectedFile} />

          {status === QueryStatus.Error && (
            <DialogContentText color="error" style={{ marginTop: '8px' }}>
              {ApiError.unknownErrorToMessage(error)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            color="primary"
            disabled={status === QueryStatus.Loading}
          >
            Cancel
          </Button>
          <Button
            onClick={add}
            disabled={
              selectedFile === undefined ||
              status === QueryStatus.Loading ||
              versionAlreadyExists ||
              !versionValid ||
              version.length === 0 ||
              family.length === 0 ||
              serialNumberRegexes.length === 0 ||
              serialNumberRegexes[0].length === 0
            }
            color="primary"
          >
            {status === QueryStatus.Loading && <CircularProgress size={18} />}
            {status !== QueryStatus.Loading && 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddFirmwareDialog
