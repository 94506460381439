import React, { FC } from 'react'

import styled from 'styled-components'
import * as color from '../color'

const StyledLabel = styled.div<{ circleColor: string }>`
  color: ${color.gray};
  :before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 8px;
    background-color: ${(props) => props.circleColor};
  }
`
type StateLabelProps = {
  circleColor: string
  label: string | JSX.Element
}

const StateLabel: FC<StateLabelProps> = (props) => (
  <StyledLabel circleColor={props.circleColor}>{props.label}</StyledLabel>
)

export default StateLabel
