import React, { FC, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { QueryStatus, useInfiniteQuery } from 'react-query'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDebounce } from '../hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import Checkbox from '@material-ui/core/Checkbox'
import * as ThingState from '../data/ThingState'
import InfiniteListFooter from '../components/InfiniteListFooter'
import Divider from '@material-ui/core/Divider'
import * as ApiError from '../data/ApiError'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import MaterialLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { dateTimeFormat } from '../dateTimeFormat'
import * as color from '../color'
import StateLabel from '../components/StateLabel'
import { Link } from 'react-router-dom'
import { RoutePath } from '../RoutePath'
import * as api from '../api'
import ExportCsv from '../components/ExportCsv'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { RegexIcon } from '../components/RegexIcon'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
      width: 280,
    },
  },
  table: {
    minWidth: 650,
  },
  card: {
    padding: theme.spacing(4),
  },
}))

type Props = {
  jwtToken: () => Promise<string>
}

const Things: FC<Props> = ({ jwtToken }) => {
  const classes = useStyles()
  const [searchTermRegex, setSearchTermRegex] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [states, setStates] = React.useState<ThingState.ThingState[]>([
    'in_use',
  ])
  const [version, setVersion] = React.useState('')
  const [setupFinished, setSetupFinished] = React.useState<boolean>(true)
  const debouncedSearchTerm = useDebounce(searchTerm, 600)
  const debouncedVersion = useDebounce(version, 600)
  const [selected, setSelected] = React.useState(new Set<string>())

  useEffect(() => {
    setSelected(new Set())
  }, [debouncedSearchTerm, states, setupFinished])

  const thingsQuery = useInfiniteQuery(
    [
      'things',
      debouncedSearchTerm,
      searchTermRegex,
      states,
      setupFinished,
      debouncedVersion,
    ],
    (
      key: string,
      searchTerm: string,
      searchTermRegex: boolean,
      states: ThingState.ThingState[],
      setupFinished: boolean,
      version: string,
      nextResultsToken: string | undefined,
    ) =>
      api.getThings(
        jwtToken,
        searchTerm,
        searchTermRegex,
        states,
        setupFinished,
        version.length === 0 ? undefined : version,
        nextResultsToken,
      ),
    {
      retry: false,
      enabled: true,
      getFetchMore: (last) => last?.nextResultsToken,
      staleTime: 1000 * 30,
    },
  )

  const { status, data, error } = thingsQuery

  const things = data?.flatMap((x) => x.results) ?? []

  const header = (
    <React.Fragment>
      <div className={classes.header}>
        <TextField
          label="Search by thing ID or serial numbers"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Use Regular Expression">
                  <IconButton
                    size="small"
                    style={{
                      borderRadius: 4,
                      backgroundColor: searchTermRegex
                        ? 'var(--color-primary)'
                        : '#e9e9e9',
                      color: searchTermRegex ? '#fff' : '#444444',
                    }}
                    aria-label="toggle regex"
                    onClick={() => {
                      setSearchTermRegex(!searchTermRegex)
                    }}
                  >
                    <RegexIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <FormControl>
          <InputLabel id="thing-state-selection">Filter by state</InputLabel>
          <Select
            labelId="thing-state-selection"
            multiple
            value={states}
            onChange={(e) =>
              setStates(e.target.value as ThingState.ThingState[])
            }
            renderValue={() => states.map(ThingState.translate).join(', ')}
          >
            {(
              ['registered', 'in_use', 'deactivated'] as ThingState.ThingState[]
            ).map((name) => (
              <MenuItem key={name} value={name}>
                {ThingState.translate(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <TextField
            label="Filter by AHU software version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            helperText={
              !searchTermRegex &&
              (version.includes('%') || version.includes('_'))
                ? 'Exact match mode. Use % for matching any sequence of characters, and _ for matching any single character.'
                : undefined
            }
          />
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={setupFinished}
              onChange={(e, checked) => setSetupFinished(checked)}
            />
          }
          label="Setup finished"
        />
      </div>
      <Divider variant="fullWidth" orientation="horizontal" />
    </React.Fragment>
  )

  let content: JSX.Element
  if (status === QueryStatus.Idle || status === QueryStatus.Loading) {
    content = <CircularProgress />
  } else if (
    status === QueryStatus.Error ||
    (data !== undefined && !Array.isArray(data[0]?.results))
  ) {
    content = <div>{ApiError.unknownErrorToMessage(error)}</div>
  } else {
    const selectedThings = things.filter((thing) => selected.has(thing.id))
    const [firstSelected, ...restSelected] = selectedThings
    content = (
      <div>
        {firstSelected && (
          <Card
            className={classes.card}
            style={{
              position: 'fixed',
              bottom: 24,
              right: 24,
            }}
          >
            <ExportCsv
              title={`Export alarms (${selected.size} selected, max 100)`}
              disabled={selected.size > 100}
              filePrefix={`all_alarms`}
              doExport={(args) => {
                return api.exportAlarmsCsvFromMultipleThings(jwtToken, {
                  thingIds: [
                    firstSelected.id,
                    ...restSelected.map((x) => x.id),
                  ],
                  startTime: args.startTime,
                  endTime: args.endTime,
                  zoneId: args.zoneId,
                })
              }}
            />
          </Card>
        )}
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    checked={
                      selected.size ===
                        data?.flatMap((x) => x.results)?.length ?? 0
                    }
                    onChange={(e, checked) => {
                      if (checked) {
                        setSelected(
                          new Set(
                            data
                              ?.flatMap((x) => x.results)
                              ?.map((thing) => thing.id) ?? [],
                          ),
                        )
                      } else {
                        setSelected(new Set())
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Thing ID</TableCell>
                <TableCell>AHU serial number</TableCell>
                <TableCell>AHU description</TableCell>
                <TableCell>AHU software version</TableCell>
                <TableCell>HMI serial number</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Setup finished</TableCell>
                <TableCell>Latest handshake at</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.flatMap((x) => x.results)
                ?.map((thing) => (
                  <React.Fragment key={thing.id}>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={selected.has(thing.id)}
                          onChange={(e, checked) => {
                            if (checked) {
                              const newSelected = new Set(selected)
                              newSelected.add(thing.id)
                              setSelected(newSelected)
                            } else {
                              const newSelected = new Set(selected)
                              newSelected.delete(thing.id)
                              setSelected(newSelected)
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link
                          component={MaterialLink}
                          to={RoutePath.thingDetails.replace(
                            ':thingId',
                            thing.id,
                          )}
                        >
                          {thing.id}
                        </Link>
                      </TableCell>
                      <TableCell>{thing.ahuSerialNumber ?? '-'}</TableCell>
                      <TableCell>{thing.ahuName ?? '-'}</TableCell>
                      <TableCell>{thing.ahuSwVersion ?? '-'}</TableCell>
                      <TableCell>{thing.serialNumber}</TableCell>
                      <TableCell>
                        <StateLabel
                          label={ThingState.translate(thing.state)}
                          circleColor={color.thingStateToColor(thing.state)}
                        />
                      </TableCell>
                      <TableCell>
                        <StateLabel
                          label={thing.setupFinished ? 'Yes' : 'No'}
                          circleColor={
                            thing.setupFinished ? color.swegonGreen : color.gray
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {thing.latestHandshake
                          ? dateTimeFormat.format(
                              Date.parse(thing.latestHandshake),
                            )
                          : 'n/a'}
                      </TableCell>
                      <TableCell>
                        {dateTimeFormat.format(Date.parse(thing.createdAt))}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <InfiniteListFooter {...thingsQuery} />
      </div>
    )
  }
  return (
    <div>
      {header}
      {content}
    </div>
  )
}

export default Things
