import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import * as ApiError from '../data/ApiError'
import Typography from '@material-ui/core/Typography'
import { DateTimePicker } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  exportCsvError: {
    marginBottom: theme.spacing(3),
  },
}))

const ExportCsv: FC<{
  title: string
  filePrefix: string
  doExport: (args: {
    startTime: string
    endTime: string | undefined
    zoneId: string | undefined
  }) => Promise<Response>
  onCompleted?: () => void
  disabled?: boolean
}> = ({ title, filePrefix, doExport, onCompleted, disabled }) => {
  const [selectedStartDate, handleStartDateChange] = React.useState(new Date())
  const [selectedEndDate, handleEndDateChange] = React.useState<Date | null>(
    null,
  )
  const [exportCsvError, setExportCsvError] = React.useState<
    string | undefined
  >(undefined)
  const [progress, setProgress] = React.useState<string | undefined>(undefined)
  const browserSupportsExport = Boolean(window.showSaveFilePicker)

  const classes = useStyles()

  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className={classes.title}
      >
        {title}
      </Typography>

      <DateTimePicker
        autoOk
        ampm={false}
        value={selectedStartDate}
        onChange={(d) => {
          if (d) {
            handleStartDateChange(d)
          }
        }}
        clearable={false}
        label="Start time *"
        style={{ marginBottom: '24px' }}
      />

      <DateTimePicker
        autoOk
        ampm={false}
        value={selectedEndDate}
        onChange={handleEndDateChange}
        minDate={selectedStartDate}
        clearable={true}
        label="End time"
        style={{ marginBottom: '24px' }}
      />

      <Button
        color="primary"
        style={{ marginLeft: '16px' }}
        disabled={progress !== undefined || !browserSupportsExport || disabled}
        onClick={() => {
          async function saveToFile() {
            try {
              const suggestedName = `${filePrefix}_export_${selectedStartDate.toISOString()}${
                selectedEndDate ? `_to_${selectedEndDate.toISOString()}` : ''
              }.csv`
              const writable = await showSaveFilePicker({ suggestedName })

              setProgress(bytesToSizeString(0))
              setExportCsvError(undefined)

              const res = await doExport({
                startTime: selectedStartDate.toISOString(),
                endTime: selectedEndDate?.toISOString() ?? undefined,
                zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })

              const reader = res.body?.getReader()
              if (!reader) {
                throw new Error('Reader undefined')
              }

              let bytesSoFar = 0
              const streamNextChunk = async () => {
                const { value, done } = await reader.read()
                if (done) {
                  await writable.close()
                  if (onCompleted) {
                    onCompleted()
                  }
                  return
                }

                bytesSoFar += value?.byteLength ?? 0
                const progressString = bytesToSizeString(bytesSoFar)
                if (progress !== progressString) {
                  setProgress(progressString)
                }

                if (value) {
                  await writable.write(value)
                }

                await streamNextChunk()
              }

              await streamNextChunk()
            } catch (err: unknown) {
              if (err instanceof ApiError.NotFound) {
                setExportCsvError('No data for given time period.')
              } else if (ApiError.isApiError(err)) {
                setExportCsvError(ApiError.toMessage(err))
              } else {
                console.error(err)
                setExportCsvError('Unknown error')
              }
            } finally {
              setProgress(undefined)
            }
          }
          saveToFile()
        }}
      >
        {progress !== undefined ? `Exporting ${progress}...` : 'Export csv'}
      </Button>
      <div className={classes.exportCsvError}>
        {!browserSupportsExport &&
          'CSV export not supported by this browser. Please use Chrome, Opera, or Edge.'}
        {exportCsvError ? exportCsvError : ''}
      </div>
    </div>
  )
}

function bytesToSizeString(bytes: number) {
  const kiloBytes = bytes / 1000
  if (kiloBytes < 1000) {
    return `${kiloBytes.toFixed(1)} KB`
  }
  const megaBytes = kiloBytes / 1000
  return `${megaBytes.toFixed(1)} MB`
}

async function showSaveFilePicker({
  suggestedName,
}: {
  suggestedName: string
}) {
  if (!window.showSaveFilePicker) {
    throw new Error('Browser not supported, please use Chrome, Edge, or Opera.')
  }

  const filehandle = await window.showSaveFilePicker({
    suggestedName,
    types: [{ accept: { 'text/csv': ['.csv'] } }],
  })

  return filehandle.createWritable()
}

export default ExportCsv
