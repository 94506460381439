(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.by.aB === region.bV.aB)
	{
		return 'on line ' + region.by.aB;
	}
	return 'on lines ' + region.by.aB + ' through ' + region.bV.aB;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dB,
		impl.eo,
		impl.ee,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		V: func(record.V),
		bA: record.bA,
		bv: record.bv
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.V;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bA;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bv) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dB,
		impl.eo,
		impl.ee,
		function(sendToApp, initialModel) {
			var view = impl.er;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dB,
		impl.eo,
		impl.ee,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bx && impl.bx(sendToApp)
			var view = impl.er;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.dc);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cY) && (_VirtualDom_doc.title = title = doc.cY);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dZ;
	var onUrlRequest = impl.d_;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bx: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cx === next.cx
							&& curr.b3 === next.b3
							&& curr.cq.a === next.cq.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dB: function(flags)
		{
			return A3(impl.dB, flags, _Browser_getUrl(), key);
		},
		er: impl.er,
		eo: impl.eo,
		ee: impl.ee
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dw: 'hidden', df: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dw: 'mozHidden', df: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dw: 'msHidden', df: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dw: 'webkitHidden', df: 'webkitvisibilitychange' }
		: { dw: 'hidden', df: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cK: _Browser_getScene(),
		c2: {
			eu: _Browser_window.pageXOffset,
			ev: _Browser_window.pageYOffset,
			aR: _Browser_doc.documentElement.clientWidth,
			du: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		aR: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		du: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cK: {
				aR: node.scrollWidth,
				du: node.scrollHeight
			},
			c2: {
				eu: node.scrollLeft,
				ev: node.scrollTop,
				aR: node.clientWidth,
				du: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cK: _Browser_getScene(),
			c2: {
				eu: x,
				ev: y,
				aR: _Browser_doc.documentElement.clientWidth,
				du: _Browser_doc.documentElement.clientHeight
			},
			bU: {
				eu: x + rect.left,
				ev: y + rect.top,
				aR: rect.width,
				du: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.i) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.k),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.k);
		} else {
			var treeLen = builder.i * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.l) : builder.l;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.i);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.k) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.k);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{l: nodeList, i: (len / $elm$core$Array$branchFactor) | 0, k: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {b$: fragment, b3: host, co: path, cq: port_, cx: protocol, cy: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Translate$Key$Dynamic = function (a) {
	return {$: 1, a: a};
};
var $author$project$Language$English = 0;
var $author$project$Main$GetRootElement = {$: 7};
var $author$project$Main$InfoContent = function (a) {
	return {$: 0, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$Config = $elm$core$Basics$identity;
var $Gizra$elm_debouncer$Debouncer$Internal$accumulateWith = F2(
	function (accumulator, _v0) {
		var config = _v0;
		return {Z: accumulator, I: config.I, J: config.J, P: config.P};
	});
var $Gizra$elm_debouncer$Debouncer$Basic$accumulateWith = $Gizra$elm_debouncer$Debouncer$Internal$accumulateWith;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $Gizra$elm_debouncer$Debouncer$Internal$allInputs = F2(
	function (i, o) {
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$List$cons,
				i,
				A2($elm$core$Maybe$withDefault, _List_Nil, o)));
	});
var $Gizra$elm_debouncer$Debouncer$Basic$allInputs = $Gizra$elm_debouncer$Debouncer$Internal$allInputs;
var $Gizra$elm_debouncer$Debouncer$Internal$lastInput = F2(
	function (i, o) {
		return $elm$core$Maybe$Just(i);
	});
var $Gizra$elm_debouncer$Debouncer$Internal$manual = {Z: $Gizra$elm_debouncer$Debouncer$Internal$lastInput, I: $elm$core$Maybe$Nothing, J: $elm$core$Maybe$Nothing, P: $elm$core$Maybe$Nothing};
var $Gizra$elm_debouncer$Debouncer$Basic$manual = $Gizra$elm_debouncer$Debouncer$Internal$manual;
var $Gizra$elm_debouncer$Debouncer$Internal$settleWhenQuietFor = F2(
	function (time, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{P: time});
	});
var $Gizra$elm_debouncer$Debouncer$Basic$settleWhenQuietFor = $Gizra$elm_debouncer$Debouncer$Internal$settleWhenQuietFor;
var $Gizra$elm_debouncer$Debouncer$Basic$debounce = function (interval) {
	return A2(
		$Gizra$elm_debouncer$Debouncer$Basic$settleWhenQuietFor,
		$elm$core$Maybe$Just(interval),
		$Gizra$elm_debouncer$Debouncer$Basic$manual);
};
var $pzp1997$assoc_list$AssocList$D = $elm$core$Basics$identity;
var $pzp1997$assoc_list$AssocList$empty = _List_Nil;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Basics$round = _Basics_round;
var $Gizra$elm_debouncer$Debouncer$Internal$fromSeconds = function (s) {
	return $elm$core$Basics$round(s * 1000);
};
var $Gizra$elm_debouncer$Debouncer$Basic$fromSeconds = $Gizra$elm_debouncer$Debouncer$Internal$fromSeconds;
var $mdgriffith$elm_animator$Internal$Timeline$Timeline = $elm$core$Basics$identity;
var $mdgriffith$elm_animator$Internal$Timeline$Timetable = $elm$core$Basics$identity;
var $ianmackenzie$elm_units$Quantity$Quantity = $elm$core$Basics$identity;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $mdgriffith$elm_animator$Internal$Time$absolute = function (posix) {
	return $elm$time$Time$posixToMillis(posix);
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $mdgriffith$elm_animator$Animator$init = function (first) {
	return {
		dn: _List_Nil,
		b6: first,
		aA: _List_Nil,
		cm: $mdgriffith$elm_animator$Internal$Time$absolute(
			$elm$time$Time$millisToPosix(0)),
		aF: $elm$core$Maybe$Nothing,
		a6: true
	};
};
var $author$project$RequestId$RequestId = $elm$core$Basics$identity;
var $author$project$RequestId$init = 0;
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $Gizra$elm_debouncer$Debouncer$Internal$Debouncer = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $Gizra$elm_debouncer$Debouncer$Internal$Settled = {$: 0};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative = $elm$core$Maybe$andThen(
	function (num) {
		return (num < 0) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(num);
	});
var $Gizra$elm_debouncer$Debouncer$Internal$sanitizeConfig = function (_v0) {
	var config = _v0;
	return {
		Z: config.Z,
		I: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.I),
		J: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.J),
		P: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.P)
	};
};
var $Gizra$elm_debouncer$Debouncer$Internal$toDebouncer = function (config) {
	return A2(
		$Gizra$elm_debouncer$Debouncer$Internal$Debouncer,
		$Gizra$elm_debouncer$Debouncer$Internal$sanitizeConfig(config),
		$Gizra$elm_debouncer$Debouncer$Internal$Settled);
};
var $Gizra$elm_debouncer$Debouncer$Basic$toDebouncer = $Gizra$elm_debouncer$Debouncer$Internal$toDebouncer;
var $author$project$Translate$Key$ErrorConnectionLostMessage = 5;
var $author$project$Translate$Key$ErrorConnectionLostTitle = 6;
var $author$project$Main$ErrorContent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Translate$Key$ErrorOutOfSyncMessage = 3;
var $author$project$Translate$Key$ErrorOutOfSyncTitle = 4;
var $author$project$Main$Failed = 1;
var $author$project$Request$Fatal = 0;
var $author$project$Main$GotNavigationMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$GotParameterValueChanges = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$GotResponse = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotRootElement = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$LanguageSelectorContent = {$: 3};
var $author$project$Node$Link$LinkActionQuit = {$: 3};
var $author$project$Main$LinkPressed = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$LoadParameters = function (a) {
	return {$: 13, a: a};
};
var $author$project$Request$Log = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Main$MsgDebounce = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$Pending = 0;
var $author$project$Request$Quit = {$: 3};
var $author$project$Request$ReadRegistersRequest = function (a) {
	return {$: 1, a: a};
};
var $author$project$Request$SendCommissioningData = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$SendRequest = function (a) {
	return {$: 8, a: a};
};
var $author$project$Translate$Key$Static = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$WriteRegisterMsg = $elm$core$Basics$identity;
var $author$project$Main$WriteRegisters = function (a) {
	return {$: 3, a: a};
};
var $author$project$Request$WriteRegistersRequest = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_animator$Internal$Timeline$Animator = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $mdgriffith$elm_animator$Animator$animator = A2(
	$mdgriffith$elm_animator$Internal$Timeline$Animator,
	$elm$core$Basics$always(false),
	F2(
		function (now, model) {
			return model;
		}));
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $mdgriffith$elm_animator$Internal$Timeline$hasChanged = function (_v0) {
	var timeline = _v0;
	var _v1 = timeline.aF;
	if (_v1.$ === 1) {
		var _v2 = timeline.aA;
		if (!_v2.b) {
			return false;
		} else {
			return true;
		}
	} else {
		return true;
	}
};
var $mdgriffith$elm_animator$Internal$Timeline$justInitialized = function (_v0) {
	var timeline = _v0;
	var _v1 = timeline.cm;
	var qty = _v1;
	return !qty;
};
var $mdgriffith$elm_animator$Internal$Timeline$Line = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_animator$Internal$Timeline$Occurring = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $ianmackenzie$elm_units$Duration$inSeconds = function (_v0) {
	var numSeconds = _v0;
	return numSeconds;
};
var $ianmackenzie$elm_units$Duration$inMilliseconds = function (duration) {
	return $ianmackenzie$elm_units$Duration$inSeconds(duration) * 1000;
};
var $ianmackenzie$elm_units$Quantity$plus = F2(
	function (_v0, _v1) {
		var y = _v0;
		var x = _v1;
		return x + y;
	});
var $mdgriffith$elm_animator$Internal$Time$advanceBy = F2(
	function (dur, time) {
		return A2(
			$ianmackenzie$elm_units$Quantity$plus,
			time,
			$ianmackenzie$elm_units$Duration$inMilliseconds(dur));
	});
var $mdgriffith$elm_animator$Internal$Time$latest = F2(
	function (oneQty, twoQty) {
		var one = oneQty;
		var two = twoQty;
		return ((one - two) <= 0) ? twoQty : oneQty;
	});
var $mdgriffith$elm_animator$Internal$Timeline$startTime = function (_v0) {
	var time = _v0.b;
	return time;
};
var $elm$core$Basics$ge = _Utils_ge;
var $mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat = F2(
	function (_v0, _v1) {
		var _this = _v0;
		var that = _v1;
		return (_this - that) >= 0;
	});
var $mdgriffith$elm_animator$Internal$Time$thisAfterThat = F2(
	function (_v0, _v1) {
		var _this = _v0;
		var that = _v1;
		return (_this - that) > 0;
	});
var $mdgriffith$elm_animator$Internal$Timeline$toOccurring = F2(
	function (_v0, _v1) {
		var duration = _v0.a;
		var event = _v0.b;
		var maybeDwell = _v0.c;
		var now = _v1.a;
		var events = _v1.b;
		var occursAt = A2($mdgriffith$elm_animator$Internal$Time$advanceBy, duration, now);
		var endsAt = function () {
			if (maybeDwell.$ === 1) {
				return occursAt;
			} else {
				var dwell = maybeDwell.a;
				return A2($mdgriffith$elm_animator$Internal$Time$advanceBy, dwell, occursAt);
			}
		}();
		if (!events.b) {
			return _Utils_Tuple2(
				endsAt,
				_List_fromArray(
					[
						A3($mdgriffith$elm_animator$Internal$Timeline$Occurring, event, occursAt, endsAt)
					]));
		} else {
			var prev = events.a;
			var remain = events.b;
			return _Utils_eq(
				$mdgriffith$elm_animator$Internal$Timeline$startTime(prev),
				occursAt) ? _Utils_Tuple2(
				endsAt,
				A2(
					$elm$core$List$cons,
					A3($mdgriffith$elm_animator$Internal$Timeline$Occurring, event, occursAt, endsAt),
					remain)) : _Utils_Tuple2(
				endsAt,
				A2(
					$elm$core$List$cons,
					A3($mdgriffith$elm_animator$Internal$Timeline$Occurring, event, occursAt, endsAt),
					events));
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$addEventsToLine = F3(
	function (now, _v0, _v1) {
		var delay = _v0.a;
		var scheduledStartingEvent = _v0.b;
		var reverseQueued = _v0.c;
		var startLineAt = _v1.a;
		var startingEvent = _v1.b;
		var events = _v1.c;
		var start = A2($mdgriffith$elm_animator$Internal$Time$advanceBy, delay, now);
		var reversedEvents = $elm$core$List$reverse(events);
		var queued = A2(
			$elm$core$List$cons,
			scheduledStartingEvent,
			$elm$core$List$reverse(reverseQueued));
		if (!reversedEvents.b) {
			var startingEventWithDwell = function () {
				var ev = startingEvent.a;
				var lastEventTime = startingEvent.b;
				return A2($mdgriffith$elm_animator$Internal$Time$thisAfterThat, start, lastEventTime) ? A3($mdgriffith$elm_animator$Internal$Timeline$Occurring, ev, lastEventTime, start) : A3($mdgriffith$elm_animator$Internal$Timeline$Occurring, ev, lastEventTime, lastEventTime);
			}();
			var startNewEventsAt = A2(
				$mdgriffith$elm_animator$Internal$Time$latest,
				$mdgriffith$elm_animator$Internal$Timeline$startTime(startingEvent),
				start);
			return A3(
				$mdgriffith$elm_animator$Internal$Timeline$Line,
				startLineAt,
				startingEventWithDwell,
				$elm$core$List$reverse(
					A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_animator$Internal$Timeline$toOccurring,
						_Utils_Tuple2(startNewEventsAt, _List_Nil),
						queued).b));
		} else {
			var _v4 = reversedEvents.a;
			var lastEvent = _v4.a;
			var lastEventTime = _v4.b;
			var eventTail = reversedEvents.b;
			var startNewEventsAt = A2($mdgriffith$elm_animator$Internal$Time$latest, lastEventTime, start);
			var newEvents = $elm$core$List$reverse(
				A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_animator$Internal$Timeline$toOccurring,
					_Utils_Tuple2(startNewEventsAt, _List_Nil),
					queued).b);
			if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, start, lastEventTime)) {
				var newLastEvent = A3(
					$mdgriffith$elm_animator$Internal$Timeline$Occurring,
					lastEvent,
					lastEventTime,
					A2($mdgriffith$elm_animator$Internal$Time$thisAfterThat, start, lastEventTime) ? start : lastEventTime);
				return A3(
					$mdgriffith$elm_animator$Internal$Timeline$Line,
					startLineAt,
					startingEvent,
					_Utils_ap(
						$elm$core$List$reverse(
							A2($elm$core$List$cons, newLastEvent, eventTail)),
						newEvents));
			} else {
				return A3(
					$mdgriffith$elm_animator$Internal$Timeline$Line,
					startLineAt,
					startingEvent,
					_Utils_ap(events, newEvents));
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$createLine = F2(
	function (now, _v0) {
		var _v1 = _v0.b;
		var dur = _v1.a;
		var startEvent = _v1.b;
		var maybeDwell = _v1.c;
		var reverseQueued = _v0.c;
		var start = A2($mdgriffith$elm_animator$Internal$Time$advanceBy, dur, now);
		var startNextEvent = function () {
			if (maybeDwell.$ === 1) {
				return start;
			} else {
				var dwell = maybeDwell.a;
				return A2($mdgriffith$elm_animator$Internal$Time$advanceBy, dwell, start);
			}
		}();
		var events = $elm$core$List$reverse(
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_animator$Internal$Timeline$toOccurring,
				_Utils_Tuple2(startNextEvent, _List_Nil),
				$elm$core$List$reverse(reverseQueued)).b);
		return A3(
			$mdgriffith$elm_animator$Internal$Timeline$Line,
			now,
			A3($mdgriffith$elm_animator$Internal$Timeline$Occurring, startEvent, start, startNextEvent),
			events);
	});
var $mdgriffith$elm_animator$Internal$Timeline$addToCurrentLine = F3(
	function (now, scheduled, lines) {
		var onCurrent = function (timelines) {
			if (!timelines.b) {
				return _List_fromArray(
					[
						A2($mdgriffith$elm_animator$Internal$Timeline$createLine, now, scheduled)
					]);
			} else {
				if (!timelines.b.b) {
					var _v1 = timelines.a;
					var startOne = _v1.a;
					var startEvent = _v1.b;
					var one = _v1.c;
					return _List_fromArray(
						[
							A3(
							$mdgriffith$elm_animator$Internal$Timeline$addEventsToLine,
							now,
							scheduled,
							A3($mdgriffith$elm_animator$Internal$Timeline$Line, startOne, startEvent, one))
						]);
				} else {
					var _v2 = timelines.a;
					var startOne = _v2.a;
					var startEventOne = _v2.b;
					var one = _v2.c;
					var _v3 = timelines.b;
					var _v4 = _v3.a;
					var startTwo = _v4.a;
					var startEventTwo = _v4.b;
					var two = _v4.c;
					var remaining = _v3.b;
					return (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, now, startOne) && A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, startTwo, now)) ? A2(
						$elm$core$List$cons,
						A3(
							$mdgriffith$elm_animator$Internal$Timeline$addEventsToLine,
							now,
							scheduled,
							A3($mdgriffith$elm_animator$Internal$Timeline$Line, startOne, startEventOne, one)),
						A2(
							$elm$core$List$cons,
							A3($mdgriffith$elm_animator$Internal$Timeline$Line, startTwo, startEventTwo, two),
							remaining)) : A2(
						$elm$core$List$cons,
						A3($mdgriffith$elm_animator$Internal$Timeline$Line, startOne, startEventOne, one),
						onCurrent(
							A2(
								$elm$core$List$cons,
								A3($mdgriffith$elm_animator$Internal$Timeline$Line, startTwo, startEventTwo, two),
								remaining)));
				}
			}
		};
		return onCurrent(lines);
	});
var $mdgriffith$elm_animator$Internal$Timeline$enqueue = F3(
	function (timeline, now, scheduled) {
		var _v0 = timeline.dn;
		var lines = _v0;
		return A3($mdgriffith$elm_animator$Internal$Timeline$addToCurrentLine, now, scheduled, lines);
	});
var $mdgriffith$elm_animator$Internal$Timeline$LastTwoEvents = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_animator$Internal$Timeline$endTime = function (_v0) {
	var end = _v0.c;
	return end;
};
var $mdgriffith$elm_animator$Internal$Time$thisBeforeThat = F2(
	function (_v0, _v1) {
		var _this = _v0;
		var that = _v1;
		return (_this - that) < 0;
	});
var $mdgriffith$elm_animator$Internal$Timeline$beforeEventEnd = F2(
	function (time, events) {
		beforeEventEnd:
		while (true) {
			if (!events.b) {
				return false;
			} else {
				var top = events.a;
				var remain = events.b;
				if (A2(
					$mdgriffith$elm_animator$Internal$Time$thisBeforeThat,
					time,
					$mdgriffith$elm_animator$Internal$Timeline$endTime(top))) {
					return true;
				} else {
					var $temp$time = time,
						$temp$events = remain;
					time = $temp$time;
					events = $temp$events;
					continue beforeEventEnd;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Time$thisBeforeOrEqualThat = F2(
	function (_v0, _v1) {
		var _this = _v0;
		var that = _v1;
		return (_this - that) <= 0;
	});
var $mdgriffith$elm_animator$Internal$Timeline$beforeLineEnd = F2(
	function (time, _v0) {
		var lineStartAt = _v0.a;
		var startingEvent = _v0.b;
		var trailing = _v0.c;
		if (A2($mdgriffith$elm_animator$Internal$Time$thisBeforeOrEqualThat, time, lineStartAt)) {
			return true;
		} else {
			if (!trailing.b) {
				return A2(
					$mdgriffith$elm_animator$Internal$Time$thisBeforeThat,
					time,
					$mdgriffith$elm_animator$Internal$Timeline$endTime(startingEvent));
			} else {
				return A2($mdgriffith$elm_animator$Internal$Timeline$beforeEventEnd, time, trailing);
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$getEvent = function (_v0) {
	var ev = _v0.a;
	return ev;
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_animator$Internal$Timeline$getTransitionAt = F3(
	function (interruptionTime, startEvent, trailing) {
		getTransitionAt:
		while (true) {
			if (!trailing.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var next = trailing.a;
				var remain = trailing.b;
				var prev = A2(
					$elm$core$Maybe$withDefault,
					startEvent,
					$elm$core$List$head(remain));
				if (A2(
					$mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat,
					interruptionTime,
					$mdgriffith$elm_animator$Internal$Timeline$endTime(prev)) && A2(
					$mdgriffith$elm_animator$Internal$Time$thisBeforeThat,
					interruptionTime,
					$mdgriffith$elm_animator$Internal$Timeline$startTime(next))) {
					return $elm$core$Maybe$Just(
						A4(
							$mdgriffith$elm_animator$Internal$Timeline$LastTwoEvents,
							$mdgriffith$elm_animator$Internal$Timeline$endTime(prev),
							$mdgriffith$elm_animator$Internal$Timeline$getEvent(prev),
							$mdgriffith$elm_animator$Internal$Timeline$startTime(next),
							$mdgriffith$elm_animator$Internal$Timeline$getEvent(next)));
				} else {
					var $temp$interruptionTime = interruptionTime,
						$temp$startEvent = startEvent,
						$temp$trailing = remain;
					interruptionTime = $temp$interruptionTime;
					startEvent = $temp$startEvent;
					trailing = $temp$trailing;
					continue getTransitionAt;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$Schedule = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_animator$Internal$Timeline$Event = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_animator$Internal$Timeline$adjustScheduledDuration = F2(
	function (fn, _v0) {
		var dur = _v0.a;
		var ev = _v0.b;
		var maybeDwell = _v0.c;
		return A3(
			$mdgriffith$elm_animator$Internal$Timeline$Event,
			fn(dur),
			ev,
			maybeDwell);
	});
var $mdgriffith$elm_animator$Internal$Timeline$getScheduledEvent = function (_v0) {
	var ev = _v0.b;
	return ev;
};
var $ianmackenzie$elm_units$Quantity$multiplyBy = F2(
	function (scale, _v0) {
		var value = _v0;
		return scale * value;
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $mdgriffith$elm_animator$Internal$Time$progress = F3(
	function (_v0, _v1, _v2) {
		var start = _v0;
		var end = _v1;
		var current = _v2;
		var total = $elm$core$Basics$abs(end - start);
		return (!total) ? 0 : A2(
			$elm$core$Basics$min,
			1,
			A2($elm$core$Basics$max, 0, (current - start) / total));
	});
var $mdgriffith$elm_animator$Internal$Timeline$interruptAtExactly = F3(
	function (startInterruption, scheduled, _v0) {
		var penultimateTime = _v0.a;
		var penultimate = _v0.b;
		var lastEventTime = _v0.c;
		var lastEvent = _v0.d;
		var delay_ = scheduled.a;
		var startingEvent = scheduled.b;
		var reverseQueued = scheduled.c;
		var amountProgress = A3($mdgriffith$elm_animator$Internal$Time$progress, penultimateTime, lastEventTime, startInterruption);
		var newStartingEvent = _Utils_eq(
			penultimate,
			$mdgriffith$elm_animator$Internal$Timeline$getScheduledEvent(startingEvent)) ? A2(
			$mdgriffith$elm_animator$Internal$Timeline$adjustScheduledDuration,
			$ianmackenzie$elm_units$Quantity$multiplyBy(amountProgress),
			startingEvent) : startingEvent;
		return A2(
			$mdgriffith$elm_animator$Internal$Timeline$createLine,
			startInterruption,
			A3($mdgriffith$elm_animator$Internal$Timeline$Schedule, delay_, newStartingEvent, reverseQueued));
	});
var $mdgriffith$elm_animator$Internal$Timeline$interruptLine = F4(
	function (startInterruption, scheduled, line, future) {
		var start = line.a;
		var startEvent = line.b;
		var trailing = line.c;
		if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, startInterruption, start)) {
			if (!future.b) {
				var _v2 = A3($mdgriffith$elm_animator$Internal$Timeline$getTransitionAt, startInterruption, startEvent, trailing);
				if (_v2.$ === 1) {
					return A2($mdgriffith$elm_animator$Internal$Timeline$beforeLineEnd, startInterruption, line) ? $elm$core$Maybe$Just(
						_List_fromArray(
							[
								A2($mdgriffith$elm_animator$Internal$Timeline$createLine, startInterruption, scheduled)
							])) : $elm$core$Maybe$Nothing;
				} else {
					var last2Events = _v2.a;
					return $elm$core$Maybe$Just(
						_List_fromArray(
							[
								A3($mdgriffith$elm_animator$Internal$Timeline$interruptAtExactly, startInterruption, scheduled, last2Events)
							]));
				}
			} else {
				var _v3 = future.a;
				var nextStart = _v3.a;
				var next = _v3.b;
				var nextEvents = _v3.c;
				var futureRemaining = future.b;
				return (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, startInterruption, nextStart) && A2(
					$mdgriffith$elm_animator$Internal$Time$thisBeforeOrEqualThat,
					startInterruption,
					$mdgriffith$elm_animator$Internal$Timeline$startTime(next))) ? $elm$core$Maybe$Just(
					A2(
						$elm$core$List$cons,
						A3($mdgriffith$elm_animator$Internal$Timeline$Line, nextStart, next, nextEvents),
						A2(
							$elm$core$List$cons,
							A3(
								$mdgriffith$elm_animator$Internal$Timeline$interruptAtExactly,
								startInterruption,
								scheduled,
								A4(
									$mdgriffith$elm_animator$Internal$Timeline$LastTwoEvents,
									$mdgriffith$elm_animator$Internal$Timeline$endTime(startEvent),
									$mdgriffith$elm_animator$Internal$Timeline$getEvent(startEvent),
									$mdgriffith$elm_animator$Internal$Timeline$startTime(next),
									$mdgriffith$elm_animator$Internal$Timeline$getEvent(next))),
							futureRemaining))) : $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$lineStartTime = function (_v0) {
	var start = _v0.a;
	return start;
};
var $mdgriffith$elm_animator$Internal$Timeline$interruptionHappensLater = F2(
	function (startInterruption, remaining) {
		if (!remaining.b) {
			return false;
		} else {
			var top = remaining.a;
			return A2(
				$mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat,
				startInterruption,
				$mdgriffith$elm_animator$Internal$Timeline$lineStartTime(top));
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$interruptLines = F5(
	function (now, startInterruption, scheduled, pastLines, lines) {
		interruptLines:
		while (true) {
			if (!lines.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var startLine = lines.a;
				var remaining = lines.b;
				if (A2($mdgriffith$elm_animator$Internal$Timeline$interruptionHappensLater, startInterruption, remaining)) {
					var $temp$now = now,
						$temp$startInterruption = startInterruption,
						$temp$scheduled = scheduled,
						$temp$pastLines = A2($elm$core$List$cons, startLine, pastLines),
						$temp$lines = remaining;
					now = $temp$now;
					startInterruption = $temp$startInterruption;
					scheduled = $temp$scheduled;
					pastLines = $temp$pastLines;
					lines = $temp$lines;
					continue interruptLines;
				} else {
					var _v1 = A4($mdgriffith$elm_animator$Internal$Timeline$interruptLine, startInterruption, scheduled, startLine, remaining);
					if (_v1.$ === 1) {
						var $temp$now = now,
							$temp$startInterruption = startInterruption,
							$temp$scheduled = scheduled,
							$temp$pastLines = A2($elm$core$List$cons, startLine, pastLines),
							$temp$lines = remaining;
						now = $temp$now;
						startInterruption = $temp$startInterruption;
						scheduled = $temp$scheduled;
						pastLines = $temp$pastLines;
						lines = $temp$lines;
						continue interruptLines;
					} else {
						var interruption = _v1.a;
						return (_Utils_eq(
							startInterruption,
							$mdgriffith$elm_animator$Internal$Timeline$lineStartTime(startLine)) && A2($mdgriffith$elm_animator$Internal$Time$thisAfterThat, startInterruption, now)) ? $elm$core$Maybe$Just(
							_Utils_ap(
								$elm$core$List$reverse(pastLines),
								interruption)) : $elm$core$Maybe$Just(
							_Utils_ap(
								$elm$core$List$reverse(pastLines),
								A2($elm$core$List$cons, startLine, interruption)));
					}
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$interrupt = F3(
	function (details, startAt, scheduled) {
		var _v0 = details.dn;
		var lines = _v0;
		var _v1 = A5($mdgriffith$elm_animator$Internal$Timeline$interruptLines, details.cm, startAt, scheduled, _List_Nil, lines);
		if (_v1.$ === 1) {
			return A3($mdgriffith$elm_animator$Internal$Timeline$enqueue, details, startAt, scheduled);
		} else {
			var interrupted = _v1.a;
			return interrupted;
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$applyInterruptionHelper = F2(
	function (interrupts, timeline) {
		applyInterruptionHelper:
		while (true) {
			if (!interrupts.b) {
				return timeline;
			} else {
				var inter = interrupts.a;
				var remaining = interrupts.b;
				var delay = function () {
					var d = inter.a;
					return d;
				}();
				var newEvents = A3(
					$mdgriffith$elm_animator$Internal$Timeline$interrupt,
					timeline,
					A2($mdgriffith$elm_animator$Internal$Time$advanceBy, delay, timeline.cm),
					inter);
				var $temp$interrupts = remaining,
					$temp$timeline = _Utils_update(
					timeline,
					{dn: newEvents});
				interrupts = $temp$interrupts;
				timeline = $temp$timeline;
				continue applyInterruptionHelper;
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$applyInterruptions = function (timeline) {
	var _v0 = timeline.aA;
	if (!_v0.b) {
		return timeline;
	} else {
		return A2(
			$mdgriffith$elm_animator$Internal$Timeline$applyInterruptionHelper,
			timeline.aA,
			_Utils_update(
				timeline,
				{aA: _List_Nil}));
	}
};
var $mdgriffith$elm_animator$Internal$Timeline$applyQueued = function (timeline) {
	var _v0 = timeline.aF;
	if (_v0.$ === 1) {
		return timeline;
	} else {
		var queued = _v0.a;
		return _Utils_update(
			timeline,
			{
				dn: A3($mdgriffith$elm_animator$Internal$Timeline$enqueue, timeline, timeline.cm, queued),
				aF: $elm$core$Maybe$Nothing
			});
	}
};
var $mdgriffith$elm_animator$Internal$Timeline$dwellingAt = F2(
	function (now, event) {
		var eventStartTime = $mdgriffith$elm_animator$Internal$Timeline$startTime(event);
		var eventEndTime = $mdgriffith$elm_animator$Internal$Timeline$endTime(event);
		return A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, now, eventStartTime) && A2($mdgriffith$elm_animator$Internal$Time$thisBeforeOrEqualThat, now, eventEndTime);
	});
var $mdgriffith$elm_animator$Internal$Timeline$Captured = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_animator$Internal$Timeline$NothingCaptured = {$: 1};
var $mdgriffith$elm_animator$Internal$Timeline$hewLine = F2(
	function (now, events) {
		hewLine:
		while (true) {
			if (!events.b) {
				return $mdgriffith$elm_animator$Internal$Timeline$NothingCaptured;
			} else {
				var top = events.a;
				var remaining = events.b;
				if (A2($mdgriffith$elm_animator$Internal$Timeline$dwellingAt, now, top)) {
					return $mdgriffith$elm_animator$Internal$Timeline$Captured(
						A3(
							$mdgriffith$elm_animator$Internal$Timeline$Line,
							$mdgriffith$elm_animator$Internal$Timeline$startTime(top),
							top,
							remaining));
				} else {
					if (A2(
						$mdgriffith$elm_animator$Internal$Time$thisAfterThat,
						now,
						$mdgriffith$elm_animator$Internal$Timeline$endTime(top))) {
						var $temp$now = now,
							$temp$events = remaining;
						now = $temp$now;
						events = $temp$events;
						continue hewLine;
					} else {
						return $mdgriffith$elm_animator$Internal$Timeline$NothingCaptured;
					}
				}
			}
		}
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$garbageCollectOldEvents = F3(
	function (now, droppable, lines) {
		garbageCollectOldEvents:
		while (true) {
			if (!lines.b) {
				return $elm$core$List$reverse(droppable);
			} else {
				var _v1 = lines.a;
				var startAt = _v1.a;
				var startingEvent = _v1.b;
				var events = _v1.c;
				var remaining = lines.b;
				if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, startAt, now)) {
					return _Utils_ap(
						$elm$core$List$reverse(droppable),
						lines);
				} else {
					if (A2($mdgriffith$elm_animator$Internal$Timeline$dwellingAt, now, startingEvent)) {
						return lines;
					} else {
						var maybeInterruptionTime = A2(
							$elm$core$Maybe$map,
							$mdgriffith$elm_animator$Internal$Timeline$lineStartTime,
							$elm$core$List$head(remaining));
						var interrupted = function () {
							if (maybeInterruptionTime.$ === 1) {
								return false;
							} else {
								var interruptionTime = maybeInterruptionTime.a;
								return A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, now, interruptionTime);
							}
						}();
						if (interrupted) {
							var $temp$now = now,
								$temp$droppable = A2(
								$elm$core$List$cons,
								A3($mdgriffith$elm_animator$Internal$Timeline$Line, startAt, startingEvent, events),
								droppable),
								$temp$lines = remaining;
							now = $temp$now;
							droppable = $temp$droppable;
							lines = $temp$lines;
							continue garbageCollectOldEvents;
						} else {
							var _v2 = A2($mdgriffith$elm_animator$Internal$Timeline$hewLine, now, events);
							if (_v2.$ === 1) {
								return _Utils_ap(
									$elm$core$List$reverse(droppable),
									lines);
							} else {
								var capturedLine = _v2.a;
								return A2($elm$core$List$cons, capturedLine, remaining);
							}
						}
					}
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$linesAreActive = F2(
	function (now, lines) {
		linesAreActive:
		while (true) {
			if (!lines.b) {
				return false;
			} else {
				var _v1 = lines.a;
				var startAt = _v1.a;
				var startingEvent = _v1.b;
				var events = _v1.c;
				var remaining = lines.b;
				if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, startAt, now)) {
					return true;
				} else {
					var maybeInterruption = function () {
						var _v5 = $elm$core$List$head(remaining);
						if (_v5.$ === 1) {
							return $elm$core$Maybe$Nothing;
						} else {
							var _v6 = _v5.a;
							var interruptionTime = _v6.a;
							return $elm$core$Maybe$Just(interruptionTime);
						}
					}();
					var last = A2(
						$elm$core$Maybe$withDefault,
						startingEvent,
						$elm$core$List$head(
							$elm$core$List$reverse(events)));
					if (!maybeInterruption.$) {
						var interruptTime = maybeInterruption.a;
						if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, interruptTime, now)) {
							return true;
						} else {
							var time = last.b;
							if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, time, now)) {
								return true;
							} else {
								var $temp$now = now,
									$temp$lines = remaining;
								now = $temp$now;
								lines = $temp$lines;
								continue linesAreActive;
							}
						}
					} else {
						var time = last.b;
						if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, time, now)) {
							return true;
						} else {
							var $temp$now = now,
								$temp$lines = remaining;
							now = $temp$now;
							lines = $temp$lines;
							continue linesAreActive;
						}
					}
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$clean = F2(
	function (runGC, details) {
		var running = function () {
			var _v1 = details.dn;
			var lines = _v1;
			return A2($mdgriffith$elm_animator$Internal$Timeline$linesAreActive, details.cm, lines);
		}();
		var events = function () {
			var _v0 = details.dn;
			var evs = _v0;
			return evs;
		}();
		return _Utils_update(
			details,
			{
				dn: runGC ? A3($mdgriffith$elm_animator$Internal$Timeline$garbageCollectOldEvents, details.cm, _List_Nil, events) : details.dn,
				a6: running
			});
	});
var $ianmackenzie$elm_units$Quantity$max = F2(
	function (_v0, _v1) {
		var x = _v0;
		var y = _v1;
		return A2($elm$core$Basics$max, x, y);
	});
var $mdgriffith$elm_animator$Internal$Timeline$update = F2(
	function (possiblyNow, _v0) {
		var timeline = _v0;
		var now = A2(
			$ianmackenzie$elm_units$Quantity$max,
			$mdgriffith$elm_animator$Internal$Time$absolute(possiblyNow),
			timeline.cm);
		return _Utils_eq(timeline.dn, _List_Nil) ? A2(
			$mdgriffith$elm_animator$Internal$Timeline$clean,
			true,
			$mdgriffith$elm_animator$Internal$Timeline$applyInterruptions(
				$mdgriffith$elm_animator$Internal$Timeline$applyQueued(
					_Utils_update(
						timeline,
						{
							dn: function () {
								var firstOccurring = A3($mdgriffith$elm_animator$Internal$Timeline$Occurring, timeline.b6, now, now);
								return _List_fromArray(
									[
										A3($mdgriffith$elm_animator$Internal$Timeline$Line, now, firstOccurring, _List_Nil)
									]);
							}(),
							cm: now
						})))) : A2(
			$mdgriffith$elm_animator$Internal$Timeline$clean,
			true,
			$mdgriffith$elm_animator$Internal$Timeline$applyInterruptions(
				$mdgriffith$elm_animator$Internal$Timeline$applyQueued(
					_Utils_update(
						timeline,
						{cm: now}))));
	});
var $mdgriffith$elm_animator$Animator$Css$watching = F3(
	function (get, set, _v0) {
		var isRunning = _v0.a;
		var updateModel = _v0.b;
		return A2(
			$mdgriffith$elm_animator$Internal$Timeline$Animator,
			function (model) {
				if (isRunning(model)) {
					return true;
				} else {
					var tl = get(model);
					return $mdgriffith$elm_animator$Internal$Timeline$hasChanged(tl) || $mdgriffith$elm_animator$Internal$Timeline$justInitialized(tl);
				}
			},
			F2(
				function (now, model) {
					var newModel = A2(updateModel, now, model);
					return A2(
						set,
						A2(
							$mdgriffith$elm_animator$Internal$Timeline$update,
							now,
							get(newModel)),
						newModel);
				}));
	});
var $author$project$Main$animator = A3(
	$mdgriffith$elm_animator$Animator$Css$watching,
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.c;
		},
		function ($) {
			return $.a1;
		}),
	F2(
		function (newOpen, model) {
			var currentBottomSheet = model.c;
			return _Utils_update(
				model,
				{
					c: _Utils_update(
						currentBottomSheet,
						{a1: newOpen})
				});
		}),
	A3(
		$mdgriffith$elm_animator$Animator$Css$watching,
		function ($) {
			return $.r;
		},
		F2(
			function (newNavStack, model) {
				return _Utils_update(
					model,
					{r: newNavStack});
			}),
		$mdgriffith$elm_animator$Animator$animator));
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $ianmackenzie$elm_units$Quantity$greaterThan = F2(
	function (_v0, _v1) {
		var y = _v0;
		var x = _v1;
		return _Utils_cmp(x, y) > 0;
	});
var $mdgriffith$elm_animator$Internal$Time$inMilliseconds = function (_v0) {
	var ms = _v0;
	return ms;
};
var $ianmackenzie$elm_units$Duration$seconds = function (numSeconds) {
	return numSeconds;
};
var $ianmackenzie$elm_units$Duration$milliseconds = function (numMilliseconds) {
	return $ianmackenzie$elm_units$Duration$seconds(0.001 * numMilliseconds);
};
var $mdgriffith$elm_animator$Internal$Time$duration = F2(
	function (one, two) {
		return A2($ianmackenzie$elm_units$Quantity$greaterThan, two, one) ? $ianmackenzie$elm_units$Duration$milliseconds(
			A2(
				$elm$core$Basics$max,
				0,
				$mdgriffith$elm_animator$Internal$Time$inMilliseconds(one) - $mdgriffith$elm_animator$Internal$Time$inMilliseconds(two))) : $ianmackenzie$elm_units$Duration$milliseconds(
			A2(
				$elm$core$Basics$max,
				0,
				$mdgriffith$elm_animator$Internal$Time$inMilliseconds(two) - $mdgriffith$elm_animator$Internal$Time$inMilliseconds(one)));
	});
var $mdgriffith$elm_animator$Internal$Timeline$endTimeAdj = F4(
	function (lookup, getAdjustment, _v0, _v1) {
		var cur = _v0.a;
		var curEnd = _v0.c;
		var next = _v1.a;
		var nextStartTime = _v1.b;
		var totalDuration = A2($mdgriffith$elm_animator$Internal$Time$duration, curEnd, nextStartTime);
		var nextAdjustment = getAdjustment(
			lookup(next));
		var adjustment = getAdjustment(
			lookup(cur));
		var totalPortions = A2($elm$core$Basics$max, adjustment.a_ + nextAdjustment.aT, 1);
		var lateBy = A2($ianmackenzie$elm_units$Quantity$multiplyBy, adjustment.a_ / totalPortions, totalDuration);
		return A2($mdgriffith$elm_animator$Internal$Time$advanceBy, lateBy, curEnd);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $mdgriffith$elm_animator$Internal$Timeline$hasDwell = function (_v0) {
	var start = _v0.b;
	var end = _v0.c;
	return !(!(start - end));
};
var $elm$core$Basics$not = _Basics_not;
var $ianmackenzie$elm_units$Quantity$minus = F2(
	function (_v0, _v1) {
		var y = _v0;
		var x = _v1;
		return x - y;
	});
var $mdgriffith$elm_animator$Internal$Time$rollbackBy = F2(
	function (dur, time) {
		return A2(
			$ianmackenzie$elm_units$Quantity$minus,
			$ianmackenzie$elm_units$Duration$inMilliseconds(dur),
			time);
	});
var $mdgriffith$elm_animator$Internal$Timeline$startTimeAdj = F4(
	function (lookup, getAdjustment, _v0, _v1) {
		var prev = _v0.a;
		var prevEnd = _v0.c;
		var cur = _v1.a;
		var curStartTime = _v1.b;
		var totalDuration = A2($mdgriffith$elm_animator$Internal$Time$duration, prevEnd, curStartTime);
		var prevAdjustment = getAdjustment(
			lookup(prev));
		var adjustment = getAdjustment(
			lookup(cur));
		var totalPortions = A2($elm$core$Basics$max, prevAdjustment.a_ + adjustment.aT, 1);
		var earlyBy = A2($ianmackenzie$elm_units$Quantity$multiplyBy, adjustment.aT / totalPortions, totalDuration);
		return A2($mdgriffith$elm_animator$Internal$Time$rollbackBy, earlyBy, curStartTime);
	});
var $mdgriffith$elm_animator$Internal$Timeline$overLines = F7(
	function (fn, lookup, details, maybePreviousEvent, _v0, futureLines, state) {
		overLines:
		while (true) {
			var lineStart = _v0.a;
			var lineStartEv = _v0.b;
			var lineRemain = _v0.c;
			var transition = function (newState) {
				if (!futureLines.b) {
					return newState;
				} else {
					var future = futureLines.a;
					var futureStart = future.a;
					var futureStartEv = future.b;
					var futureRemain = future.c;
					var restOfFuture = futureLines.b;
					return A2($mdgriffith$elm_animator$Internal$Time$thisBeforeOrEqualThat, futureStart, details.cm) ? A7($mdgriffith$elm_animator$Internal$Timeline$overLines, fn, lookup, details, $elm$core$Maybe$Nothing, future, restOfFuture, newState) : newState;
				}
			};
			var now = function () {
				if (!futureLines.b) {
					return details.cm;
				} else {
					var _v11 = futureLines.a;
					var futureStart = _v11.a;
					var futureStartEv = _v11.b;
					var futureRemain = _v11.c;
					var restOfFuture = futureLines.b;
					return A2($mdgriffith$elm_animator$Internal$Time$thisBeforeThat, futureStart, details.cm) ? futureStart : details.cm;
				}
			}();
			var eventStartTime = function () {
				if (maybePreviousEvent.$ === 1) {
					return $mdgriffith$elm_animator$Internal$Timeline$startTime(lineStartEv);
				} else {
					var prev = maybePreviousEvent.a;
					return A4($mdgriffith$elm_animator$Internal$Timeline$startTimeAdj, lookup, fn.bb, prev, lineStartEv);
				}
			}();
			if (A2($mdgriffith$elm_animator$Internal$Time$thisBeforeThat, now, eventStartTime)) {
				return transition(
					A7(
						fn.bm,
						$mdgriffith$elm_animator$Internal$Time$inMilliseconds(lineStart),
						$elm$core$Maybe$Just(
							lookup(details.b6)),
						lookup(
							$mdgriffith$elm_animator$Internal$Timeline$getEvent(lineStartEv)),
						$mdgriffith$elm_animator$Internal$Time$inMilliseconds(eventStartTime),
						$mdgriffith$elm_animator$Internal$Time$inMilliseconds(now),
						function () {
							if (!lineRemain.b) {
								return $elm$core$Maybe$Nothing;
							} else {
								var upcoming = lineRemain.a;
								return $elm$core$Maybe$Just(
									{
										c7: lookup(
											$mdgriffith$elm_animator$Internal$Timeline$getEvent(upcoming)),
										d5: !$mdgriffith$elm_animator$Internal$Timeline$hasDwell(upcoming),
										aM: $mdgriffith$elm_animator$Internal$Time$inMilliseconds(
											A4($mdgriffith$elm_animator$Internal$Timeline$startTimeAdj, lookup, fn.bb, lineStartEv, upcoming))
									});
							}
						}(),
						state));
			} else {
				var eventEndTime = function () {
					if (!lineRemain.b) {
						return $mdgriffith$elm_animator$Internal$Timeline$endTime(lineStartEv);
					} else {
						var upcoming = lineRemain.a;
						return A4($mdgriffith$elm_animator$Internal$Timeline$endTimeAdj, lookup, fn.bb, lineStartEv, upcoming);
					}
				}();
				if (A2($mdgriffith$elm_animator$Internal$Time$thisAfterOrEqualThat, now, eventEndTime)) {
					if (!lineRemain.b) {
						return transition(
							A2(
								fn.bi,
								lookup(
									$mdgriffith$elm_animator$Internal$Timeline$getEvent(lineStartEv)),
								A2($mdgriffith$elm_animator$Internal$Time$duration, eventStartTime, now)));
					} else {
						var next = lineRemain.a;
						var lineRemain2 = lineRemain.b;
						var nextStartTime = A4($mdgriffith$elm_animator$Internal$Timeline$startTimeAdj, lookup, fn.bb, lineStartEv, next);
						var nextEndTime = function () {
							if (!lineRemain2.b) {
								return $mdgriffith$elm_animator$Internal$Timeline$endTime(next);
							} else {
								var upcoming = lineRemain2.a;
								return A4($mdgriffith$elm_animator$Internal$Timeline$endTimeAdj, lookup, fn.bb, next, upcoming);
							}
						}();
						if (A2($mdgriffith$elm_animator$Internal$Time$thisBeforeThat, now, nextStartTime)) {
							return transition(
								A7(
									fn.bm,
									$mdgriffith$elm_animator$Internal$Time$inMilliseconds(eventEndTime),
									$elm$core$Maybe$Just(
										lookup(
											$mdgriffith$elm_animator$Internal$Timeline$getEvent(lineStartEv))),
									lookup(
										$mdgriffith$elm_animator$Internal$Timeline$getEvent(next)),
									$mdgriffith$elm_animator$Internal$Time$inMilliseconds(nextStartTime),
									$mdgriffith$elm_animator$Internal$Time$inMilliseconds(now),
									function () {
										if (!lineRemain2.b) {
											return $elm$core$Maybe$Nothing;
										} else {
											var upcoming = lineRemain2.a;
											return $elm$core$Maybe$Just(
												{
													c7: lookup(
														$mdgriffith$elm_animator$Internal$Timeline$getEvent(upcoming)),
													d5: !$mdgriffith$elm_animator$Internal$Timeline$hasDwell(upcoming),
													aM: $mdgriffith$elm_animator$Internal$Time$inMilliseconds(
														A4($mdgriffith$elm_animator$Internal$Timeline$startTimeAdj, lookup, fn.bb, next, upcoming))
												});
										}
									}(),
									$mdgriffith$elm_animator$Internal$Timeline$hasDwell(lineStartEv) ? A2(
										fn.bi,
										lookup(
											$mdgriffith$elm_animator$Internal$Timeline$getEvent(lineStartEv)),
										A2($mdgriffith$elm_animator$Internal$Time$duration, eventStartTime, eventEndTime)) : state));
						} else {
							if (A2($mdgriffith$elm_animator$Internal$Time$thisBeforeThat, now, nextEndTime)) {
								return transition(
									A2(
										fn.bi,
										lookup(
											$mdgriffith$elm_animator$Internal$Timeline$getEvent(next)),
										A2($mdgriffith$elm_animator$Internal$Time$duration, nextStartTime, now)));
							} else {
								if (!lineRemain2.b) {
									return transition(
										A2(
											fn.bi,
											lookup(
												$mdgriffith$elm_animator$Internal$Timeline$getEvent(next)),
											A2($mdgriffith$elm_animator$Internal$Time$duration, nextStartTime, now)));
								} else {
									var next2 = lineRemain2.a;
									var lineRemain3 = lineRemain2.b;
									var next2StartTime = A4($mdgriffith$elm_animator$Internal$Timeline$startTimeAdj, lookup, fn.bb, next, next2);
									var next2EndTime = function () {
										if (!lineRemain3.b) {
											return $mdgriffith$elm_animator$Internal$Timeline$endTime(next2);
										} else {
											var upcoming = lineRemain3.a;
											return A4($mdgriffith$elm_animator$Internal$Timeline$endTimeAdj, lookup, fn.bb, next2, upcoming);
										}
									}();
									if (A2($mdgriffith$elm_animator$Internal$Time$thisBeforeThat, now, next2StartTime)) {
										var after = $mdgriffith$elm_animator$Internal$Timeline$hasDwell(next) ? A2(
											fn.bi,
											lookup(
												$mdgriffith$elm_animator$Internal$Timeline$getEvent(next)),
											A2($mdgriffith$elm_animator$Internal$Time$duration, nextStartTime, nextEndTime)) : A3(fn.bc, lookup, next, lineRemain2);
										return transition(
											A7(
												fn.bm,
												$mdgriffith$elm_animator$Internal$Time$inMilliseconds(nextEndTime),
												$elm$core$Maybe$Just(
													lookup(
														$mdgriffith$elm_animator$Internal$Timeline$getEvent(next))),
												lookup(
													$mdgriffith$elm_animator$Internal$Timeline$getEvent(next2)),
												$mdgriffith$elm_animator$Internal$Time$inMilliseconds(next2StartTime),
												$mdgriffith$elm_animator$Internal$Time$inMilliseconds(now),
												function () {
													if (!lineRemain3.b) {
														return $elm$core$Maybe$Nothing;
													} else {
														var upcoming = lineRemain3.a;
														return $elm$core$Maybe$Just(
															{
																c7: lookup(
																	$mdgriffith$elm_animator$Internal$Timeline$getEvent(upcoming)),
																d5: !$mdgriffith$elm_animator$Internal$Timeline$hasDwell(upcoming),
																aM: $mdgriffith$elm_animator$Internal$Time$inMilliseconds(
																	A4($mdgriffith$elm_animator$Internal$Timeline$startTimeAdj, lookup, fn.bb, next2, upcoming))
															});
													}
												}(),
												after));
									} else {
										if (A2($mdgriffith$elm_animator$Internal$Time$thisBeforeThat, now, next2EndTime)) {
											return transition(
												A2(
													fn.bi,
													lookup(
														$mdgriffith$elm_animator$Internal$Timeline$getEvent(next2)),
													A2($mdgriffith$elm_animator$Internal$Time$duration, next2StartTime, now)));
										} else {
											var after = $mdgriffith$elm_animator$Internal$Timeline$hasDwell(next2) ? A2(
												fn.bi,
												lookup(
													$mdgriffith$elm_animator$Internal$Timeline$getEvent(next2)),
												A2($mdgriffith$elm_animator$Internal$Time$duration, next2StartTime, next2EndTime)) : A3(fn.bc, lookup, next2, lineRemain3);
											var $temp$fn = fn,
												$temp$lookup = lookup,
												$temp$details = details,
												$temp$maybePreviousEvent = $elm$core$Maybe$Just(next),
												$temp$_v0 = A3($mdgriffith$elm_animator$Internal$Timeline$Line, nextEndTime, next2, lineRemain3),
												$temp$futureLines = futureLines,
												$temp$state = after;
											fn = $temp$fn;
											lookup = $temp$lookup;
											details = $temp$details;
											maybePreviousEvent = $temp$maybePreviousEvent;
											_v0 = $temp$_v0;
											futureLines = $temp$futureLines;
											state = $temp$state;
											continue overLines;
										}
									}
								}
							}
						}
					}
				} else {
					return transition(
						A2(
							fn.bi,
							lookup(
								$mdgriffith$elm_animator$Internal$Timeline$getEvent(lineStartEv)),
							A2($mdgriffith$elm_animator$Internal$Time$duration, eventStartTime, now)));
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$foldp = F3(
	function (lookup, fn, _v0) {
		var timelineDetails = _v0;
		var _v1 = timelineDetails.dn;
		var timetable = _v1;
		var start = fn.by(
			lookup(timelineDetails.b6));
		if (!timetable.b) {
			return start;
		} else {
			var firstLine = timetable.a;
			var remainingLines = timetable.b;
			return A7($mdgriffith$elm_animator$Internal$Timeline$overLines, fn, lookup, timelineDetails, $elm$core$Maybe$Nothing, firstLine, remainingLines, start);
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$pass = F7(
	function (_v0, _v1, target, _v2, _v3, _v4, _v5) {
		return target;
	});
var $mdgriffith$elm_animator$Internal$Timeline$current = function (timeline) {
	var details = timeline;
	return A3(
		$mdgriffith$elm_animator$Internal$Timeline$foldp,
		$elm$core$Basics$identity,
		{
			bb: function (_v0) {
				return {aT: 0, a_: 0};
			},
			bc: F3(
				function (lookup, target, future) {
					return $mdgriffith$elm_animator$Internal$Timeline$getEvent(target);
				}),
			bi: F2(
				function (cur, duration) {
					return cur;
				}),
			bj: function (_v1) {
				return $elm$core$Maybe$Nothing;
			},
			bm: $mdgriffith$elm_animator$Internal$Timeline$pass,
			by: function (_v2) {
				return details.b6;
			}
		},
		timeline);
};
var $mdgriffith$elm_animator$Animator$current = $mdgriffith$elm_animator$Internal$Timeline$current;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $pzp1997$assoc_list$AssocList$remove = F2(
	function (targetKey, _v0) {
		var alist = _v0;
		return A2(
			$elm$core$List$filter,
			function (_v1) {
				var key = _v1.a;
				return !_Utils_eq(key, targetKey);
			},
			alist);
	});
var $pzp1997$assoc_list$AssocList$insert = F3(
	function (key, value, dict) {
		var _v0 = A2($pzp1997$assoc_list$AssocList$remove, key, dict);
		var alteredAlist = _v0;
		return A2(
			$elm$core$List$cons,
			_Utils_Tuple2(key, value),
			alteredAlist);
	});
var $pzp1997$assoc_list$AssocList$fromList = function (alist) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, result) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($pzp1997$assoc_list$AssocList$insert, key, value, result);
			}),
		_List_Nil,
		alist);
};
var $pzp1997$assoc_list$AssocList$get = F2(
	function (targetKey, _v0) {
		get:
		while (true) {
			var alist = _v0;
			if (!alist.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v2 = alist.a;
				var key = _v2.a;
				var value = _v2.b;
				var rest = alist.b;
				if (_Utils_eq(key, targetKey)) {
					return $elm$core$Maybe$Just(value);
				} else {
					var $temp$targetKey = targetKey,
						$temp$_v0 = rest;
					targetKey = $temp$targetKey;
					_v0 = $temp$_v0;
					continue get;
				}
			}
		}
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $author$project$Request$getId = function (request) {
	switch (request.$) {
		case 0:
			var x = request.a;
			return $elm$core$Maybe$Just(x.aX);
		case 1:
			var x = request.a;
			return $elm$core$Maybe$Just(x.aX);
		case 2:
			var requestId = request.a;
			return $elm$core$Maybe$Just(requestId);
		case 3:
			return $elm$core$Maybe$Nothing;
		case 4:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Parameter$Value$get = function (value) {
	if (!value.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var a = value.a;
		return $elm$core$Maybe$Just(a);
	}
};
var $author$project$Parameter$getValue = function (parameter) {
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.ba;
		},
		$author$project$Parameter$Value$get(parameter.ba));
};
var $mdgriffith$elm_animator$Animator$TransitionTo = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_animator$Animator$event = $mdgriffith$elm_animator$Animator$TransitionTo;
var $mdgriffith$elm_animator$Animator$initializeSchedule = F2(
	function (waiting, steps) {
		initializeSchedule:
		while (true) {
			if (!steps.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (!steps.a.$) {
					var additionalWait = steps.a.a;
					var moreSteps = steps.b;
					var $temp$waiting = A2($ianmackenzie$elm_units$Quantity$plus, waiting, additionalWait),
						$temp$steps = moreSteps;
					waiting = $temp$waiting;
					steps = $temp$steps;
					continue initializeSchedule;
				} else {
					var _v1 = steps.a;
					var dur = _v1.a;
					var checkpoint = _v1.b;
					var moreSteps = steps.b;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(
							A3(
								$mdgriffith$elm_animator$Internal$Timeline$Schedule,
								waiting,
								A3($mdgriffith$elm_animator$Internal$Timeline$Event, dur, checkpoint, $elm$core$Maybe$Nothing),
								_List_Nil),
							moreSteps));
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$millis = $ianmackenzie$elm_units$Duration$milliseconds;
var $mdgriffith$elm_animator$Internal$Timeline$addToDwell = F2(
	function (duration, maybeDwell) {
		if (!$ianmackenzie$elm_units$Duration$inMilliseconds(duration)) {
			return maybeDwell;
		} else {
			if (maybeDwell.$ === 1) {
				return $elm$core$Maybe$Just(duration);
			} else {
				var existing = maybeDwell.a;
				return $elm$core$Maybe$Just(
					A2($ianmackenzie$elm_units$Quantity$plus, duration, existing));
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$extendEventDwell = F2(
	function (extendBy, thisEvent) {
		var at = thisEvent.a;
		var ev = thisEvent.b;
		var maybeDwell = thisEvent.c;
		return (!$ianmackenzie$elm_units$Duration$inMilliseconds(extendBy)) ? thisEvent : A3(
			$mdgriffith$elm_animator$Internal$Timeline$Event,
			at,
			ev,
			A2($mdgriffith$elm_animator$Internal$Timeline$addToDwell, extendBy, maybeDwell));
	});
var $mdgriffith$elm_animator$Animator$stepsToEvents = F2(
	function (currentStep, _v0) {
		var delay = _v0.a;
		var startEvent = _v0.b;
		var events = _v0.c;
		if (!events.b) {
			if (!currentStep.$) {
				var waiting = currentStep.a;
				return A3(
					$mdgriffith$elm_animator$Internal$Timeline$Schedule,
					delay,
					A2($mdgriffith$elm_animator$Internal$Timeline$extendEventDwell, waiting, startEvent),
					events);
			} else {
				var dur = currentStep.a;
				var checkpoint = currentStep.b;
				return A3(
					$mdgriffith$elm_animator$Internal$Timeline$Schedule,
					delay,
					startEvent,
					_List_fromArray(
						[
							A3($mdgriffith$elm_animator$Internal$Timeline$Event, dur, checkpoint, $elm$core$Maybe$Nothing)
						]));
			}
		} else {
			var _v3 = events.a;
			var durationTo = _v3.a;
			var recentEvent = _v3.b;
			var maybeDwell = _v3.c;
			var remaining = events.b;
			if (!currentStep.$) {
				var dur = currentStep.a;
				return A3(
					$mdgriffith$elm_animator$Internal$Timeline$Schedule,
					delay,
					startEvent,
					A2(
						$elm$core$List$cons,
						A3(
							$mdgriffith$elm_animator$Internal$Timeline$Event,
							durationTo,
							recentEvent,
							A2($mdgriffith$elm_animator$Internal$Timeline$addToDwell, dur, maybeDwell)),
						remaining));
			} else {
				var dur = currentStep.a;
				var checkpoint = currentStep.b;
				return _Utils_eq(checkpoint, recentEvent) ? A3(
					$mdgriffith$elm_animator$Internal$Timeline$Schedule,
					delay,
					startEvent,
					A2(
						$elm$core$List$cons,
						A3(
							$mdgriffith$elm_animator$Internal$Timeline$Event,
							durationTo,
							recentEvent,
							A2($mdgriffith$elm_animator$Internal$Timeline$addToDwell, dur, maybeDwell)),
						remaining)) : A3(
					$mdgriffith$elm_animator$Internal$Timeline$Schedule,
					delay,
					startEvent,
					A2(
						$elm$core$List$cons,
						A3($mdgriffith$elm_animator$Internal$Timeline$Event, dur, checkpoint, $elm$core$Maybe$Nothing),
						events));
			}
		}
	});
var $mdgriffith$elm_animator$Animator$interrupt = F2(
	function (steps, _v0) {
		var tl = _v0;
		return _Utils_update(
			tl,
			{
				aA: function () {
					var _v1 = A2(
						$mdgriffith$elm_animator$Animator$initializeSchedule,
						$mdgriffith$elm_animator$Animator$millis(0),
						steps);
					if (_v1.$ === 1) {
						return tl.aA;
					} else {
						var _v2 = _v1.a;
						var schedule = _v2.a;
						var otherSteps = _v2.b;
						return A2(
							$elm$core$List$cons,
							A3($elm$core$List$foldl, $mdgriffith$elm_animator$Animator$stepsToEvents, schedule, otherSteps),
							tl.aA);
					}
				}(),
				a6: true
			});
	});
var $mdgriffith$elm_animator$Animator$go = F3(
	function (duration, ev, timeline) {
		return A2(
			$mdgriffith$elm_animator$Animator$interrupt,
			_List_fromArray(
				[
					A2($mdgriffith$elm_animator$Animator$event, duration, ev)
				]),
			timeline);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Request$isReadRegistersRequest = function (req) {
	if (req.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Request$isWriteRegistersRequest = function (req) {
	if (!req.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Parameter$Value$Locked = {$: 1};
var $author$project$Parameter$Value$NotAvailable = {$: 0};
var $author$project$Parameter$Value$Ready = function (a) {
	return {$: 1, a: a};
};
var $author$project$Parameter$Value$lock = function (value) {
	if (!value.$) {
		return $author$project$Parameter$Value$NotAvailable;
	} else {
		var x = value.a;
		return $author$project$Parameter$Value$Ready(
			_Utils_update(
				x,
				{ab: $author$project$Parameter$Value$Locked}));
	}
};
var $author$project$Parameter$lock = function (parameter) {
	return _Utils_update(
		parameter,
		{
			ba: $author$project$Parameter$Value$lock(parameter.ba)
		});
};
var $author$project$Parameter$Value$LockedWithRemoteValueVersion = function (a) {
	return {$: 2, a: a};
};
var $author$project$Parameter$Value$lockWithVersion = F2(
	function (version, value) {
		if (!value.$) {
			return $author$project$Parameter$Value$NotAvailable;
		} else {
			var x = value.a;
			return $author$project$Parameter$Value$Ready(
				_Utils_update(
					x,
					{
						ab: $author$project$Parameter$Value$LockedWithRemoteValueVersion(version)
					}));
		}
	});
var $author$project$Parameter$lockWithVersion = F2(
	function (version, parameter) {
		return _Utils_update(
			parameter,
			{
				ba: A2($author$project$Parameter$Value$lockWithVersion, version, parameter.ba)
			});
	});
var $pzp1997$assoc_list$AssocList$map = F2(
	function (alter, _v0) {
		var alist = _v0;
		return A2(
			$elm$core$List$map,
			function (_v1) {
				var key = _v1.a;
				var value = _v1.b;
				return _Utils_Tuple2(
					key,
					A2(alter, key, value));
			},
			alist);
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $pzp1997$assoc_list$AssocList$member = F2(
	function (targetKey, dict) {
		var _v0 = A2($pzp1997$assoc_list$AssocList$get, targetKey, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$RequestId$plus = F2(
	function (_v0, value) {
		var id = _v0;
		return id + value;
	});
var $Gizra$elm_debouncer$Debouncer$Basic$ProvideInput = function (a) {
	return {$: 0, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Basic$provideInput = $Gizra$elm_debouncer$Debouncer$Basic$ProvideInput;
var $author$project$Parameter$Value$RemoteValueVersion = $elm$core$Basics$identity;
var $author$project$Parameter$Value$remoteValueVersionFromInt = function (version) {
	return version;
};
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Request$encodeLog = F2(
	function (logLevel, message) {
		var logLevelString = 'fatal';
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('log')),
					_Utils_Tuple2(
					'level',
					$elm$json$Json$Encode$string(logLevelString)),
					_Utils_Tuple2(
					'message',
					$elm$json$Json$Encode$string(message))
				]));
	});
var $author$project$Request$encodeQuit = $elm$json$Json$Encode$object(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'type',
			$elm$json$Json$Encode$string('quit'))
		]));
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Parameter$Register$encode = function (_v0) {
	var register = _v0;
	return $elm$json$Json$Encode$int(register);
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$RequestId$toInt = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$Request$encodeReadRegisters = function (payload) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('read_registers')),
				_Utils_Tuple2(
				'requestId',
				$elm$json$Json$Encode$int(
					$author$project$RequestId$toInt(payload.aX))),
				_Utils_Tuple2(
				'registers',
				A2($elm$json$Json$Encode$list, $author$project$Parameter$Register$encode, payload.cB))
			]));
};
var $author$project$Request$encodeReconnect = function (requestId) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('reconnect')),
				_Utils_Tuple2(
				'requestId',
				$elm$json$Json$Encode$int(
					$author$project$RequestId$toInt(requestId)))
			]));
};
var $author$project$Request$tuple2Encoder = F3(
	function (enc1, enc2, _v0) {
		var val1 = _v0.a;
		var val2 = _v0.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					enc1(val1),
					enc2(val2)
				]));
	});
var $author$project$Request$encodeSendCommissioningDataPayload = function (payload) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('send_commissioning_data')),
				_Utils_Tuple2(
				'data',
				A2(
					$elm$json$Json$Encode$list,
					A2($author$project$Request$tuple2Encoder, $author$project$Parameter$Register$encode, $elm$json$Json$Encode$int),
					payload.aU))
			]));
};
var $author$project$Request$encodeWriteRegistersPayload = function (payload) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('write_registers')),
				_Utils_Tuple2(
				'requestId',
				$elm$json$Json$Encode$int(
					$author$project$RequestId$toInt(payload.aX))),
				_Utils_Tuple2(
				'data',
				A2(
					$elm$json$Json$Encode$list,
					A2($author$project$Request$tuple2Encoder, $author$project$Parameter$Register$encode, $elm$json$Json$Encode$int),
					payload.aU))
			]));
};
var $author$project$Request$encode = function (req) {
	switch (req.$) {
		case 0:
			var registers = req.a;
			return $author$project$Request$encodeWriteRegistersPayload(registers);
		case 1:
			var registers = req.a;
			return $author$project$Request$encodeReadRegisters(registers);
		case 2:
			var requestId = req.a;
			return $author$project$Request$encodeReconnect(requestId);
		case 3:
			return $author$project$Request$encodeQuit;
		case 4:
			var logLevel = req.a;
			var message = req.b;
			return A2($author$project$Request$encodeLog, logLevel, message);
		default:
			var registers = req.a;
			return $author$project$Request$encodeSendCommissioningDataPayload(registers);
	}
};
var $author$project$Main$outgoing = _Platform_outgoingPort('outgoing', $elm$core$Basics$identity);
var $author$project$Main$sendRequest = function (req) {
	return $author$project$Main$outgoing(
		$author$project$Request$encode(req));
};
var $author$project$Parameter$setValue = F2(
	function (value, parameter) {
		var _v0 = parameter.ba;
		if (_v0.$ === 1) {
			var x = _v0.a;
			return _Utils_update(
				parameter,
				{
					ba: $author$project$Parameter$Value$Ready(
						_Utils_update(
							x,
							{ba: value}))
				});
		} else {
			return parameter;
		}
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $mdgriffith$elm_animator$Animator$slowly = $mdgriffith$elm_animator$Animator$millis(400);
var $author$project$Parameter$SpecTimer = function (a) {
	return {$: 2, a: a};
};
var $author$project$Parameter$timerTick = function (param) {
	var _v0 = param.cR;
	switch (_v0.$) {
		case 0:
			return param;
		case 1:
			return param;
		case 2:
			var timeLeft = _v0.a;
			return _Utils_update(
				param,
				{
					cR: $author$project$Parameter$SpecTimer(
						A2($elm$core$Basics$max, 0, timeLeft - 1))
				});
		case 3:
			return param;
		case 4:
			return param;
		default:
			return param;
	}
};
var $author$project$Parameter$Register$toInt = function (_v0) {
	var reg = _v0;
	return reg;
};
var $author$project$Language$toString = function (language) {
	switch (language) {
		case 0:
			return 'en';
		case 1:
			return 'fi';
		case 2:
			return 'de';
		case 3:
			return 'no';
		case 4:
			return 'fr';
		default:
			return 'sv';
	}
};
var $author$project$ParameterId$toString = function (_v0) {
	var id = _v0;
	return $elm$core$String$fromInt(id);
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.cx;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.b$,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.cy,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.cq,
					_Utils_ap(http, url.b3)),
				url.co)));
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $mdgriffith$elm_animator$Animator$update = F3(
	function (newTime, _v0, model) {
		var updateModel = _v0.b;
		return A2(updateModel, newTime, model);
	});
var $Gizra$elm_debouncer$Debouncer$Internal$Check = function (a) {
	return {$: 4, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$InputProvidedAt = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $Gizra$elm_debouncer$Debouncer$Internal$ManualEmitAt = function (a) {
	return {$: 3, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal = function (a) {
	return {$: 2, a: a};
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$core$Process$sleep = _Process_sleep;
var $Gizra$elm_debouncer$Debouncer$Internal$Unsettled = function (a) {
	return {$: 1, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$cancel = function (_v0) {
	var config = _v0.a;
	var state = _v0.b;
	return A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, config, $Gizra$elm_debouncer$Debouncer$Internal$Settled);
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $Gizra$elm_debouncer$Debouncer$Internal$update = F2(
	function (msg, debouncer) {
		var wrappedConfig = debouncer.a;
		var config = wrappedConfig;
		var state = debouncer.b;
		switch (msg.$) {
			case 0:
				var input = msg.a;
				var time = msg.b;
				var newState = function () {
					if (!state.$) {
						return $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							{
								ao: $elm$core$Maybe$Nothing,
								aZ: time,
								D: A2(config.Z, input, $elm$core$Maybe$Nothing),
								a9: time
							});
					} else {
						var unsettled = state.a;
						return $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							_Utils_update(
								unsettled,
								{
									aZ: time,
									D: A2(config.Z, input, unsettled.D)
								}));
					}
				}();
				var newDebouncer = A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState);
				var checks = function () {
					if (!state.$) {
						return $elm_community$list_extra$List$Extra$unique(
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[config.I, config.J, config.P])));
					} else {
						return A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[config.P]));
					}
				}();
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty),
					A2(
						$elm$core$List$partition,
						function (interval) {
							return interval <= 0;
						},
						checks));
				var checkNow = _v1.a;
				var checkLater = _v1.b;
				var _v2 = checkNow ? A2(
					$Gizra$elm_debouncer$Debouncer$Internal$update,
					$Gizra$elm_debouncer$Debouncer$Internal$Check(time),
					newDebouncer) : _Utils_Tuple3(newDebouncer, _List_Nil, $elm$core$Maybe$Nothing);
				var checkedDebouncer = _v2.a;
				var checkedIntervals = _v2.b;
				var emit = _v2.c;
				return _Utils_Tuple3(
					checkedDebouncer,
					_Utils_ap(checkedIntervals, checkLater),
					emit);
			case 1:
				return _Utils_Tuple3(
					$Gizra$elm_debouncer$Debouncer$Internal$cancel(debouncer),
					_List_Nil,
					$elm$core$Maybe$Nothing);
			case 2:
				var emit = function () {
					if (!state.$) {
						return $elm$core$Maybe$Nothing;
					} else {
						var unsettled = state.a;
						return unsettled.D;
					}
				}();
				return _Utils_Tuple3(
					$Gizra$elm_debouncer$Debouncer$Internal$cancel(debouncer),
					_List_Nil,
					emit);
			case 3:
				var time = msg.a;
				if (!state.$) {
					return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
				} else {
					var unsettled = state.a;
					var _v7 = unsettled.D;
					if (!_v7.$) {
						var newState = $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							_Utils_update(
								unsettled,
								{
									ao: $elm$core$Maybe$Just(time),
									D: $elm$core$Maybe$Nothing
								}));
						var intervals = function () {
							var _v8 = config.I;
							if (!_v8.$) {
								var emit = _v8.a;
								return _List_fromArray(
									[emit]);
							} else {
								return _List_Nil;
							}
						}();
						return _Utils_Tuple3(
							A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState),
							intervals,
							unsettled.D);
					} else {
						return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
					}
				}
			default:
				var time = msg.a;
				if (!state.$) {
					return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
				} else {
					var unsettled = state.a;
					var shouldSettle = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							function (interval) {
								return _Utils_cmp(unsettled.aZ + interval, time) < 1;
							},
							config.P));
					var becauseEmitWhileUnsettled = function () {
						var _v13 = config.J;
						if (!_v13.$) {
							var interval = _v13.a;
							var _v14 = unsettled.ao;
							if (!_v14.$) {
								var lastEmittedAt = _v14.a;
								return _Utils_cmp(lastEmittedAt + interval, time) < 1;
							} else {
								return _Utils_cmp(unsettled.a9 + interval, time) < 1;
							}
						} else {
							return false;
						}
					}();
					var becauseEmitWhenUnsettled = function () {
						var _v11 = config.I;
						if (!_v11.$) {
							var interval = _v11.a;
							var _v12 = unsettled.ao;
							if (!_v12.$) {
								return false;
							} else {
								return _Utils_cmp(unsettled.a9 + interval, time) < 1;
							}
						} else {
							return false;
						}
					}();
					var shouldEmit = (!_Utils_eq(unsettled.D, $elm$core$Maybe$Nothing)) && (shouldSettle || (becauseEmitWhenUnsettled || becauseEmitWhileUnsettled));
					var emit = shouldEmit ? unsettled.D : $elm$core$Maybe$Nothing;
					var intervals = function () {
						if (shouldEmit && (!shouldSettle)) {
							var _v10 = config.J;
							if (!_v10.$) {
								var interval = _v10.a;
								return _List_fromArray(
									[interval]);
							} else {
								return _List_Nil;
							}
						} else {
							return _List_Nil;
						}
					}();
					var newState = shouldSettle ? $Gizra$elm_debouncer$Debouncer$Internal$Settled : (shouldEmit ? $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
						_Utils_update(
							unsettled,
							{
								ao: $elm$core$Maybe$Just(time),
								D: $elm$core$Maybe$Nothing
							})) : state);
					return _Utils_Tuple3(
						A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState),
						intervals,
						emit);
				}
		}
	});
var $Gizra$elm_debouncer$Debouncer$Basic$update = F2(
	function (msg, debouncer) {
		switch (msg.$) {
			case 0:
				var input = msg.a;
				return _Utils_Tuple3(
					debouncer,
					A2(
						$elm$core$Task$perform,
						A2(
							$elm$core$Basics$composeL,
							A2(
								$elm$core$Basics$composeL,
								$Gizra$elm_debouncer$Debouncer$Basic$MsgInternal,
								$Gizra$elm_debouncer$Debouncer$Internal$InputProvidedAt(input)),
							$elm$time$Time$posixToMillis),
						$elm$time$Time$now),
					$elm$core$Maybe$Nothing);
			case 1:
				return _Utils_Tuple3(
					debouncer,
					A2(
						$elm$core$Task$perform,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal, $Gizra$elm_debouncer$Debouncer$Internal$ManualEmitAt),
							$elm$time$Time$posixToMillis),
						$elm$time$Time$now),
					$elm$core$Maybe$Nothing);
			default:
				var subMsg = msg.a;
				var _v1 = A2($Gizra$elm_debouncer$Debouncer$Internal$update, subMsg, debouncer);
				var updatedDebouncer = _v1.a;
				var intervals = _v1.b;
				var output = _v1.c;
				var cmds = $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						function (interval) {
							return A2(
								$elm$core$Task$perform,
								A2(
									$elm$core$Basics$composeL,
									A2($elm$core$Basics$composeL, $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal, $Gizra$elm_debouncer$Debouncer$Internal$Check),
									$elm$time$Time$posixToMillis),
								A2(
									$elm$core$Task$andThen,
									$elm$core$Basics$always($elm$time$Time$now),
									$elm$core$Process$sleep(interval)));
						},
						intervals));
				return _Utils_Tuple3(updatedDebouncer, cmds, output);
		}
	});
var $author$project$Main$updateIf = F3(
	function (predicate, updateFn, dict) {
		return A2(
			$pzp1997$assoc_list$AssocList$map,
			function (_v0) {
				return function (value) {
					return predicate(value) ? updateFn(value) : value;
				};
			},
			dict);
	});
var $author$project$Main$ReconnectContent = function (a) {
	return {$: 2, a: a};
};
var $pzp1997$assoc_list$AssocList$update = F3(
	function (targetKey, alter, dict) {
		var alist = dict;
		var maybeValue = A2($pzp1997$assoc_list$AssocList$get, targetKey, dict);
		if (!maybeValue.$) {
			var _v1 = alter(maybeValue);
			if (!_v1.$) {
				var alteredValue = _v1.a;
				return A2(
					$elm$core$List$map,
					function (entry) {
						var key = entry.a;
						return _Utils_eq(key, targetKey) ? _Utils_Tuple2(targetKey, alteredValue) : entry;
					},
					alist);
			} else {
				return A2($pzp1997$assoc_list$AssocList$remove, targetKey, dict);
			}
		} else {
			var _v2 = alter($elm$core$Maybe$Nothing);
			if (!_v2.$) {
				var alteredValue = _v2.a;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(targetKey, alteredValue),
					alist);
			} else {
				return dict;
			}
		}
	});
var $author$project$Main$updateModelByResponseErr = F2(
	function (requestId, model) {
		var setError = function (maybeReq) {
			return A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$mapFirst(
					$elm$core$Basics$always(1)),
				maybeReq);
		};
		return _Utils_update(
			model,
			{
				t: A3($pzp1997$assoc_list$AssocList$update, requestId, setError, model.t),
				c: {
					bO: $author$project$Main$ReconnectContent(
						{
							cX: $author$project$Translate$Key$Static(5),
							cY: $author$project$Translate$Key$Static(6)
						}),
					a1: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, true, model.c.a1)
				}
			});
	});
var $author$project$Main$updateModelByResponseOk = F2(
	function (requestId, model) {
		return _Utils_update(
			model,
			{
				t: A2($pzp1997$assoc_list$AssocList$remove, requestId, model.t)
			});
	});
var $author$project$Parameter$RegisterValue$get = function (_v0) {
	var value = _v0;
	return value;
};
var $author$project$Parameter$Value$Unlocked = {$: 0};
var $author$project$Parameter$Value$versionGreaterThanOrEqual = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return _Utils_cmp(a, b) > -1;
	});
var $author$project$Parameter$Value$updateRemoteValue = F3(
	function (version, remoteValue, value) {
		if (!value.$) {
			return $author$project$Parameter$Value$Ready(
				{
					ab: $author$project$Parameter$Value$Unlocked,
					aG: remoteValue,
					aH: version,
					ba: $author$project$Parameter$RegisterValue$get(remoteValue)
				});
		} else {
			var x = value.a;
			var isLocked = function () {
				var _v2 = x.ab;
				switch (_v2.$) {
					case 1:
						return true;
					case 2:
						var lockVersion = _v2.a;
						return !_Utils_eq(lockVersion, version);
					default:
						return false;
				}
			}();
			var newLock = isLocked ? x.ab : $author$project$Parameter$Value$Unlocked;
			var _v1 = A2($author$project$Parameter$Value$versionGreaterThanOrEqual, version, x.aH) ? _Utils_Tuple2(remoteValue, version) : _Utils_Tuple2(x.aG, x.aH);
			var newRemoteValue = _v1.a;
			var newRemoteValueVersion = _v1.b;
			var newValue = isLocked ? x.ba : $author$project$Parameter$RegisterValue$get(newRemoteValue);
			return $author$project$Parameter$Value$Ready(
				_Utils_update(
					x,
					{ab: newLock, aG: newRemoteValue, aH: newRemoteValueVersion, ba: newValue}));
		}
	});
var $author$project$Parameter$updateRemoteValue = F3(
	function (version, registerValue, parameter) {
		return _Utils_update(
			parameter,
			{
				cR: function () {
					var _v0 = parameter.cR;
					if (_v0.$ === 2) {
						return $author$project$Parameter$SpecTimer(
							$author$project$Parameter$RegisterValue$get(registerValue));
					} else {
						return parameter.cR;
					}
				}(),
				ba: A3($author$project$Parameter$Value$updateRemoteValue, version, registerValue, parameter.ba)
			});
	});
var $pzp1997$assoc_list$AssocList$values = function (_v0) {
	var alist = _v0;
	return A2($elm$core$List$map, $elm$core$Tuple$second, alist);
};
var $elm_community$maybe_extra$Maybe$Extra$cons = F2(
	function (item, list) {
		if (!item.$) {
			var v = item.a;
			return A2($elm$core$List$cons, v, list);
		} else {
			return list;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$values = A2($elm$core$List$foldr, $elm_community$maybe_extra$Maybe$Extra$cons, _List_Nil);
var $author$project$Main$linkActionsToNewModelAndCmds = F2(
	function (model, actions) {
		var combineValueChanges = function (msgs) {
			var valueChanges = function (changes) {
				return $elm$core$List$isEmpty(changes) ? _List_Nil : _List_fromArray(
					[
						$author$project$Main$GotParameterValueChanges(changes)
					]);
			}(
				A3(
					$elm$core$List$foldr,
					F2(
						function (changes, acc) {
							return _Utils_ap(changes, acc);
						}),
					_List_Nil,
					A2(
						$elm$core$List$filterMap,
						function (x) {
							if (x.$ === 1) {
								var changes = x.a;
								return $elm$core$Maybe$Just(changes);
							} else {
								return $elm$core$Maybe$Nothing;
							}
						},
						msgs)));
			var rest = A2(
				$elm$core$List$filterMap,
				function (x) {
					if (x.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var other = x;
						return $elm$core$Maybe$Just(other);
					}
				},
				msgs);
			return _Utils_ap(valueChanges, rest);
		};
		return A3(
			$elm$core$List$foldr,
			F2(
				function (nextAction, acc) {
					var _v33 = acc;
					var previousModel = _v33.a;
					var previousCmds = _v33.b;
					var _v34 = A2($author$project$Main$update, nextAction, previousModel);
					var newModel = _v34.a;
					var newCmds = _v34.b;
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[previousCmds, newCmds])));
				}),
			_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
			combineValueChanges(
				A2(
					$elm$core$List$map,
					function (action) {
						switch (action.$) {
							case 0:
								var x = action.a;
								return $author$project$Main$GotParameterValueChanges(
									_List_fromArray(
										[
											{aV: false, aX: x.aX, ba: x.ba}
										]));
							case 1:
								var x = action.a;
								var data = A2(
									$elm_community$list_extra$List$Extra$uniqueBy,
									A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Parameter$Register$toInt),
									A2(
										$elm$core$List$filterMap,
										function (p) {
											return A2(
												$elm$core$Maybe$map,
												$elm$core$Tuple$pair(p.O),
												$author$project$Parameter$getValue(p));
										},
										A2(
											$elm$core$List$filterMap,
											function (id) {
												return A2($pzp1997$assoc_list$AssocList$get, id, model.aq);
											},
											x.dx)));
								return $author$project$Main$SendRequest(
									$author$project$Request$SendCommissioningData(
										{aU: data}));
							case 2:
								var x = action.a;
								var sourceValue = A2(
									$elm$core$Maybe$andThen,
									$author$project$Parameter$getValue,
									A2($pzp1997$assoc_list$AssocList$get, x.ec, model.aq));
								if (!sourceValue.$) {
									var value = sourceValue.a;
									return $author$project$Main$GotParameterValueChanges(
										_List_fromArray(
											[
												{aV: false, aX: x.ei, ba: value}
											]));
								} else {
									return $author$project$Main$SendRequest(
										A2($author$project$Request$Log, 0, 'No source value, unable to copy value'));
								}
							default:
								return $author$project$Main$SendRequest($author$project$Request$Quit);
						}
					},
					actions)));
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 5:
					var newTime = msg.a;
					return _Utils_Tuple2(
						A3($mdgriffith$elm_animator$Animator$update, newTime, $author$project$Main$animator, model),
						$elm$core$Platform$Cmd$none);
				case 0:
					if (!msg.a.$) {
						var event = msg.a.a;
						switch (event.$) {
							case 0:
								var ev = event.a;
								var readRegistersRequest = function (registers) {
									return $author$project$Request$ReadRegistersRequest(
										{aX: model.L, cB: registers});
								}(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.O;
										},
										ev.aq));
								var _v2 = A2(
									$author$project$Main$update,
									$author$project$Main$SendRequest(readRegistersRequest),
									model);
								var newModel = _v2.a;
								var newCmds = _v2.b;
								return _Utils_Tuple2(
									_Utils_update(
										newModel,
										{
											c: {
												bO: model.c.bO,
												a1: $mdgriffith$elm_animator$Animator$init(false)
											},
											r: $mdgriffith$elm_animator$Animator$init(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													A2(
														$elm$core$Maybe$map,
														A2(
															$elm$core$Basics$composeL,
															$elm$core$List$singleton,
															function ($) {
																return $.aX;
															}),
														$elm$core$List$head(ev.S)))),
											S: $pzp1997$assoc_list$AssocList$fromList(
												A2(
													$elm$core$List$map,
													function (p) {
														return A2($elm$core$Tuple$pair, p.aX, p);
													},
													ev.S)),
											aq: $pzp1997$assoc_list$AssocList$fromList(
												A2(
													$elm$core$List$map,
													function (p) {
														return A2($elm$core$Tuple$pair, p.aX, p);
													},
													ev.aq)),
											bE: ev.bE
										}),
									newCmds);
							case 5:
								var response = event.a;
								var _v3 = A2(
									$author$project$Main$update,
									$author$project$Main$GotResponse(response),
									model);
								var newModel = _v3.a;
								var newCmd = _v3.b;
								return _Utils_Tuple2(newModel, newCmd);
							case 1:
								var linkPreviousActions = A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.aS;
										},
										A2(
											$elm$core$Maybe$andThen,
											function ($) {
												return $.dI;
											},
											A2(
												$elm$core$Maybe$andThen,
												function (pageId) {
													return A2($pzp1997$assoc_list$AssocList$get, pageId, model.S);
												},
												$elm$core$List$head(
													$mdgriffith$elm_animator$Animator$current(model.r))))));
								var _v4 = A2(
									$author$project$Main$update,
									$author$project$Main$GotNavigationMsg(
										$author$project$Main$LinkPressed(
											{aS: linkPreviousActions, eh: $elm$core$Maybe$Nothing})),
									model);
								var newModel = _v4.a;
								var cmds = _v4.b;
								return _Utils_Tuple2(newModel, cmds);
							case 2:
								var insets = event.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{G: insets}),
									$elm$core$Platform$Cmd$none);
							case 4:
								var pageId = event.a;
								return A2($pzp1997$assoc_list$AssocList$member, pageId, model.S) ? _Utils_Tuple2(
									_Utils_update(
										model,
										{
											r: $mdgriffith$elm_animator$Animator$init(
												_List_fromArray(
													[pageId]))
										}),
									$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
									_Utils_update(
										model,
										{
											c: {
												bO: $author$project$Main$ErrorContent(
													{
														cX: $author$project$Translate$Key$Static(3),
														cY: $author$project$Translate$Key$Static(4)
													}),
												a1: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, true, model.c.a1)
											}
										}),
									$elm$core$Platform$Cmd$none);
							default:
								var config = event.a;
								var minimumReadRegistersPollingTimeMs = 2000;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											c: $elm_community$maybe_extra$Maybe$Extra$isJust(config.dF) ? model.c : {
												bO: $author$project$Main$LanguageSelectorContent,
												a1: $mdgriffith$elm_animator$Animator$init(true)
											},
											dF: A2($elm$core$Maybe$withDefault, model.dF, config.dF),
											d2: config.d2,
											d3: A2(
												$elm$core$Maybe$withDefault,
												model.d3,
												A2(
													$elm$core$Maybe$map,
													$elm$core$Basics$max(minimumReadRegistersPollingTimeMs),
													config.d3)),
											d9: config.d9,
											ek: config.ek
										}),
									$elm$core$Platform$Cmd$none);
						}
					} else {
						var err = msg.a.a;
						var errorString = $elm$json$Json$Decode$errorToString(err);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c: {
										bO: $author$project$Main$ErrorContent(
											{
												cX: $author$project$Translate$Key$Static(5),
												cY: $author$project$Translate$Key$Static(6)
											}),
										a1: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, true, model.c.a1)
									}
								}),
							$author$project$Main$sendRequest(
								A2($author$project$Request$Log, 0, errorString)));
					}
				case 1:
					var changes = msg.a;
					var updateParameterValue = F2(
						function (change, modelToUpdate) {
							var changedRegister = A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.O;
								},
								A2($pzp1997$assoc_list$AssocList$get, change.aX, model.aq));
							return _Utils_update(
								modelToUpdate,
								{
									aq: A3(
										$author$project$Main$updateIf,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.aX;
											},
											$elm$core$Basics$eq(change.aX)),
										$author$project$Parameter$lock,
										A3(
											$author$project$Main$updateIf,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.O;
												},
												A2(
													$elm$core$Basics$composeR,
													$elm$core$Maybe$Just,
													$elm$core$Basics$eq(changedRegister))),
											$author$project$Parameter$setValue(change.ba),
											modelToUpdate.aq))
								});
						});
					var instantChanges = A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.aV;
							},
							$elm$core$Basics$not),
						changes);
					var debouncedChanges = A2(
						$elm$core$List$filter,
						function ($) {
							return $.aV;
						},
						changes);
					var modelWithUpdatedValues = A3(
						$elm$core$List$foldr,
						updateParameterValue,
						model,
						_Utils_ap(instantChanges, debouncedChanges));
					var _v5 = $elm$core$List$isEmpty(instantChanges) ? _Utils_Tuple2(modelWithUpdatedValues, $elm$core$Platform$Cmd$none) : A2(
						$author$project$Main$update,
						$author$project$Main$WriteRegisters(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.aX;
								},
								instantChanges)),
						modelWithUpdatedValues);
					var instantModel = _v5.a;
					var instantCmds = _v5.b;
					var _v6 = A3(
						$elm$core$List$foldr,
						F2(
							function (change, acc) {
								var _v7 = acc;
								var oldModel = _v7.a;
								var oldCmds = _v7.b;
								var _v8 = A2(
									$author$project$Main$update,
									$author$project$Main$MsgDebounce(
										$Gizra$elm_debouncer$Debouncer$Basic$provideInput(change.aX)),
									oldModel);
								var newModel = _v8.a;
								var newCmds = _v8.b;
								return _Utils_Tuple2(
									newModel,
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[oldCmds, newCmds])));
							}),
						_Utils_Tuple2(instantModel, instantCmds),
						debouncedChanges);
					var debouncedModel = _v6.a;
					var debouncedCmds = _v6.b;
					return _Utils_Tuple2(debouncedModel, debouncedCmds);
				case 2:
					if (msg.a.$ === 1) {
						var _v9 = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Main$sendRequest($author$project$Request$Quit));
					} else {
						var link = msg.a.a;
						var hasQuitAction = A2(
							$elm$core$List$any,
							$elm$core$Basics$eq($author$project$Node$Link$LinkActionQuit),
							link.aS);
						var openLinkCmd = function () {
							if (hasQuitAction) {
								return $elm$core$Platform$Cmd$none;
							} else {
								var _v13 = link.eh;
								if ((!_v13.$) && (_v13.a.$ === 1)) {
									var url = _v13.a.a;
									var replacements = _List_fromArray(
										[
											_Utils_Tuple2(
											'{{serialNumber}}',
											A2($elm$core$Maybe$withDefault, '', model.d9)),
											_Utils_Tuple2(
											'{{language}}',
											$author$project$Language$toString(model.dF))
										]);
									var filledUrl = A3(
										$elm$core$List$foldl,
										F2(
											function (_v14, string) {
												var placeholder = _v14.a;
												var placeholderValue = _v14.b;
												return A3($elm$core$String$replace, placeholder, placeholderValue, string);
											}),
										$elm$url$Url$toString(url),
										replacements);
									return $elm$browser$Browser$Navigation$load(filledUrl);
								} else {
									return $elm$core$Platform$Cmd$none;
								}
							}
						}();
						var currentNavStack = $mdgriffith$elm_animator$Animator$current(model.r);
						var newNavStack = function () {
							var _v11 = link.eh;
							if (!_v11.$) {
								var target = _v11.a;
								if (!target.$) {
									var targetPage = target.a;
									return A2($elm$core$List$member, targetPage, currentNavStack) ? A2(
										$elm_community$list_extra$List$Extra$dropWhile,
										$elm$core$Basics$neq(targetPage),
										currentNavStack) : A2($elm$core$List$cons, targetPage, currentNavStack);
								} else {
									return currentNavStack;
								}
							} else {
								return A2($elm$core$List$drop, 1, currentNavStack);
							}
						}();
						var previous = (_Utils_cmp(
							$elm$core$List$length(newNavStack),
							$elm$core$List$length(currentNavStack)) > 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(currentNavStack);
						var _v10 = A2($author$project$Main$linkActionsToNewModelAndCmds, model, link.aS);
						var newModel = _v10.a;
						var newCmds = _v10.b;
						return $elm$core$List$isEmpty(newNavStack) ? _Utils_Tuple2(
							model,
							$author$project$Main$sendRequest($author$project$Request$Quit)) : _Utils_Tuple2(
							_Utils_update(
								newModel,
								{
									r: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, newNavStack, newModel.r),
									a5: previous
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[newCmds, openLinkCmd])));
					}
				case 3:
					var parameterIds = msg.a;
					var parametersToWrite = $elm_community$maybe_extra$Maybe$Extra$values(
						A2(
							$elm$core$List$map,
							function (parameterId) {
								return A2($pzp1997$assoc_list$AssocList$get, parameterId, model.aq);
							},
							parameterIds));
					var registersAndValues = $elm_community$maybe_extra$Maybe$Extra$values(
						A2(
							$elm$core$List$map,
							function (p) {
								return A2(
									$elm$core$Maybe$map,
									$elm$core$Tuple$pair(p.O),
									$author$project$Parameter$getValue(p));
							},
							parametersToWrite));
					var writeRequest = $author$project$Request$WriteRegistersRequest(
						{aU: registersAndValues, aX: model.L});
					var newParameters = A3(
						$author$project$Main$updateIf,
						function (p) {
							return A2(
								$elm$core$List$member,
								p.O,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.O;
									},
									parametersToWrite));
						},
						$author$project$Parameter$lockWithVersion(
							$author$project$Parameter$Value$remoteValueVersionFromInt(
								$author$project$RequestId$toInt(model.L))),
						model.aq);
					var _v15 = A2(
						$author$project$Main$update,
						$author$project$Main$SendRequest(writeRequest),
						_Utils_update(
							model,
							{aq: newParameters}));
					var newModel = _v15.a;
					var newCmds = _v15.b;
					return _Utils_Tuple2(newModel, newCmds);
				case 4:
					var subMsg = msg.a;
					var _v16 = A2($Gizra$elm_debouncer$Debouncer$Basic$update, subMsg, model.aW);
					var subModel = _v16.a;
					var subCmd = _v16.b;
					var emittedMsg = _v16.c;
					var mappedCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$MsgDebounce, subCmd);
					var updatedModel = _Utils_update(
						model,
						{aW: subModel});
					if (!emittedMsg.$) {
						var emitted = emittedMsg.a;
						var all = A2(
							$elm_community$list_extra$List$Extra$uniqueBy,
							$author$project$ParameterId$toString,
							A2(
								$elm$core$List$map,
								function (_v18) {
									var m = _v18;
									return m;
								},
								emitted));
						return A2(
							$elm$core$Tuple$mapSecond,
							function (cmd) {
								return $elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[cmd, mappedCmd]));
							},
							A2(
								$author$project$Main$update,
								$author$project$Main$WriteRegisters(all),
								updatedModel));
					} else {
						return _Utils_Tuple2(updatedModel, mappedCmd);
					}
				case 6:
					var result = msg.a;
					if (!result.$) {
						var payload = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									cF: {du: payload.bU.du, aR: payload.bU.aR}
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var err = result.a;
						return _Utils_Tuple2(
							model,
							$author$project$Main$sendRequest(
								A2($author$project$Request$Log, 0, 'Missing root element.')));
					}
				case 7:
					return _Utils_Tuple2(
						model,
						A2(
							$elm$core$Task$attempt,
							$author$project$Main$GotRootElement,
							$elm$browser$Browser$Dom$getElement('root')));
				case 8:
					var request = msg.a;
					var nextRequestId = function () {
						var _v22 = $author$project$Request$getId(request);
						if (!_v22.$) {
							return A2($author$project$RequestId$plus, model.L, 1);
						} else {
							return model.L;
						}
					}();
					var activeRequests = function () {
						var _v21 = $author$project$Request$getId(request);
						if (!_v21.$) {
							var id = _v21.a;
							return A3(
								$pzp1997$assoc_list$AssocList$insert,
								id,
								_Utils_Tuple2(0, request),
								model.t);
						} else {
							return model.t;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{t: activeRequests, L: nextRequestId}),
						$author$project$Main$sendRequest(request));
				case 9:
					switch (msg.a.$) {
						case 0:
							var response = msg.a.a;
							if (!response.$) {
								var payload = response.a;
								return _Utils_Tuple2(
									A2(
										$author$project$Main$updateModelByResponseOk,
										payload.aX,
										_Utils_update(
											model,
											{
												aq: A3(
													$elm$core$List$foldl,
													F2(
														function (_v24, params) {
															var reg = _v24.a;
															var value = _v24.b;
															return A3(
																$author$project$Main$updateIf,
																A2(
																	$elm$core$Basics$composeR,
																	function ($) {
																		return $.O;
																	},
																	$elm$core$Basics$eq(reg)),
																A2(
																	$author$project$Parameter$updateRemoteValue,
																	$author$project$Parameter$Value$remoteValueVersionFromInt(
																		$author$project$RequestId$toInt(payload.aX)),
																	value),
																params);
														}),
													model.aq,
													payload.aU)
											})),
									$elm$core$Platform$Cmd$none);
							} else {
								var requestId = response.a;
								return _Utils_Tuple2(
									A2($author$project$Main$updateModelByResponseErr, requestId, model),
									$elm$core$Platform$Cmd$none);
							}
						case 1:
							var response = msg.a.a;
							if (!response.$) {
								var payload = response.a;
								return _Utils_Tuple2(
									A2(
										$author$project$Main$updateModelByResponseOk,
										payload.aX,
										_Utils_update(
											model,
											{
												aq: A3(
													$elm$core$List$foldl,
													F2(
														function (_v26, params) {
															var reg = _v26.a;
															var value = _v26.b;
															return A3(
																$author$project$Main$updateIf,
																A2(
																	$elm$core$Basics$composeR,
																	function ($) {
																		return $.O;
																	},
																	$elm$core$Basics$eq(reg)),
																A2(
																	$author$project$Parameter$updateRemoteValue,
																	$author$project$Parameter$Value$remoteValueVersionFromInt(
																		$author$project$RequestId$toInt(payload.aX)),
																	value),
																params);
														}),
													model.aq,
													payload.aU)
											})),
									$elm$core$Platform$Cmd$none);
							} else {
								var requestId = response.a;
								return _Utils_Tuple2(
									A2($author$project$Main$updateModelByResponseErr, requestId, model),
									$elm$core$Platform$Cmd$none);
							}
						default:
							var response = msg.a.a;
							if (!response.$) {
								var requestId = response.a;
								var modelCleared = _Utils_update(
									model,
									{
										t: $pzp1997$assoc_list$AssocList$empty,
										c: {
											bO: model.c.bO,
											a1: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, false, model.c.a1)
										}
									});
								var allParameters = $pzp1997$assoc_list$AssocList$values(model.aq);
								var _v28 = A2(
									$author$project$Main$update,
									$author$project$Main$LoadParameters(allParameters),
									A2($author$project$Main$updateModelByResponseOk, requestId, modelCleared));
								var newModel = _v28.a;
								var newCmds = _v28.b;
								return _Utils_Tuple2(newModel, newCmds);
							} else {
								var requestId = response.a;
								return _Utils_Tuple2(
									A2($author$project$Main$updateModelByResponseErr, requestId, model),
									$elm$core$Platform$Cmd$none);
							}
					}
				case 10:
					var a = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								c: {
									bO: $author$project$Main$InfoContent(
										{
											b5: a.b5,
											cX: $author$project$Translate$Key$Dynamic(a.dz),
											cY: A2($elm$core$Maybe$map, $author$project$Translate$Key$Dynamic, a.cY)
										}),
									a1: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, true, model.c.a1)
								}
							}),
						$elm$core$Platform$Cmd$none);
				case 11:
					var current = model.c;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								c: _Utils_update(
									current,
									{
										a1: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, false, model.c.a1)
									})
							}),
						$elm$core$Platform$Cmd$none);
				case 12:
					var lang = msg.a;
					var current = model.c;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								c: _Utils_update(
									current,
									{
										a1: A3($mdgriffith$elm_animator$Animator$go, $mdgriffith$elm_animator$Animator$slowly, false, model.c.a1)
									}),
								dF: lang
							}),
						$elm$core$Platform$Cmd$none);
				case 13:
					var parameters = msg.a;
					var anyPendingOrFailedReadOrWriteRequests = A2(
						$elm$core$List$any,
						function (_v29) {
							var state = _v29.a;
							var req = _v29.b;
							return ((!state) || (state === 1)) && ($author$project$Request$isReadRegistersRequest(req) || $author$project$Request$isWriteRegistersRequest(req));
						},
						$pzp1997$assoc_list$AssocList$values(model.t));
					if (anyPendingOrFailedReadOrWriteRequests) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var readRegistersRequest = function (registers) {
							return $author$project$Request$ReadRegistersRequest(
								{
									aX: model.L,
									cB: A2($elm_community$list_extra$List$Extra$uniqueBy, $author$project$Parameter$Register$toInt, registers)
								});
						}(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.O;
								},
								parameters));
						var $temp$msg = $author$project$Main$SendRequest(readRegistersRequest),
							$temp$model = model;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					}
				default:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aq: A2(
									$pzp1997$assoc_list$AssocList$map,
									F2(
										function (_v30, param) {
											return $author$project$Parameter$timerTick(param);
										}),
									model.aq)
							}),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Main$init = function (_v0) {
	var model = {
		t: $pzp1997$assoc_list$AssocList$empty,
		c: {
			bO: $author$project$Main$InfoContent(
				{
					b5: $elm$core$Maybe$Nothing,
					cX: $author$project$Translate$Key$Dynamic(''),
					cY: $elm$core$Maybe$Nothing
				}),
			a1: $mdgriffith$elm_animator$Animator$init(false)
		},
		aW: $Gizra$elm_debouncer$Debouncer$Basic$toDebouncer(
			A2(
				$Gizra$elm_debouncer$Debouncer$Basic$accumulateWith,
				$Gizra$elm_debouncer$Debouncer$Basic$allInputs,
				$Gizra$elm_debouncer$Debouncer$Basic$debounce(
					$Gizra$elm_debouncer$Debouncer$Basic$fromSeconds(0.7)))),
		dF: 0,
		r: $mdgriffith$elm_animator$Animator$init(_List_Nil),
		L: $author$project$RequestId$init,
		S: $pzp1997$assoc_list$AssocList$empty,
		aq: $pzp1997$assoc_list$AssocList$empty,
		a5: $elm$core$Maybe$Nothing,
		d2: true,
		d3: 8000,
		cF: {du: 0, aR: 0},
		G: {dd: 0, dG: 0, d7: 0, el: 0},
		d9: $elm$core$Maybe$Nothing,
		ek: $elm$core$Maybe$Nothing,
		bE: $elm$core$Dict$empty
	};
	return _Utils_Tuple2(
		model,
		A2($author$project$Main$update, $author$project$Main$GetRootElement, model).b);
};
var $author$project$Main$EventReceived = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Tick = function (a) {
	return {$: 5, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Event$BackPressed = {$: 1};
var $author$project$Event$Config = function (a) {
	return {$: 3, a: a};
};
var $author$project$Event$OpenPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Event$Response = function (a) {
	return {$: 5, a: a};
};
var $author$project$Event$SafeAreaInsets = function (a) {
	return {$: 2, a: a};
};
var $author$project$Event$Schema = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Event$Config$Config = F5(
	function (language, thingNickname, serialNumber, quitEnabled, readRegistersPollingTimeMs) {
		return {dF: language, d2: quitEnabled, d3: readRegistersPollingTimeMs, d9: serialNumber, ek: thingNickname};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Language$Finnish = 1;
var $author$project$Language$French = 4;
var $author$project$Language$German = 2;
var $author$project$Language$Norwegian = 3;
var $author$project$Language$Swedish = 5;
var $author$project$Language$fromString = function (str) {
	switch (str) {
		case 'en':
			return $elm$core$Maybe$Just(0);
		case 'fi':
			return $elm$core$Maybe$Just(1);
		case 'de':
			return $elm$core$Maybe$Just(2);
		case 'no':
			return $elm$core$Maybe$Just(3);
		case 'fr':
			return $elm$core$Maybe$Just(4);
		case 'sv':
			return $elm$core$Maybe$Just(5);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Language$decoder = function () {
	var strDecoder = function (str) {
		var _v0 = $author$project$Language$fromString(str);
		if (!_v0.$) {
			var lang = _v0.a;
			return $elm$json$Json$Decode$succeed(lang);
		} else {
			return $elm$json$Json$Decode$fail('Trying to decode language, but language ' + (str + ' is not supported.'));
		}
	};
	return A2($elm$json$Json$Decode$andThen, strDecoder, $elm$json$Json$Decode$string);
}();
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$map5 = _Json_map5;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $elm_community$json_extra$Json$Decode$Extra$optionalField = F2(
	function (fieldName, decoder) {
		var finishDecoding = function (json) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$field, fieldName, $elm$json$Json$Decode$value),
				json);
			if (!_v0.$) {
				var val = _v0.a;
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$Just,
					A2($elm$json$Json$Decode$field, fieldName, decoder));
			} else {
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			}
		};
		return A2($elm$json$Json$Decode$andThen, finishDecoding, $elm$json$Json$Decode$value);
	});
var $author$project$Event$Config$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Event$Config$Config,
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'language', $author$project$Language$decoder),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'thingNickname', $elm$json$Json$Decode$string),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'serialNumber', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(true),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'quitEnabled', $elm$json$Json$Decode$bool)),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'readRegistersPollingTimeMs', $elm$json$Json$Decode$float));
var $author$project$Event$Insets$Insets = F4(
	function (top, right, bottom, left) {
		return {dd: bottom, dG: left, d7: right, el: top};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Event$Insets$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Event$Insets$Insets,
	A2($elm$json$Json$Decode$field, 'top', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'right', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'bottom', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'left', $elm$json$Json$Decode$float));
var $author$project$Event$Schema$Schema = F4(
	function (pages, parameters, translations, version) {
		return {S: pages, aq: parameters, bE: translations, bH: version};
	});
var $author$project$Page$Page = F5(
	function (id, title, nodes, linkPrevious, linkNext) {
		return {aX: id, cf: linkNext, dI: linkPrevious, a0: nodes, cY: title};
	});
var $author$project$Node$NodeDateTime = function (a) {
	return {$: 5, a: a};
};
var $author$project$Node$NodeImage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Node$NodeLink = function (a) {
	return {$: 1, a: a};
};
var $author$project$Node$NodeParameter = function (a) {
	return {$: 0, a: a};
};
var $author$project$Node$NodeText = function (a) {
	return {$: 3, a: a};
};
var $author$project$Node$NodeWeeklyTimer = function (a) {
	return {$: 4, a: a};
};
var $author$project$Node$DateTime$DateTime = F9(
	function (title, timeId, dayId, monthId, yearId, target, marginBottom, actions, visibility) {
		return {aS: actions, bQ: dayId, ap: marginBottom, cj: monthId, eh: target, bD: timeId, cY: title, av: visibility, c4: yearId};
	});
var $elm_community$json_extra$Json$Decode$Extra$andMap = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $author$project$PageId$PageId = $elm$core$Basics$identity;
var $author$project$PageId$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$ParameterId$ParameterId = $elm$core$Basics$identity;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$ParameterId$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $author$project$Visibility$deadEndToString = function (end) {
	var col = $elm$core$String$fromInt(end.dh);
	var prefix = 'Problem parsing visibility (col=' + (col + '). ');
	var _v0 = end.d1;
	switch (_v0.$) {
		case 0:
			var a = _v0.a;
			return _Utils_ap(prefix, a);
		case 1:
			return prefix + 'Expecting int.';
		case 2:
			return prefix + 'Expected hex.';
		case 3:
			return prefix + 'Expecting octal.';
		case 4:
			return prefix + 'Expecting binary.';
		case 5:
			return prefix + 'Expecting float.';
		case 6:
			return prefix + 'Expecting number.';
		case 7:
			return prefix + 'Expecting variable.';
		case 8:
			var a = _v0.a;
			return prefix + ('Expecting symbol ' + a);
		case 9:
			var a = _v0.a;
			return prefix + ('Expecting keyword ' + a);
		case 10:
			return prefix + 'Expecting end.';
		case 11:
			return prefix + 'Unexpected char.';
		case 12:
			var a = _v0.a;
			return _Utils_ap(prefix, a);
		default:
			return prefix + 'Bad repeat.';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {dh: col, di: contextStack, d1: problem, cG: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.cG, s.dh, x, s.d));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $author$project$Visibility$And = 0;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $author$project$Visibility$ExprAnd = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Visibility$ExprComparison = function (a) {
	return {$: 2, a: a};
};
var $author$project$Visibility$ExprFalse = {$: 1};
var $author$project$Visibility$ExprOr = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Visibility$ExprTrue = {$: 0};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $author$project$Visibility$Or = 1;
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $author$project$Visibility$booleanOperatorToString = function (expType) {
	if (!expType) {
		return '&&';
	} else {
		return '||';
	}
};
var $author$project$Visibility$Equal = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Visibility$ComparisonTargetInt = function (a) {
	return {$: 1, a: a};
};
var $author$project$Visibility$ComparisonTargetParameterId = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {dh: s.dh + (newOffset - s.b), d: s.d, e: s.e, b: newOffset, cG: s.cG, a: s.a};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.b, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.a);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.cG, s.dh - (floatOffset + s.b), invalid, s.d));
		} else {
			if (_Utils_eq(s.b, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.b, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.b, floatOffset, s.a));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.b, s.a)) {
			var zeroOffset = s.b + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.dE,
				c.dv,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.dE,
				c.dY,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.dE,
				c.db,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.a),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.dE,
				c.bY,
				c.dC,
				c.dt,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.dE,
				c.bY,
				c.dC,
				c.dt,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.b, s.a),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				db: $elm$core$Result$Err(invalid),
				bY: expecting,
				dt: $elm$core$Result$Err(invalid),
				dv: $elm$core$Result$Err(invalid),
				dC: $elm$core$Result$Ok($elm$core$Basics$identity),
				dE: invalid,
				dY: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.cG, s.dh, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{dh: newCol, d: s.d, e: s.e, b: newOffset, cG: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $author$project$Visibility$negInt = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$negate),
				$elm$parser$Parser$symbol('-')),
			$elm$parser$Parser$int),
			$elm$parser$Parser$int
		]));
var $author$project$ParameterId$parser = A2(
	$elm$parser$Parser$keeper,
	$elm$parser$Parser$succeed($elm$core$Basics$identity),
	$elm$parser$Parser$int);
var $author$project$Visibility$parameterId = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$symbol('$')),
	$author$project$ParameterId$parser);
var $author$project$Visibility$comparisonTarget = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$parser$Parser$map, $author$project$Visibility$ComparisonTargetInt, $author$project$Visibility$negInt),
			A2($elm$parser$Parser$map, $author$project$Visibility$ComparisonTargetParameterId, $author$project$Visibility$parameterId)
		]));
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{dh: col, d: s0.d, e: s0.e, b: offset, cG: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.cG, s.dh, s);
	};
};
var $elm$parser$Parser$Advanced$spaces = $elm$parser$Parser$Advanced$chompWhile(
	function (c) {
		return (c === ' ') || ((c === '\n') || (c === '\r'));
	});
var $elm$parser$Parser$spaces = $elm$parser$Parser$Advanced$spaces;
var $author$project$Visibility$equals = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($author$project$Visibility$Equal),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable(
					A2($elm$parser$Parser$ignorer, $author$project$Visibility$comparisonTarget, $elm$parser$Parser$spaces)),
				$elm$parser$Parser$symbol('==')),
			$elm$parser$Parser$spaces)),
	$author$project$Visibility$comparisonTarget);
var $author$project$Visibility$GreaterThan = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Visibility$greaterThan = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($author$project$Visibility$GreaterThan),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable(
					A2($elm$parser$Parser$ignorer, $author$project$Visibility$comparisonTarget, $elm$parser$Parser$spaces)),
				$elm$parser$Parser$symbol('>')),
			$elm$parser$Parser$spaces)),
	$author$project$Visibility$comparisonTarget);
var $author$project$Visibility$LessThan = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Visibility$lessThan = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($author$project$Visibility$LessThan),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable(
					A2($elm$parser$Parser$ignorer, $author$project$Visibility$comparisonTarget, $elm$parser$Parser$spaces)),
				$elm$parser$Parser$symbol('<')),
			$elm$parser$Parser$spaces)),
	$author$project$Visibility$comparisonTarget);
var $author$project$Visibility$NotEqual = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Visibility$notEquals = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($author$project$Visibility$NotEqual),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable(
					A2($elm$parser$Parser$ignorer, $author$project$Visibility$comparisonTarget, $elm$parser$Parser$spaces)),
				$elm$parser$Parser$symbol('!=')),
			$elm$parser$Parser$spaces)),
	$author$project$Visibility$comparisonTarget);
var $author$project$Visibility$comparisonParser = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[$author$project$Visibility$greaterThan, $author$project$Visibility$lessThan, $author$project$Visibility$equals, $author$project$Visibility$notEquals]));
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $author$project$Visibility$parseOperator = F2(
	function (booleanOp, toExpr) {
		var zeroOrMoreHelp = function (revOps) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							A2(
								$elm$parser$Parser$ignorer,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed(
										function (op) {
											return $elm$parser$Parser$Loop(
												A2($elm$core$List$cons, op, revOps));
										}),
									$elm$parser$Parser$spaces),
								$elm$parser$Parser$symbol(
									$author$project$Visibility$booleanOperatorToString(booleanOp))),
							$elm$parser$Parser$spaces),
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$lazy(
								function (_v3) {
									return $author$project$Visibility$cyclic$parseExpr();
								}),
							$elm$parser$Parser$spaces)),
						A2(
						$elm$parser$Parser$map,
						function (_v4) {
							return $elm$parser$Parser$Done(
								$elm$core$List$reverse(revOps));
						},
						$elm$parser$Parser$succeed(0))
					]));
		};
		var zeroOrMore = A2($elm$parser$Parser$loop, _List_Nil, zeroOrMoreHelp);
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$keeper,
					$elm$parser$Parser$succeed(toExpr),
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$backtrackable(
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol('(')),
										$elm$parser$Parser$spaces),
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$lazy(
											function (_v0) {
												return $author$project$Visibility$cyclic$parseExpr();
											}),
										$elm$parser$Parser$spaces))),
							$elm$parser$Parser$symbol(
								$author$project$Visibility$booleanOperatorToString(booleanOp))),
						$elm$parser$Parser$spaces)),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$lazy(
						function (_v1) {
							return $author$project$Visibility$cyclic$parseExpr();
						}),
					$elm$parser$Parser$spaces)),
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$lazy(
					function (_v2) {
						return zeroOrMore;
					}),
				$elm$parser$Parser$symbol(')')));
	});
function $author$project$Visibility$cyclic$parseExpr() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($author$project$Visibility$ExprTrue),
				$elm$parser$Parser$symbol('true')),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($author$project$Visibility$ExprFalse),
				$elm$parser$Parser$symbol('false')),
				A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed($author$project$Visibility$ExprComparison),
				$author$project$Visibility$comparisonParser),
				A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				A2($author$project$Visibility$parseOperator, 0, $author$project$Visibility$ExprAnd)),
				A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				A2($author$project$Visibility$parseOperator, 1, $author$project$Visibility$ExprOr))
			]));
}
var $author$project$Visibility$parseExpr = $author$project$Visibility$cyclic$parseExpr();
$author$project$Visibility$cyclic$parseExpr = function () {
	return $author$project$Visibility$parseExpr;
};
var $author$project$Visibility$parse = A2($elm$parser$Parser$ignorer, $author$project$Visibility$parseExpr, $elm$parser$Parser$end);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {dh: col, d1: problem, cG: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.cG, p.dh, p.d1);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{dh: 1, d: _List_Nil, e: 1, b: 0, cG: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $author$project$Visibility$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (exprString) {
		var parseResult = A2($elm$parser$Parser$run, $author$project$Visibility$parse, exprString);
		if (!parseResult.$) {
			var expr = parseResult.a;
			return $elm$json$Json$Decode$succeed(expr);
		} else {
			var err = parseResult.a;
			return $elm$json$Json$Decode$fail(
				A2(
					$elm$core$String$join,
					'. ',
					A2($elm$core$List$map, $author$project$Visibility$deadEndToString, err)));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Node$Link$LinkActionCopyParameterValue = function (a) {
	return {$: 2, a: a};
};
var $author$project$Node$Link$linkActionCopyParameterValueDecoder = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (sourceId, targetId) {
			return $author$project$Node$Link$LinkActionCopyParameterValue(
				{ec: sourceId, ei: targetId});
		}),
	A2($elm$json$Json$Decode$field, 'sourceId', $author$project$ParameterId$decoder),
	A2($elm$json$Json$Decode$field, 'targetId', $author$project$ParameterId$decoder));
var $author$project$Node$Link$LinkActionSendCommissioningData = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Node$Link$linkActionSendCommissioningDataDecoder = A2(
	$elm$json$Json$Decode$map,
	function (ids) {
		return $author$project$Node$Link$LinkActionSendCommissioningData(
			{dx: ids});
	},
	A2(
		$elm$json$Json$Decode$field,
		'ids',
		$elm$json$Json$Decode$list($author$project$ParameterId$decoder)));
var $author$project$Node$Link$LinkActionSetParameterValue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Node$Link$linkActionSetParameterValueDecoder = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (id, value) {
			return $author$project$Node$Link$LinkActionSetParameterValue(
				{aX: id, ba: value});
		}),
	A2($elm$json$Json$Decode$field, 'id', $author$project$ParameterId$decoder),
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm_community$json_extra$Json$Decode$Extra$when = F3(
	function (checkDecoder, check, passDecoder) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (checkVal) {
				return check(checkVal) ? passDecoder : $elm$json$Json$Decode$fail('Check failed with input');
			},
			checkDecoder);
	});
var $author$project$Node$Link$linkActionDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A3(
			$elm_community$json_extra$Json$Decode$Extra$when,
			A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string),
			$elm$core$Basics$eq('set_parameter_value'),
			$author$project$Node$Link$linkActionSetParameterValueDecoder),
			A3(
			$elm_community$json_extra$Json$Decode$Extra$when,
			A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string),
			$elm$core$Basics$eq('send_commissioning_data'),
			$author$project$Node$Link$linkActionSendCommissioningDataDecoder),
			A3(
			$elm_community$json_extra$Json$Decode$Extra$when,
			A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string),
			$elm$core$Basics$eq('copy_parameter_value'),
			$author$project$Node$Link$linkActionCopyParameterValueDecoder),
			A3(
			$elm_community$json_extra$Json$Decode$Extra$when,
			A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string),
			$elm$core$Basics$eq('quit'),
			$elm$json$Json$Decode$succeed($author$project$Node$Link$LinkActionQuit))
		]));
var $author$project$Node$DateTime$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'visibility', $author$project$Visibility$decoder),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(_List_Nil),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$optionalField,
				'actions',
				$elm$json$Json$Decode$list($author$project$Node$Link$linkActionDecoder))),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(true),
				A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'marginBottom', $elm$json$Json$Decode$bool)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'target', $author$project$PageId$decoder),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'yearId', $author$project$ParameterId$decoder),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'monthId', $author$project$ParameterId$decoder),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'dayId', $author$project$ParameterId$decoder),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'timeId', $author$project$ParameterId$decoder),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
									$elm$json$Json$Decode$succeed($author$project$Node$DateTime$DateTime))))))))));
var $author$project$Node$Image$Image = F5(
	function (url, title, text, marginBottom, visibility) {
		return {ap: marginBottom, cX: text, cY: title, aQ: url, av: visibility};
	});
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Node$Image$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Node$Image$Image,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(true),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'marginBottom', $elm$json$Json$Decode$bool)),
	A2($elm$json$Json$Decode$field, 'visibility', $author$project$Visibility$decoder));
var $author$project$Node$ParameterReference$ParameterReference = F7(
	function (id, title, text, info, marginBottom, visibility, icon) {
		return {b5: icon, aX: id, dz: info, ap: marginBottom, cX: text, cY: title, av: visibility};
	});
var $author$project$IconCode$IconCode = $elm$core$Basics$identity;
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$parser$Parser$ExpectingBinary = {$: 4};
var $elm$parser$Parser$ExpectingFloat = {$: 5};
var $elm$parser$Parser$ExpectingHex = {$: 2};
var $elm$parser$Parser$ExpectingNumber = {$: 6};
var $elm$parser$Parser$ExpectingOctal = {$: 3};
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $elm$parser$Parser$number = function (i) {
	return $elm$parser$Parser$Advanced$number(
		{
			db: A2($elm$core$Result$fromMaybe, $elm$parser$Parser$ExpectingBinary, i.db),
			bY: $elm$parser$Parser$ExpectingNumber,
			dt: A2($elm$core$Result$fromMaybe, $elm$parser$Parser$ExpectingFloat, i.dt),
			dv: A2($elm$core$Result$fromMaybe, $elm$parser$Parser$ExpectingHex, i.dv),
			dC: A2($elm$core$Result$fromMaybe, $elm$parser$Parser$ExpectingInt, i.dC),
			dE: $elm$parser$Parser$ExpectingNumber,
			dY: A2($elm$core$Result$fromMaybe, $elm$parser$Parser$ExpectingOctal, i.dY)
		});
};
var $author$project$IconCode$hex = $elm$parser$Parser$number(
	{
		db: $elm$core$Maybe$Nothing,
		dt: $elm$core$Maybe$Nothing,
		dv: $elm$core$Maybe$Just($elm$core$Basics$identity),
		dC: $elm$core$Maybe$Nothing,
		dY: $elm$core$Maybe$Nothing
	});
var $author$project$IconCode$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (code) {
		var result = A2($elm$parser$Parser$run, $author$project$IconCode$hex, code);
		if (!result.$) {
			var a = result.a;
			return $elm$json$Json$Decode$succeed(
				$elm$core$Char$fromCode(a));
		} else {
			return $elm$json$Json$Decode$fail('Trying to decode icon, but icon ' + (code + ' is not hexadecimal number. Icon must be in hexadecimal format, eg: 0x1234'));
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$map7 = _Json_map7;
var $author$project$Node$ParameterReference$decoder = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Node$ParameterReference$ParameterReference,
	A2($elm$json$Json$Decode$field, 'id', $author$project$ParameterId$decoder),
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'text', $elm$json$Json$Decode$string),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'info', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(true),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'marginBottom', $elm$json$Json$Decode$bool)),
	A2($elm$json$Json$Decode$field, 'visibility', $author$project$Visibility$decoder),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'icon', $author$project$IconCode$decoder));
var $author$project$Node$Text$Text = F7(
	function (title, text, marginBottom, info, style, visibility, icon) {
		return {b5: icon, dz: info, ap: marginBottom, X: style, cX: text, cY: title, av: visibility};
	});
var $author$project$Node$Text$TextStyleTitle = 0;
var $author$project$Node$Text$TextStyleSubtitle = 1;
var $author$project$Node$Text$textStyleDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'title':
				return $elm$json$Json$Decode$succeed(0);
			case 'subtitle':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Trying to decode text style, but style ' + (str + ' is not supported.'));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Node$Text$decoder = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Node$Text$Text,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(true),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'marginBottom', $elm$json$Json$Decode$bool)),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'info', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(0),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'style', $author$project$Node$Text$textStyleDecoder)),
	A2($elm$json$Json$Decode$field, 'visibility', $author$project$Visibility$decoder),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'icon', $author$project$IconCode$decoder));
var $author$project$Node$WeeklyTimer$WeeklyTimer = F8(
	function (priority, daysEnabledId, startTimeId, endTimeId, fanSpeedSettingId, target, marginBottom, visibility) {
		return {bR: daysEnabledId, bW: endTimeId, b_: fanSpeedSettingId, ap: marginBottom, cu: priority, cS: startTimeId, eh: target, av: visibility};
	});
var $author$project$Node$WeeklyTimer$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'visibility', $author$project$Visibility$decoder),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(true),
			A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'marginBottom', $elm$json$Json$Decode$bool)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'target', $author$project$PageId$decoder),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'fanSpeedSettingId', $author$project$ParameterId$decoder),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'endTimeId', $author$project$ParameterId$decoder),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'startTimeId', $author$project$ParameterId$decoder),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'daysEnabledId', $author$project$ParameterId$decoder),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'priority', $elm$json$Json$Decode$int),
								$elm$json$Json$Decode$succeed($author$project$Node$WeeklyTimer$WeeklyTimer)))))))));
var $author$project$Node$Position$Default = 0;
var $author$project$Node$Link$Link = F8(
	function (title, target, style, marginBottom, visibility, actions, position, icon) {
		return {aS: actions, b5: icon, ap: marginBottom, d0: position, X: style, eh: target, cY: title, av: visibility};
	});
var $author$project$Node$Link$Target$TargetPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Node$Link$Target$TargetUrl = function (a) {
	return {$: 1, a: a};
};
var $author$project$Url$Extras$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (urlString) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Decode$fail('Invalid url.'),
			A2(
				$elm$core$Maybe$map,
				$elm$json$Json$Decode$succeed,
				$elm$url$Url$fromString(urlString)));
	},
	$elm$json$Json$Decode$string);
var $author$project$Node$Link$Target$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$Node$Link$Target$TargetUrl, $author$project$Url$Extras$decoder),
			A2($elm$json$Json$Decode$map, $author$project$Node$Link$Target$TargetPage, $author$project$PageId$decoder)
		]));
var $author$project$Node$Position$Bottom = 1;
var $author$project$Node$Position$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'default':
				return $elm$json$Json$Decode$succeed(0);
			case 'bottom':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Trying to decode position, but position ' + (str + ' is not supported.'));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Node$Link$LinkStyleButton = 1;
var $author$project$Node$Link$LinkStyleNormal = 0;
var $author$project$Node$Link$linkStyleDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'normal':
				return $elm$json$Json$Decode$succeed(0);
			case 'button':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Trying to decode link style, but style ' + (str + ' is not supported.'));
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$map8 = _Json_map8;
var $author$project$Node$Link$linkDecoder = A9(
	$elm$json$Json$Decode$map8,
	$author$project$Node$Link$Link,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'target', $author$project$Node$Link$Target$decoder),
	A2($elm$json$Json$Decode$field, 'style', $author$project$Node$Link$linkStyleDecoder),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(true),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'marginBottom', $elm$json$Json$Decode$bool)),
	A2($elm$json$Json$Decode$field, 'visibility', $author$project$Visibility$decoder),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(_List_Nil),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$optionalField,
			'actions',
			$elm$json$Json$Decode$list($author$project$Node$Link$linkActionDecoder))),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(0),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'position', $author$project$Node$Position$decoder)),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'icon', $author$project$IconCode$decoder));
var $author$project$Node$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'parameter':
				return A2($elm$json$Json$Decode$map, $author$project$Node$NodeParameter, $author$project$Node$ParameterReference$decoder);
			case 'link':
				return A2($elm$json$Json$Decode$map, $author$project$Node$NodeLink, $author$project$Node$Link$linkDecoder);
			case 'image':
				return A2($elm$json$Json$Decode$map, $author$project$Node$NodeImage, $author$project$Node$Image$decoder);
			case 'text':
				return A2($elm$json$Json$Decode$map, $author$project$Node$NodeText, $author$project$Node$Text$decoder);
			case 'weeklytimer':
				return A2($elm$json$Json$Decode$map, $author$project$Node$NodeWeeklyTimer, $author$project$Node$WeeklyTimer$decoder);
			case 'datetime':
				return A2($elm$json$Json$Decode$map, $author$project$Node$NodeDateTime, $author$project$Node$DateTime$decoder);
			default:
				return $elm$json$Json$Decode$fail('Trying to decode node type, but type \'' + (str + '\' is not supported.'));
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $author$project$Node$Link$LinkPrevious = function (actions) {
	return {aS: actions};
};
var $author$project$Node$Link$linkPreviousDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Node$Link$LinkPrevious,
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(_List_Nil),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$optionalField,
			'actions',
			$elm$json$Json$Decode$list($author$project$Node$Link$linkActionDecoder))));
var $author$project$Page$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Page$Page,
	A2($elm$json$Json$Decode$field, 'id', $author$project$PageId$decoder),
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'nodes',
		$elm$json$Json$Decode$list(
			$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $author$project$Node$decoder;
				}))),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'linkPrevious', $author$project$Node$Link$linkPreviousDecoder),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'linkNext', $author$project$Node$Link$linkDecoder));
var $author$project$Parameter$SpecMultiOption = function (a) {
	return {$: 5, a: a};
};
var $author$project$Parameter$SpecNumeric = function (a) {
	return {$: 0, a: a};
};
var $author$project$Parameter$SpecOption = function (a) {
	return {$: 1, a: a};
};
var $author$project$Parameter$SpecSwitch = function (a) {
	return {$: 3, a: a};
};
var $author$project$Parameter$SpecTime = function (a) {
	return {$: 4, a: a};
};
var $author$project$Parameter$Register$Register = $elm$core$Basics$identity;
var $author$project$Parameter$Register$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $author$project$Parameter$baseDecoder = function (specDecoder) {
	return A6(
		$elm$json$Json$Decode$map5,
		F5(
			function (id, register, value, readonly, specification) {
				return {aX: id, E: readonly, O: register, cR: specification, ba: value};
			}),
		A2($elm$json$Json$Decode$field, 'id', $author$project$ParameterId$decoder),
		A2($elm$json$Json$Decode$field, 'register', $author$project$Parameter$Register$decoder),
		$elm$json$Json$Decode$succeed($author$project$Parameter$Value$NotAvailable),
		A2($elm$json$Json$Decode$field, 'readonly', $elm$json$Json$Decode$bool),
		specDecoder);
};
var $author$project$Parameter$MultiOption$MultiOption = function (options) {
	return {cn: options};
};
var $author$project$Parameter$MultiOption$AnOption = F3(
	function (name, shortName, bitPosition) {
		return {bK: bitPosition, ck: name, ea: shortName};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Parameter$MultiOption$anOptionDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Parameter$MultiOption$AnOption,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'shortName', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'bitPosition', $elm$json$Json$Decode$int));
var $author$project$Parameter$MultiOption$decoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Parameter$MultiOption$MultiOption,
	A2(
		$elm$json$Json$Decode$field,
		'options',
		$elm$json$Json$Decode$list($author$project$Parameter$MultiOption$anOptionDecoder)));
var $author$project$Parameter$Numeric$Numeric = F5(
	function (min, max, step, power, unit) {
		return {dK: max, dL: min, bt: power, a8: step, en: unit};
	});
var $author$project$Parameter$Limit$deadEndToString = function (end) {
	var col = $elm$core$String$fromInt(end.dh);
	var prefix = 'Problem parsing limit (col=' + (col + '). ');
	var _v0 = end.d1;
	switch (_v0.$) {
		case 0:
			var a = _v0.a;
			return _Utils_ap(prefix, a);
		case 1:
			return prefix + 'Expecting int.';
		case 2:
			return prefix + 'Expected hex.';
		case 3:
			return prefix + 'Expecting octal.';
		case 4:
			return prefix + 'Expecting binary.';
		case 5:
			return prefix + 'Expecting float.';
		case 6:
			return prefix + 'Expecting number.';
		case 7:
			return prefix + 'Expecting variable.';
		case 8:
			var a = _v0.a;
			return prefix + ('Expecting symbol ' + a);
		case 9:
			var a = _v0.a;
			return prefix + ('Expecting keyword ' + a);
		case 10:
			return prefix + 'Expecting end.';
		case 11:
			return prefix + 'Unexpected char.';
		case 12:
			var a = _v0.a;
			return _Utils_ap(prefix, a);
		default:
			return prefix + 'Bad repeat.';
	}
};
var $author$project$Parameter$Limit$LimitConstant = function (a) {
	return {$: 0, a: a};
};
var $author$project$Parameter$Limit$LimitParameter = function (a) {
	return {$: 1, a: a};
};
var $author$project$Parameter$Limit$negInt = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$negate),
				$elm$parser$Parser$symbol('-')),
			$elm$parser$Parser$int),
			$elm$parser$Parser$int
		]));
var $author$project$Parameter$Limit$parameterId = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$symbol('$')),
	$author$project$ParameterId$parser);
var $author$project$Parameter$Limit$parser = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2($elm$parser$Parser$map, $author$project$Parameter$Limit$LimitParameter, $author$project$Parameter$Limit$parameterId),
				A2($elm$parser$Parser$map, $author$project$Parameter$Limit$LimitConstant, $author$project$Parameter$Limit$negInt)
			])),
	$elm$parser$Parser$end);
var $author$project$Parameter$Limit$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (exprString) {
		var parseResult = A2($elm$parser$Parser$run, $author$project$Parameter$Limit$parser, exprString);
		if (!parseResult.$) {
			var expr = parseResult.a;
			return $elm$json$Json$Decode$succeed(expr);
		} else {
			var err = parseResult.a;
			return $elm$json$Json$Decode$fail(
				A2(
					$elm$core$String$join,
					'. ',
					A2($elm$core$List$map, $author$project$Parameter$Limit$deadEndToString, err)));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Parameter$Numeric$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Parameter$Numeric$Numeric,
	A2($elm$json$Json$Decode$field, 'min', $author$project$Parameter$Limit$decoder),
	A2($elm$json$Json$Decode$field, 'max', $author$project$Parameter$Limit$decoder),
	A2($elm$json$Json$Decode$field, 'step', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'power', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'unit', $elm$json$Json$Decode$string));
var $author$project$Parameter$Option$Option = function (options) {
	return {cn: options};
};
var $author$project$Parameter$Option$AnOption = F2(
	function (name, value) {
		return {ck: name, ba: value};
	});
var $author$project$Parameter$Option$anOptionParameterDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Parameter$Option$AnOption,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$int));
var $author$project$Parameter$Option$decoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Parameter$Option$Option,
	A2(
		$elm$json$Json$Decode$field,
		'options',
		$elm$json$Json$Decode$list($author$project$Parameter$Option$anOptionParameterDecoder)));
var $author$project$Parameter$Switch$Switch = F2(
	function (valueOn, valueOff) {
		return {c0: valueOff, bG: valueOn};
	});
var $author$project$Parameter$Switch$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Parameter$Switch$Switch,
	A2($elm$json$Json$Decode$field, 'valueOn', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'valueOff', $elm$json$Json$Decode$int));
var $author$project$Parameter$Time$Time = F2(
	function (step, clock) {
		return {bN: clock, a8: step};
	});
var $author$project$Parameter$Time$TwelveHours = 1;
var $author$project$Parameter$Time$TwentyFourHours = 0;
var $author$project$Parameter$Time$clockDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (stringValue) {
		switch (stringValue) {
			case '24h':
				return $elm$json$Json$Decode$succeed(0);
			case '12h':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid clock given to time. Must be one of the following: 24h, 12h');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Parameter$Time$TimeStep = $elm$core$Basics$identity;
var $author$project$Parameter$Time$validSteps = _List_fromArray(
	[1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30, 60]);
var $author$project$Parameter$Time$timeStepDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (intValue) {
		return A2($elm$core$List$member, intValue, $author$project$Parameter$Time$validSteps) ? $elm$json$Json$Decode$succeed(intValue) : $elm$json$Json$Decode$fail(
			'Invalid step given to time. Must be one of the following: ' + A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $elm$core$String$fromInt, $author$project$Parameter$Time$validSteps)));
	},
	$elm$json$Json$Decode$int);
var $author$project$Parameter$Time$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Parameter$Time$Time,
	A2($elm$json$Json$Decode$field, 'step', $author$project$Parameter$Time$timeStepDecoder),
	A2($elm$json$Json$Decode$field, 'clock', $author$project$Parameter$Time$clockDecoder));
var $author$project$Parameter$TypeMultiOption = 5;
var $author$project$Parameter$TypeNumeric = 0;
var $author$project$Parameter$TypeOption = 1;
var $author$project$Parameter$TypeSwitch = 3;
var $author$project$Parameter$TypeTime = 4;
var $author$project$Parameter$TypeTimer = 2;
var $author$project$Parameter$parameterTypeDecoder = function () {
	var strParameterTypeDecoder = function (str) {
		switch (str) {
			case 'numeric':
				return $elm$json$Json$Decode$succeed(0);
			case 'option':
				return $elm$json$Json$Decode$succeed(1);
			case 'timer':
				return $elm$json$Json$Decode$succeed(2);
			case 'switch':
				return $elm$json$Json$Decode$succeed(3);
			case 'time':
				return $elm$json$Json$Decode$succeed(4);
			case 'multioption':
				return $elm$json$Json$Decode$succeed(5);
			default:
				return $elm$json$Json$Decode$fail('Trying to decode parameter type, but type \'' + (str + '\' is not supported.'));
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		strParameterTypeDecoder,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $author$project$Parameter$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (parameterType) {
		var specDecoder = function () {
			switch (parameterType) {
				case 0:
					return A2($elm$json$Json$Decode$map, $author$project$Parameter$SpecNumeric, $author$project$Parameter$Numeric$decoder);
				case 1:
					return A2($elm$json$Json$Decode$map, $author$project$Parameter$SpecOption, $author$project$Parameter$Option$decoder);
				case 2:
					return $elm$json$Json$Decode$succeed(
						$author$project$Parameter$SpecTimer(0));
				case 3:
					return A2($elm$json$Json$Decode$map, $author$project$Parameter$SpecSwitch, $author$project$Parameter$Switch$decoder);
				case 4:
					return A2($elm$json$Json$Decode$map, $author$project$Parameter$SpecTime, $author$project$Parameter$Time$decoder);
				default:
					return A2($elm$json$Json$Decode$map, $author$project$Parameter$SpecMultiOption, $author$project$Parameter$MultiOption$decoder);
			}
		}();
		return $author$project$Parameter$baseDecoder(specDecoder);
	},
	$author$project$Parameter$parameterTypeDecoder);
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Event$Schema$pagesField = 'pages';
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $author$project$Language$all = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $author$project$Event$Schema$validateLanguages = function (schema) {
	return A2(
		$elm$core$List$concatMap,
		function (language) {
			return A2($elm$core$Dict$member, language, schema.bE) ? _List_Nil : _List_fromArray(
				['Missing language ' + (language + ' in translations.')]);
		},
		A2($elm$core$List$map, $author$project$Language$toString, $author$project$Language$all));
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$diff = F2(
	function (t1, t2) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, t) {
					return A2($elm$core$Dict$remove, k, t);
				}),
			t1,
			t2);
	});
var $elm$core$Set$diff = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$diff, dict1, dict2);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Parameter$toNumeric = function (parameter) {
	var _v0 = parameter.cR;
	if (!_v0.$) {
		var numeric = _v0.a;
		return $elm$core$Maybe$Just(
			{aX: parameter.aX, E: parameter.E, O: parameter.O, cR: numeric, ba: parameter.ba});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Parameter$Limit$toParameter = function (limit) {
	if (!limit.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var p = limit.a;
		return $elm$core$Maybe$Just(p);
	}
};
var $author$project$Event$Schema$validateLimitReferences = function (schema) {
	var makeMessage = function (paramId) {
		return 'Parameter min/max limit referencing parameter \'' + (paramId + ('\' points to nowhere. Are you sure you have configured a parameter with id \'' + (paramId + '\'?')));
	};
	var existingParameterIds = $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aX;
				},
				$author$project$ParameterId$toString),
			schema.aq));
	var allReferencedParameterIds = $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			$author$project$ParameterId$toString,
			A2(
				$elm$core$List$filterMap,
				$author$project$Parameter$Limit$toParameter,
				A2(
					$elm$core$List$concatMap,
					function (p) {
						return _List_fromArray(
							[p.cR.dL, p.cR.dK]);
					},
					A2($elm$core$List$filterMap, $author$project$Parameter$toNumeric, schema.aq)))));
	var missingParameterIds = A2($elm$core$Set$diff, allReferencedParameterIds, existingParameterIds);
	return A2(
		$elm$core$List$map,
		makeMessage,
		$elm$core$Set$toList(missingParameterIds));
};
var $author$project$Node$toLink = function (node) {
	switch (node.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var link = node.a;
			return $elm$core$Maybe$Just(link);
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			return $elm$core$Maybe$Nothing;
		case 4:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Node$Link$Target$toPageId = function (target) {
	if (!target.$) {
		var pageId = target.a;
		return $elm$core$Maybe$Just(pageId);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$PageId$toString = function (_v0) {
	var pageId = _v0;
	return pageId;
};
var $author$project$Event$Schema$validatePageIds = function (schema) {
	var targetPageIds = $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			$author$project$PageId$toString,
			A2(
				$elm$core$List$filterMap,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.eh;
					},
					$author$project$Node$Link$Target$toPageId),
				A2(
					$elm$core$List$filterMap,
					$author$project$Node$toLink,
					A2(
						$elm$core$List$concatMap,
						function (page) {
							return _Utils_ap(
								page.a0,
								A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										A2($elm$core$Basics$composeR, $author$project$Node$NodeLink, $elm$core$List$singleton),
										page.cf)));
						},
						schema.S)))));
	var makeMessage = function (targetId) {
		return 'Node (type=link, target=' + (targetId + (') points to nowhere. Are you sure you have configured a page with id=' + (targetId + '?')));
	};
	var existingPageIds = $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aX;
				},
				$author$project$PageId$toString),
			schema.S));
	var missingTargetPageIds = A2($elm$core$Set$diff, targetPageIds, existingPageIds);
	return A2(
		$elm$core$List$map,
		makeMessage,
		$elm$core$Set$toList(missingTargetPageIds));
};
var $author$project$Node$referencesToParameters = function (node) {
	switch (node.$) {
		case 0:
			var p = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple3(node, 'id', p.aX)
				]);
		case 1:
			var link = node.a;
			return A2(
				$elm$core$List$concatMap,
				function (action) {
					switch (action.$) {
						case 0:
							var x = action.a;
							return _List_fromArray(
								[
									_Utils_Tuple3(node, 'action.id', x.aX)
								]);
						case 1:
							var x = action.a;
							return A2(
								$elm$core$List$map,
								function (id) {
									return _Utils_Tuple3(node, 'action.ids', id);
								},
								x.dx);
						case 2:
							var x = action.a;
							return _List_fromArray(
								[
									_Utils_Tuple3(node, 'action.sourceId', x.ec),
									_Utils_Tuple3(node, 'action.targetId', x.ei)
								]);
						default:
							return _List_Nil;
					}
				},
				link.aS);
		case 2:
			return _List_Nil;
		case 3:
			return _List_Nil;
		case 4:
			var timer = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple3(node, 'startTimeId', timer.cS),
					_Utils_Tuple3(node, '', timer.bW),
					_Utils_Tuple3(node, '', timer.b_),
					_Utils_Tuple3(node, '', timer.bR)
				]);
		default:
			var time = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple3(node, 'timeId', time.bD),
					_Utils_Tuple3(node, 'dayId', time.bQ),
					_Utils_Tuple3(node, 'monthId', time.cj),
					_Utils_Tuple3(node, 'yearId', time.c4)
				]);
	}
};
var $author$project$Node$Link$Target$toString = function (target) {
	if (!target.$) {
		var pageId = target.a;
		return $author$project$PageId$toString(pageId);
	} else {
		var url = target.a;
		return $elm$url$Url$toString(url);
	}
};
var $author$project$Node$fieldsAndValues = function (node) {
	switch (node.$) {
		case 0:
			var p = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple2('type', 'parameter'),
					_Utils_Tuple2(
					'id',
					$author$project$ParameterId$toString(p.aX))
				]);
		case 1:
			var link = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple2('type', 'link'),
					_Utils_Tuple2(
					'target',
					$author$project$Node$Link$Target$toString(link.eh))
				]);
		case 2:
			var image = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple2('type', 'image'),
					_Utils_Tuple2('url', image.aQ)
				]);
		case 3:
			var text = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple2('type', 'text'),
					_Utils_Tuple2('text', text.cX)
				]);
		case 4:
			var timer = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple2('type', 'weeklytimer'),
					_Utils_Tuple2(
					'priority',
					$elm$core$String$fromInt(timer.cu))
				]);
		default:
			var time = node.a;
			return _List_fromArray(
				[
					_Utils_Tuple2('type', 'datetime'),
					_Utils_Tuple2(
					'timeId',
					$author$project$ParameterId$toString(time.bD))
				]);
	}
};
var $author$project$Node$toString = function (node) {
	return 'Node (' + (A2(
		$elm$core$String$join,
		', ',
		A2(
			$elm$core$List$map,
			function (_v0) {
				var field = _v0.a;
				var value = _v0.b;
				return field + ('=' + value);
			},
			$author$project$Node$fieldsAndValues(node))) + ')');
};
var $author$project$Event$Schema$validateParameterIds = function (schema) {
	var existingParameterIds = $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aX;
				},
				$author$project$ParameterId$toString),
			schema.aq));
	var missingParameterIdMessages = A2(
		$elm$core$List$map,
		function (_v1) {
			var node = _v1.a;
			var field = _v1.b;
			var parameterId = _v1.c;
			return $author$project$Node$toString(node) + (' field ' + (field + (' points to nowhere. Are you sure you have configured a parameter with id ' + ($author$project$ParameterId$toString(parameterId) + '?'))));
		},
		A2(
			$elm$core$List$filter,
			function (_v0) {
				var parameterId = _v0.c;
				return !A2(
					$elm$core$Set$member,
					$author$project$ParameterId$toString(parameterId),
					existingParameterIds);
			},
			A2(
				$elm$core$List$concatMap,
				$author$project$Node$referencesToParameters,
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.a0;
					},
					schema.S))));
	return missingParameterIdMessages;
};
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Set$isEmpty = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$isEmpty(dict);
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Node$maybeToSingletonList = function (x) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2($elm$core$Maybe$map, $elm$core$List$singleton, x));
};
var $author$project$Node$toTranslationKeys = function (node) {
	switch (node.$) {
		case 0:
			var paramRef = node.a;
			return _Utils_ap(
				$author$project$Node$maybeToSingletonList(paramRef.cX),
				_List_fromArray(
					[paramRef.cY]));
		case 1:
			var link = node.a;
			return _List_fromArray(
				[link.cY]);
		case 2:
			var img = node.a;
			return _Utils_ap(
				$author$project$Node$maybeToSingletonList(img.cY),
				$author$project$Node$maybeToSingletonList(img.cX));
		case 3:
			var data = node.a;
			return _Utils_ap(
				$author$project$Node$maybeToSingletonList(data.cY),
				_Utils_ap(
					_List_fromArray(
						[data.cX]),
					$author$project$Node$maybeToSingletonList(data.dz)));
		case 4:
			return _List_Nil;
		default:
			return _List_Nil;
	}
};
var $author$project$Parameter$translationKeys = function (parameter) {
	var _v0 = parameter.cR;
	switch (_v0.$) {
		case 0:
			var p = _v0.a;
			return _List_fromArray(
				[p.en]);
		case 1:
			var p = _v0.a;
			return A2(
				$elm$core$List$map,
				function ($) {
					return $.ck;
				},
				p.cn);
		case 2:
			return _List_Nil;
		case 3:
			return _List_Nil;
		case 4:
			return _List_Nil;
		default:
			var p = _v0.a;
			return A2(
				$elm$core$List$concatMap,
				function (opt) {
					return A2(
						$elm$core$List$cons,
						opt.ck,
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2($elm$core$Maybe$map, $elm$core$List$singleton, opt.ea)));
				},
				p.cn);
	}
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Event$Schema$validateTranslationKeys = function (schema) {
	var parameterKeys = A2($elm$core$List$concatMap, $author$project$Parameter$translationKeys, schema.aq);
	var pageTitleKeys = A2(
		$elm$core$List$map,
		function ($) {
			return $.cY;
		},
		schema.S);
	var pageLinkNextKeys = A2(
		$elm$core$List$map,
		function ($) {
			return $.cY;
		},
		A2(
			$elm$core$List$filterMap,
			function ($) {
				return $.cf;
			},
			schema.S));
	var nodeKeys = A2(
		$elm$core$List$concatMap,
		$author$project$Node$toTranslationKeys,
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.a0;
			},
			schema.S));
	var allKeys = $elm$core$Set$fromList(
		_Utils_ap(
			parameterKeys,
			_Utils_ap(
				pageTitleKeys,
				_Utils_ap(pageLinkNextKeys, nodeKeys))));
	var missingTranslationKeys = A2(
		$elm$core$List$filter,
		A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
		$elm$core$Dict$values(
			A2(
				$elm$core$Dict$map,
				F2(
					function (language, keyDict) {
						return function (diff) {
							return $elm$core$Set$isEmpty(diff) ? '' : ('Missing translation keys (' + (A2(
								$elm$core$String$join,
								',',
								$elm$core$Set$toList(diff)) + (') for language=' + language)));
						}(
							A2(
								$elm$core$Set$diff,
								allKeys,
								$elm$core$Set$fromList(
									$elm$core$Dict$keys(keyDict))));
					}),
				schema.bE)));
	return missingTranslationKeys;
};
var $author$project$Visibility$getParameterId = function (target) {
	if (target.$ === 1) {
		return _List_Nil;
	} else {
		var paramId = target.a;
		return _List_fromArray(
			[paramId]);
	}
};
var $author$project$Visibility$getParameterIds = function (op) {
	switch (op.$) {
		case 0:
			var lhs = op.a;
			var rhs = op.b;
			return _Utils_ap(
				$author$project$Visibility$getParameterId(lhs),
				$author$project$Visibility$getParameterId(rhs));
		case 1:
			var lhs = op.a;
			var rhs = op.b;
			return _Utils_ap(
				$author$project$Visibility$getParameterId(lhs),
				$author$project$Visibility$getParameterId(rhs));
		case 2:
			var lhs = op.a;
			var rhs = op.b;
			return _Utils_ap(
				$author$project$Visibility$getParameterId(lhs),
				$author$project$Visibility$getParameterId(rhs));
		default:
			var lhs = op.a;
			var rhs = op.b;
			return _Utils_ap(
				$author$project$Visibility$getParameterId(lhs),
				$author$project$Visibility$getParameterId(rhs));
	}
};
var $author$project$Visibility$parameterReferences = function (exp) {
	switch (exp.$) {
		case 0:
			return _List_Nil;
		case 1:
			return _List_Nil;
		case 2:
			var rule = exp.a;
			return $author$project$Visibility$getParameterIds(rule);
		case 3:
			var lhs = exp.a;
			var rhs = exp.b;
			var rest = exp.c;
			return A2(
				$elm$core$List$concatMap,
				$author$project$Visibility$parameterReferences,
				A2(
					$elm$core$List$cons,
					lhs,
					A2($elm$core$List$cons, rhs, rest)));
		default:
			var lhs = exp.a;
			var rhs = exp.b;
			var rest = exp.c;
			return A2(
				$elm$core$List$concatMap,
				$author$project$Visibility$parameterReferences,
				A2(
					$elm$core$List$cons,
					lhs,
					A2($elm$core$List$cons, rhs, rest)));
	}
};
var $author$project$Node$visibility = function (node) {
	switch (node.$) {
		case 0:
			var paramRef = node.a;
			return paramRef.av;
		case 1:
			var link = node.a;
			return link.av;
		case 2:
			var img = node.a;
			return img.av;
		case 3:
			var data = node.a;
			return data.av;
		case 4:
			var timer = node.a;
			return timer.av;
		default:
			var time = node.a;
			return time.av;
	}
};
var $author$project$Event$Schema$validateVisibilityReferences = function (schema) {
	var nodeVisibilities = A2(
		$elm$core$List$map,
		$author$project$Node$visibility,
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.a0;
			},
			schema.S));
	var makeMessage = function (paramId) {
		return 'Visibility rule referencing parameter \'' + (paramId + ('\' points to nowhere. Are you sure you have configured a parameter with id \'' + (paramId + '\'?')));
	};
	var existingParameterIds = $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aX;
				},
				$author$project$ParameterId$toString),
			schema.aq));
	var allReferencedParameterIds = $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			$author$project$ParameterId$toString,
			A2($elm$core$List$concatMap, $author$project$Visibility$parameterReferences, nodeVisibilities)));
	var missingParameterIds = A2($elm$core$Set$diff, allReferencedParameterIds, existingParameterIds);
	return A2(
		$elm$core$List$map,
		makeMessage,
		$elm$core$Set$toList(missingParameterIds));
};
var $author$project$Event$Schema$validateSchema = function (schema) {
	var versionErrors = (schema.bH !== 1) ? _List_fromArray(
		[
			'Version mismatch. Only version 1 is supported was ' + ($elm$core$String$fromInt(schema.bH) + '.')
		]) : _List_Nil;
	var noPagesErrors = $elm$core$List$isEmpty(schema.S) ? _List_fromArray(
		['Problem with the value at json.' + ($author$project$Event$Schema$pagesField + ': Expecting a NON EMPTY LIST.')]) : _List_Nil;
	var errors = A2(
		$elm$core$List$append,
		versionErrors,
		A2(
			$elm$core$List$append,
			noPagesErrors,
			A2(
				$elm$core$List$append,
				$author$project$Event$Schema$validateLimitReferences(schema),
				A2(
					$elm$core$List$append,
					$author$project$Event$Schema$validateVisibilityReferences(schema),
					A2(
						$elm$core$List$append,
						$author$project$Event$Schema$validateTranslationKeys(schema),
						A2(
							$elm$core$List$append,
							$author$project$Event$Schema$validateLanguages(schema),
							A2(
								$elm$core$List$append,
								$author$project$Event$Schema$validatePageIds(schema),
								$author$project$Event$Schema$validateParameterIds(schema))))))));
	return $elm$core$List$isEmpty(errors) ? $elm$json$Json$Decode$succeed(schema) : $elm$json$Json$Decode$fail(
		A2($elm$core$String$join, ' ', errors));
};
var $author$project$Event$Schema$decoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Event$Schema$validateSchema,
	A5(
		$elm$json$Json$Decode$map4,
		$author$project$Event$Schema$Schema,
		A2(
			$elm$json$Json$Decode$field,
			$author$project$Event$Schema$pagesField,
			$elm$json$Json$Decode$list($author$project$Page$decoder)),
		A2(
			$elm$json$Json$Decode$field,
			'parameters',
			$elm$json$Json$Decode$list($author$project$Parameter$decoder)),
		A2(
			$elm$json$Json$Decode$field,
			'translations',
			$elm$json$Json$Decode$dict(
				$elm$json$Json$Decode$dict($elm$json$Json$Decode$string))),
		A2($elm$json$Json$Decode$field, 'version', $elm$json$Json$Decode$int)));
var $author$project$Event$TypeBackPressed = 1;
var $author$project$Event$TypeConfig = 3;
var $author$project$Event$TypeOpenPage = 4;
var $author$project$Event$TypeResponse = 5;
var $author$project$Event$TypeSafeAreaInsets = 2;
var $author$project$Event$TypeSchema = 0;
var $author$project$Event$eventTypeDecoder = function () {
	var strEventTypeDecoder = function (str) {
		switch (str) {
			case 'schema':
				return $elm$json$Json$Decode$succeed(0);
			case 'back_pressed':
				return $elm$json$Json$Decode$succeed(1);
			case 'safe_area_insets':
				return $elm$json$Json$Decode$succeed(2);
			case 'config':
				return $elm$json$Json$Decode$succeed(3);
			case 'open_page':
				return $elm$json$Json$Decode$succeed(4);
			case 'response':
				return $elm$json$Json$Decode$succeed(5);
			default:
				return $elm$json$Json$Decode$fail('Trying to decode event type, but type ' + (str + ' is not supported.'));
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		strEventTypeDecoder,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Request$ReadRegistersResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Request$ReconnectResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Request$WriteRegistersResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Request$ReadRegistersResponsePayload = F2(
	function (id, data) {
		return {aU: data, aX: id};
	});
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $author$project$Request$arrayAsTuple2 = F2(
	function (a, b) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (aVal) {
				return A2(
					$elm$json$Json$Decode$andThen,
					function (bVal) {
						return $elm$json$Json$Decode$succeed(
							_Utils_Tuple2(aVal, bVal));
					},
					A2($elm$json$Json$Decode$index, 1, b));
			},
			A2($elm$json$Json$Decode$index, 0, a));
	});
var $author$project$Parameter$RegisterValue$RegisterValue = $elm$core$Basics$identity;
var $author$project$Parameter$RegisterValue$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $author$project$RequestId$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $author$project$Request$readRegistersResponsePayloadDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (success) {
		return success ? A2(
			$elm$json$Json$Decode$map,
			$elm$core$Result$Ok,
			A3(
				$elm$json$Json$Decode$map2,
				$author$project$Request$ReadRegistersResponsePayload,
				A2($elm$json$Json$Decode$field, 'requestId', $author$project$RequestId$decoder),
				A2(
					$elm$json$Json$Decode$field,
					'data',
					$elm$json$Json$Decode$list(
						A2($author$project$Request$arrayAsTuple2, $author$project$Parameter$Register$decoder, $author$project$Parameter$RegisterValue$decoder))))) : A2(
			$elm$json$Json$Decode$map,
			$elm$core$Result$Err,
			A2($elm$json$Json$Decode$field, 'requestId', $author$project$RequestId$decoder));
	},
	A2($elm$json$Json$Decode$field, 'success', $elm$json$Json$Decode$bool));
var $author$project$Request$reconnectResponsePayloadDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (success) {
		return success ? A2(
			$elm$json$Json$Decode$map,
			$elm$core$Result$Ok,
			A2($elm$json$Json$Decode$field, 'requestId', $author$project$RequestId$decoder)) : A2(
			$elm$json$Json$Decode$map,
			$elm$core$Result$Err,
			A2($elm$json$Json$Decode$field, 'requestId', $author$project$RequestId$decoder));
	},
	A2($elm$json$Json$Decode$field, 'success', $elm$json$Json$Decode$bool));
var $author$project$Request$writeRegistersResponsePayloadDecoder = $author$project$Request$readRegistersResponsePayloadDecoder;
var $author$project$Request$responseDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (type_) {
		switch (type_) {
			case 'read_registers':
				return A2($elm$json$Json$Decode$map, $author$project$Request$ReadRegistersResponse, $author$project$Request$readRegistersResponsePayloadDecoder);
			case 'write_registers':
				return A2($elm$json$Json$Decode$map, $author$project$Request$WriteRegistersResponse, $author$project$Request$writeRegistersResponsePayloadDecoder);
			case 'reconnect':
				return A2($elm$json$Json$Decode$map, $author$project$Request$ReconnectResponse, $author$project$Request$reconnectResponsePayloadDecoder);
			default:
				return $elm$json$Json$Decode$fail('Trying to decode response.type, but response.type \'' + (type_ + '\' is not supported.'));
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Event$decode = function (value) {
	return A2(
		$elm$core$Result$andThen,
		function (str) {
			var eventType = A2($elm$json$Json$Decode$decodeString, $author$project$Event$eventTypeDecoder, str);
			if (!eventType.$) {
				switch (eventType.a) {
					case 0:
						var _v1 = eventType.a;
						return A2(
							$elm$core$Result$map,
							$author$project$Event$Schema,
							A2($elm$json$Json$Decode$decodeString, $author$project$Event$Schema$decoder, str));
					case 1:
						var _v2 = eventType.a;
						return $elm$core$Result$Ok($author$project$Event$BackPressed);
					case 2:
						var _v3 = eventType.a;
						return A2(
							$elm$core$Result$map,
							$author$project$Event$SafeAreaInsets,
							A2($elm$json$Json$Decode$decodeString, $author$project$Event$Insets$decoder, str));
					case 3:
						var _v4 = eventType.a;
						return A2(
							$elm$core$Result$map,
							$author$project$Event$Config,
							A2($elm$json$Json$Decode$decodeString, $author$project$Event$Config$decoder, str));
					case 4:
						var _v5 = eventType.a;
						return A2(
							$elm$core$Result$map,
							$author$project$Event$OpenPage,
							A2(
								$elm$json$Json$Decode$decodeString,
								A2($elm$json$Json$Decode$field, 'page', $author$project$PageId$decoder),
								str));
					default:
						var _v6 = eventType.a;
						return A2(
							$elm$core$Result$map,
							$author$project$Event$Response,
							A2(
								$elm$json$Json$Decode$decodeString,
								A2($elm$json$Json$Decode$field, 'response', $author$project$Request$responseDecoder),
								str));
				}
			} else {
				var err = eventType.a;
				return $elm$core$Result$Err(err);
			}
		},
		A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$string, value));
};
var $author$project$Main$onIncoming = _Platform_incomingPort('onIncoming', $elm$json$Json$Decode$value);
var $author$project$Main$incoming = function (toMsg) {
	return $author$project$Main$onIncoming(
		function (value) {
			return toMsg(
				$author$project$Event$decode(value));
		});
};
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {cp: pids, cV: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {bX: event, cb: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.cp,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.cb;
		var event = _v0.bX;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.cV);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Main$TickTimers = {$: 14};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {cw: processes, cW: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.cw;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.cW);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Parameter$isTimer = function (p) {
	var _v0 = p.cR;
	if (_v0.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Node$toParameterReference = function (node) {
	if (!node.$) {
		var p = node.a;
		return $elm$core$Maybe$Just(p);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$timerSubs = function (model) {
	var anyTimersOnCurrentPage = A2(
		$elm$core$List$any,
		$author$project$Parameter$isTimer,
		A2(
			$elm$core$List$filterMap,
			function (param) {
				return A2($pzp1997$assoc_list$AssocList$get, param.aX, model.aq);
			},
			A2(
				$elm$core$List$filterMap,
				$author$project$Node$toParameterReference,
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.a0;
						},
						A2(
							$elm$core$Maybe$andThen,
							function (pageId) {
								return A2($pzp1997$assoc_list$AssocList$get, pageId, model.S);
							},
							$elm$core$List$head(
								$mdgriffith$elm_animator$Animator$current(model.r))))))));
	var allParameters = $pzp1997$assoc_list$AssocList$values(model.aq);
	if ($elm$core$List$isEmpty(allParameters)) {
		return $elm$core$Platform$Sub$none;
	} else {
		var timerTickSub = anyTimersOnCurrentPage ? A2(
			$elm$time$Time$every,
			1000.0,
			$elm$core$Basics$always($author$project$Main$TickTimers)) : $elm$core$Platform$Sub$none;
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					A2(
					$elm$time$Time$every,
					model.d3,
					$elm$core$Basics$always(
						$author$project$Main$LoadParameters(allParameters))),
					timerTickSub
				]));
	}
};
var $elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {bp: oldTime, cD: request, cV: subs};
	});
var $elm$browser$Browser$AnimationManager$init = $elm$core$Task$succeed(
	A3($elm$browser$Browser$AnimationManager$State, _List_Nil, $elm$core$Maybe$Nothing, 0));
var $elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var $elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var $elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _v0) {
		var request = _v0.cD;
		var oldTime = _v0.bp;
		var _v1 = _Utils_Tuple2(request, subs);
		if (_v1.a.$ === 1) {
			if (!_v1.b.b) {
				var _v2 = _v1.a;
				return $elm$browser$Browser$AnimationManager$init;
			} else {
				var _v4 = _v1.a;
				return A2(
					$elm$core$Task$andThen,
					function (pid) {
						return A2(
							$elm$core$Task$andThen,
							function (time) {
								return $elm$core$Task$succeed(
									A3(
										$elm$browser$Browser$AnimationManager$State,
										subs,
										$elm$core$Maybe$Just(pid),
										time));
							},
							$elm$browser$Browser$AnimationManager$now);
					},
					$elm$core$Process$spawn(
						A2(
							$elm$core$Task$andThen,
							$elm$core$Platform$sendToSelf(router),
							$elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_v1.b.b) {
				var pid = _v1.a.a;
				return A2(
					$elm$core$Task$andThen,
					function (_v3) {
						return $elm$browser$Browser$AnimationManager$init;
					},
					$elm$core$Process$kill(pid));
			} else {
				return $elm$core$Task$succeed(
					A3($elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var $elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _v0) {
		var subs = _v0.cV;
		var oldTime = _v0.bp;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(
						$elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			$elm$core$Task$andThen,
			function (pid) {
				return A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return $elm$core$Task$succeed(
							A3(
								$elm$browser$Browser$AnimationManager$State,
								subs,
								$elm$core$Maybe$Just(pid),
								newTime));
					},
					$elm$core$Task$sequence(
						A2($elm$core$List$map, send, subs)));
			},
			$elm$core$Process$spawn(
				A2(
					$elm$core$Task$andThen,
					$elm$core$Platform$sendToSelf(router),
					$elm$browser$Browser$AnimationManager$rAF)));
	});
var $elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Time(
				A2($elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Delta(
				A2($elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager($elm$browser$Browser$AnimationManager$init, $elm$browser$Browser$AnimationManager$onEffects, $elm$browser$Browser$AnimationManager$onSelfMsg, 0, $elm$browser$Browser$AnimationManager$subMap);
var $elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var $elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return $elm$browser$Browser$AnimationManager$subscription(
		$elm$browser$Browser$AnimationManager$Time(tagger));
};
var $elm$browser$Browser$Events$onAnimationFrame = $elm$browser$Browser$AnimationManager$onAnimationFrame;
var $mdgriffith$elm_animator$Animator$toSubscription = F3(
	function (toMsg, model, _v0) {
		var isRunning = _v0.a;
		return isRunning(model) ? $elm$browser$Browser$Events$onAnimationFrame(toMsg) : $elm$core$Platform$Sub$none;
	});
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A3($mdgriffith$elm_animator$Animator$toSubscription, $author$project$Main$Tick, model, $author$project$Main$animator),
				$author$project$Main$incoming($author$project$Main$EventReceived),
				$elm$browser$Browser$Events$onResize(
				F2(
					function (_v0, _v1) {
						return $author$project$Main$GetRootElement;
					})),
				$author$project$Main$timerSubs(model)
			]));
};
var $author$project$Main$InfoPressed = function (a) {
	return {$: 10, a: a};
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Component$Icon$ArrowLeft = 0;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Component$Icon$arrowLeft = function (props) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$height(props.eb),
				$elm$svg$Svg$Attributes$fill(props.ds)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M0 0h24v24H0z'),
								$elm$svg$Svg$Attributes$fill('none')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Component$Icon$close = function (props) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$height(props.eb),
				$elm$svg$Svg$Attributes$fill(props.ds)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0V0z'),
						$elm$svg$Svg$Attributes$fill('none')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z')
					]),
				_List_Nil)
			]));
};
var $author$project$Component$Icon$info = function (props) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$height(props.eb),
				$elm$svg$Svg$Attributes$fill(props.ds)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z'),
						$elm$svg$Svg$Attributes$fill('none')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z')
					]),
				_List_Nil)
			]));
};
var $author$project$Component$Icon$minus = function (props) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$height(props.eb),
				$elm$svg$Svg$Attributes$fill(props.ds)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M18.073 11l-12.315.002c-.419 0-.758.339-.758.757s.34.758.758.758h12.315c.418 0 .758-.34.758-.758S18.49 11 18.073 11z')
					]),
				_List_Nil)
			]));
};
var $author$project$Component$Icon$plus = function (props) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$height(props.eb),
				$elm$svg$Svg$Attributes$fill(props.ds)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M19.033 11.173h-6.275V4.898c0-.418-.34-.757-.758-.757s-.758.34-.758.757v6.276H4.967c-.419 0-.758.34-.758.758s.34.757.758.757h6.275v6.276c0 .418.34.758.758.758s.758-.34.758-.758V12.69h6.275c.419 0 .758-.339.758-.757s-.34-.76-.758-.76z')
					]),
				_List_Nil)
			]));
};
var $author$project$Component$Icon$view = F2(
	function (icon, iconProps) {
		switch (icon) {
			case 0:
				return $author$project$Component$Icon$arrowLeft(iconProps);
			case 1:
				return $author$project$Component$Icon$info(iconProps);
			case 2:
				return $author$project$Component$Icon$close(iconProps);
			case 3:
				return $author$project$Component$Icon$plus(iconProps);
			default:
				return $author$project$Component$Icon$minus(iconProps);
		}
	});
var $author$project$Page$backButton = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('back'),
				$elm$html$Html$Events$onClick(props.bq)
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Component$Icon$view,
				0,
				{ds: '#000000', eb: '30px'})
			]));
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Page$headerHeight = 50;
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Component$ProgressBar$view = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('progress-bar')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('line')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('subline -inc')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('subline -dec')
				]),
			_List_Nil)
		]));
var $elm_community$html_extra$Html$Extra$nothing = $elm$html$Html$text('');
var $elm_community$html_extra$Html$Extra$viewIf = F2(
	function (condition, html) {
		return condition ? html : $elm_community$html_extra$Html$Extra$nothing;
	});
var $author$project$Page$headerView = F4(
	function (props, thingNickname, showBackButton, page) {
		var thingNicknameString = A2($elm$core$Maybe$withDefault, '', thingNickname);
		var progressBarView = props.dJ ? $author$project$Component$ProgressBar$view : $elm$html$Html$text('');
		var paddingTop = $elm$core$String$fromFloat(props.G.el) + 'px';
		var height = $elm$core$String$fromFloat($author$project$Page$headerHeight + props.G.el) + 'px';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-header'),
					A2($elm$html$Html$Attributes$style, 'height', height),
					A2($elm$html$Html$Attributes$style, 'padding-top', paddingTop)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_List_fromArray(
						[
							A2(
							$elm_community$html_extra$Html$Extra$viewIf,
							showBackButton,
							$author$project$Page$backButton(
								{
									bq: props.dH(
										{
											aS: A2(
												$elm$core$Maybe$withDefault,
												_List_Nil,
												A2(
													$elm$core$Maybe$map,
													function ($) {
														return $.aS;
													},
													page.dI)),
											eh: $elm$core$Maybe$Nothing
										})
								})),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									props.c$(
										$author$project$Translate$Key$Dynamic(page.cY)))
								])),
							A2(
							$elm_community$html_extra$Html$Extra$viewIf,
							!$elm$core$String$isEmpty(thingNicknameString),
							A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('subtitle')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(thingNicknameString)
									])))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('progress')
						]),
					_List_fromArray(
						[progressBarView]))
				]));
	});
var $author$project$Request$isReconnectRequest = function (req) {
	if (req.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $author$project$Translate$English$translate = function (key) {
	switch (key) {
		case 0:
			return 'Close';
		case 1:
			return 'Ok';
		case 2:
			return 'Reconnect';
		case 3:
			return 'Something went wrong. Please try updating the application to the newest version.';
		case 4:
			return 'Error!';
		case 5:
			return 'Connection was lost. Please try again.';
		case 6:
			return 'Error!';
		default:
			return 'Choose language';
	}
};
var $author$project$Translate$Finnish$translate = function (key) {
	switch (key) {
		case 0:
			return 'Sulje';
		case 1:
			return 'Ok';
		case 2:
			return 'Yhdistä uudelleen';
		case 3:
			return 'Jokin meni pieleen. Yritä päivittää sovellus uusimpaan versioon ja yritä uudelleen.';
		case 4:
			return 'Virhe!';
		case 5:
			return 'Yhteys katkesi. Yritä uudelleen.';
		case 6:
			return 'Virhe!';
		default:
			return 'Valitse kieli';
	}
};
var $author$project$Translate$French$translate = function (key) {
	switch (key) {
		case 0:
			return 'Fermer';
		case 1:
			return 'OK';
		case 2:
			return 'Reconnecter';
		case 3:
			return 'Un problème est survenu. Veuillez essayer de mettre l’application à jour.';
		case 4:
			return 'Erreur!';
		case 5:
			return 'Connexion perdue. Veuillez réessayer.';
		case 6:
			return 'Erreur!';
		default:
			return 'Choix de langue';
	}
};
var $author$project$Translate$German$translate = function (key) {
	switch (key) {
		case 0:
			return 'Schließen';
		case 1:
			return 'OK';
		case 2:
			return 'Neu verbinden';
		case 3:
			return 'Etwas ist falsch gelaufen. Versuchen Sie bitte, die Anwendung auf die neueste Version zu aktualisieren.';
		case 4:
			return 'Fehler!';
		case 5:
			return 'Die Verbindung wurde unterbrochen. Versuchen Sie es bitte noch einmal.';
		case 6:
			return 'Fehler!';
		default:
			return 'Sprache auswählen';
	}
};
var $author$project$Translate$Norwegian$translate = function (key) {
	switch (key) {
		case 0:
			return 'Lukk';
		case 1:
			return 'Ok';
		case 2:
			return 'Koble til på nytt';
		case 3:
			return 'Noe gikk galt. Prøv å oppdatere applikasjonen til den nyeste versjonen.';
		case 4:
			return 'Feil!';
		case 5:
			return 'Tilkoblingen gikk tapt. Prøv på nytt.';
		case 6:
			return 'Feil!';
		default:
			return 'Velg språk';
	}
};
var $author$project$Translate$Swedish$translate = function (key) {
	switch (key) {
		case 0:
			return 'Stänga';
		case 1:
			return 'Ok';
		case 2:
			return 'Återansluta';
		case 3:
			return 'Något gick fel. Försök uppdatera appen till den senaste versionen.';
		case 4:
			return 'Fel!';
		case 5:
			return 'Anslutningen förlorades. Var god försök igen.';
		case 6:
			return 'Fel!';
		default:
			return 'Välj språk';
	}
};
var $author$project$Translate$translateStatic = F2(
	function (language, key) {
		switch (language) {
			case 0:
				return $author$project$Translate$English$translate(key);
			case 1:
				return $author$project$Translate$Finnish$translate(key);
			case 4:
				return $author$project$Translate$French$translate(key);
			case 2:
				return $author$project$Translate$German$translate(key);
			case 3:
				return $author$project$Translate$Norwegian$translate(key);
			default:
				return $author$project$Translate$Swedish$translate(key);
		}
	});
var $author$project$Translate$translate = F3(
	function (context, language, key) {
		if (key.$ === 1) {
			var k = key.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'MISSING KEY: ' + k,
				A2(
					$elm$core$Maybe$andThen,
					$elm$core$Dict$get(k),
					A2(
						$elm$core$Dict$get,
						$author$project$Language$toString(language),
						context)));
		} else {
			var k = key.a;
			return A2($author$project$Translate$translateStatic, language, k);
		}
	});
var $author$project$Main$InfoHide = {$: 11};
var $author$project$Main$LanguageChosen = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$QuitPressed = {$: 1};
var $author$project$Request$Reconnect = function (a) {
	return {$: 2, a: a};
};
var $author$project$Translate$Key$ButtonOk = 1;
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Component$BottomSheetError$contentView = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('inner')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(props.cY)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(props.cX)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(props.cs)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						props.c$(
							$author$project$Translate$Key$Static(1)))
					]))
			]));
};
var $author$project$Component$Icon$Close = 2;
var $mdgriffith$elm_animator$Internal$Interpolate$FullDefault = {$: 0};
var $mdgriffith$elm_animator$Internal$Interpolate$Position = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_animator$Animator$at = $mdgriffith$elm_animator$Internal$Interpolate$Position($mdgriffith$elm_animator$Internal$Interpolate$FullDefault);
var $mdgriffith$elm_animator$Animator$Css$Center = {$: 0};
var $mdgriffith$elm_animator$Animator$Css$defaultTransformOptions = {
	a2: $mdgriffith$elm_animator$Animator$Css$Center,
	aJ: {eu: 0, ev: 0, c5: 1}
};
var $mdgriffith$elm_animator$Animator$Css$explainActive = function (attrs) {
	explainActive:
	while (true) {
		if (!attrs.b) {
			return false;
		} else {
			if (attrs.a.$ === 4) {
				var on = attrs.a.a;
				var others = attrs.b;
				return on;
			} else {
				var skip = attrs.a;
				var others = attrs.b;
				var $temp$attrs = others;
				attrs = $temp$attrs;
				continue explainActive;
			}
		}
	}
};
var $mdgriffith$elm_animator$Animator$Css$viewAxes = function (options) {
	var _v0 = function () {
		var _v1 = options.a2;
		if (!_v1.$) {
			return A2($elm$core$Tuple$pair, 0, 0);
		} else {
			var ox = _v1.a;
			var oy = _v1.b;
			return A2($elm$core$Tuple$pair, ox, oy);
		}
	}();
	var x = _v0.a;
	var y = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'width', '0px'),
				A2($elm$html$Html$Attributes$style, 'height', '0px'),
				A2(
				$elm$html$Html$Attributes$style,
				'left',
				'calc(50% + ' + ($elm$core$String$fromFloat(x) + 'px - 3px)')),
				A2(
				$elm$html$Html$Attributes$style,
				'top',
				'calc(50% + ' + ($elm$core$String$fromFloat(y) + 'px - 3px)'))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'top', '10px'),
						A2($elm$html$Html$Attributes$style, 'left', '-1px'),
						A2($elm$html$Html$Attributes$style, 'width', '2px'),
						A2($elm$html$Html$Attributes$style, 'height', '50px'),
						A2($elm$html$Html$Attributes$style, 'background-color', 'black')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'bottom', '-5px'),
								A2($elm$html$Html$Attributes$style, 'left', '-3px'),
								A2($elm$html$Html$Attributes$style, 'width', '0'),
								A2($elm$html$Html$Attributes$style, 'height', '0'),
								A2($elm$html$Html$Attributes$style, 'border-left', '4px solid transparent'),
								A2($elm$html$Html$Attributes$style, 'border-right', '4px solid transparent'),
								A2($elm$html$Html$Attributes$style, 'border-top', '8px solid black')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'right', '-3px'),
								A2($elm$html$Html$Attributes$style, 'bottom', '-22px'),
								A2($elm$html$Html$Attributes$style, 'font-size', '12px')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Y')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'top', '-1px'),
						A2($elm$html$Html$Attributes$style, 'left', '10px'),
						A2($elm$html$Html$Attributes$style, 'width', '50px'),
						A2($elm$html$Html$Attributes$style, 'height', '2px'),
						A2($elm$html$Html$Attributes$style, 'background-color', 'black')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'right', '-5px'),
								A2($elm$html$Html$Attributes$style, 'top', '-3px'),
								A2($elm$html$Html$Attributes$style, 'width', '0'),
								A2($elm$html$Html$Attributes$style, 'height', '0'),
								A2($elm$html$Html$Attributes$style, 'border-top', '4px solid transparent'),
								A2($elm$html$Html$Attributes$style, 'border-bottom', '4px solid transparent'),
								A2($elm$html$Html$Attributes$style, 'border-left', '8px solid black')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'right', '-14px'),
								A2($elm$html$Html$Attributes$style, 'top', '-6px'),
								A2($elm$html$Html$Attributes$style, 'font-size', '12px')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('X')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'width', '6px'),
						A2($elm$html$Html$Attributes$style, 'height', '6px'),
						A2($elm$html$Html$Attributes$style, 'left', '-3px'),
						A2($elm$html$Html$Attributes$style, 'top', '-3px'),
						A2($elm$html$Html$Attributes$style, 'background-color', 'red'),
						A2($elm$html$Html$Attributes$style, 'border-radius', '3px')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'width', '12px'),
						A2($elm$html$Html$Attributes$style, 'height', '12px'),
						A2($elm$html$Html$Attributes$style, 'left', '-7px'),
						A2($elm$html$Html$Attributes$style, 'top', '-7px'),
						A2($elm$html$Html$Attributes$style, 'background-color', 'transparent'),
						A2($elm$html$Html$Attributes$style, 'border-radius', '7px'),
						A2($elm$html$Html$Attributes$style, 'border', '1px solid red')
					]),
				_List_Nil)
			]));
};
var $mdgriffith$elm_animator$Animator$Css$explainElement = function (transformOptions) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%'),
				A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(238, 238, 238, 0.4)'),
				A2($elm$html$Html$Attributes$style, 'border', '2px solid #DDD'),
				A2($elm$html$Html$Attributes$style, 'z-index', '10')
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_animator$Animator$Css$viewAxes(transformOptions)
			]));
};
var $mdgriffith$elm_animator$Animator$Css$getTransformOptions = function (attrs) {
	getTransformOptions:
	while (true) {
		if (!attrs.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (attrs.a.$ === 3) {
				var _v1 = attrs.a;
				var opts = _v1.a;
				return $elm$core$Maybe$Just(opts);
			} else {
				var rest = attrs.b;
				var $temp$attrs = rest;
				attrs = $temp$attrs;
				continue getTransformOptions;
			}
		}
	}
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $mdgriffith$elm_animator$Animator$Css$renderClassName = F2(
	function (str, anim) {
		renderClassName:
		while (true) {
			if (!anim.b) {
				return str;
			} else {
				if (!anim.b.b) {
					var top = anim.a;
					return (str === '') ? top.ck : _Utils_ap(str, top.ck);
				} else {
					var top = anim.a;
					var _v1 = anim.b;
					var next = _v1.a;
					var remaining = _v1.b;
					var $temp$str = str + (top.ck + '-'),
						$temp$anim = A2($elm$core$List$cons, next, remaining);
					str = $temp$str;
					anim = $temp$anim;
					continue renderClassName;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$renderDelay = F2(
	function (str, anim) {
		renderDelay:
		while (true) {
			if (!anim.b) {
				return str;
			} else {
				if (!anim.b.b) {
					var top = anim.a;
					return (str === '') ? ($elm$core$String$fromFloat(top.ak) + 'ms') : (str + ($elm$core$String$fromFloat(top.ak) + 'ms'));
				} else {
					var top = anim.a;
					var _v1 = anim.b;
					var next = _v1.a;
					var remaining = _v1.b;
					var $temp$str = str + ($elm$core$String$fromFloat(top.ak) + 'ms, '),
						$temp$anim = A2($elm$core$List$cons, next, remaining);
					str = $temp$str;
					anim = $temp$anim;
					continue renderDelay;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$renderDuration = F2(
	function (str, anim) {
		renderDuration:
		while (true) {
			if (!anim.b) {
				return str;
			} else {
				if (!anim.b.b) {
					var top = anim.a;
					return (str === '') ? ($elm$core$String$fromFloat(top.Q) + 'ms') : (str + ($elm$core$String$fromFloat(top.Q) + 'ms'));
				} else {
					var top = anim.a;
					var _v1 = anim.b;
					var next = _v1.a;
					var remaining = _v1.b;
					var $temp$str = str + ($elm$core$String$fromFloat(top.Q) + 'ms, '),
						$temp$anim = A2($elm$core$List$cons, next, remaining);
					str = $temp$str;
					anim = $temp$anim;
					continue renderDuration;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$repeatToString = function (rep) {
	if (!rep.$) {
		return 'infinite';
	} else {
		var n = rep.a;
		return $elm$core$String$fromInt(n);
	}
};
var $mdgriffith$elm_animator$Animator$Css$renderIterations = F2(
	function (str, anim) {
		renderIterations:
		while (true) {
			if (!anim.b) {
				return str;
			} else {
				if (!anim.b.b) {
					var top = anim.a;
					return (str === '') ? $mdgriffith$elm_animator$Animator$Css$repeatToString(top.ar) : _Utils_ap(
						str,
						$mdgriffith$elm_animator$Animator$Css$repeatToString(top.ar));
				} else {
					var top = anim.a;
					var _v1 = anim.b;
					var next = _v1.a;
					var remaining = _v1.b;
					var $temp$str = str + ($mdgriffith$elm_animator$Animator$Css$repeatToString(top.ar) + ', '),
						$temp$anim = A2($elm$core$List$cons, next, remaining);
					str = $temp$str;
					anim = $temp$anim;
					continue renderIterations;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$renderKeyframes = F2(
	function (str, anim) {
		renderKeyframes:
		while (true) {
			if (!anim.b) {
				return str;
			} else {
				var top = anim.a;
				var remain = anim.b;
				var $temp$str = str + ('\n' + top.aY),
					$temp$anim = remain;
				str = $temp$str;
				anim = $temp$anim;
				continue renderKeyframes;
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$renderName = F2(
	function (str, anim) {
		renderName:
		while (true) {
			if (!anim.b) {
				return str;
			} else {
				if (!anim.b.b) {
					var top = anim.a;
					return (str === '') ? top.ck : _Utils_ap(str, top.ck);
				} else {
					var top = anim.a;
					var _v1 = anim.b;
					var next = _v1.a;
					var remaining = _v1.b;
					var $temp$str = str + (top.ck + ', '),
						$temp$anim = A2($elm$core$List$cons, next, remaining);
					str = $temp$str;
					anim = $temp$anim;
					continue renderName;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$timingFnName = function (fn) {
	return 'linear';
};
var $mdgriffith$elm_animator$Animator$Css$renderTiming = F2(
	function (str, anim) {
		renderTiming:
		while (true) {
			if (!anim.b) {
				return str;
			} else {
				if (!anim.b.b) {
					var top = anim.a;
					return (str === '') ? $mdgriffith$elm_animator$Animator$Css$timingFnName(top.at) : _Utils_ap(
						str,
						$mdgriffith$elm_animator$Animator$Css$timingFnName(top.at));
				} else {
					var top = anim.a;
					var _v1 = anim.b;
					var next = _v1.a;
					var remaining = _v1.b;
					var $temp$str = str + ($mdgriffith$elm_animator$Animator$Css$timingFnName(top.at) + ', '),
						$temp$anim = A2($elm$core$List$cons, next, remaining);
					str = $temp$str;
					anim = $temp$anim;
					continue renderTiming;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$renderAnimations = function (animations) {
	return A2($mdgriffith$elm_animator$Animator$Css$renderKeyframes, '', animations) + ('\n.' + (A2($mdgriffith$elm_animator$Animator$Css$renderClassName, '', animations) + ('{\n            animation-name: ' + (A2($mdgriffith$elm_animator$Animator$Css$renderName, '', animations) + (';\n            animation-delay: ' + (A2($mdgriffith$elm_animator$Animator$Css$renderDelay, '', animations) + (';\n            animation-duration: ' + (A2($mdgriffith$elm_animator$Animator$Css$renderDuration, '', animations) + (';\n            animation-timing-function: ' + (A2($mdgriffith$elm_animator$Animator$Css$renderTiming, '', animations) + (';\n            animation-fill-mode: forwards;\n            animation-iteration-count: ' + (A2($mdgriffith$elm_animator$Animator$Css$renderIterations, '', animations) + ';\n        }\n        '))))))))))));
};
var $mdgriffith$elm_animator$Internal$Timeline$Frame = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_animator$Internal$Timeline$getLastEvent = F2(
	function (head, rest) {
		getLastEvent:
		while (true) {
			if (!rest.b) {
				return head;
			} else {
				var top = rest.a;
				var tail = rest.b;
				var $temp$head = top,
					$temp$rest = tail;
				head = $temp$head;
				rest = $temp$rest;
				continue getLastEvent;
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$findLastEventInLines = F2(
	function (first, remaining) {
		findLastEventInLines:
		while (true) {
			if (!remaining.b) {
				var startEv = first.b;
				var rem = first.c;
				return A2($mdgriffith$elm_animator$Internal$Timeline$getLastEvent, startEv, rem);
			} else {
				var r1 = remaining.a;
				var r1Remain = remaining.b;
				var $temp$first = r1,
					$temp$remaining = r1Remain;
				first = $temp$first;
				remaining = $temp$remaining;
				continue findLastEventInLines;
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Timeline$getFrames = F4(
	function (currentTime, config, fn, newFrames) {
		getFrames:
		while (true) {
			if (A2($mdgriffith$elm_animator$Internal$Time$thisBeforeOrEqualThat, currentTime, config.bz)) {
				if (!newFrames.b) {
					return _List_fromArray(
						[
							A2(
							$mdgriffith$elm_animator$Internal$Timeline$Frame,
							0,
							fn(config.bz)),
							A2(
							$mdgriffith$elm_animator$Internal$Timeline$Frame,
							1,
							fn(config.bk))
						]);
				} else {
					return A2(
						$elm$core$List$cons,
						A2(
							$mdgriffith$elm_animator$Internal$Timeline$Frame,
							0,
							fn(config.bz)),
						newFrames);
				}
			} else {
				var _new = A2(
					$mdgriffith$elm_animator$Internal$Timeline$Frame,
					A3($mdgriffith$elm_animator$Internal$Time$progress, config.bz, config.bk, currentTime),
					fn(currentTime));
				var $temp$currentTime = A2($mdgriffith$elm_animator$Internal$Time$rollbackBy, config.br, currentTime),
					$temp$config = config,
					$temp$fn = fn,
					$temp$newFrames = A2($elm$core$List$cons, _new, newFrames);
				currentTime = $temp$currentTime;
				config = $temp$config;
				fn = $temp$fn;
				newFrames = $temp$newFrames;
				continue getFrames;
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Time$numberOfFrames = F4(
	function (fps, lastFrameTime, startAt, endAt) {
		var totalDurationInMs = $ianmackenzie$elm_units$Duration$inMilliseconds(
			A2($mdgriffith$elm_animator$Internal$Time$duration, startAt, endAt));
		var millisecondsPerFrame = 1000 / fps;
		var framesSinceLastFrame = A2(
			$elm$core$Basics$max,
			0,
			$ianmackenzie$elm_units$Duration$inMilliseconds(
				A2($mdgriffith$elm_animator$Internal$Time$duration, lastFrameTime, startAt))) / millisecondsPerFrame;
		var offset = 1 - (framesSinceLastFrame - $elm$core$Basics$floor(framesSinceLastFrame));
		return _Utils_Tuple2(
			offset * millisecondsPerFrame,
			A2(
				$elm$core$Basics$max,
				1,
				$elm$core$Basics$round(totalDurationInMs / millisecondsPerFrame)));
	});
var $mdgriffith$elm_animator$Internal$Timeline$zeroDuration = $ianmackenzie$elm_units$Duration$milliseconds(0);
var $mdgriffith$elm_animator$Internal$Timeline$capture = F4(
	function (fps, lookup, fn, _v0) {
		var timelineDetails = _v0;
		var _v1 = timelineDetails.dn;
		var timetable = _v1;
		var start = fn.by(
			lookup(timelineDetails.b6));
		if (!timetable.b) {
			return {
				Q: $mdgriffith$elm_animator$Internal$Timeline$zeroDuration,
				bh: $elm$core$Maybe$Nothing,
				h: _List_fromArray(
					[
						A2($mdgriffith$elm_animator$Internal$Timeline$Frame, 1, start)
					])
			};
		} else {
			var firstLine = timetable.a;
			var remainingLines = timetable.b;
			var millisecondsPerFrame = 1000 / fps;
			var lastEvent = A2($mdgriffith$elm_animator$Internal$Timeline$findLastEventInLines, firstLine, remainingLines);
			var frames = A4(
				$mdgriffith$elm_animator$Internal$Timeline$getFrames,
				$mdgriffith$elm_animator$Internal$Timeline$startTime(lastEvent),
				{
					bk: $mdgriffith$elm_animator$Internal$Timeline$startTime(lastEvent),
					b: 0,
					br: $ianmackenzie$elm_units$Duration$milliseconds(millisecondsPerFrame),
					bz: timelineDetails.cm
				},
				function (currentTime) {
					return A7(
						$mdgriffith$elm_animator$Internal$Timeline$overLines,
						fn,
						lookup,
						_Utils_update(
							timelineDetails,
							{cm: currentTime}),
						$elm$core$Maybe$Nothing,
						firstLine,
						remainingLines,
						start);
				},
				_List_Nil);
			var _v3 = A2(
				$mdgriffith$elm_animator$Internal$Time$thisAfterThat,
				timelineDetails.cm,
				$mdgriffith$elm_animator$Internal$Timeline$startTime(lastEvent)) ? _Utils_Tuple2(0, 1) : A4(
				$mdgriffith$elm_animator$Internal$Time$numberOfFrames,
				fps,
				timelineDetails.cm,
				timelineDetails.cm,
				$mdgriffith$elm_animator$Internal$Timeline$startTime(lastEvent));
			var numberOfFrames = _v3.b;
			return {
				Q: A2(
					$mdgriffith$elm_animator$Internal$Time$thisAfterThat,
					timelineDetails.cm,
					$mdgriffith$elm_animator$Internal$Timeline$startTime(lastEvent)) ? $mdgriffith$elm_animator$Internal$Timeline$zeroDuration : A2(
					$mdgriffith$elm_animator$Internal$Time$duration,
					timelineDetails.cm,
					$mdgriffith$elm_animator$Internal$Timeline$startTime(lastEvent)),
				bh: function () {
					var _v4 = fn.bj(
						lookup(
							$mdgriffith$elm_animator$Internal$Timeline$getEvent(lastEvent)));
					if (_v4.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var period = _v4.a;
						var lastEventEv = lookup(
							$mdgriffith$elm_animator$Internal$Timeline$getEvent(lastEvent));
						var dwellStartTime = $mdgriffith$elm_animator$Internal$Timeline$startTime(lastEvent);
						var endAt = function () {
							if (period.$ === 1) {
								var n = period.a;
								var totalDur = period.b;
								return A2($mdgriffith$elm_animator$Internal$Time$advanceBy, totalDur, dwellStartTime);
							} else {
								var totalDur = period.a;
								return A2($mdgriffith$elm_animator$Internal$Time$advanceBy, totalDur, dwellStartTime);
							}
						}();
						var _v5 = A4($mdgriffith$elm_animator$Internal$Time$numberOfFrames, fps, dwellStartTime, dwellStartTime, endAt);
						var dwellOffset = _v5.a;
						var numberOfDwellFrames = _v5.b;
						var dwellFrames = A4(
							$mdgriffith$elm_animator$Internal$Timeline$getFrames,
							endAt,
							{
								bk: endAt,
								b: dwellOffset,
								br: $ianmackenzie$elm_units$Duration$milliseconds(millisecondsPerFrame),
								bz: dwellStartTime
							},
							function (currentTime) {
								return A2(
									fn.bi,
									lastEventEv,
									A2($mdgriffith$elm_animator$Internal$Time$duration, dwellStartTime, currentTime));
							},
							_List_Nil);
						return $elm$core$Maybe$Just(
							{h: dwellFrames, a4: period});
					}
				}(),
				h: frames
			};
		}
	});
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $mdgriffith$elm_animator$Internal$Interpolate$average = F3(
	function (x, y, progress) {
		return $elm$core$Basics$sqrt(((x * x) * (1 - progress)) + ((y * y) * progress));
	});
var $mdgriffith$elm_animator$Internal$Time$millis = function (ms) {
	return ms;
};
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$rgba = F4(
	function (r, g, b, a) {
		return A4($avh4$elm_color$Color$RgbaSpace, r, g, b, a);
	});
var $avh4$elm_color$Color$toRgba = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {ag: a, bd: b, bl: g, bw: r};
};
var $mdgriffith$elm_animator$Internal$Interpolate$lerpColor = F7(
	function (prevEndTime, maybePrev, target, targetTime, now, maybeLookAhead, state) {
		var two = $avh4$elm_color$Color$toRgba(target);
		var progress = A3(
			$mdgriffith$elm_animator$Internal$Time$progress,
			$mdgriffith$elm_animator$Internal$Time$millis(prevEndTime),
			$mdgriffith$elm_animator$Internal$Time$millis(targetTime),
			$mdgriffith$elm_animator$Internal$Time$millis(now));
		var one = $avh4$elm_color$Color$toRgba(state);
		return A4(
			$avh4$elm_color$Color$rgba,
			A3($mdgriffith$elm_animator$Internal$Interpolate$average, one.bw, two.bw, progress),
			A3($mdgriffith$elm_animator$Internal$Interpolate$average, one.bl, two.bl, progress),
			A3($mdgriffith$elm_animator$Internal$Interpolate$average, one.bd, two.bd, progress),
			A3($mdgriffith$elm_animator$Internal$Interpolate$average, one.ag, two.ag, progress));
	});
var $mdgriffith$elm_animator$Internal$Interpolate$coloring = {
	bb: function (_v0) {
		return {aT: 0, a_: 0};
	},
	bc: F3(
		function (lookup, target, future) {
			return lookup(
				$mdgriffith$elm_animator$Internal$Timeline$getEvent(target));
		}),
	bi: F2(
		function (clr, duration) {
			return clr;
		}),
	bj: function (_v1) {
		return $elm$core$Maybe$Nothing;
	},
	bm: $mdgriffith$elm_animator$Internal$Interpolate$lerpColor,
	by: $elm$core$Basics$identity
};
var $ianmackenzie$elm_units$Pixels$inPixels = function (_v0) {
	var numPixels = _v0;
	return numPixels;
};
var $mdgriffith$elm_animator$Animator$Css$expand = F2(
	function (_v0, _v1) {
		var lastFrame = _v0.b;
		var percent = _v1.a;
		var rotation = _v1.b;
		return A2(
			$mdgriffith$elm_animator$Internal$Timeline$Frame,
			percent,
			{ah: lastFrame.ah, ai: lastFrame.ai, aj: lastFrame.aj, am: lastFrame.am, an: lastFrame.an, U: lastFrame.U, aI: rotation, m: lastFrame.m, n: lastFrame.n, p: lastFrame.p, eu: lastFrame.eu, ev: lastFrame.ev, c5: lastFrame.c5});
	});
var $ianmackenzie$elm_units$Pixels$pixels = function (numPixels) {
	return numPixels;
};
var $ianmackenzie$elm_units$Pixels$pixelsPerSecond = function (numPixelsPerSecond) {
	return numPixelsPerSecond;
};
var $mdgriffith$elm_animator$Animator$Css$mapDwellFrames = F2(
	function (combinedFrames, dwell) {
		var lastFrame = function () {
			if (!combinedFrames.b) {
				return A2(
					$mdgriffith$elm_animator$Internal$Timeline$Frame,
					1,
					{
						ah: 0,
						ai: 0,
						aj: 1,
						am: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(0),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						an: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(0),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						U: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(1),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						aI: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(0),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						m: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(1),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						n: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(1),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						p: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(1),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						eu: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(0),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						ev: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(0),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						},
						c5: {
							d0: $ianmackenzie$elm_units$Pixels$pixels(0),
							c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
						}
					});
			} else {
				var last = combinedFrames.a;
				return last;
			}
		}();
		return {
			h: A2(
				$elm$core$List$map,
				$mdgriffith$elm_animator$Animator$Css$expand(lastFrame),
				dwell.h),
			a4: dwell.a4
		};
	});
var $mdgriffith$elm_animator$Internal$Interpolate$adjustTiming = function (m) {
	if (!m.$) {
		var departure = m.a;
		var arrival = m.b;
		return {aT: arrival.H, a_: departure.K};
	} else {
		var departure = m.a;
		var arrival = m.b;
		return {aT: arrival.H, a_: departure.K};
	}
};
var $ianmackenzie$elm_units$Quantity$divideBy = F2(
	function (divisor, _v0) {
		var value = _v0;
		return value / divisor;
	});
var $ianmackenzie$elm_units$Quantity$per = F2(
	function (_v0, _v1) {
		var independentValue = _v0;
		var dependentValue = _v1;
		return dependentValue / independentValue;
	});
var $mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing = F3(
	function (ease, period, target) {
		var targetPixels = $ianmackenzie$elm_units$Pixels$pixels(
			ease(target));
		var sampleSize = 16;
		var deltaSample = sampleSize / $ianmackenzie$elm_units$Duration$inMilliseconds(period);
		var next = $ianmackenzie$elm_units$Pixels$pixels(
			ease(target + deltaSample));
		var dx2 = A2($ianmackenzie$elm_units$Quantity$minus, targetPixels, next);
		var prev = $ianmackenzie$elm_units$Pixels$pixels(
			ease(target - deltaSample));
		var dx1 = A2($ianmackenzie$elm_units$Quantity$minus, prev, targetPixels);
		var dx = A2(
			$ianmackenzie$elm_units$Quantity$divideBy,
			2,
			A2($ianmackenzie$elm_units$Quantity$plus, dx1, dx2));
		return A2(
			$ianmackenzie$elm_units$Quantity$per,
			$ianmackenzie$elm_units$Duration$milliseconds(sampleSize),
			dx);
	});
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $ianmackenzie$elm_units$Quantity$isInfinite = function (_v0) {
	var value = _v0;
	return $elm$core$Basics$isInfinite(value);
};
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $ianmackenzie$elm_units$Quantity$isNaN = function (_v0) {
	var value = _v0;
	return $elm$core$Basics$isNaN(value);
};
var $ianmackenzie$elm_units$Quantity$zero = 0;
var $mdgriffith$elm_animator$Internal$Interpolate$velocityBetween = F4(
	function (one, oneTime, two, twoTime) {
		var duration = A2($mdgriffith$elm_animator$Internal$Time$duration, oneTime, twoTime);
		var distance = A2($ianmackenzie$elm_units$Quantity$minus, one, two);
		var vel = A2($ianmackenzie$elm_units$Quantity$per, duration, distance);
		return ($ianmackenzie$elm_units$Quantity$isNaN(vel) || $ianmackenzie$elm_units$Quantity$isInfinite(vel)) ? $ianmackenzie$elm_units$Quantity$zero : vel;
	});
var $mdgriffith$elm_animator$Internal$Interpolate$velocityAtTarget = F3(
	function (lookup, t, maybeLookAhead) {
		var target = t.a;
		var targetTime = t.b;
		var targetEndTime = t.c;
		if (_Utils_eq(targetEndTime, targetTime)) {
			if (maybeLookAhead.$ === 1) {
				var _v1 = lookup(target);
				if (_v1.$ === 1) {
					return $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0);
				} else {
					var period = _v1.c;
					var toX = _v1.d;
					if (!period.$) {
						var dur = period.a;
						return A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, dur, 0);
					} else {
						var n = period.a;
						var dur = period.b;
						return (!n) ? $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0) : A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, dur, 0);
					}
				}
			} else {
				var _v3 = maybeLookAhead.a;
				var lookAhead = _v3.a;
				var aheadTime = _v3.b;
				var aheadEndTime = _v3.c;
				var targetPosition = function () {
					var _v6 = lookup(target);
					if (!_v6.$) {
						var toX = _v6.d;
						return $ianmackenzie$elm_units$Pixels$pixels(
							toX(0));
					} else {
						var x = _v6.c;
						return $ianmackenzie$elm_units$Pixels$pixels(x);
					}
				}();
				var _v4 = lookup(lookAhead);
				if (_v4.$ === 1) {
					var aheadPosition = _v4.c;
					return A4(
						$mdgriffith$elm_animator$Internal$Interpolate$velocityBetween,
						targetPosition,
						targetTime,
						$ianmackenzie$elm_units$Pixels$pixels(aheadPosition),
						aheadTime);
				} else {
					var period = _v4.c;
					var toX = _v4.d;
					if (_Utils_eq(aheadEndTime, aheadTime)) {
						return A4(
							$mdgriffith$elm_animator$Internal$Interpolate$velocityBetween,
							targetPosition,
							targetTime,
							$ianmackenzie$elm_units$Pixels$pixels(
								toX(0)),
							aheadTime);
					} else {
						if (!period.$) {
							var periodDuration = period.a;
							return A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0);
						} else {
							var n = period.a;
							var periodDuration = period.b;
							return A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0);
						}
					}
				}
			}
		} else {
			var _v7 = lookup(target);
			if (_v7.$ === 1) {
				return $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0);
			} else {
				var period = _v7.c;
				var toX = _v7.d;
				if (!period.$) {
					var periodDuration = period.a;
					return A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0);
				} else {
					var n = period.a;
					var periodDuration = period.b;
					return A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0);
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$afterMove = F3(
	function (lookup, target, future) {
		return {
			d0: function () {
				var _v0 = lookup(
					$mdgriffith$elm_animator$Internal$Timeline$getEvent(target));
				if (!_v0.$) {
					var depart = _v0.a;
					var arrive = _v0.b;
					var period = _v0.c;
					var toX = _v0.d;
					return $ianmackenzie$elm_units$Pixels$pixels(
						toX(0));
				} else {
					var x = _v0.c;
					return $ianmackenzie$elm_units$Pixels$pixels(x);
				}
			}(),
			c1: A3(
				$mdgriffith$elm_animator$Internal$Interpolate$velocityAtTarget,
				lookup,
				target,
				$elm$core$List$head(future))
		};
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $mdgriffith$elm_animator$Internal$Interpolate$wrapUnitAfter = F2(
	function (dur, total) {
		var totalDuration = $elm$core$Basics$round(
			$ianmackenzie$elm_units$Duration$inMilliseconds(total));
		var periodDuration = $elm$core$Basics$round(
			$ianmackenzie$elm_units$Duration$inMilliseconds(dur));
		if ((!periodDuration) || (!totalDuration)) {
			return 0;
		} else {
			var remaining = A2($elm$core$Basics$modBy, periodDuration, totalDuration);
			return (!remaining) ? 1 : (remaining / periodDuration);
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$dwellFor = F2(
	function (movement, duration) {
		if (movement.$ === 1) {
			var pos = movement.c;
			return {
				d0: $ianmackenzie$elm_units$Pixels$pixels(pos),
				c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
			};
		} else {
			var period = movement.c;
			var toX = movement.d;
			if (!period.$) {
				var periodDuration = period.a;
				var progress = A2($mdgriffith$elm_animator$Internal$Interpolate$wrapUnitAfter, periodDuration, duration);
				return {
					d0: $ianmackenzie$elm_units$Pixels$pixels(
						toX(progress)),
					c1: A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, progress)
				};
			} else {
				var n = period.a;
				var periodDuration = period.b;
				var totalMS = $ianmackenzie$elm_units$Duration$inMilliseconds(duration);
				var iterationTimeMS = $ianmackenzie$elm_units$Duration$inMilliseconds(periodDuration);
				var iteration = $elm$core$Basics$floor(totalMS / iterationTimeMS);
				if (_Utils_cmp(iteration, n) > -1) {
					return {
						d0: $ianmackenzie$elm_units$Pixels$pixels(
							toX(1)),
						c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
					};
				} else {
					var progress = A2($mdgriffith$elm_animator$Internal$Interpolate$wrapUnitAfter, periodDuration, duration);
					return {
						d0: $ianmackenzie$elm_units$Pixels$pixels(
							toX(progress)),
						c1: A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, progress)
					};
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$dwellPeriod = function (movement) {
	if (movement.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		var period = movement.c;
		return $elm$core$Maybe$Just(period);
	}
};
var $ianmackenzie$elm_units$Pixels$inPixelsPerSecond = function (_v0) {
	var numPixelsPerSecond = _v0;
	return numPixelsPerSecond;
};
var $mdgriffith$elm_animator$Internal$Interpolate$Spline = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_animator$Internal$Interpolate$zeroPoint = {eu: 0, ev: 0};
var $mdgriffith$elm_animator$Internal$Interpolate$createSpline = function (config) {
	var totalX = config.bV.eu - config.by.eu;
	var startVelScale = 1 / (config.ae.eu / totalX);
	var startVelocity = (!config.az.f) ? config.ae : (((!(config.ae.eu - $mdgriffith$elm_animator$Internal$Interpolate$zeroPoint.eu)) && (!(config.ae.ev - $mdgriffith$elm_animator$Internal$Interpolate$zeroPoint.ev))) ? {eu: totalX * (config.az.f * 3), ev: 0} : {eu: (startVelScale * config.ae.eu) * (config.az.f * 3), ev: (startVelScale * config.ae.ev) * (config.az.f * 3)});
	var endVelScale = 1 / (config._.eu / totalX);
	var endVelocity = (!config.ax.f) ? config._ : (((!(config._.eu - $mdgriffith$elm_animator$Internal$Interpolate$zeroPoint.eu)) && (!(config._.ev - $mdgriffith$elm_animator$Internal$Interpolate$zeroPoint.ev))) ? {eu: totalX * (config.ax.f * 3), ev: 0} : {eu: (endVelScale * config._.eu) * (config.ax.f * 3), ev: (endVelScale * config._.ev) * (config.ax.f * 3)});
	return A4(
		$mdgriffith$elm_animator$Internal$Interpolate$Spline,
		config.by,
		{eu: config.by.eu + ((1 / 3) * startVelocity.eu), ev: config.by.ev + ((1 / 3) * startVelocity.ev)},
		{eu: config.bV.eu + (((-1) / 3) * endVelocity.eu), ev: config.bV.ev + (((-1) / 3) * endVelocity.ev)},
		config.bV);
};
var $mdgriffith$elm_animator$Internal$Interpolate$findAtXOnSpline = F6(
	function (spline, desiredX, tolerance, jumpSize, t, depth) {
		findAtXOnSpline:
		while (true) {
			var p1 = spline.a;
			var p2 = spline.b;
			var p3 = spline.c;
			var p4 = spline.d;
			var point = function () {
				if (t <= 0.5) {
					var q3 = {eu: p3.eu + (t * (p4.eu - p3.eu)), ev: p3.ev + (t * (p4.ev - p3.ev))};
					var q2 = {eu: p2.eu + (t * (p3.eu - p2.eu)), ev: p2.ev + (t * (p3.ev - p2.ev))};
					var r2 = {eu: q2.eu + (t * (q3.eu - q2.eu)), ev: q2.ev + (t * (q3.ev - q2.ev))};
					var q1 = {eu: p1.eu + (t * (p2.eu - p1.eu)), ev: p1.ev + (t * (p2.ev - p1.ev))};
					var r1 = {eu: q1.eu + (t * (q2.eu - q1.eu)), ev: q1.ev + (t * (q2.ev - q1.ev))};
					return {eu: r1.eu + (t * (r2.eu - r1.eu)), ev: r1.ev + (t * (r2.ev - r1.ev))};
				} else {
					var q3 = {eu: p4.eu + ((1 - t) * (p3.eu - p4.eu)), ev: p4.ev + ((1 - t) * (p3.ev - p4.ev))};
					var q2 = {eu: p3.eu + ((1 - t) * (p2.eu - p3.eu)), ev: p3.ev + ((1 - t) * (p2.ev - p3.ev))};
					var r2 = {eu: q3.eu + ((1 - t) * (q2.eu - q3.eu)), ev: q3.ev + ((1 - t) * (q2.ev - q3.ev))};
					var q1 = {eu: p2.eu + ((1 - t) * (p1.eu - p2.eu)), ev: p2.ev + ((1 - t) * (p1.ev - p2.ev))};
					var r1 = {eu: q2.eu + ((1 - t) * (q1.eu - q2.eu)), ev: q2.ev + ((1 - t) * (q1.ev - q2.ev))};
					return {eu: r2.eu + ((1 - t) * (r1.eu - r2.eu)), ev: r2.ev + ((1 - t) * (r1.ev - r2.ev))};
				}
			}();
			if (depth === 10) {
				return {bs: point, bB: t};
			} else {
				if (($elm$core$Basics$abs(point.eu - desiredX) < 1) && ($elm$core$Basics$abs(point.eu - desiredX) >= 0)) {
					return {bs: point, bB: t};
				} else {
					if ((point.eu - desiredX) > 0) {
						var $temp$spline = spline,
							$temp$desiredX = desiredX,
							$temp$tolerance = tolerance,
							$temp$jumpSize = jumpSize / 2,
							$temp$t = t - jumpSize,
							$temp$depth = depth + 1;
						spline = $temp$spline;
						desiredX = $temp$desiredX;
						tolerance = $temp$tolerance;
						jumpSize = $temp$jumpSize;
						t = $temp$t;
						depth = $temp$depth;
						continue findAtXOnSpline;
					} else {
						var $temp$spline = spline,
							$temp$desiredX = desiredX,
							$temp$tolerance = tolerance,
							$temp$jumpSize = jumpSize / 2,
							$temp$t = t + jumpSize,
							$temp$depth = depth + 1;
						spline = $temp$spline;
						desiredX = $temp$desiredX;
						tolerance = $temp$tolerance;
						jumpSize = $temp$jumpSize;
						t = $temp$t;
						depth = $temp$depth;
						continue findAtXOnSpline;
					}
				}
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$interpolateValue = F3(
	function (start, end, t) {
		return (t <= 0.5) ? (start + (t * (end - start))) : (end + ((1 - t) * (start - end)));
	});
var $mdgriffith$elm_animator$Internal$Interpolate$firstDerivativeOnSpline = F2(
	function (_v0, proportion) {
		var p1 = _v0.a;
		var p2 = _v0.b;
		var p3 = _v0.c;
		var p4 = _v0.d;
		var vy3 = p4.ev - p3.ev;
		var vy2 = p3.ev - p2.ev;
		var wy2 = A3($mdgriffith$elm_animator$Internal$Interpolate$interpolateValue, vy2, vy3, proportion);
		var vy1 = p2.ev - p1.ev;
		var wy1 = A3($mdgriffith$elm_animator$Internal$Interpolate$interpolateValue, vy1, vy2, proportion);
		var vx3 = p4.eu - p3.eu;
		var vx2 = p3.eu - p2.eu;
		var wx2 = A3($mdgriffith$elm_animator$Internal$Interpolate$interpolateValue, vx2, vx3, proportion);
		var vx1 = p2.eu - p1.eu;
		var wx1 = A3($mdgriffith$elm_animator$Internal$Interpolate$interpolateValue, vx1, vx2, proportion);
		return {
			eu: 3 * A3($mdgriffith$elm_animator$Internal$Interpolate$interpolateValue, wx1, wx2, proportion),
			ev: 3 * A3($mdgriffith$elm_animator$Internal$Interpolate$interpolateValue, wy1, wy2, proportion)
		};
	});
var $mdgriffith$elm_animator$Internal$Interpolate$newVelocityAtTarget = F3(
	function (target, targetTime, lookAhead) {
		var targetPosition = function () {
			if (!target.$) {
				var toX = target.d;
				return $ianmackenzie$elm_units$Pixels$pixels(
					toX(0));
			} else {
				var x = target.c;
				return $ianmackenzie$elm_units$Pixels$pixels(x);
			}
		}();
		var _v0 = lookAhead.c7;
		if (_v0.$ === 1) {
			var aheadPosition = _v0.c;
			return A4(
				$mdgriffith$elm_animator$Internal$Interpolate$velocityBetween,
				targetPosition,
				$mdgriffith$elm_animator$Internal$Time$millis(targetTime),
				$ianmackenzie$elm_units$Pixels$pixels(aheadPosition),
				$mdgriffith$elm_animator$Internal$Time$millis(lookAhead.aM));
		} else {
			var period = _v0.c;
			var toX = _v0.d;
			if (lookAhead.d5) {
				if (!period.$) {
					var periodDuration = period.a;
					return A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0);
				} else {
					var n = period.a;
					var periodDuration = period.b;
					return A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0);
				}
			} else {
				return A4(
					$mdgriffith$elm_animator$Internal$Interpolate$velocityBetween,
					targetPosition,
					$mdgriffith$elm_animator$Internal$Time$millis(targetTime),
					$ianmackenzie$elm_units$Pixels$pixels(
						toX(0)),
					$mdgriffith$elm_animator$Internal$Time$millis(lookAhead.aM));
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$nullDeparture = {K: 0, f: 0};
var $mdgriffith$elm_animator$Internal$Interpolate$interpolateBetween = F7(
	function (startTimeInMs, maybePrevious, target, targetTimeInMs, now, maybeLookAhead, state) {
		var targetVelocity = function () {
			if (maybeLookAhead.$ === 1) {
				if (target.$ === 1) {
					return 0;
				} else {
					var period = target.c;
					var toX = target.d;
					if (!period.$) {
						var periodDuration = period.a;
						return $ianmackenzie$elm_units$Pixels$inPixelsPerSecond(
							A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0));
					} else {
						var n = period.a;
						var periodDuration = period.b;
						return $ianmackenzie$elm_units$Pixels$inPixelsPerSecond(
							A3($mdgriffith$elm_animator$Internal$Interpolate$derivativeOfEasing, toX, periodDuration, 0));
					}
				}
			} else {
				var lookAhead = maybeLookAhead.a;
				return $ianmackenzie$elm_units$Pixels$inPixelsPerSecond(
					A3($mdgriffith$elm_animator$Internal$Interpolate$newVelocityAtTarget, target, targetTimeInMs, lookAhead));
			}
		}();
		var targetPosition = function () {
			if (!target.$) {
				var toX = target.d;
				return $ianmackenzie$elm_units$Pixels$pixels(
					toX(0));
			} else {
				var x = target.c;
				return $ianmackenzie$elm_units$Pixels$pixels(x);
			}
		}();
		var curve = $mdgriffith$elm_animator$Internal$Interpolate$createSpline(
			{
				ax: function () {
					if (target.$ === 1) {
						var arrival = target.b;
						return arrival;
					} else {
						var arrival = target.b;
						return arrival;
					}
				}(),
				az: function () {
					if (maybePrevious.$ === 1) {
						return $mdgriffith$elm_animator$Internal$Interpolate$nullDeparture;
					} else {
						if (maybePrevious.a.$ === 1) {
							var _v2 = maybePrevious.a;
							var departure = _v2.a;
							return departure;
						} else {
							var _v3 = maybePrevious.a;
							var departure = _v3.a;
							return departure;
						}
					}
				}(),
				bV: {
					eu: targetTimeInMs,
					ev: $ianmackenzie$elm_units$Pixels$inPixels(targetPosition)
				},
				_: {eu: 1000, ev: targetVelocity},
				by: {
					eu: startTimeInMs,
					ev: $ianmackenzie$elm_units$Pixels$inPixels(state.d0)
				},
				ae: {
					eu: 1000,
					ev: $ianmackenzie$elm_units$Pixels$inPixelsPerSecond(state.c1)
				}
			});
		var current = A6($mdgriffith$elm_animator$Internal$Interpolate$findAtXOnSpline, curve, now, 1, 0.25, 0.5, 0);
		var firstDerivative = A2($mdgriffith$elm_animator$Internal$Interpolate$firstDerivativeOnSpline, curve, current.bB);
		return {
			d0: $ianmackenzie$elm_units$Pixels$pixels(current.bs.ev),
			c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(1000 * (firstDerivative.ev / firstDerivative.eu))
		};
	});
var $mdgriffith$elm_animator$Internal$Spring$criticalDamping = F2(
	function (k, m) {
		return 2 * $elm$core$Basics$sqrt(k * m);
	});
var $elm$core$Basics$e = _Basics_e;
var $mdgriffith$elm_animator$Internal$Spring$toleranceForSpringSettleTimeCalculation = (-1) * A2($elm$core$Basics$logBase, $elm$core$Basics$e, 0.005);
var $mdgriffith$elm_animator$Internal$Spring$settlesAt = function (_v0) {
	var stiffness = _v0.aK;
	var damping = _v0.ay;
	var mass = _v0.aC;
	var m = mass;
	var k = stiffness;
	var springAspect = $elm$core$Basics$sqrt(k / m);
	var cCritical = A2($mdgriffith$elm_animator$Internal$Spring$criticalDamping, k, m);
	var c = damping;
	if (_Utils_eq(
		$elm$core$Basics$round(c),
		$elm$core$Basics$round(cCritical))) {
		return 1000 * (8.5 / springAspect);
	} else {
		if ((c - cCritical) > 0) {
			var dampingAspect = c / cCritical;
			return 1000 * ($mdgriffith$elm_animator$Internal$Spring$toleranceForSpringSettleTimeCalculation / (dampingAspect * springAspect));
		} else {
			var dampingAspect = c / cCritical;
			return 1000 * ($mdgriffith$elm_animator$Internal$Spring$toleranceForSpringSettleTimeCalculation / (dampingAspect * springAspect));
		}
	}
};
var $mdgriffith$elm_animator$Internal$Spring$mapToRange = F3(
	function (minimum, maximum, x) {
		var total = maximum - minimum;
		return minimum + (x * total);
	});
var $mdgriffith$elm_animator$Internal$Spring$wobble2Ratio = F2(
	function (wobble, duration) {
		var ms = $ianmackenzie$elm_units$Duration$inMilliseconds(duration);
		var scalingBelowDur = ms / 350;
		var top = A2(
			$elm$core$Basics$max,
			0.43,
			0.8 * A2($elm$core$Basics$min, 1, scalingBelowDur));
		var bounded = A2(
			$elm$core$Basics$min,
			1,
			A2($elm$core$Basics$max, 0, wobble));
		return A3($mdgriffith$elm_animator$Internal$Spring$mapToRange, 0.43, top, 1 - bounded);
	});
var $mdgriffith$elm_animator$Internal$Spring$wobble2Damping = F4(
	function (wobble, k, m, duration) {
		return A2($mdgriffith$elm_animator$Internal$Spring$wobble2Ratio, wobble, duration) * A2($mdgriffith$elm_animator$Internal$Spring$criticalDamping, k, m);
	});
var $mdgriffith$elm_animator$Internal$Spring$select = F2(
	function (wobbliness, duration) {
		var k = 150;
		var durMS = $ianmackenzie$elm_units$Duration$inMilliseconds(duration);
		var damping = A4($mdgriffith$elm_animator$Internal$Spring$wobble2Damping, wobbliness, k, 1, duration);
		var initiallySettlesAt = $mdgriffith$elm_animator$Internal$Spring$settlesAt(
			{ay: damping, aC: 1, aK: k});
		var newCritical = A2($mdgriffith$elm_animator$Internal$Spring$criticalDamping, k, durMS / initiallySettlesAt);
		return {ay: damping, aC: durMS / initiallySettlesAt, aK: k};
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $mdgriffith$elm_animator$Internal$Spring$step = F4(
	function (target, _v0, dtms, motion) {
		var stiffness = _v0.aK;
		var damping = _v0.ay;
		var mass = _v0.aC;
		var fspring = stiffness * (target - motion.d0);
		var fdamper = ((-1) * damping) * motion.c1;
		var dt = dtms / 1000;
		var a = (fspring + fdamper) / mass;
		var newVelocity = motion.c1 + (a * dt);
		var newPos = motion.d0 + (newVelocity * dt);
		return {d0: newPos, c1: newVelocity};
	});
var $mdgriffith$elm_animator$Internal$Spring$stepOver = F4(
	function (duration, params, target, state) {
		var durMS = $ianmackenzie$elm_units$Duration$inMilliseconds(duration);
		var frames = durMS / 16;
		var remainder = 16 * (frames - $elm$core$Basics$floor(frames));
		var steps = (remainder > 0) ? A2(
			$elm$core$List$cons,
			remainder,
			A2(
				$elm$core$List$repeat,
				($elm$core$Basics$floor(durMS) / 16) | 0,
				16)) : A2(
			$elm$core$List$repeat,
			($elm$core$Basics$floor(durMS) / 16) | 0,
			16);
		return A3(
			$elm$core$List$foldl,
			A2($mdgriffith$elm_animator$Internal$Spring$step, target, params),
			state,
			steps);
	});
var $mdgriffith$elm_animator$Internal$Interpolate$springInterpolation = F7(
	function (prevEndTime, _v0, target, targetTime, now, _v1, state) {
		var wobble = function () {
			if (!target.$) {
				var arrival = target.b;
				return arrival.es;
			} else {
				var arrival = target.b;
				return arrival.es;
			}
		}();
		var targetPos = function () {
			if (!target.$) {
				var toX = target.d;
				return toX(0);
			} else {
				var x = target.c;
				return x;
			}
		}();
		var duration = A2(
			$mdgriffith$elm_animator$Internal$Time$duration,
			$mdgriffith$elm_animator$Internal$Time$millis(prevEndTime),
			$mdgriffith$elm_animator$Internal$Time$millis(targetTime));
		var params = A2($mdgriffith$elm_animator$Internal$Spring$select, wobble, duration);
		var _new = A4(
			$mdgriffith$elm_animator$Internal$Spring$stepOver,
			A2(
				$mdgriffith$elm_animator$Internal$Time$duration,
				$mdgriffith$elm_animator$Internal$Time$millis(prevEndTime),
				$mdgriffith$elm_animator$Internal$Time$millis(now)),
			params,
			targetPos,
			{
				d0: $ianmackenzie$elm_units$Pixels$inPixels(state.d0),
				c1: $ianmackenzie$elm_units$Pixels$inPixelsPerSecond(state.c1)
			});
		return {
			d0: $ianmackenzie$elm_units$Pixels$pixels(_new.d0),
			c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(_new.c1)
		};
	});
var $mdgriffith$elm_animator$Internal$Interpolate$lerp = F7(
	function (prevEndTime, maybePrev, target, targetTime, now, maybeLookAhead, state) {
		var wobble = function () {
			if (!target.$) {
				var arrival = target.b;
				return arrival.es;
			} else {
				var arrival = target.b;
				return arrival.es;
			}
		}();
		var nothingHappened = function () {
			if (!target.$) {
				return false;
			} else {
				var x = target.c;
				return _Utils_eq(
					x,
					$ianmackenzie$elm_units$Pixels$inPixels(state.d0)) && (!$ianmackenzie$elm_units$Pixels$inPixelsPerSecond(state.c1));
			}
		}();
		if (nothingHappened) {
			return state;
		} else {
			if (maybeLookAhead.$ === 1) {
				return (!(!wobble)) ? A7($mdgriffith$elm_animator$Internal$Interpolate$springInterpolation, prevEndTime, maybePrev, target, targetTime, now, maybeLookAhead, state) : A7($mdgriffith$elm_animator$Internal$Interpolate$interpolateBetween, prevEndTime, maybePrev, target, targetTime, now, maybeLookAhead, state);
			} else {
				return A7($mdgriffith$elm_animator$Internal$Interpolate$interpolateBetween, prevEndTime, maybePrev, target, targetTime, now, maybeLookAhead, state);
			}
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$startMoving = function (movement) {
	return {
		d0: function () {
			if (!movement.$) {
				var toX = movement.d;
				return $ianmackenzie$elm_units$Pixels$pixels(
					toX(0));
			} else {
				var depart = movement.a;
				var arrive = movement.b;
				var x = movement.c;
				return $ianmackenzie$elm_units$Pixels$pixels(x);
			}
		}(),
		c1: $ianmackenzie$elm_units$Pixels$pixelsPerSecond(0)
	};
};
var $mdgriffith$elm_animator$Internal$Interpolate$moving = {bb: $mdgriffith$elm_animator$Internal$Interpolate$adjustTiming, bc: $mdgriffith$elm_animator$Internal$Interpolate$afterMove, bi: $mdgriffith$elm_animator$Internal$Interpolate$dwellFor, bj: $mdgriffith$elm_animator$Internal$Interpolate$dwellPeriod, bm: $mdgriffith$elm_animator$Internal$Interpolate$lerp, by: $mdgriffith$elm_animator$Internal$Interpolate$startMoving};
var $mdgriffith$elm_animator$Animator$Css$LinearTiming = 0;
var $mdgriffith$elm_animator$Animator$Css$LoopAnim = {$: 0};
var $mdgriffith$elm_animator$Animator$Css$RepeatAnim = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_animator$Animator$Css$renderFrame = F8(
	function (i, total, name, frames, renderer, stubber, rendered, stub) {
		renderFrame:
		while (true) {
			if (!frames.b) {
				return {h: rendered, bF: stub};
			} else {
				var _v1 = frames.a;
				var percent = _v1.a;
				var frm = _v1.b;
				var remain = frames.b;
				if (total === 1) {
					var keyframe = ('0% {' + (name + (': ' + (renderer(frm) + ';}\n')))) + ('100% {' + (name + (': ' + (renderer(frm) + ';}\n'))));
					return {
						h: keyframe,
						bF: stubber(frm)
					};
				} else {
					var keyframe = $elm$core$String$fromFloat(100 * percent) + ('% {' + (name + (': ' + (renderer(frm) + ';}\n'))));
					var $temp$i = i + 1,
						$temp$total = total,
						$temp$name = name,
						$temp$frames = remain,
						$temp$renderer = renderer,
						$temp$stubber = stubber,
						$temp$rendered = _Utils_ap(rendered, keyframe),
						$temp$stub = _Utils_ap(
						stub,
						stubber(frm));
					i = $temp$i;
					total = $temp$total;
					name = $temp$name;
					frames = $temp$frames;
					renderer = $temp$renderer;
					stubber = $temp$stubber;
					rendered = $temp$rendered;
					stub = $temp$stub;
					continue renderFrame;
				}
			}
		}
	});
var $mdgriffith$elm_animator$Animator$Css$renderAnimation = F6(
	function (now, attrName, frames, renderer, stubber, anims) {
		var rendered = A8(
			$mdgriffith$elm_animator$Animator$Css$renderFrame,
			0,
			$elm$core$List$length(frames.h),
			attrName,
			frames.h,
			renderer,
			stubber,
			'',
			'');
		var name = attrName + ('-' + ($elm$core$String$fromInt(
			$elm$core$Basics$floor(
				$mdgriffith$elm_animator$Internal$Time$inMilliseconds(now))) + ('-' + rendered.bF)));
		var newKeyFrames = '@keyframes ' + (name + (' {\n' + (rendered.h + '\n}')));
		var duration = $ianmackenzie$elm_units$Duration$inMilliseconds(frames.Q);
		var anim = {
			ak: 0,
			Q: duration,
			aY: newKeyFrames,
			ck: name,
			ar: $mdgriffith$elm_animator$Animator$Css$RepeatAnim(1),
			at: 0
		};
		var _v0 = frames.bh;
		if (_v0.$ === 1) {
			return A2($elm$core$List$cons, anim, anims);
		} else {
			var details = _v0.a;
			var dwellFrames = A8(
				$mdgriffith$elm_animator$Animator$Css$renderFrame,
				0,
				$elm$core$List$length(details.h),
				attrName,
				details.h,
				renderer,
				stubber,
				'',
				'');
			var dwell = {
				ak: duration,
				Q: function () {
					var _v1 = details.a4;
					if (_v1.$ === 1) {
						var dwellDur = _v1.b;
						return $ianmackenzie$elm_units$Duration$inMilliseconds(dwellDur);
					} else {
						var dwellDur = _v1.a;
						return $ianmackenzie$elm_units$Duration$inMilliseconds(dwellDur);
					}
				}(),
				aY: '@keyframes ' + (name + ('-dwell' + (' {\n' + (dwellFrames.h + '\n}')))),
				ck: name + '-dwell',
				ar: function () {
					var _v2 = details.a4;
					if (_v2.$ === 1) {
						var n = _v2.a;
						return $mdgriffith$elm_animator$Animator$Css$RepeatAnim(n);
					} else {
						return $mdgriffith$elm_animator$Animator$Css$LoopAnim;
					}
				}(),
				at: 0
			};
			return A2(
				$elm$core$List$cons,
				dwell,
				A2($elm$core$List$cons, anim, anims));
		}
	});
var $mdgriffith$elm_animator$Animator$Css$stubFloat = function (f) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(f * 1000));
};
var $mdgriffith$elm_animator$Animator$Css$stubColor = function (clr) {
	var _v0 = $avh4$elm_color$Color$toRgba(clr);
	var red = _v0.bw;
	var green = _v0.bl;
	var blue = _v0.bd;
	var alpha = _v0.ag;
	return _Utils_ap(
		$mdgriffith$elm_animator$Animator$Css$stubFloat(red) + '-',
		_Utils_ap(
			$mdgriffith$elm_animator$Animator$Css$stubFloat(green) + '-',
			_Utils_ap(
				$mdgriffith$elm_animator$Animator$Css$stubFloat(blue) + '-',
				$mdgriffith$elm_animator$Animator$Css$stubFloat(alpha))));
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $avh4$elm_color$Color$toCssString = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var roundTo = function (x) {
		return $elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return $elm$core$Basics$round(x * 10000) / 100;
	};
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				$elm$core$String$fromFloat(
				pct(r)),
				'%,',
				$elm$core$String$fromFloat(
				pct(g)),
				'%,',
				$elm$core$String$fromFloat(
				pct(b)),
				'%,',
				$elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var $mdgriffith$elm_animator$Animator$Css$toTransform = function (options) {
	return function (rendered) {
		return function (x) {
			return function (y) {
				return function (z) {
					return function (rotation) {
						return function (scaleX) {
							return function (scaleY) {
								return function (scaleZ) {
									return function (facingX) {
										return function (facingY) {
											return function (facingZ) {
												toTransform:
												while (true) {
													if (!x.b) {
														return rendered;
													} else {
														var _v1 = x.a;
														var percent = _v1.a;
														var topX = _v1.b;
														var rX = x.b;
														if (!y.b) {
															return rendered;
														} else {
															var _v3 = y.a;
															var topY = _v3.b;
															var rY = y.b;
															if (!z.b) {
																return rendered;
															} else {
																var _v5 = z.a;
																var topZ = _v5.b;
																var rZ = z.b;
																if (!rotation.b) {
																	return rendered;
																} else {
																	var _v7 = rotation.a;
																	var topR = _v7.b;
																	var rR = rotation.b;
																	if (!scaleX.b) {
																		return rendered;
																	} else {
																		var _v9 = scaleX.a;
																		var topSx = _v9.b;
																		var rsx = scaleX.b;
																		if (!scaleY.b) {
																			return rendered;
																		} else {
																			var _v11 = scaleY.a;
																			var topSy = _v11.b;
																			var rsy = scaleY.b;
																			if (!scaleZ.b) {
																				return rendered;
																			} else {
																				var _v13 = scaleZ.a;
																				var topSz = _v13.b;
																				var rsz = scaleZ.b;
																				if (!facingX.b) {
																					return rendered;
																				} else {
																					var _v15 = facingX.a;
																					var topFx = _v15.b;
																					var rfx = facingX.b;
																					if (!facingY.b) {
																						return rendered;
																					} else {
																						var _v17 = facingY.a;
																						var topFy = _v17.b;
																						var rfy = facingY.b;
																						if (!facingZ.b) {
																							return rendered;
																						} else {
																							var _v19 = facingZ.a;
																							var topFz = _v19.b;
																							var rfz = facingZ.b;
																							var $temp$options = options,
																								$temp$rendered = A2(
																								$elm$core$List$cons,
																								A2(
																									$mdgriffith$elm_animator$Internal$Timeline$Frame,
																									percent,
																									{ah: options.aJ.eu, ai: options.aJ.ev, aj: options.aJ.c5, am: topFx, an: topFy, U: topFz, aI: topR, m: topSx, n: topSy, p: topSz, eu: topX, ev: topY, c5: topZ}),
																								rendered),
																								$temp$x = rX,
																								$temp$y = rY,
																								$temp$z = rZ,
																								$temp$rotation = rR,
																								$temp$scaleX = rsx,
																								$temp$scaleY = rsy,
																								$temp$scaleZ = rsz,
																								$temp$facingX = rfx,
																								$temp$facingY = rfy,
																								$temp$facingZ = rfz;
																							options = $temp$options;
																							rendered = $temp$rendered;
																							x = $temp$x;
																							y = $temp$y;
																							z = $temp$z;
																							rotation = $temp$rotation;
																							scaleX = $temp$scaleX;
																							scaleY = $temp$scaleY;
																							scaleZ = $temp$scaleZ;
																							facingX = $temp$facingX;
																							facingY = $temp$facingY;
																							facingZ = $temp$facingZ;
																							continue toTransform;
																						}
																					}
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$core$Basics$atan2 = _Basics_atan2;
var $elm$core$Basics$toPolar = function (_v0) {
	var x = _v0.a;
	var y = _v0.b;
	return _Utils_Tuple2(
		$elm$core$Basics$sqrt((x * x) + (y * y)),
		A2($elm$core$Basics$atan2, y, x));
};
var $mdgriffith$elm_animator$Animator$Css$transformToString = function (details) {
	var _v0 = $elm$core$Basics$toPolar(
		_Utils_Tuple2(
			$ianmackenzie$elm_units$Pixels$inPixels(details.U.d0),
			$ianmackenzie$elm_units$Pixels$inPixels(details.am.d0)));
	var rotationAroundY = _v0.b;
	var _v1 = $elm$core$Basics$toPolar(
		_Utils_Tuple2(
			$ianmackenzie$elm_units$Pixels$inPixels(details.U.d0),
			$ianmackenzie$elm_units$Pixels$inPixels(details.an.d0)));
	var rotationAroundX = _v1.b;
	return ('translate3d(' + ($elm$core$String$fromFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.eu.d0)) + ('px, ' + ($elm$core$String$fromFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.ev.d0)) + ('px, ' + ($elm$core$String$fromFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.c5.d0)) + 'px)')))))) + ((' rotate3d(0, 1, 0, ' + ($elm$core$String$fromFloat(rotationAroundY) + 'rad)')) + ((' rotate3d(1, 0, 0, ' + ($elm$core$String$fromFloat(rotationAroundX) + 'rad)')) + ((' rotate3d(' + ($elm$core$String$fromFloat(details.ah) + (', ' + ($elm$core$String$fromFloat(details.ai) + (', ' + ($elm$core$String$fromFloat(details.aj) + (', ' + ($elm$core$String$fromFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.aI.d0)) + 'rad)')))))))) + (' scale3d(' + ($elm$core$String$fromFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.m.d0)) + (', ' + ($elm$core$String$fromFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.n.d0)) + (', ' + ($elm$core$String$fromFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.p.d0)) + ')')))))))));
};
var $mdgriffith$elm_animator$Animator$Css$transformToStub = function (details) {
	var _v0 = $elm$core$Basics$toPolar(
		_Utils_Tuple2(
			$ianmackenzie$elm_units$Pixels$inPixels(details.U.d0),
			$ianmackenzie$elm_units$Pixels$inPixels(details.am.d0)));
	var rotationAroundY = _v0.b;
	var _v1 = $elm$core$Basics$toPolar(
		_Utils_Tuple2(
			$ianmackenzie$elm_units$Pixels$inPixels(details.U.d0),
			$ianmackenzie$elm_units$Pixels$inPixels(details.an.d0)));
	var rotationAroundX = _v1.b;
	return $mdgriffith$elm_animator$Animator$Css$stubFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.eu.d0)) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.ev.d0)) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.c5.d0)) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(rotationAroundY) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(rotationAroundX) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(details.ah) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(details.ai) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(details.aj) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.aI.d0)) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.m.d0)) + ('-' + ($mdgriffith$elm_animator$Animator$Css$stubFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.n.d0)) + ('-' + $mdgriffith$elm_animator$Animator$Css$stubFloat(
		$ianmackenzie$elm_units$Pixels$inPixels(details.p.d0)))))))))))))))))))))));
};
var $mdgriffith$elm_animator$Internal$Interpolate$Osc = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_animator$Internal$Interpolate$Pos = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $mdgriffith$elm_animator$Internal$Interpolate$withDefault = F2(
	function (def, defaultOr) {
		if (!defaultOr.$) {
			return def;
		} else {
			var specified = defaultOr.a;
			return specified;
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$fillDefaults = F2(
	function (builtInDefault, specified) {
		if (!specified.$) {
			return builtInDefault;
		} else {
			var partial = specified.a;
			return {
				c9: A2($mdgriffith$elm_animator$Internal$Interpolate$withDefault, builtInDefault.c9, partial.c9),
				da: A2($mdgriffith$elm_animator$Internal$Interpolate$withDefault, builtInDefault.da, partial.da),
				dk: A2($mdgriffith$elm_animator$Internal$Interpolate$withDefault, builtInDefault.dk, partial.dk),
				dl: A2($mdgriffith$elm_animator$Internal$Interpolate$withDefault, builtInDefault.dl, partial.dl),
				es: A2($mdgriffith$elm_animator$Internal$Interpolate$withDefault, builtInDefault.es, partial.es)
			};
		}
	});
var $mdgriffith$elm_animator$Internal$Interpolate$linearDefault = {c9: 0, da: 0, dk: 0, dl: 0, es: 0};
var $mdgriffith$elm_animator$Internal$Interpolate$withLinearDefault = function (defMovement) {
	if (!defMovement.$) {
		var specifiedPersonality = defMovement.a;
		var period = defMovement.b;
		var fn = defMovement.c;
		var personality = A2($mdgriffith$elm_animator$Internal$Interpolate$fillDefaults, $mdgriffith$elm_animator$Internal$Interpolate$linearDefault, specifiedPersonality);
		return A4(
			$mdgriffith$elm_animator$Internal$Interpolate$Osc,
			{K: personality.dk, f: personality.dl},
			{H: personality.c9, f: personality.da, es: personality.es},
			period,
			fn);
	} else {
		var specifiedPersonality = defMovement.a;
		var p = defMovement.b;
		var personality = A2($mdgriffith$elm_animator$Internal$Interpolate$fillDefaults, $mdgriffith$elm_animator$Internal$Interpolate$linearDefault, specifiedPersonality);
		return A3(
			$mdgriffith$elm_animator$Internal$Interpolate$Pos,
			{K: personality.dk, f: personality.dl},
			{H: personality.c9, f: personality.da, es: personality.es},
			p);
	}
};
var $mdgriffith$elm_animator$Internal$Interpolate$standardDefault = {c9: 0, da: 0.8, dk: 0, dl: 0.4, es: 0};
var $mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault = function (defMovement) {
	if (!defMovement.$) {
		var specifiedPersonality = defMovement.a;
		var period = defMovement.b;
		var fn = defMovement.c;
		var personality = A2($mdgriffith$elm_animator$Internal$Interpolate$fillDefaults, $mdgriffith$elm_animator$Internal$Interpolate$standardDefault, specifiedPersonality);
		return A4(
			$mdgriffith$elm_animator$Internal$Interpolate$Osc,
			{K: personality.dk, f: personality.dl},
			{H: personality.c9, f: personality.da, es: personality.es},
			period,
			fn);
	} else {
		var specifiedPersonality = defMovement.a;
		var p = defMovement.b;
		var personality = A2($mdgriffith$elm_animator$Internal$Interpolate$fillDefaults, $mdgriffith$elm_animator$Internal$Interpolate$standardDefault, specifiedPersonality);
		return A3(
			$mdgriffith$elm_animator$Internal$Interpolate$Pos,
			{K: personality.dk, f: personality.dl},
			{H: personality.c9, f: personality.da, es: personality.es},
			p);
	}
};
var $mdgriffith$elm_animator$Animator$Css$renderAttrs = F3(
	function (timeline, attr, anim) {
		var details = timeline;
		switch (attr.$) {
			case 4:
				return anim;
			case 0:
				var attrName = attr.a;
				var lookup = attr.b;
				return A6(
					$mdgriffith$elm_animator$Animator$Css$renderAnimation,
					details.cm,
					attrName,
					A4($mdgriffith$elm_animator$Internal$Timeline$capture, 60, lookup, $mdgriffith$elm_animator$Internal$Interpolate$coloring, timeline),
					$avh4$elm_color$Color$toCssString,
					$mdgriffith$elm_animator$Animator$Css$stubColor,
					anim);
			case 1:
				var attrName = attr.a;
				var lookup = attr.b;
				var toString = attr.c;
				return A6(
					$mdgriffith$elm_animator$Animator$Css$renderAnimation,
					details.cm,
					attrName,
					A4(
						$mdgriffith$elm_animator$Internal$Timeline$capture,
						60,
						A2($elm$core$Basics$composeR, lookup, $mdgriffith$elm_animator$Internal$Interpolate$withLinearDefault),
						$mdgriffith$elm_animator$Internal$Interpolate$moving,
						timeline),
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.d0;
						},
						A2($elm$core$Basics$composeR, $ianmackenzie$elm_units$Pixels$inPixels, toString)),
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.d0;
						},
						A2($elm$core$Basics$composeR, $ianmackenzie$elm_units$Pixels$inPixels, $mdgriffith$elm_animator$Animator$Css$stubFloat)),
					anim);
			case 2:
				var attrName = attr.a;
				var lookup = attr.b;
				var toString = attr.c;
				return A6(
					$mdgriffith$elm_animator$Animator$Css$renderAnimation,
					details.cm,
					attrName,
					A4(
						$mdgriffith$elm_animator$Internal$Timeline$capture,
						60,
						A2($elm$core$Basics$composeR, lookup, $mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault),
						$mdgriffith$elm_animator$Internal$Interpolate$moving,
						timeline),
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.d0;
						},
						A2($elm$core$Basics$composeR, $ianmackenzie$elm_units$Pixels$inPixels, toString)),
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.d0;
						},
						A2($elm$core$Basics$composeR, $ianmackenzie$elm_units$Pixels$inPixels, $mdgriffith$elm_animator$Animator$Css$stubFloat)),
					anim);
			default:
				var options = attr.a;
				var lookupTransform = attr.b;
				var lookup = function (state) {
					var _v1 = lookupTransform(state);
					var deets = _v1;
					return deets;
				};
				var rotation = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.F;
							},
							$mdgriffith$elm_animator$Internal$Interpolate$withLinearDefault)),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var scaleX = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.m;
							},
							$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault)),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var scaleY = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.n;
							},
							$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault)),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var scaleZ = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.p;
							},
							$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault)),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var x = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.eu;
							},
							$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault)),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var y = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.ev;
							},
							$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault)),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var z = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.c5;
							},
							$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault)),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var facingZ = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.z;
							},
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.c5;
								},
								$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault))),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var facingY = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.z;
							},
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.ev;
								},
								$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault))),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var facingX = A4(
					$mdgriffith$elm_animator$Internal$Timeline$capture,
					60,
					A2(
						$elm$core$Basics$composeR,
						lookup,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.z;
							},
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.eu;
								},
								$mdgriffith$elm_animator$Internal$Interpolate$withStandardDefault))),
					$mdgriffith$elm_animator$Internal$Interpolate$moving,
					timeline);
				var combined = $mdgriffith$elm_animator$Animator$Css$toTransform(options)(_List_Nil)(x.h)(y.h)(z.h)(rotation.h)(scaleX.h)(scaleY.h)(scaleZ.h)(facingX.h)(facingY.h)(facingZ.h);
				return A6(
					$mdgriffith$elm_animator$Animator$Css$renderAnimation,
					details.cm,
					'transform',
					{
						Q: x.Q,
						bh: A2(
							$elm$core$Maybe$map,
							$mdgriffith$elm_animator$Animator$Css$mapDwellFrames(combined),
							rotation.bh),
						h: $elm$core$List$reverse(combined)
					},
					$mdgriffith$elm_animator$Animator$Css$transformToString,
					$mdgriffith$elm_animator$Animator$Css$transformToStub,
					anim);
		}
	});
var $mdgriffith$elm_animator$Animator$Css$renderTransformOptions = function (opts) {
	return A2(
		$elm$html$Html$Attributes$style,
		'transform-origin',
		function () {
			var _v0 = opts.a2;
			if (!_v0.$) {
				return 'center';
			} else {
				var x = _v0.a;
				var y = _v0.b;
				return ('calc(50% + ' + ($elm$core$String$fromFloat(x) + 'px) calc(50% + ')) + ($elm$core$String$fromFloat(y) + 'px)');
			}
		}());
};
var $mdgriffith$elm_animator$Animator$Css$stylesheet = function (str) {
	return A3(
		$elm$html$Html$node,
		'style',
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_animator$Animator$Css$node = F5(
	function (name, timeline, divAttrs, attrs, children) {
		var transformOptions = A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_animator$Animator$Css$defaultTransformOptions,
			$mdgriffith$elm_animator$Animator$Css$getTransformOptions(divAttrs));
		var explainIsOn = $mdgriffith$elm_animator$Animator$Css$explainActive(divAttrs);
		var possiblyExplainAttr = explainIsOn ? A2($elm$html$Html$Attributes$style, 'position', 'relative') : A2($elm$html$Html$Attributes$style, '', '');
		var animations = $elm$core$List$reverse(
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_animator$Animator$Css$renderAttrs(timeline),
				_List_Nil,
				divAttrs));
		return A3(
			$elm$html$Html$node,
			name,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class(
					A2($mdgriffith$elm_animator$Animator$Css$renderClassName, '', animations)),
				A2(
					$elm$core$List$cons,
					possiblyExplainAttr,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_animator$Animator$Css$renderTransformOptions(transformOptions),
						attrs))),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_animator$Animator$Css$stylesheet(
					$mdgriffith$elm_animator$Animator$Css$renderAnimations(animations)),
				A2(
					$elm$core$List$cons,
					explainIsOn ? $mdgriffith$elm_animator$Animator$Css$explainElement(transformOptions) : $elm$html$Html$text(''),
					children)));
	});
var $mdgriffith$elm_animator$Animator$Css$div = $mdgriffith$elm_animator$Animator$Css$node('div');
var $mdgriffith$elm_animator$Animator$Css$Linear = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $mdgriffith$elm_animator$Animator$Css$opacity = function (lookup) {
	return A3($mdgriffith$elm_animator$Animator$Css$Linear, 'opacity', lookup, $elm$core$String$fromFloat);
};
var $mdgriffith$elm_animator$Animator$Css$Movement = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $mdgriffith$elm_animator$Animator$Css$style = F3(
	function (name, toString, lookup) {
		return A3($mdgriffith$elm_animator$Animator$Css$Movement, name, lookup, toString);
	});
var $mdgriffith$elm_animator$Animator$Css$TransformAttr = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_animator$Animator$Css$transform = $mdgriffith$elm_animator$Animator$Css$TransformAttr($mdgriffith$elm_animator$Animator$Css$defaultTransformOptions);
var $author$project$Component$IconButton$view = F2(
	function (attrs, content) {
		return A2(
			$elm$html$Html$button,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('icon-button'),
				attrs),
			content);
	});
var $mdgriffith$elm_animator$Animator$Css$Transform = $elm$core$Basics$identity;
var $mdgriffith$elm_animator$Animator$Css$xy = function (coords) {
	return {
		z: {
			eu: $mdgriffith$elm_animator$Animator$at(0),
			ev: $mdgriffith$elm_animator$Animator$at(0),
			c5: $mdgriffith$elm_animator$Animator$at(1)
		},
		F: $mdgriffith$elm_animator$Animator$at(0),
		m: $mdgriffith$elm_animator$Animator$at(1),
		n: $mdgriffith$elm_animator$Animator$at(1),
		p: $mdgriffith$elm_animator$Animator$at(1),
		eu: $mdgriffith$elm_animator$Animator$at(coords.eu),
		ev: $mdgriffith$elm_animator$Animator$at(coords.ev),
		c5: $mdgriffith$elm_animator$Animator$at(0)
	};
};
var $author$project$Component$BottomSheet$view = function (props) {
	return A4(
		$mdgriffith$elm_animator$Animator$Css$div,
		props.a1,
		_List_fromArray(
			[
				A3(
				$mdgriffith$elm_animator$Animator$Css$style,
				'z-index',
				function (x) {
					return (!x) ? '-1' : '1';
				},
				function (open) {
					return $mdgriffith$elm_animator$Animator$at(
						open ? 100 : 0);
				})
			]),
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('bottom-sheet')
			]),
		_List_fromArray(
			[
				A4(
				$mdgriffith$elm_animator$Animator$Css$div,
				props.a1,
				_List_fromArray(
					[
						$mdgriffith$elm_animator$Animator$Css$transform(
						function (open) {
							return $mdgriffith$elm_animator$Animator$Css$xy(
								{
									eu: 0,
									ev: open ? 0 : props.cF.du
								});
						})
					]),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('content'),
						A2(
						$elm$html$Html$Attributes$style,
						'padding-bottom',
						$elm$core$String$fromFloat(props.b8.dd + 16) + 'px')
					]),
				_List_fromArray(
					[
						A2(
						$elm_community$html_extra$Html$Extra$viewIf,
						props.be,
						A2(
							$author$project$Component$IconButton$view,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('close'),
									$elm$html$Html$Events$onClick(props.bu)
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Component$Icon$view,
									2,
									{ds: '#000000', eb: '30px'})
								]))),
						props.bO
					])),
				A4(
				$mdgriffith$elm_animator$Animator$Css$div,
				props.a1,
				_List_fromArray(
					[
						$mdgriffith$elm_animator$Animator$Css$opacity(
						function (open) {
							return $mdgriffith$elm_animator$Animator$at(
								open ? 0.6 : 0);
						}),
						A3(
						$mdgriffith$elm_animator$Animator$Css$style,
						'z-index',
						function (x) {
							return (!x) ? '-1' : '1';
						},
						function (open) {
							return $mdgriffith$elm_animator$Animator$at(
								open ? 100 : 0);
						})
					]),
				props.be ? _List_fromArray(
					[
						$elm$html$Html$Attributes$class('background'),
						$elm$html$Html$Events$onClick(props.bu)
					]) : _List_fromArray(
					[
						$elm$html$Html$Attributes$class('background')
					]),
				_List_Nil)
			]));
};
var $author$project$Component$BottomSheetError$view = function (props) {
	return $author$project$Component$BottomSheet$view(
		{
			be: props.be,
			bO: $author$project$Component$BottomSheetError$contentView(props),
			b8: props.b8,
			a1: props.a1,
			bu: props.bu,
			cF: props.cF
		});
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $author$project$IconCode$toChar = function (_v0) {
	var icon = _v0;
	return icon;
};
var $author$project$IconCode$view = function (icon) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('icon')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$elm$core$String$fromChar(
					$author$project$IconCode$toChar(icon)))
			]));
};
var $author$project$Component$BottomSheetInfo$contentView = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('inner')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title')
					]),
				_List_fromArray(
					[
						function () {
						var _v0 = props.b5;
						if (!_v0.$) {
							var i = _v0.a;
							return $author$project$IconCode$view(i);
						} else {
							return $elm$html$Html$text('');
						}
					}(),
						$elm$html$Html$text(props.cY)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(props.cX)
					]))
			]));
};
var $author$project$Component$BottomSheetInfo$view = function (props) {
	return $author$project$Component$BottomSheet$view(
		{
			be: props.be,
			bO: $author$project$Component$BottomSheetInfo$contentView(props),
			b8: props.b8,
			a1: props.a1,
			bu: props.bu,
			cF: props.cF
		});
};
var $author$project$Translate$Key$CommonChooseLanguage = 7;
var $author$project$Language$nativeName = function (language) {
	switch (language) {
		case 0:
			return 'English';
		case 1:
			return 'Suomi';
		case 2:
			return 'Deutsch';
		case 3:
			return 'Norsk';
		case 4:
			return 'Français';
		default:
			return 'Svenska';
	}
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Component$BottomSheetLanguageSelector$contentView = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('inner')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						props.c$(
							$author$project$Translate$Key$Static(7)))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('option-list')
					]),
				A2(
					$elm$core$List$map,
					function (lang) {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('option'),
									$elm$html$Html$Events$onClick(
									props.cr(lang))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Language$nativeName(lang))
								]));
					},
					A2($elm$core$List$sortBy, $author$project$Language$nativeName, $author$project$Language$all)))
			]));
};
var $author$project$Component$BottomSheetLanguageSelector$view = function (props) {
	return $author$project$Component$BottomSheet$view(
		{
			be: props.be,
			bO: $author$project$Component$BottomSheetLanguageSelector$contentView(props),
			b8: props.b8,
			a1: props.a1,
			bu: props.bu,
			cF: props.cF
		});
};
var $author$project$Translate$Key$ButtonClose = 0;
var $author$project$Translate$Key$ButtonReconnect = 2;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Component$Spinner$view = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('spinner')
		]),
	_List_Nil);
var $author$project$Component$BottomSheetReconnect$contentView = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('inner')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(props.cY)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(props.cX)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('buttons')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button'),
								$elm$html$Html$Events$onClick(props.ct),
								$elm$html$Html$Attributes$disabled(props.cz)
							]),
						_List_fromArray(
							[
								props.cA ? $author$project$Component$Spinner$view : $elm$html$Html$text(
								props.c$(
									$author$project$Translate$Key$Static(2)))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button -normal'),
								$elm$html$Html$Events$onClick(props.cs)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								props.c$(
									$author$project$Translate$Key$Static(0)))
							]))
					]))
			]));
};
var $author$project$Component$BottomSheetReconnect$view = function (props) {
	return $author$project$Component$BottomSheet$view(
		{
			be: props.be,
			bO: $author$project$Component$BottomSheetReconnect$contentView(props),
			b8: props.b8,
			a1: props.a1,
			bu: props.bu,
			cF: props.cF
		});
};
var $author$project$Main$viewBottomSheet = F2(
	function (translate, model) {
		var _v0 = model.c.bO;
		switch (_v0.$) {
			case 0:
				var content = _v0.a;
				return $author$project$Component$BottomSheetInfo$view(
					{
						be: true,
						b5: content.b5,
						b8: model.G,
						a1: model.c.a1,
						bu: $author$project$Main$InfoHide,
						cF: model.cF,
						cX: translate(content.cX),
						cY: A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Maybe$map, translate, content.cY))
					});
			case 1:
				var content = _v0.a;
				return $author$project$Component$BottomSheetError$view(
					{
						be: false,
						b8: model.G,
						a1: model.c.a1,
						bu: $author$project$Main$InfoHide,
						cs: $author$project$Main$GotNavigationMsg($author$project$Main$QuitPressed),
						cF: model.cF,
						cX: translate(content.cX),
						cY: translate(content.cY),
						c$: translate
					});
			case 2:
				var content = _v0.a;
				var reconnecting = A2(
					$elm$core$List$any,
					function (_v1) {
						var state = _v1.a;
						var req = _v1.b;
						return (!state) && $author$project$Request$isReconnectRequest(req);
					},
					$pzp1997$assoc_list$AssocList$values(model.t));
				return $author$project$Component$BottomSheetReconnect$view(
					{
						be: false,
						b8: model.G,
						a1: model.c.a1,
						bu: $author$project$Main$InfoHide,
						cs: $author$project$Main$GotNavigationMsg($author$project$Main$QuitPressed),
						ct: $author$project$Main$SendRequest(
							$author$project$Request$Reconnect(model.L)),
						cz: reconnecting,
						cA: reconnecting,
						cF: model.cF,
						cX: translate(content.cX),
						cY: translate(content.cY),
						c$: translate
					});
			default:
				return $author$project$Component$BottomSheetLanguageSelector$view(
					{be: false, b8: model.G, a1: model.c.a1, bu: $author$project$Main$InfoHide, cr: $author$project$Main$LanguageChosen, cF: model.cF, c$: translate});
		}
	});
var $author$project$Node$DateTime$ViewProps = F8(
	function (translate, onClick, isLink, title, time, day, month, year) {
		return {bP: day, b9: isLink, ci: month, bq: onClick, aM: time, cY: title, c$: translate, c3: year};
	});
var $author$project$Node$WeeklyTimer$ViewProps = F8(
	function (valueChange, priority, translate, onClick, daysEnabled, startTime, endTime, fanSpeedSetting) {
		return {bf: daysEnabled, bk: endTime, bZ: fanSpeedSetting, bq: onClick, cu: priority, bz: startTime, c$: translate, ep: valueChange};
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$andMap = $elm$core$Maybe$map2($elm$core$Basics$apR);
var $author$project$Parameter$Title$TitleTypeTitle = 1;
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Component$Icon$Info = 1;
var $author$project$Parameter$Title$view = function (props) {
	var info = A2($elm$core$Maybe$withDefault, '', props.dz);
	var icon = function () {
		var _v1 = props.b5;
		if (!_v1.$) {
			var i = _v1.a;
			return $author$project$IconCode$view(i);
		} else {
			return $elm$html$Html$text('');
		}
	}();
	var _class = function () {
		var _v0 = props.cZ;
		switch (_v0) {
			case 0:
				return '-parameter';
			case 1:
				return '-title';
			default:
				return '-subtitle';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('node-title ' + _class)
			]),
		_List_fromArray(
			[
				icon,
				$elm$html$Html$text(props.cY),
				A2(
				$elm_community$html_extra$Html$Extra$viewIf,
				!$elm$core$String$isEmpty(info),
				A2(
					$author$project$Component$IconButton$view,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('info'),
							$elm$html$Html$Events$onClick(props.dA)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Component$Icon$view,
							1,
							{ds: '#0064b3', eb: '20px'})
						])))
			]));
};
var $author$project$Node$imageView = function (props) {
	var title = A2($elm$core$Maybe$withDefault, '', props.cY);
	var text_ = A2($elm$core$Maybe$withDefault, '', props.cX);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('inner')
			]),
		_List_fromArray(
			[
				A2(
				$elm_community$html_extra$Html$Extra$viewIf,
				!$elm$core$String$isEmpty(title),
				$author$project$Parameter$Title$view(
					{
						b5: $elm$core$Maybe$Nothing,
						dz: props.dz,
						dA: props.dA(
							{
								b5: $elm$core$Maybe$Nothing,
								dz: A2($elm$core$Maybe$withDefault, '', props.dz),
								cY: props.cY
							}),
						cY: title,
						cZ: 1
					})),
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('img'),
						$elm$html$Html$Attributes$src(props.aQ)
					]),
				_List_Nil),
				A2(
				$elm_community$html_extra$Html$Extra$viewIf,
				!$elm$core$String$isEmpty(text_),
				A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(text_)
						])))
			]));
};
var $author$project$Visibility$evaluateComparison = F3(
	function (lhs, rhs, operator) {
		switch (operator.$) {
			case 1:
				return _Utils_cmp(lhs, rhs) < 0;
			case 0:
				return _Utils_cmp(lhs, rhs) > 0;
			case 2:
				return _Utils_eq(lhs, rhs);
			default:
				return !_Utils_eq(lhs, rhs);
		}
	});
var $author$project$Visibility$getValue = F2(
	function (paramDict, target) {
		if (!target.$) {
			var paramId = target.a;
			return A2(
				$elm$core$Maybe$andThen,
				function (value) {
					if (!value.$) {
						return $elm$core$Maybe$Nothing;
					} else {
						var v = value.a;
						return $elm$core$Maybe$Just(v.ba);
					}
				},
				A2($pzp1997$assoc_list$AssocList$get, paramId, paramDict));
		} else {
			var value = target.a;
			return $elm$core$Maybe$Just(value);
		}
	});
var $author$project$Visibility$tupleAndThen = function (_v0) {
	var fa = _v0.a;
	var fb = _v0.b;
	return A2(
		$elm$core$Maybe$andThen,
		function (a) {
			return A2(
				$elm$core$Maybe$map,
				function (b) {
					return _Utils_Tuple2(a, b);
				},
				fb);
		},
		fa);
};
var $author$project$Visibility$evaluateWithBindings = F2(
	function (paramDict, op) {
		var lhsAndRhs = function () {
			switch (op.$) {
				case 1:
					var l = op.a;
					var r = op.b;
					return _Utils_Tuple2(
						A2($author$project$Visibility$getValue, paramDict, l),
						A2($author$project$Visibility$getValue, paramDict, r));
				case 0:
					var l = op.a;
					var r = op.b;
					return _Utils_Tuple2(
						A2($author$project$Visibility$getValue, paramDict, l),
						A2($author$project$Visibility$getValue, paramDict, r));
				case 2:
					var l = op.a;
					var r = op.b;
					return _Utils_Tuple2(
						A2($author$project$Visibility$getValue, paramDict, l),
						A2($author$project$Visibility$getValue, paramDict, r));
				default:
					var l = op.a;
					var r = op.b;
					return _Utils_Tuple2(
						A2($author$project$Visibility$getValue, paramDict, l),
						A2($author$project$Visibility$getValue, paramDict, r));
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (_v0) {
				var lhs = _v0.a;
				var rhs = _v0.b;
				return A3($author$project$Visibility$evaluateComparison, lhs, rhs, op);
			},
			$author$project$Visibility$tupleAndThen(lhsAndRhs));
	});
var $author$project$Visibility$foldAnd = F2(
	function (a, b) {
		return A3($elm$core$Maybe$map2, $elm$core$Basics$and, a, b);
	});
var $author$project$Visibility$foldOr = F2(
	function (a, b) {
		return A3($elm$core$Maybe$map2, $elm$core$Basics$or, a, b);
	});
var $author$project$Visibility$evaluate = F2(
	function (paramDict, exp) {
		switch (exp.$) {
			case 0:
				return $elm$core$Maybe$Just(true);
			case 1:
				return $elm$core$Maybe$Just(false);
			case 2:
				var rule = exp.a;
				return A2($author$project$Visibility$evaluateWithBindings, paramDict, rule);
			case 3:
				var lhs = exp.a;
				var rhs = exp.b;
				var rest = exp.c;
				return A3(
					$elm$core$List$foldl,
					$author$project$Visibility$foldAnd,
					$elm$core$Maybe$Just(true),
					A2(
						$elm$core$List$map,
						$author$project$Visibility$evaluate(paramDict),
						A2(
							$elm$core$List$cons,
							lhs,
							A2($elm$core$List$cons, rhs, rest))));
			default:
				var lhs = exp.a;
				var rhs = exp.b;
				var rest = exp.c;
				return A3(
					$elm$core$List$foldl,
					$author$project$Visibility$foldOr,
					$elm$core$Maybe$Just(false),
					A2(
						$elm$core$List$map,
						$author$project$Visibility$evaluate(paramDict),
						A2(
							$elm$core$List$cons,
							lhs,
							A2($elm$core$List$cons, rhs, rest))));
		}
	});
var $author$project$Node$isNodeVisible = F2(
	function (parameters, expr) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($author$project$Visibility$evaluate, parameters, expr));
	});
var $author$project$Node$Link$linkStyleClass = function (linkStyle) {
	if (!linkStyle) {
		return '-normal';
	} else {
		return '-button';
	}
};
var $author$project$Node$linkView = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'inner',
							$author$project$Node$Link$linkStyleClass(props.X)
						]))),
				$elm$html$Html$Events$onClick(props.bq)
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = props.b5;
				if (!_v0.$) {
					var i = _v0.a;
					return $author$project$IconCode$view(i);
				} else {
					return $elm$html$Html$text('');
				}
			}(),
				$elm$html$Html$text(props.cY)
			]));
};
var $author$project$Parameter$Title$TitleTypeParameter = 0;
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $author$project$Parameter$MultiOption$bitAt = F2(
	function (position, value) {
		var shifted = value >>> position;
		return (shifted & 1) === 1;
	});
var $author$project$Parameter$MultiOption$isChecked = F2(
	function (value, option) {
		return A2($author$project$Parameter$MultiOption$bitAt, option.bK, value);
	});
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $author$project$Parameter$MultiOption$setBitAt = F3(
	function (position, value, bit) {
		var bitMask = 1 << position;
		return bit ? (value | bitMask) : (value & (~bitMask));
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Parameter$MultiOption$anOptionView = F4(
	function (translate, valueChange, value, option) {
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('option', true),
							_Utils_Tuple2(
							'-checked',
							A2($author$project$Parameter$MultiOption$isChecked, value, option))
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					translate(
						$author$project$Translate$Key$Dynamic(option.ck))),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$checked(
							A2($author$project$Parameter$MultiOption$isChecked, value, option)),
							$elm$html$Html$Events$onCheck(
							function (checked) {
								return valueChange(
									$elm$core$Maybe$Just(
										A3($author$project$Parameter$MultiOption$setBitAt, option.bK, value, checked)));
							})
						]),
					_List_Nil)
				]));
	});
var $author$project$Parameter$MultiOption$view = F3(
	function (translate, base, multiOption) {
		var text_ = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, translate, base.cX));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('parameter -multioption')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header')
						]),
					_List_fromArray(
						[
							$author$project$Parameter$Title$view(
							{
								b5: base.b5,
								dz: A2($elm$core$Maybe$map, translate, base.dz),
								dA: base.dA,
								cY: translate(base.cY),
								cZ: 0
							}),
							A2(
							$elm_community$html_extra$Html$Extra$viewIf,
							!$elm$core$String$isEmpty(text_),
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(text_)
									])))
						])),
					function () {
					var _v0 = base.ba;
					if (!_v0.$) {
						return $elm$html$Html$text('');
					} else {
						var localAndRemoteValue = _v0.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('options')
								]),
							base.E ? $elm$core$List$singleton(
								$elm$html$Html$text(
									A2(
										$elm$core$String$join,
										', ',
										A2(
											$elm$core$List$map,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.ck;
												},
												A2($elm$core$Basics$composeR, $author$project$Translate$Key$Dynamic, translate)),
											A2(
												$elm$core$List$filter,
												function (option) {
													return A2($author$project$Parameter$MultiOption$isChecked, localAndRemoteValue.ba, option);
												},
												multiOption.cn))))) : A2(
								$elm$core$List$map,
								A3($author$project$Parameter$MultiOption$anOptionView, translate, base.eq, localAndRemoteValue.ba),
								multiOption.cn));
					}
				}()
				]));
	});
var $author$project$Component$Icon$Minus = 4;
var $author$project$Component$Icon$Plus = 3;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $author$project$Parameter$Limit$evaluate = F2(
	function (paramDict, limit) {
		if (!limit.$) {
			var v = limit.a;
			return $elm$core$Maybe$Just(v);
		} else {
			var paramId = limit.a;
			return A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.ba;
				},
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Parameter$Value$get,
					A2($pzp1997$assoc_list$AssocList$get, paramId, paramDict)));
		}
	});
var $elm$core$Basics$pow = _Basics_pow;
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $author$project$Parameter$Numeric$formatWithPower = F2(
	function (param, value) {
		var withPower = A2(
			$myrho$elm_round$Round$round,
			-param.bt,
			value * A2($elm$core$Basics$pow, 10, param.bt));
		return withPower;
	});
var $author$project$Parameter$Value$isModified = function (value) {
	if (!value.$) {
		return false;
	} else {
		var x = value.a;
		return !_Utils_eq(
			$author$project$Parameter$RegisterValue$get(x.aG),
			x.ba);
	}
};
var $author$project$Parameter$Numeric$view = F4(
	function (parameters, translate, base, param) {
		var valueClass = ($author$project$Parameter$Value$isModified(base.ba) && (!base.E)) ? 'value -modified' : '';
		var text_ = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, translate, base.cX));
		var paramMin = A2($author$project$Parameter$Limit$evaluate, parameters, param.dL);
		var paramMax = A2($author$project$Parameter$Limit$evaluate, parameters, param.dK);
		var clampedValue = function () {
			var _v5 = _Utils_Tuple3(
				paramMin,
				paramMax,
				$author$project$Parameter$Value$get(base.ba));
			if (((!_v5.a.$) && (!_v5.b.$)) && (!_v5.c.$)) {
				var min = _v5.a.a;
				var max = _v5.b.a;
				var val = _v5.c.a;
				return $elm$core$Maybe$Just(
					A3($elm$core$Basics$clamp, min, max, val.ba));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var filled = function () {
			if (clampedValue.$ === 1) {
				return 0;
			} else {
				var v = clampedValue.a;
				var percentage = function () {
					var _v4 = _Utils_Tuple2(paramMin, paramMax);
					if ((!_v4.a.$) && (!_v4.b.$)) {
						var min = _v4.a.a;
						var max = _v4.b.a;
						return ((v - min) / (max - min)) * 100;
					} else {
						return 0;
					}
				}();
				return A3($elm$core$Basics$clamp, 0, 100, percentage);
			}
		}();
		var minusDisabled = function () {
			if (!paramMin.$) {
				var min = paramMin.a;
				return _Utils_eq(
					min,
					A2($elm$core$Maybe$withDefault, min, clampedValue));
			} else {
				return true;
			}
		}();
		var plusDisabled = function () {
			if (!paramMax.$) {
				var max = paramMax.a;
				return _Utils_eq(
					max,
					A2($elm$core$Maybe$withDefault, max, clampedValue));
			} else {
				return true;
			}
		}();
		var valueAsText = function () {
			if (clampedValue.$ === 1) {
				return 'n/a';
			} else {
				var v = clampedValue.a;
				return A2($author$project$Parameter$Numeric$formatWithPower, param, v);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('parameter -numeric')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header')
						]),
					_List_fromArray(
						[
							$author$project$Parameter$Title$view(
							{
								b5: base.b5,
								dz: A2($elm$core$Maybe$map, translate, base.dz),
								dA: base.dA,
								cY: translate(base.cY),
								cZ: 0
							}),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('value'),
									$elm$html$Html$Attributes$class(valueClass)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(valueAsText),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('unit')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											translate(
												$author$project$Translate$Key$Dynamic(param.en)))
										]))
								]))
						])),
					A2(
					$elm_community$html_extra$Html$Extra$viewIf,
					!$elm$core$String$isEmpty(text_),
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('sub-header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(text_)
							]))),
					A2(
					$elm_community$html_extra$Html$Extra$viewIf,
					!base.E,
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button'),
										$elm$html$Html$Attributes$disabled(minusDisabled),
										$elm$html$Html$Events$onClick(
										base.eq(
											A2(
												$elm$core$Maybe$map,
												function (minValue) {
													return A2(
														$elm$core$Basics$max,
														minValue,
														A2($elm$core$Maybe$withDefault, minValue, clampedValue) - param.a8);
												},
												paramMin)))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('inside')
											]),
										_List_fromArray(
											[
												A2(
												$author$project$Component$Icon$view,
												4,
												{ds: '#39b812', eb: '16px'})
											]))
									])),
								A3(
								$elm$html$Html$node,
								'swegon-slider',
								_List_fromArray(
									[
										A2(
										$elm$html$Html$Attributes$attribute,
										'data-value',
										$elm$core$String$fromInt(
											A2($elm$core$Maybe$withDefault, 0, clampedValue))),
										A2(
										$elm$html$Html$Attributes$attribute,
										'data-min',
										$elm$core$String$fromInt(
											A2($elm$core$Maybe$withDefault, 0, paramMin))),
										A2(
										$elm$html$Html$Attributes$attribute,
										'data-max',
										$elm$core$String$fromInt(
											A2($elm$core$Maybe$withDefault, 0, paramMax))),
										A2(
										$elm$html$Html$Attributes$attribute,
										'data-step',
										$elm$core$String$fromInt(param.a8)),
										A2(
										$elm$html$Html$Events$on,
										'valueChanged',
										A2(
											$elm$json$Json$Decode$map,
											A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, base.eq),
											A2(
												$elm$json$Json$Decode$at,
												_List_fromArray(
													['target', 'value']),
												$elm$json$Json$Decode$int)))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button'),
										$elm$html$Html$Attributes$disabled(plusDisabled),
										$elm$html$Html$Events$onClick(
										base.eq(
											A2(
												$elm$core$Maybe$map,
												function (maxValue) {
													return A2(
														$elm$core$Basics$min,
														maxValue,
														A2($elm$core$Maybe$withDefault, maxValue, clampedValue) + param.a8);
												},
												paramMax)))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('inside')
											]),
										_List_fromArray(
											[
												A2(
												$author$project$Component$Icon$view,
												3,
												{ds: '#39b812', eb: '16px'})
											]))
									]))
							]))),
					A2(
					$elm_community$html_extra$Html$Extra$viewIf,
					base.E,
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('readonlybar')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('inside'),
										A2(
										$elm$html$Html$Attributes$style,
										'width',
										$elm$core$String$fromFloat(filled) + '%')
									]),
								_List_Nil)
							])))
				]));
	});
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Parameter$Option$anOptionView = F3(
	function (translate, value, opt) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(opt.ba)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(value, opt.ba))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					translate(
						$author$project$Translate$Key$Dynamic(opt.ck)))
				]));
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$select = _VirtualDom_node('select');
var $author$project$Parameter$Option$valueAvailableView = F3(
	function (isModified, isReadonly, value) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('value'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('-modified', isModified && (!isReadonly)),
							_Utils_Tuple2('-readonly', isReadonly)
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(value)
				]));
	});
var $author$project$Parameter$Option$valueNotAvailableView = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('value -not-available')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('n/a')
		]));
var $author$project$Parameter$Option$view = F3(
	function (translate, base, param) {
		var valueView = function () {
			var _v1 = base.ba;
			if (!_v1.$) {
				return $author$project$Parameter$Option$valueNotAvailableView;
			} else {
				var x = _v1.a;
				var isModified = $author$project$Parameter$Value$isModified(base.ba);
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Parameter$Option$valueNotAvailableView,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.ck;
							},
							A2(
								$elm$core$Basics$composeR,
								$author$project$Translate$Key$Dynamic,
								A2(
									$elm$core$Basics$composeR,
									translate,
									A2($author$project$Parameter$Option$valueAvailableView, isModified, base.E)))),
						A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.ba;
								},
								$elm$core$Basics$eq(x.ba)),
							param.cn)));
			}
		}();
		var text_ = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, translate, base.cX));
		var selectElement = function () {
			var _v0 = base.ba;
			if (!_v0.$) {
				return $elm$html$Html$text('');
			} else {
				var x = _v0.a;
				return A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('select'),
							$elm$html$Html$Events$onInput(
							A2($elm$core$Basics$composeL, base.eq, $elm$core$String$toInt)),
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromInt(x.ba)),
							$elm$html$Html$Attributes$disabled(base.E)
						]),
					A2(
						$elm$core$List$map,
						A2($author$project$Parameter$Option$anOptionView, translate, x.ba),
						param.cn));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('parameter -option')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header')
						]),
					_List_fromArray(
						[
							$author$project$Parameter$Title$view(
							{
								b5: base.b5,
								dz: A2($elm$core$Maybe$map, translate, base.dz),
								dA: base.dA,
								cY: translate(base.cY),
								cZ: 0
							}),
							A2(
							$elm_community$html_extra$Html$Extra$viewIf,
							!$elm$core$String$isEmpty(text_),
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(text_)
									])))
						])),
					selectElement,
					valueView
				]));
	});
var $author$project$Parameter$Switch$view = F3(
	function (translate, base, param) {
		var text_ = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, translate, base.cX));
		var checkedToValue = function (bool) {
			return bool ? param.bG : param.c0;
		};
		var switchElement = function () {
			var _v0 = base.ba;
			if (!_v0.$) {
				return $elm$html$Html$text('');
			} else {
				var x = _v0.a;
				return A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('switch')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('checkbox'),
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Attributes$checked(
									_Utils_eq(param.bG, x.ba)),
									$elm$html$Html$Attributes$disabled(base.E),
									$elm$html$Html$Events$onCheck(
									A2(
										$elm$core$Basics$composeL,
										A2($elm$core$Basics$composeL, base.eq, $elm$core$Maybe$Just),
										checkedToValue))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('handle')
								]),
							_List_Nil)
						]));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('parameter -switch')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header')
						]),
					_List_fromArray(
						[
							$author$project$Parameter$Title$view(
							{
								b5: base.b5,
								dz: A2($elm$core$Maybe$map, translate, base.dz),
								dA: base.dA,
								cY: translate(base.cY),
								cZ: 0
							}),
							A2(
							$elm_community$html_extra$Html$Extra$viewIf,
							!$elm$core$String$isEmpty(text_),
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(text_)
									])))
						])),
					switchElement
				]));
	});
var $author$project$Parameter$Time$formatTime = F3(
	function (clock, hour, minute) {
		var minutePrefix = (minute < 10) ? '0' : '';
		var hourPrefix = ((!clock) && (hour < 10)) ? '0' : '';
		var _v0 = function () {
			if (!clock) {
				return _Utils_Tuple2(hour, '');
			} else {
				return (!hour) ? _Utils_Tuple2(12, 'AM') : ((hour < 12) ? _Utils_Tuple2(hour, 'AM') : ((hour === 12) ? _Utils_Tuple2(hour, 'PM') : _Utils_Tuple2(hour - 12, 'PM')));
			}
		}();
		var convertedHours = _v0.a;
		var amPmSuffix = _v0.b;
		return hourPrefix + ($elm$core$String$fromInt(convertedHours) + (':' + (minutePrefix + ($elm$core$String$fromInt(minute) + ((amPmSuffix === '') ? '' : (' ' + amPmSuffix))))));
	});
var $author$project$Parameter$Time$stepToInt = function (_v0) {
	var step = _v0;
	return step;
};
var $author$project$Parameter$Time$options = F2(
	function (time, value) {
		var step = $author$project$Parameter$Time$stepToInt(time.a8);
		var mins = A2(
			$elm$core$List$map,
			$elm$core$Basics$mul(step),
			A2($elm$core$List$range, 0, (59 / step) | 0));
		return A2(
			$elm$core$List$concatMap,
			function (hour) {
				return A2(
					$elm$core$List$map,
					function (minute) {
						return A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(
									A3($author$project$Parameter$Time$formatTime, 0, hour, minute)),
									$elm$html$Html$Attributes$selected(
									_Utils_eq(
										value,
										A3($author$project$Parameter$Time$formatTime, 0, hour, minute)))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A3($author$project$Parameter$Time$formatTime, time.bN, hour, minute))
								]));
					},
					mins);
			},
			A2($elm$core$List$range, 0, 23));
	});
var $author$project$Parameter$Time$timeStringToInt = function (string) {
	var parts = A2($elm$core$String$split, ':', string);
	var minute = A2(
		$elm$core$Maybe$andThen,
		$elm$core$String$toInt,
		$elm$core$List$head(
			A2($elm$core$List$drop, 1, parts)));
	var hour = A2(
		$elm$core$Maybe$andThen,
		$elm$core$String$toInt,
		$elm$core$List$head(parts));
	return A3(
		$elm$core$Maybe$map2,
		F2(
			function (hh, mm) {
				return (hh * 60) + mm;
			}),
		hour,
		minute);
};
var $author$project$Parameter$Time$timeValueToString = F2(
	function (clock, value) {
		var clampedValue = A3($elm$core$Basics$clamp, 0, 1439, value);
		var hours = (clampedValue / 60) | 0;
		var minutes = A2($elm$core$Basics$modBy, 60, clampedValue);
		return A3($author$project$Parameter$Time$formatTime, clock, hours, minutes);
	});
var $author$project$Parameter$Time$valueAvailableView = F3(
	function (isModified, isReadonly, value) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('value'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('-modified', isModified && (!isReadonly)),
							_Utils_Tuple2('-readonly', isReadonly)
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(value)
				]));
	});
var $author$project$Parameter$Time$valueNotAvailableView = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('value -not-available')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('n/a')
		]));
var $author$project$Parameter$Time$view = F3(
	function (translate, base, time) {
		var value = function () {
			var _v1 = base.ba;
			if (!_v1.$) {
				return '00:00';
			} else {
				var x = _v1.a;
				return A2($author$project$Parameter$Time$timeValueToString, time.bN, x.ba);
			}
		}();
		var valueView = function () {
			var _v0 = base.ba;
			if (!_v0.$) {
				return $author$project$Parameter$Time$valueNotAvailableView;
			} else {
				var isModified = $author$project$Parameter$Value$isModified(base.ba);
				return A3($author$project$Parameter$Time$valueAvailableView, isModified, base.E, value);
			}
		}();
		var text_ = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, translate, base.cX));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('parameter -option')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header')
						]),
					_List_fromArray(
						[
							$author$project$Parameter$Title$view(
							{
								b5: base.b5,
								dz: A2($elm$core$Maybe$map, translate, base.dz),
								dA: base.dA,
								cY: translate(base.cY),
								cZ: 0
							}),
							A2(
							$elm_community$html_extra$Html$Extra$viewIf,
							!$elm$core$String$isEmpty(text_),
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(text_)
									])))
						])),
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('select'),
							$elm$html$Html$Events$onInput(
							A2($elm$core$Basics$composeR, $author$project$Parameter$Time$timeStringToInt, base.eq)),
							$elm$html$Html$Attributes$value(value),
							$elm$html$Html$Attributes$disabled(base.E)
						]),
					A2($author$project$Parameter$Time$options, time, value)),
					valueView
				]));
	});
var $author$project$Parameter$Timer$formatTime = function (seconds) {
	var remainder = seconds % 60;
	var remainderPadded = (remainder < 10) ? ('0' + $elm$core$String$fromInt(remainder)) : $elm$core$String$fromInt(remainder);
	var minutes = (seconds / 60) | 0;
	return $elm$core$String$fromInt(minutes) + (':' + remainderPadded);
};
var $author$project$Parameter$Timer$view = F3(
	function (translate, base, timeLeft) {
		var value = function () {
			var _v1 = base.ba;
			if (_v1.$ === 1) {
				return $author$project$Parameter$Timer$formatTime(timeLeft);
			} else {
				return 'n/a';
			}
		}();
		var text_ = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, translate, base.cX));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('parameter -timer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title')
						]),
					_List_fromArray(
						[
							function () {
							var _v0 = base.b5;
							if (!_v0.$) {
								var i = _v0.a;
								return $author$project$IconCode$view(i);
							} else {
								return $elm$html$Html$text('');
							}
						}(),
							$elm$html$Html$text(
							translate(base.cY))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('time')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						])),
					A2(
					$elm_community$html_extra$Html$Extra$viewIf,
					!$elm$core$String$isEmpty(text_),
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(text_)
							])))
				]));
	});
var $author$project$Parameter$view = function (props) {
	var translate = props.c$;
	var parameter = props.d$;
	var valueChangedWithDebounce = F2(
		function (debounced, value) {
			return props.eq(
				A2(
					$elm$core$Maybe$map,
					function (v) {
						return {aV: debounced, aX: parameter.aX, ba: v};
					},
					value));
		});
	var base = {
		b5: props.b5,
		dz: A2($elm$core$Maybe$map, $author$project$Translate$Key$Dynamic, props.dz),
		dA: props.dA(
			{
				b5: props.b5,
				dz: A2($elm$core$Maybe$withDefault, '', props.dz),
				cY: $elm$core$Maybe$Just(props.cY)
			}),
		E: parameter.E,
		O: parameter.O,
		cX: A2($elm$core$Maybe$map, $author$project$Translate$Key$Dynamic, props.cX),
		cY: $author$project$Translate$Key$Dynamic(props.cY),
		ba: parameter.ba,
		eq: function (_v1) {
			return props.eq($elm$core$Maybe$Nothing);
		}
	};
	var _v0 = parameter.cR;
	switch (_v0.$) {
		case 0:
			var spec = _v0.a;
			return A4(
				$author$project$Parameter$Numeric$view,
				props.aq,
				translate,
				_Utils_update(
					base,
					{
						eq: valueChangedWithDebounce(true)
					}),
				spec);
		case 1:
			var spec = _v0.a;
			return A3(
				$author$project$Parameter$Option$view,
				translate,
				_Utils_update(
					base,
					{
						eq: valueChangedWithDebounce(false)
					}),
				spec);
		case 2:
			var timeLeft = _v0.a;
			return A3($author$project$Parameter$Timer$view, translate, base, timeLeft);
		case 3:
			var spec = _v0.a;
			return A3(
				$author$project$Parameter$Switch$view,
				translate,
				_Utils_update(
					base,
					{
						eq: valueChangedWithDebounce(false)
					}),
				spec);
		case 4:
			var spec = _v0.a;
			return A3(
				$author$project$Parameter$Time$view,
				translate,
				_Utils_update(
					base,
					{
						eq: valueChangedWithDebounce(false)
					}),
				spec);
		default:
			var spec = _v0.a;
			return A3(
				$author$project$Parameter$MultiOption$view,
				translate,
				_Utils_update(
					base,
					{
						eq: valueChangedWithDebounce(true)
					}),
				spec);
	}
};
var $author$project$Node$parameterReferenceView = F2(
	function (props, paramRef) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$text(''),
			A2(
				$elm$core$Maybe$map,
				function (parameter) {
					return $author$project$Parameter$view(
						{
							b5: paramRef.b5,
							dz: paramRef.dz,
							dA: props.dA,
							d$: parameter,
							aq: A2(
								$pzp1997$assoc_list$AssocList$map,
								F2(
									function (_v0, val) {
										return val.ba;
									}),
								props.aq),
							cX: paramRef.cX,
							cY: paramRef.cY,
							c$: props.c$,
							eq: props.eq
						});
				},
				A2($pzp1997$assoc_list$AssocList$get, paramRef.aX, props.aq)));
	});
var $author$project$Parameter$Title$TitleTypeSubtitle = 2;
var $author$project$Node$textView = function (props) {
	var title = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $author$project$Translate$Key$Dynamic, props.c$),
			props.cY));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('inner')
			]),
		_List_fromArray(
			[
				A2(
				$elm_community$html_extra$Html$Extra$viewIf,
				!$elm$core$String$isEmpty(title),
				$author$project$Parameter$Title$view(
					{
						b5: props.b5,
						dz: A2(
							$elm$core$Maybe$map,
							A2($elm$core$Basics$composeR, $author$project$Translate$Key$Dynamic, props.c$),
							props.dz),
						dA: props.dA(
							{
								b5: props.b5,
								dz: A2($elm$core$Maybe$withDefault, '', props.dz),
								cY: props.cY
							}),
						cY: title,
						cZ: function () {
							var _v0 = props.X;
							if (!_v0) {
								return 1;
							} else {
								return 2;
							}
						}()
					})),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						props.c$(
							$author$project$Translate$Key$Dynamic(props.cX)))
					]))
			]));
};
var $author$project$Parameter$toMultiOption = function (parameter) {
	var _v0 = parameter.cR;
	if (_v0.$ === 5) {
		var multiOption = _v0.a;
		return $elm$core$Maybe$Just(
			{aX: parameter.aX, E: parameter.E, O: parameter.O, cR: multiOption, ba: parameter.ba});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Parameter$toOption = function (parameter) {
	var _v0 = parameter.cR;
	if (_v0.$ === 1) {
		var option = _v0.a;
		return $elm$core$Maybe$Just(
			{aX: parameter.aX, E: parameter.E, O: parameter.O, cR: option, ba: parameter.ba});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Parameter$toTime = function (parameter) {
	var _v0 = parameter.cR;
	if (_v0.$ === 4) {
		var time = _v0.a;
		return $elm$core$Maybe$Just(
			{aX: parameter.aX, E: parameter.E, O: parameter.O, cR: time, ba: parameter.ba});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Node$DateTime$formatDayMonthYear = F4(
	function (clock, d, m, y) {
		if (!clock) {
			return $elm$core$String$fromInt(d) + ('.' + ($elm$core$String$fromInt(m) + ('.' + $elm$core$String$fromInt(y))));
		} else {
			return $elm$core$String$fromInt(m) + ('/' + ($elm$core$String$fromInt(d) + ('/' + $elm$core$String$fromInt(y))));
		}
	});
var $author$project$Node$DateTime$formatTimeValue = F2(
	function (clock, value) {
		if (!value.$) {
			return 'n/a';
		} else {
			var a = value.a;
			return A2($author$project$Parameter$Time$timeValueToString, clock, a.ba);
		}
	});
var $elm$core$Maybe$map4 = F5(
	function (func, ma, mb, mc, md) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						return $elm$core$Maybe$Just(
							A4(func, a, b, c, d));
					}
				}
			}
		}
	});
var $author$project$Node$DateTime$formatValue = function (props) {
	var year = $author$project$Parameter$Value$get(props.c3.ba);
	var time = $author$project$Parameter$Value$get(props.aM.ba);
	var month = $author$project$Parameter$Value$get(props.ci.ba);
	var day = $author$project$Parameter$Value$get(props.bP.ba);
	return A2(
		$elm$core$Maybe$withDefault,
		'n/a',
		A5(
			$elm$core$Maybe$map4,
			F4(
				function (_v0, d, m, y) {
					return A4($author$project$Node$DateTime$formatDayMonthYear, props.aM.cR.bN, d.ba, m.ba, y.ba) + (' ' + A2($author$project$Node$DateTime$formatTimeValue, props.aM.cR.bN, props.aM.ba));
				}),
			time,
			day,
			month,
			year));
};
var $author$project$Node$DateTime$view = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('inner', true),
						_Utils_Tuple2('-is-link', props.b9)
					])),
				$elm$html$Html$Events$onClick(props.bq)
			]),
		_List_fromArray(
			[
				$author$project$Parameter$Title$view(
				{
					b5: $elm$core$Maybe$Nothing,
					dz: $elm$core$Maybe$Nothing,
					dA: props.bq,
					cY: props.c$(
						$author$project$Translate$Key$Dynamic(props.cY)),
					cZ: 0
				}),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('value', true)
							]))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Node$DateTime$formatValue(props))
					]))
			]));
};
var $author$project$Node$WeeklyTimer$formatTimeValue = F2(
	function (clock, value) {
		if (!value.$) {
			return 'n/a';
		} else {
			var a = value.a;
			return A2($author$project$Parameter$Time$timeValueToString, clock, a.ba);
		}
	});
var $author$project$Node$WeeklyTimer$formatVentilationState = F2(
	function (translate, parameter) {
		var _v0 = parameter.ba;
		if (!_v0.$) {
			return 'n/a';
		} else {
			var a = _v0.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'n/a',
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.ck;
						},
						A2($elm$core$Basics$composeR, $author$project$Translate$Key$Dynamic, translate)),
					A2(
						$elm_community$list_extra$List$Extra$find,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.ba;
							},
							$elm$core$Basics$eq(a.ba)),
						parameter.cR.cn)));
		}
	});
var $author$project$Node$WeeklyTimer$priorityView = function (priority) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('priority')
			]),
		A2(
			$elm$core$List$repeat,
			priority,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('circle')
					]),
				_List_Nil)));
};
var $author$project$Node$WeeklyTimer$Off = 1;
var $author$project$Node$WeeklyTimer$On = 0;
var $author$project$Node$WeeklyTimer$weekdayState = F2(
	function (parameter, bitPosition) {
		var _v0 = parameter.ba;
		if (!_v0.$) {
			return 1;
		} else {
			var a = _v0.a;
			return A2(
				$author$project$Parameter$MultiOption$isChecked,
				a.ba,
				{bK: bitPosition}) ? 0 : 1;
		}
	});
var $author$project$Node$WeeklyTimer$weekdayStateClass = function (state) {
	switch (state) {
		case 0:
			return '-on';
		case 1:
			return '-off';
		default:
			return '-overlap';
	}
};
var $author$project$Node$WeeklyTimer$weekdayView = F3(
	function (parameter, translate, option) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('weekday'),
					$elm$html$Html$Attributes$class(
					$author$project$Node$WeeklyTimer$weekdayStateClass(
						A2($author$project$Node$WeeklyTimer$weekdayState, parameter, option.bK)))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					translate(
						$author$project$Translate$Key$Dynamic(
							A2($elm$core$Maybe$withDefault, option.ck, option.ea))))
				]));
	});
var $author$project$Node$WeeklyTimer$weekdaysView = function (props) {
	var weekdays = A2(
		$elm$core$List$map,
		function (option) {
			return A3($author$project$Node$WeeklyTimer$weekdayView, props.bf, props.c$, option);
		},
		props.bf.cR.cn);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('weekdays')
			]),
		weekdays);
};
var $author$project$Node$WeeklyTimer$view = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('timer'),
				$elm$html$Html$Events$onClick(props.bq)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Node$WeeklyTimer$formatTimeValue, props.bz.cR.bN, props.bz.ba) + ('-' + A2($author$project$Node$WeeklyTimer$formatTimeValue, props.bk.cR.bN, props.bk.ba)))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('state')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Node$WeeklyTimer$formatVentilationState, props.c$, props.bZ)),
						$author$project$Node$WeeklyTimer$priorityView(props.cu)
					])),
				$author$project$Node$WeeklyTimer$weekdaysView(props)
			]));
};
var $author$project$Node$view = F2(
	function (props, node) {
		var parameters = A2(
			$pzp1997$assoc_list$AssocList$map,
			function (_v6) {
				return function ($) {
					return $.ba;
				};
			},
			props.aq);
		var nodeTypeClass = function () {
			switch (node.$) {
				case 0:
					return '-parameter';
				case 1:
					return '-link';
				case 2:
					return '-image';
				case 3:
					return '-text';
				case 4:
					return '-weekly-timer';
				default:
					return '-datetime';
			}
		}();
		var maybeNodeContent = A2(
			$author$project$Node$isNodeVisible,
			parameters,
			$author$project$Node$visibility(node)) ? $elm$core$Maybe$Just(
			function () {
				switch (node.$) {
					case 0:
						var paramRef = node.a;
						return A2($author$project$Node$parameterReferenceView, props, paramRef);
					case 1:
						var link = node.a;
						return $author$project$Node$linkView(
							{
								b5: link.b5,
								bq: props.dH(
									{
										aS: link.aS,
										eh: $elm$core$Maybe$Just(link.eh)
									}),
								X: link.X,
								cY: props.c$(
									$author$project$Translate$Key$Dynamic(link.cY))
							});
					case 2:
						var image = node.a;
						return $author$project$Node$imageView(
							{
								dz: $elm$core$Maybe$Nothing,
								dA: props.dA,
								cX: A2(
									$elm$core$Maybe$map,
									A2($elm$core$Basics$composeR, $author$project$Translate$Key$Dynamic, props.c$),
									image.cX),
								cY: A2(
									$elm$core$Maybe$map,
									A2($elm$core$Basics$composeR, $author$project$Translate$Key$Dynamic, props.c$),
									image.cY),
								aQ: image.aQ
							});
					case 3:
						var text_ = node.a;
						return $author$project$Node$textView(
							{b5: text_.b5, dz: text_.dz, dA: props.dA, X: text_.X, cX: text_.cX, cY: text_.cY, c$: props.c$});
					case 4:
						var timer = node.a;
						var startTime = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toTime,
							A2($pzp1997$assoc_list$AssocList$get, timer.cS, props.aq));
						var fanSpeedSetting = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toOption,
							A2($pzp1997$assoc_list$AssocList$get, timer.b_, props.aq));
						var endTime = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toTime,
							A2($pzp1997$assoc_list$AssocList$get, timer.bW, props.aq));
						var daysEnabled = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toMultiOption,
							A2($pzp1997$assoc_list$AssocList$get, timer.bR, props.aq));
						var weeklyProps = A2(
							$elm_community$maybe_extra$Maybe$Extra$andMap,
							fanSpeedSetting,
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								endTime,
								A2(
									$elm_community$maybe_extra$Maybe$Extra$andMap,
									startTime,
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										daysEnabled,
										$elm$core$Maybe$Just(
											A4(
												$author$project$Node$WeeklyTimer$ViewProps,
												props.eq,
												timer.cu,
												props.c$,
												props.dH(
													{
														aS: _List_Nil,
														eh: $elm$core$Maybe$Just(
															$author$project$Node$Link$Target$TargetPage(timer.eh))
													})))))));
						if (!weeklyProps.$) {
							var a = weeklyProps.a;
							return $author$project$Node$WeeklyTimer$view(a);
						} else {
							return $elm$html$Html$text('');
						}
					default:
						var time = node.a;
						var yearParam = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toNumeric,
							A2($pzp1997$assoc_list$AssocList$get, time.c4, props.aq));
						var timeParam = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toTime,
							A2($pzp1997$assoc_list$AssocList$get, time.bD, props.aq));
						var monthParam = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toNumeric,
							A2($pzp1997$assoc_list$AssocList$get, time.cj, props.aq));
						var dayParam = A2(
							$elm$core$Maybe$andThen,
							$author$project$Parameter$toNumeric,
							A2($pzp1997$assoc_list$AssocList$get, time.bQ, props.aq));
						var timeProps = A2(
							$elm_community$maybe_extra$Maybe$Extra$andMap,
							yearParam,
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								monthParam,
								A2(
									$elm_community$maybe_extra$Maybe$Extra$andMap,
									dayParam,
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										timeParam,
										$elm$core$Maybe$Just(
											A4(
												$author$project$Node$DateTime$ViewProps,
												props.c$,
												props.dH(
													{
														aS: time.aS,
														eh: A2($elm$core$Maybe$map, $author$project$Node$Link$Target$TargetPage, time.eh)
													}),
												!_Utils_eq(time.eh, $elm$core$Maybe$Nothing),
												time.cY))))));
						if (!timeProps.$) {
							var a = timeProps.a;
							return $author$project$Node$DateTime$view(a);
						} else {
							return $elm$html$Html$text('');
						}
				}
			}()) : $elm$core$Maybe$Nothing;
		var marginBottom = function () {
			switch (node.$) {
				case 0:
					var paramRef = node.a;
					return paramRef.ap;
				case 1:
					var link = node.a;
					return link.ap;
				case 2:
					var image = node.a;
					return image.ap;
				case 3:
					var text_ = node.a;
					return text_.ap;
				case 4:
					var timer = node.a;
					return timer.ap;
				default:
					var time = node.a;
					return time.ap;
			}
		}();
		var nodeClass = A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$core$Maybe$Just('node'),
						marginBottom ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just('-no-margin'),
						$elm$core$Maybe$Just(nodeTypeClass)
					])));
		if (!maybeNodeContent.$) {
			var nodeContent = maybeNodeContent.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(nodeClass)
					]),
				_List_fromArray(
					[nodeContent]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Page$bottomView = F2(
	function (props, bottomNodes) {
		return $elm$core$List$isEmpty(bottomNodes) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('footer'),
					A2(
					$elm$html$Html$Attributes$style,
					'padding-bottom',
					$elm$core$String$fromFloat(props.G.dd + 16) + 'px')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Node$view(props),
				bottomNodes));
	});
var $author$project$Node$position = function (node) {
	switch (node.$) {
		case 0:
			return 0;
		case 1:
			var link = node.a;
			return link.d0;
		case 2:
			return 0;
		case 3:
			return 0;
		case 4:
			return 0;
		default:
			return 0;
	}
};
var $author$project$Page$contentView = F2(
	function (props, page) {
		var _v0 = A2(
			$elm$core$List$partition,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Node$position,
				$elm$core$Basics$eq(0)),
			page.a0);
		var contentNodes = _v0.a;
		var bottomNodes = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content'),
							A2(
							$elm$html$Html$Attributes$style,
							'padding-right',
							$elm$core$String$fromFloat(props.G.d7 + 16) + 'px'),
							A2(
							$elm$html$Html$Attributes$style,
							'padding-bottom',
							$elm$core$String$fromFloat(props.G.dd + 16) + 'px'),
							A2(
							$elm$html$Html$Attributes$style,
							'padding-left',
							$elm$core$String$fromFloat(props.G.dG + 16) + 'px')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Node$view(props),
						contentNodes)),
					A2($author$project$Page$bottomView, props, bottomNodes)
				]));
	});
var $author$project$Main$viewPage = F5(
	function (rootElementSize, show, page, pageProps, navStack) {
		var pageTransitionTop = $elm$core$String$fromFloat($author$project$Page$headerHeight + pageProps.G.el) + 'px';
		var pageTransitionHeight = 'calc(100% - ' + ($elm$core$String$fromFloat($author$project$Page$headerHeight + pageProps.G.el) + 'px)');
		var pageElem = show ? A2($author$project$Page$contentView, pageProps, page) : $elm$html$Html$text('');
		return A4(
			$mdgriffith$elm_animator$Animator$Css$div,
			navStack,
			_List_fromArray(
				[
					$mdgriffith$elm_animator$Animator$Css$transform(
					function (x) {
						return _Utils_eq(
							$elm$core$List$head(x),
							$elm$core$Maybe$Just(page.aX)) ? $mdgriffith$elm_animator$Animator$Css$xy(
							{eu: 0, ev: 0}) : (A2(
							$elm$core$List$any,
							$elm$core$Basics$eq(page.aX),
							A2($elm$core$List$drop, 1, x)) ? $mdgriffith$elm_animator$Animator$Css$xy(
							{eu: -rootElementSize.aR, ev: 0}) : $mdgriffith$elm_animator$Animator$Css$xy(
							{eu: rootElementSize.aR, ev: 0}));
					})
				]),
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-transition'),
					A2($elm$html$Html$Attributes$style, 'top', pageTransitionTop),
					A2($elm$html$Html$Attributes$style, 'height', pageTransitionHeight)
				]),
			_List_fromArray(
				[pageElem]));
	});
var $author$project$Main$view = function (model) {
	var translate = A2($author$project$Translate$translate, model.bE, model.dF);
	var pendingWriteOrReconnectRequests = A2(
		$elm$core$List$any,
		function (_v1) {
			var reqState = _v1.a;
			var req = _v1.b;
			return (!reqState) && ($author$project$Request$isWriteRegistersRequest(req) || $author$project$Request$isReconnectRequest(req));
		},
		$pzp1997$assoc_list$AssocList$values(model.t));
	var pendingReadRequests = A2(
		$elm$core$List$any,
		function (_v0) {
			var reqState = _v0.a;
			var req = _v0.b;
			return (!reqState) && $author$project$Request$isReadRegistersRequest(req);
		},
		$pzp1997$assoc_list$AssocList$values(model.t));
	var pages = $pzp1997$assoc_list$AssocList$values(model.S);
	var navStackSize = $elm$core$List$length(
		$mdgriffith$elm_animator$Animator$current(model.r));
	var showBackButton = (navStackSize > 1) || model.d2;
	var isPageVisible = function (page) {
		return A2(
			$elm$core$List$any,
			$elm$core$Basics$eq(page.aX),
			A2(
				$elm$core$List$take,
				2,
				$mdgriffith$elm_animator$Animator$current(model.r))) || _Utils_eq(
			model.a5,
			$elm$core$Maybe$Just(page.aX));
	};
	var atLeastOneParameterIsReady = A2(
		$elm$core$List$any,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Parameter$getValue,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Basics$eq($elm$core$Maybe$Nothing),
				$elm$core$Basics$not)),
		$pzp1997$assoc_list$AssocList$values(model.aq));
	var loading = ((!atLeastOneParameterIsReady) && pendingReadRequests) || pendingWriteOrReconnectRequests;
	var pageProps = {
		dA: $author$project$Main$InfoPressed,
		dH: A2($elm$core$Basics$composeL, $author$project$Main$GotNavigationMsg, $author$project$Main$LinkPressed),
		dJ: loading,
		aq: model.aq,
		G: model.G,
		c$: translate,
		eq: function (maybeChange) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$Main$GotParameterValueChanges(_List_Nil),
				A2(
					$elm$core$Maybe$map,
					function (change) {
						return $author$project$Main$GotParameterValueChanges(
							_List_fromArray(
								[change]));
					},
					maybeChange));
		}
	};
	var pageHeader = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			$elm$core$List$singleton,
			A2(
				$elm$core$Maybe$map,
				A3($author$project$Page$headerView, pageProps, model.ek, showBackButton),
				A2(
					$elm$core$Maybe$andThen,
					function (pageId) {
						return A2($pzp1997$assoc_list$AssocList$get, pageId, model.S);
					},
					$elm$core$List$head(
						$mdgriffith$elm_animator$Animator$current(model.r))))));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('app')
			]),
		_Utils_ap(
			pageHeader,
			_Utils_ap(
				A2(
					$elm$core$List$map,
					function (p) {
						return A5(
							$author$project$Main$viewPage,
							model.cF,
							isPageVisible(p),
							p,
							pageProps,
							model.r);
					},
					pages),
				_List_fromArray(
					[
						A2($author$project$Main$viewBottomSheet, translate, model)
					]))));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{dB: $author$project$Main$init, ee: $author$project$Main$subscriptions, eo: $author$project$Main$update, er: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));