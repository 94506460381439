import { FirmwareFamily } from './FirmwareFamily'

export interface Firmware {
  readonly id: string
  readonly family: FirmwareFamily
  readonly fileSizeBytes: number
  readonly version: string
  readonly published: boolean
  readonly serialNumberRegexes: string[]
  readonly createdAt: string
}

export const versionRegex =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/
