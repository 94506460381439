import React, { FC } from 'react'

import Typography from '@material-ui/core/Typography'

type Props = {
  field: string
  value: JSX.Element | string | undefined
  className?: string
}

const KeyValueData: FC<Props> = ({ field, value, className }) => {
  return (
    <div className={className}>
      <Typography display="block" variant="caption">
        {field}
      </Typography>
      <Typography variant="body1" component="div">
        {value}
      </Typography>
    </div>
  )
}

export default KeyValueData
