import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { QueryStatus } from 'react-query'
import Typography from '@material-ui/core/Typography'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import CircularProgress from '@material-ui/core/CircularProgress'
import KeyValueData from './KeyValueData'
import Card from '@material-ui/core/Card'
import { UserDetails } from '../data/UserDetails'
import ThingDetails from './ThingDetails'
import * as ApiError from '../data/ApiError'
import { dateTimeFormat } from '../dateTimeFormat'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import * as ThingState from '../data/ThingState'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  container: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    marginTop: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  inputItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      minWidth: 150,
      marginRight: 16,
    },
  },
  things: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}))

type Props = {
  status: QueryStatus
  error: unknown | undefined
  user: UserDetails | undefined
}

const UserDetailsComponent: FC<Props> = ({ status, error, user }) => {
  const [states, setStates] = React.useState<ThingState.ThingState[]>([
    'in_use',
  ])
  const [setupFinished, setSetupFinished] = React.useState(true)
  const filteredThings = React.useMemo(
    () =>
      user?.things?.filter(
        (x) => x.setupFinished === setupFinished && states.includes(x.state),
      ) ?? [],
    [user, setupFinished, states],
  )
  const classes = useStyles()
  if (status === QueryStatus.Idle || status === QueryStatus.Loading) {
    return <CircularProgress />
  } else if (status === QueryStatus.Error || user === undefined) {
    return <div>{ApiError.unknownErrorToMessage(error)}</div>
  } else {
    return (
      <div className={classes.container}>
        <Typography display="block" variant="overline">
          User details
        </Typography>
        <KeyValueData field="User ID" value={user.id} />
        <KeyValueData field="Email" value={user.email} />
        <KeyValueData
          field="Email verified"
          value={
            user.emailVerified ? (
              <div>
                Yes <VerifiedUserIcon fontSize="small" />
              </div>
            ) : (
              'No'
            )
          }
        />
        <KeyValueData field="Blocked" value={user.blocked ? 'Yes' : 'No'} />
        <KeyValueData field="Language" value={user.language} />
        <KeyValueData
          field="Created at"
          value={dateTimeFormat.format(Date.parse(user.createdAt))}
        />
        <Typography
          className={classes.title}
          display="block"
          variant="overline"
        >
          Things
        </Typography>
        <div className={classes.inputItems}>
          <FormControl>
            <InputLabel id="thing-state-selection">Filter by state</InputLabel>
            <Select
              labelId="thing-state-selection"
              multiple
              value={states}
              onChange={(e) =>
                setStates(e.target.value as ThingState.ThingState[])
              }
              renderValue={() => states.map(ThingState.translate).join(', ')}
            >
              {(
                [
                  'registered',
                  'in_use',
                  'deactivated',
                ] as ThingState.ThingState[]
              ).map((name) => (
                <MenuItem key={name} value={name}>
                  {ThingState.translate(name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={setupFinished}
                onChange={(e, checked) => setSetupFinished(checked)}
              />
            }
            label="Setup finished"
          />
        </div>

        <div className={classes.things}>
          {filteredThings.map((thing) => (
            <Card className={classes.card} key={thing.id}>
              <ThingDetails thing={thing} />
            </Card>
          ))}
          {filteredThings.length === 0 && '-'}
        </div>
      </div>
    )
  }
}

export default UserDetailsComponent
