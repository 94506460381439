import React, { FC } from 'react'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { OtaUpdateSession, translateState } from '../data/OtaUpdateSession'
import { versionRegex } from '../data/Firmware'
import { useLocation } from 'react-router-dom'
import OtaUpdateTable from '../components/OtaUpdateTable'
import Paper from '@material-ui/core/Paper'

function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
      width: 260,
    },
  },
  buttonAddFirmware: {
    marginLeft: 'auto',
  },
}))

const thingIdRegex =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

type Props = {
  jwtToken: () => Promise<string>
}

const OtaUpdateComponent: FC<Props> = ({ jwtToken }) => {
  const classes = useStyles()

  const [thingId, setThingId] = React.useState('')
  const [version, setVersion] = React.useState('')
  const [states, setStates] = React.useState<OtaUpdateSession['state'][]>([])

  const queryParams = useQueryParams()

  React.useEffect(() => {
    const queryParamThingId = queryParams.get('thingId')
    if (queryParamThingId) {
      setThingId(queryParamThingId)
    }
  }, [queryParams])

  const thingIdNoError = thingId.length === 0 || thingIdRegex.test(thingId)
  const versionNoError = version.length === 0 || versionRegex.test(version)

  const header = (
    <React.Fragment>
      <div className={classes.header}>
        <TextField
          label="Thing ID"
          value={thingId}
          error={!thingIdNoError}
          helperText={
            !thingIdNoError
              ? 'Enter a valid thing ID, eg. "aaaef73c-7c05-4d37-bb94-5236ce010999"'
              : undefined
          }
          onChange={(e) => setThingId(e.target.value)}
        />
        <TextField
          label="Version"
          value={version}
          error={!versionNoError}
          helperText={
            !versionNoError ? 'Enter a valid version, eg. "1.0.0"' : undefined
          }
          onChange={(e) => setVersion(e.target.value)}
        />
        <FormControl>
          <InputLabel id="ota-state-selection">Filter by state</InputLabel>
          <Select
            labelId="ota-state-selection"
            multiple
            value={states}
            onChange={(e) =>
              setStates(e.target.value as OtaUpdateSession['state'][])
            }
            renderValue={() => states.map(translateState).join(', ')}
          >
            {(
              [
                'created',
                'idle',
                'in_progress',
                'success',
                'failure',
              ] as OtaUpdateSession['state'][]
            ).map((name) => (
              <MenuItem key={name} value={name}>
                {translateState(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <Divider variant="fullWidth" orientation="horizontal" />
    </React.Fragment>
  )

  return (
    <div>
      {header}
      <OtaUpdateTable
        tableComponent={Paper}
        jwtToken={jwtToken}
        thingId={
          thingIdNoError && thingId && thingId.length > 0 ? thingId : undefined
        }
        version={
          versionNoError && version && version.length > 0 ? version : undefined
        }
        states={states}
        limit={20}
      />
    </div>
  )
}

export default OtaUpdateComponent
