export enum RoutePath {
  root = '/',
  dashboard = '/dashboard',
  firmware = '/firmware',
  users = '/users',
  otaUpdate = '/otaupdate',
  things = '/things',
  thingDetails = '/things/:thingId',
  thingRemoteDebugging = '/thing/:thingId/remotedebugging',
}
