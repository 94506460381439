import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MutationResult, QueryStatus } from 'react-query'
import * as ApiError from '../data/ApiError'

type DeleteDialogProps = {
  readonly title: string
  readonly description: string
  readonly open: boolean
  readonly onDelete: () => void
  readonly onClose: () => void
  readonly deleteResult: MutationResult<Response, unknown>
}

const DeleteDialog: FC<DeleteDialogProps> = (props) => {
  const { status, error } = props.deleteResult

  const { onClose } = props

  const close = React.useCallback(() => {
    if (status !== QueryStatus.Loading) {
      onClose()
    }
  }, [status, onClose])

  return (
    <div>
      <Dialog open={props.open} onClose={close}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>

          {status === QueryStatus.Error && (
            <DialogContentText color="error" style={{ marginTop: '8px' }}>
              {ApiError.unknownErrorToMessage(error)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            disabled={status === QueryStatus.Loading}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={props.onDelete}
            disabled={status === QueryStatus.Loading}
            color="primary"
            autoFocus
          >
            {status === QueryStatus.Loading && <CircularProgress size={18} />}
            {status !== QueryStatus.Loading && 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteDialog
