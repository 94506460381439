import { FirmwareFamily } from './FirmwareFamily'

export interface OtaUpdateSessionBase {
  readonly firmware: string
  readonly thingId: string
  readonly sessionId: number
  readonly firmwareVersion: string
  readonly firmwareFamily: FirmwareFamily
  readonly fileUrl: string
  readonly updatedAt: string | undefined
  readonly createdAt: string
  readonly createdByUserId: string | undefined
  readonly createdByAdminId: string | undefined
}

export type OtaUpdateSessionCreated = OtaUpdateSessionBase & {
  readonly state: 'created'
}

export type OtaUpdateSessionStarted = OtaUpdateSessionBase & {
  readonly state: 'started'
}

export type OtaUpdateSessionIdle = OtaUpdateSessionBase & {
  readonly state: 'idle'
}

export type OtaUpdateSessionInProgress = OtaUpdateSessionBase & {
  readonly state: 'in_progress'
  readonly progress: number
}

export type OtaUpdateSessionSuccess = OtaUpdateSessionBase & {
  readonly state: 'success'
}

export type OtaUpdateSessionFailure = OtaUpdateSessionBase & {
  readonly state: 'failure'
  readonly error: string
}

export type OtaUpdateSession =
  | OtaUpdateSessionCreated
  | OtaUpdateSessionStarted
  | OtaUpdateSessionIdle
  | OtaUpdateSessionInProgress
  | OtaUpdateSessionSuccess
  | OtaUpdateSessionFailure

export function translateState(state: OtaUpdateSession['state']): string {
  switch (state) {
    case 'created':
      return 'Created'
    case 'started':
      return 'Started'
    case 'idle':
      return 'Idle'
    case 'in_progress':
      return 'In progress'
    case 'success':
      return 'Success'
    case 'failure':
      return 'Failure'
  }
}
