import { ThingState } from './data/ThingState'

export const swegonGreen = '#39b812'

export const swegonOrange = '#dc5f1f'

export const swegonBlue = '#0064b3'

export const warning = '#fb0061'

export const whiteDarker = '#f5f5f5'

export const gray = '#9b9b9b'

export function thingStateToColor(state: ThingState): string {
  switch (state) {
    case 'deactivated':
      return swegonOrange
    case 'in_use':
      return swegonGreen
    case 'registered':
      return swegonBlue
  }
}
