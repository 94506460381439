const ResizeSensor = require("css-element-queries/src/ResizeSensor");

customElements.define(
  "swegon-slider",
  class extends HTMLElement {
    constructor() {
      super();
    }

    get value() {
      return parseInt(this.getAttribute("data-value"), 10);
    }

    set value(value) {
      this.setAttribute("data-value", "" + value);
    }

    get min() {
      return parseInt(this.getAttribute("data-min"), 10);
    }

    set min(min) {
      this.setAttribute("data-min", "" + min);
    }

    get max() {
      return parseInt(this.getAttribute("data-max"), 10);
    }

    set max(max) {
      this.setAttribute("data-max", "" + max);
    }

    get step() {
      return parseInt(this.getAttribute("data-step"), 10);
    }

    set step(step) {
      this.setAttribute("data-step", "" + step);
    }

    _props() {
      return {
        min: this.min,
        max: this.max,
        step: this.step,
        value: this.value,
      };
    }

    _render() {
      const { min, max, value } = this._props();
      const thumbRect = this._thumbDiv.getBoundingClientRect();
      const sliderRect = this._sliderDiv.getBoundingClientRect();
      const ratio = (value - min) / (max - min);
      const ratioClamped = Math.max(0, Math.min(1, ratio));
      const valueLeft =
        ratioClamped * (sliderRect.width - thumbRect.width);
      this._thumbDiv.style.left = valueLeft + "px";

      const halfThumb = thumbRect.width / 2;
      this._railLeftDiv.style.width = valueLeft + halfThumb + "px";
      this._railRightDiv.style.left = valueLeft + halfThumb + "px";
      this._railRightDiv.style.width =
        sliderRect.width - valueLeft - halfThumb + "px";
    }

    connectedCallback() {
      const useShadowDom = false;
      const parentDiv = useShadowDom
        ? this.attachShadow({ mode: "open" })
        : this;

      const sliderDiv = document.createElement("div");
      sliderDiv.className = "swegon-slider";
      this._sliderDiv = sliderDiv;

      parentDiv.innerHTML = "";
      parentDiv.appendChild(sliderDiv);

      const railLeftDiv = document.createElement("div");
      railLeftDiv.className = "swegon-slider__rail-left";
      this._railLeftDiv = railLeftDiv;
      sliderDiv.appendChild(railLeftDiv);

      const railRightDiv = document.createElement("div");
      railRightDiv.className = "swegon-slider__rail-right";
      this._railRightDiv = railRightDiv;
      sliderDiv.appendChild(railRightDiv);

      const thumbDiv = document.createElement("div");
      thumbDiv.className = "swegon-slider__thumb";
      this._thumbDiv = thumbDiv;
      sliderDiv.appendChild(thumbDiv);

      let _offsetFromLeft = undefined;

      const calculateValue = ({ ratio, min, max, step }) => {
        const value = min + ratio * (max - min);

        const stepsToValue = Math.round((value - min) / step);
        return Math.max(min, Math.min(max, min + step * stepsToValue));
      };

      new ResizeSensor(sliderDiv, () => {
        this._render();
      });

      thumbDiv.onpointerdown = (e) => {
        e.target.setPointerCapture(e.pointerId);
        const thumbRect = thumbDiv.getBoundingClientRect();
        _offsetFromLeft = e.clientX - thumbRect.x;
      };

      thumbDiv.onpointermove = (event) => {
        if (_offsetFromLeft === undefined) {
          return;
        }

        const { min, max, step } = this._props();

        const thumbRect = thumbDiv.getBoundingClientRect();
        const sliderRect = sliderDiv.getBoundingClientRect();
        const offsetLeft = event.clientX - sliderRect.left - _offsetFromLeft;
        const left = Math.max(
          0,
          Math.min(sliderRect.width - thumbRect.width, offsetLeft)
        );
        const value = calculateValue({
          ratio: left / (sliderRect.width - thumbRect.width),
          min: min,
          max: max,
          step: step,
        });

        if (value !== this.value) {
          this.value = value;
          this._render();
          this.dispatchEvent(new CustomEvent("valueChanged"));
        }
      };

      thumbDiv.onpointerup = (event) => {
        _offsetFromLeft = undefined;
      };

      thumbDiv.oncontextmenu = function (event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      };
    }

    attributeChangedCallback(name, oldValue, newValue) {
      if (oldValue === newValue) return;
      if (this._sliderDiv === undefined) return;
      if (
        ["data-value", "data-min", "data-max", "data-step"].includes(name) &&
        oldValue !== newValue
      ) {
        this._render();
      }
    }

    static get observedAttributes() {
      return ["data-min", "data-max", "data-value", "data-step"];
    }
  }
);
