export type DynamicMenuRequest =
  | ReadRegistersRequest
  | WriteRegistersRequest
  | ReconnectRequest
  | QuitRequest

function isDynamicMenuRequest(x: unknown): x is DynamicMenuRequest {
  const allTypes: Record<DynamicMenuRequest['type'], true> = {
    read_registers: true,
    write_registers: true,
    reconnect: true,
    quit: true,
  }
  const type =
    x && typeof x === 'object' ? (x as Record<string, unknown>)['type'] : ''
  return allTypes[type as DynamicMenuRequest['type']]
}

type ReadRegistersRequest = {
  type: 'read_registers'
  requestId: number
  registers: number[]
}

type WriteRegistersRequest = {
  type: 'write_registers'
  requestId: number
  data: readonly (readonly [number, number])[]
}

type ReconnectRequest = {
  type: 'reconnect'
  requestId: number
}

type QuitRequest = {
  type: 'quit'
}

type ConfigEvent = {
  type: 'config'
  language: string
  thingNickname: string
  quitEnabled: boolean
}

export type DynamicMenuEvent = DynamicMenuResponse | ConfigEvent

type DynamicMenuResponse = {
  type: 'response'
  response:
    | {
        type: 'read_registers'
        requestId: number
        success: false
      }
    | {
        type: 'read_registers'
        requestId: number
        success: true
        data: readonly (readonly [number, number])[]
      }
    | {
        type: 'write_registers'
        requestId: number
        success: false
      }
    | {
        type: 'write_registers'
        requestId: number
        success: true
        data: readonly (readonly [number, number])[]
      }
    | { type: 'reconnect'; requestId: number; success: boolean }
}

type DynamicMenuListener = {
  onReadRegisters(registers: Set<number>): Promise<Record<number, number>>
  onWriteRegisters(registers: Record<number, number>): Promise<void>
  onReconnect(): Promise<void>
}

export function bindListener(
  app: ElmApp,
  listener: DynamicMenuListener,
): () => void {
  const send: (event: DynamicMenuEvent) => void = (event) => {
    app.ports.onIncoming.send(JSON.stringify(event))
  }

  const eventHandler: (request: unknown) => void = async (request) => {
    if (!isDynamicMenuRequest(request)) {
      return
    }
    switch (request.type) {
      case 'read_registers':
        try {
          const result = await listener.onReadRegisters(
            new Set(request.registers),
          )
          send({
            type: 'response',
            response: {
              type: 'read_registers',
              requestId: request.requestId,
              success: true,
              data: Object.keys(result).map((key) => [
                Number(key),
                result[Number(key)],
              ]),
            },
          })
        } catch (e) {
          send({
            type: 'response',
            response: {
              type: 'read_registers',
              requestId: request.requestId,
              success: false,
            },
          })
        }
        break
      case 'write_registers':
        try {
          const registers: Record<number, number> = {}
          request.data.forEach(([register, value]) => {
            registers[register] = value
          })
          await listener.onWriteRegisters(registers)
          send({
            type: 'response',
            response: {
              type: 'write_registers',
              requestId: request.requestId,
              success: true,
              data: request.data,
            },
          })
        } catch (e) {
          send({
            type: 'response',
            response: {
              type: 'write_registers',
              requestId: request.requestId,
              success: false,
            },
          })
        }
        break
      case 'reconnect':
        try {
          await listener.onReconnect()
          send({
            type: 'response',
            response: {
              type: 'reconnect',
              requestId: request.requestId,
              success: true,
            },
          })
        } catch (e) {
          send({
            type: 'response',
            response: {
              type: 'reconnect',
              requestId: request.requestId,
              success: false,
            },
          })
        }
        break
      default:
        break
    }
  }

  app.ports.outgoing.subscribe(eventHandler)

  return () => {
    app.ports.outgoing.unsubscribe(eventHandler)
  }
}
