export class RegisterStorageService {
  static readonly LOCAL_REGISTERS_START = 5000
  static readonly LOCAL_REGISTERS_END = 5999
  private readonly REGISTERS_KEY = 'ahu2020-registers'
  private readonly storage: Storage = window.localStorage

  readRegisters(registers: Set<number>): Record<number, number> {
    const rawItem = this.storage.getItem(this.REGISTERS_KEY)
    const item = rawItem ? JSON.parse(rawItem) : null

    const values: Record<number, number> = {}

    registers.forEach((register) => {
      const value = (item ? item[register] : null) ?? 0
      values[register] = value
    })

    return values
  }

  writeRegisters(registers: Record<number, number>): void {
    const rawItem = this.storage.getItem(this.REGISTERS_KEY)
    const item = rawItem ? JSON.parse(rawItem) : {}

    Object.keys(registers).forEach((register) => {
      item[Number(register)] = registers[Number(register)]
    })

    this.storage.setItem(this.REGISTERS_KEY, JSON.stringify(item))
  }
}
