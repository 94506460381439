import React, { FC, useRef, useEffect, useState } from 'react'
import { Elm } from '../../dynamic-menu-web/src/Main.elm'
import schema from '../schema.json'
import '../../dynamic-menu-web/src/swegon-slider.js'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as api from '../api'
import Button from '@material-ui/core/Button'
import { bindListener } from '../data/DynamicMenuAdapter'
import { RoutePath } from '../RoutePath'
import { RegisterStorageService } from '../data/RegisterStorageService'

const registerStorage = new RegisterStorageService()

const ThingRemoteDebugging: FC<{ jwtToken: () => Promise<string> }> = ({
  jwtToken,
}) => {
  const appRef = useRef<HTMLDivElement>(null)
  const [elmApp, setElmApp] = useState<ElmApp | undefined>(undefined)
  const routeMatch = useRouteMatch<{ thingId: string }>()
  const [active, setActive] = useState(true)
  const thingId = routeMatch.params.thingId
  const history = useHistory()

  useEffect(() => {
    // Handle disconnect automatically after certain time to prevent
    // polling backend forever in case remote debugging view is not closed.
    if (active) {
      const keepOpenForMinutes = 15
      const timeout = setTimeout(() => {
        setActive(false)
        window.alert(
          `Connection closed automatically after ${keepOpenForMinutes} minutes. You can open the connection again by pressing 'Reconnect'.`,
        )
      }, keepOpenForMinutes * 60_000)
      return () => {
        clearTimeout(timeout)
      }
    } else {
      // Nothing to do
    }
  }, [active])

  useEffect(() => {
    const app = Elm.Main.init({ node: appRef.current })
    setElmApp(app)
    app.ports.onIncoming.send(JSON.stringify(replaceImageUrl(schema)))
    app.ports.onIncoming.send(
      JSON.stringify({
        type: 'config',
        language: 'en',
        thingNickname: thingId,
        quitEnabled: false,
      }),
    )
  }, [])

  useEffect(() => {
    if (elmApp) {
      return bindListener(elmApp, {
        onReadRegisters: async (registers) => {
          if (active) {
            try {
              const localRegisters = new Set<number>()
              const remoteRegisters = new Set<number>()

              registers.forEach((register) => {
                if (
                  register >= RegisterStorageService.LOCAL_REGISTERS_START &&
                  register <= RegisterStorageService.LOCAL_REGISTERS_END
                ) {
                  localRegisters.add(register)
                } else {
                  remoteRegisters.add(register)
                }
              })

              const remoteResult = await api.readRegisters(
                jwtToken,
                thingId,
                remoteRegisters,
              )
              const localResult = registerStorage.readRegisters(localRegisters)
              return Promise.resolve({ ...remoteResult, ...localResult })
            } catch (e) {
              return Promise.reject('Error')
            }
          } else {
            return Promise.reject('Timeout')
          }
        },
        onReconnect: () => {
          setActive(true)
          return Promise.resolve()
        },
        onWriteRegisters: async (registersAndValues) => {
          if (active) {
            try {
              const localRegisters: Record<number, number> = {}
              const remoteRegisters: Record<number, number> = {}

              Object.keys(registersAndValues)
                .map((x) => Number(x))
                .forEach((register) => {
                  if (
                    register >= RegisterStorageService.LOCAL_REGISTERS_START &&
                    register <= RegisterStorageService.LOCAL_REGISTERS_END
                  ) {
                    localRegisters[register] = registersAndValues[register]
                  } else {
                    remoteRegisters[register] = registersAndValues[register]
                  }
                })

              await api.writeRegisters(jwtToken, thingId, remoteRegisters)
              registerStorage.writeRegisters(localRegisters)
              return Promise.resolve()
            } catch (e) {
              return Promise.reject('Error')
            }
          } else {
            return Promise.reject('Timeout')
          }
        },
      })
    }
  }, [elmApp, active])

  return (
    <div>
      {active && (
        <Button
          color="primary"
          onClick={() => {
            history.replace(RoutePath.thingDetails.replace(':thingId', thingId))
          }}
        >
          Exit
        </Button>
      )}
      <div style={{ width: '100%', height: '600px' }}>
        <div id="app" ref={appRef} />
      </div>
    </div>
  )
}

export default ThingRemoteDebugging

type ImageNode = { type: 'image'; url: string }
type PageNode = Record<string, unknown> | ImageNode

type Schema = {
  pages: { nodes: PageNode[] }[]
}

function isImageNode(node: PageNode): node is ImageNode {
  return node.type === 'image'
}

function replaceNode(node: PageNode): PageNode {
  if (isImageNode(node)) {
    return {
      ...node,
      url: node.url.startsWith('images/') ? `/${node.url}` : node.url,
    }
  }
  return node
}

function replaceImageUrl(schema: Schema) {
  return {
    ...schema,
    pages: schema.pages.map((page) => ({
      ...page,
      nodes: page.nodes.map(replaceNode),
    })),
  }
}
