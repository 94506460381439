import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { QueryStatus, useInfiniteQuery, useQuery } from 'react-query'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDebounce } from '../hooks'
import UserDetailsComponent from '../components/UserDetails'
import InfiniteListFooter from '../components/InfiniteListFooter'
import Divider from '@material-ui/core/Divider'
import * as ApiError from '../data/ApiError'
import * as api from '../api'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: 260,
    },
  },
  footer: {
    marginTop: theme.spacing(1),
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}))

type Props = {
  jwtToken: () => Promise<string>
}

const Users: FC<Props> = ({ jwtToken }) => {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 400)
  const [selectedUserId, setSelectedUserId] = React.useState<
    string | undefined
  >(undefined)
  const userQuery = useInfiniteQuery(
    ['users', debouncedSearchTerm],
    (key: string, searchTerm: string, nextResultsToken: string | undefined) =>
      api.fetchUsers(jwtToken, searchTerm, nextResultsToken),
    {
      retry: false,
      enabled: true,
      getFetchMore: (last) => last?.nextResultsToken,
      staleTime: 1000 * 30,
    },
  )
  const { status, data, error } = userQuery

  const {
    status: userDetailsStatus,
    data: userDetailsData,
    error: userDetailsError,
  } = useQuery(
    ['userDetails', selectedUserId],
    (key: string, selectedUserId: string) =>
      api.fetchUserDetails(jwtToken, selectedUserId),
    {
      retry: false,
      enabled: selectedUserId !== undefined,
    },
  )

  const header = (
    <div className={classes.header}>
      <TextField
        label="Search by user ID or email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Divider variant="fullWidth" orientation="horizontal" />
    </div>
  )

  let content: JSX.Element
  if (status === QueryStatus.Idle || status === QueryStatus.Loading) {
    content = <CircularProgress />
  } else if (status === QueryStatus.Error) {
    content = <div>{ApiError.unknownErrorToMessage(error)}</div>
  } else {
    content = (
      <div>
        {data
          ?.flatMap((x) => x.results)
          ?.map((user) => (
            <Accordion
              key={user.id}
              expanded={selectedUserId === user.id}
              onChange={() =>
                setSelectedUserId(
                  selectedUserId === user.id ? undefined : user.id,
                )
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{user.id}</Typography>
                <Typography className={classes.secondaryHeading}>
                  {user.email}{' '}
                  {user.emailVerified && (
                    <VerifiedUserIcon
                      style={{ marginLeft: '8px' }}
                      fontSize="small"
                    />
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <UserDetailsComponent
                  error={userDetailsError}
                  status={userDetailsStatus}
                  user={userDetailsData}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        <InfiniteListFooter {...userQuery} />
      </div>
    )
  }
  return (
    <div>
      {header}
      {content}
    </div>
  )
}

export default Users
