import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MutationResult, QueryStatus } from 'react-query'
import * as ApiError from '../data/ApiError'
import { Firmware } from '../data/Firmware'
import RegExprsInputList from './RegExprsInputList'

type EditFirmwareDialogProps = {
  readonly open: boolean
  readonly firmware: Pick<Firmware, 'id' | 'serialNumberRegexes' | 'version'>
  readonly onSave: (request: { serialNumberRegexes: string[] }) => void
  readonly onClose: () => void
  readonly saveResult: MutationResult<Response, unknown>
}

const EditFirmwareDialog: FC<EditFirmwareDialogProps> = (props) => {
  const [serialNumberRegexes, setSerialNumberRegexes] = React.useState<
    string[]
  >(props.firmware.serialNumberRegexes)
  const { open, onClose, onSave } = props

  React.useEffect(() => {
    if (!open && serialNumberRegexes.length !== 0) {
      setSerialNumberRegexes([])
    }
  }, [open, serialNumberRegexes, setSerialNumberRegexes])

  const save = React.useCallback(() => {
    if (serialNumberRegexes.length > 0) {
      onSave({
        serialNumberRegexes: serialNumberRegexes
          .map((regex) => regex.trim())
          .filter((regex) => regex.length > 0),
      })
    }
  }, [serialNumberRegexes, onSave])

  const { status, error } = props.saveResult

  const close = React.useCallback(() => {
    if (status !== QueryStatus.Loading) {
      onClose()
    }
  }, [status, onClose])

  return (
    <div>
      <Dialog open={props.open} onClose={close} aria-labelledby="add-firmware">
        <DialogTitle id="add-firmware">
          Update firmware ({props.firmware.version}) RegExprs
        </DialogTitle>
        <DialogContent>
          <RegExprsInputList
            autoFocus={true}
            value={serialNumberRegexes}
            onChange={setSerialNumberRegexes}
          />

          {status === QueryStatus.Error && (
            <DialogContentText color="error" style={{ marginTop: '8px' }}>
              {ApiError.unknownErrorToMessage(error)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            color="primary"
            disabled={status === QueryStatus.Loading}
          >
            Cancel
          </Button>
          <Button
            onClick={save}
            disabled={
              status === QueryStatus.Loading || serialNumberRegexes.length === 0
            }
            color="primary"
          >
            {status === QueryStatus.Loading && <CircularProgress size={18} />}
            {status !== QueryStatus.Loading && 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditFirmwareDialog
