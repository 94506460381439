import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MutationResult, QueryStatus } from 'react-query'
import * as ApiError from '../data/ApiError'

type ConfirmDialogProps = {
  readonly title: string
  readonly description: string
  readonly open: boolean
  readonly confirmText: string
  readonly onConfirm: () => void
  readonly onClose: () => void
  readonly confirmResult: MutationResult<Response, unknown>
}

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const { status, error } = props.confirmResult

  const { onClose } = props

  const close = React.useCallback(() => {
    if (status !== QueryStatus.Loading) {
      onClose()
    }
  }, [status, onClose])

  return (
    <div>
      <Dialog open={props.open} onClose={close}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>

          {status === QueryStatus.Error && (
            <DialogContentText color="error" style={{ marginTop: '8px' }}>
              {ApiError.unknownErrorToMessage(error)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            disabled={status === QueryStatus.Loading}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={props.onConfirm}
            disabled={status === QueryStatus.Loading}
            color="primary"
            autoFocus
          >
            {status === QueryStatus.Loading && <CircularProgress size={18} />}
            {status !== QueryStatus.Loading && props.confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog
