import React from 'react'
import './App.css'
import {
  withAuthenticator,
  ThemeProvider as AmplifyThemeProvider,
  Theme,
} from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import DevicesIcon from '@material-ui/icons/Devices'
import StorageIcon from '@material-ui/icons/Storage'
import SystemUpdateIcon from '@material-ui/icons/SystemUpdateAlt'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  NavLink,
} from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Things from './pages/Things'
import Users from './pages/Users'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import Firmware from './pages/Firmware'
import OtaUpdate from './pages/OtaUpdate'
import { RoutePath } from './RoutePath'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ThingRemoteDebugging from './pages/ThingRemoteDebugging'
import ThingDetails from './pages/ThingDetails'
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator'

// Step 1: Create a new Theme with your custom values
const amplifyTheme: Theme = {
  name: 'theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          80: '#39b812',
        },
      },
    },
    fontSizes: {
      xxxs: '0.6rem',
      xxs: '0.8rem',
      xs: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '2rem',
      xl: '2.4rem',
      xxl: '3.2rem',
      xxxl: '4rem',
      xxxxl: '4.8rem',
    },
  },
}

function authConfiguration(): {
  region: string
  userPoolId: string
  userPoolWebClientId: string
} {
  if (
    ['localhost', 'admin.ahu2020.com'].some(
      (x) => window.location.hostname === x,
    )
  ) {
    return {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_s1PQSGmOD',
      userPoolWebClientId: '56j3dn14nfrvmusdnjojnirfu2',
    }
  } else {
    return {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_Pzw2fpivb',
      userPoolWebClientId: '21ftm1d5hjfngg179lha1lruoc',
    }
  }
}

Auth.configure({
  mandatorySignIn: true,
  ...authConfiguration(),
})

async function getJwtToken(): Promise<string> {
  const session = await Auth.currentSession()
  return session.getAccessToken().getJwtToken()
}

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  logoutButton: {
    marginLeft: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginTop: '64px',
  },
}))

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: '#39b812',
      contrastText: '#ffffff',
    },
  },
})

const useNavListItemStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.grey[200],
  },
}))

const queryCache = new QueryCache()

type NavListItemProps = Readonly<{
  name: string
  to: RoutePath
  icon: JSX.Element
}>

const NavListItem: React.FC<NavListItemProps> = ({
  name,
  to,
  icon,
}: {
  name: string
  to: RoutePath
  icon: JSX.Element
}) => {
  const classes = useNavListItemStyles()
  return (
    <ListItem
      button
      key={name}
      {...{ component: NavLink, to }}
      activeClassName={classes.active}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  )
}

function App(props: { signOut?: SignOut }) {
  const classes = useStyles()
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router>
            <div className={classes.root}>
              <CssBaseline />
              <AppBar
                color="default"
                position="fixed"
                className={classes.appBar}
              >
                <Toolbar>
                  <Typography variant="h6" noWrap>
                    <Switch>
                      <Route path={RoutePath.dashboard}>Dashboard</Route>
                      <Route path={RoutePath.users}>Users</Route>
                      <Route path={RoutePath.things} exact>
                        Things
                      </Route>
                      <Route path={RoutePath.thingRemoteDebugging}>
                        Thing remote debugging
                      </Route>
                      <Route path={RoutePath.thingDetails}>Thing details</Route>
                      <Route path={RoutePath.firmware}>Firmware</Route>
                      <Route path={RoutePath.otaUpdate}>OTA Updates</Route>
                    </Switch>
                  </Typography>

                  <Button
                    className={classes.logoutButton}
                    color="inherit"
                    onClick={() => {
                      if (props.signOut) props.signOut()
                    }}
                  >
                    Logout
                  </Button>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
                anchor="left"
              >
                <div className={classes.toolbar} />
                <List>
                  <NavListItem
                    name="Dashboard"
                    icon={<DashboardIcon />}
                    to={RoutePath.dashboard}
                  />
                  <NavListItem
                    name="Users"
                    icon={<PeopleIcon />}
                    to={RoutePath.users}
                  />
                  <NavListItem
                    name="Things"
                    icon={<DevicesIcon />}
                    to={RoutePath.things}
                  />
                  <NavListItem
                    name="Firmware"
                    icon={<StorageIcon />}
                    to={RoutePath.firmware}
                  />
                  <NavListItem
                    name="OTA Updates"
                    icon={<SystemUpdateIcon />}
                    to={RoutePath.otaUpdate}
                  />
                </List>
              </Drawer>
              <main className={classes.content}>
                <Switch>
                  <Route path={RoutePath.users}>
                    <Users jwtToken={getJwtToken} />
                  </Route>
                  <Route path={RoutePath.things} exact>
                    <Things jwtToken={getJwtToken} />
                  </Route>
                  <Route path={RoutePath.thingRemoteDebugging}>
                    <ThingRemoteDebugging jwtToken={getJwtToken} />
                  </Route>
                  <Route path={RoutePath.thingDetails}>
                    <ThingDetails jwtToken={getJwtToken} />
                  </Route>
                  <Route path={RoutePath.dashboard}>
                    <Dashboard />
                  </Route>
                  <Route path={RoutePath.firmware}>
                    <Firmware jwtToken={getJwtToken} />
                  </Route>
                  <Route path={RoutePath.otaUpdate}>
                    <OtaUpdate jwtToken={getJwtToken} />
                  </Route>
                  <Route path={RoutePath.root} exact={true}>
                    <Redirect to={RoutePath.dashboard} />
                  </Route>
                </Switch>
              </main>
            </div>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ReactQueryCacheProvider>
  )
}

const WithAuthenticator = withAuthenticator(App, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})

const AppWithTheme = (): React.ReactElement => {
  return (
    <AmplifyThemeProvider theme={amplifyTheme}>
      <WithAuthenticator />
    </AmplifyThemeProvider>
  )
}

export default AppWithTheme
