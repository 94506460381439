import { assertNever } from '../assertNever'

export class TokenInvalid {}

export class NotFound {}

export class MaintenanceBreak {}

export class Unknown {}

export class Conflict {}

export class BadRequest {}

export type ApiError =
  | TokenInvalid
  | NotFound
  | Conflict
  | MaintenanceBreak
  | BadRequest
  | Unknown

export function fromResponse(response: Response): ApiError | undefined {
  if (response.status < 300) {
    return undefined
  } else if (response.status === 404) {
    return new NotFound()
  } else if (response.status === 401) {
    return new TokenInvalid()
  } else if (response.status === 409) {
    return new Conflict()
  } else if (response.status === 503) {
    return new MaintenanceBreak()
  } else if (response.status === 400) {
    return new BadRequest()
  } else {
    return new Unknown()
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function rejectOrJson(response: Response): Promise<any> {
  const error = fromResponse(response)
  if (error) {
    return Promise.reject(error)
  } else {
    return response.json()
  }
}

export function rejectOrString(response: Response): Promise<string> {
  const error = fromResponse(response)
  if (error) {
    return Promise.reject(error)
  } else {
    return response.text()
  }
}

export function rejectOrResponse(response: Response): Promise<Response> {
  const error = fromResponse(response)
  if (error) {
    return Promise.reject(error)
  } else {
    return Promise.resolve(response)
  }
}

export function toMessage(error: ApiError): string {
  if (error instanceof MaintenanceBreak) {
    return 'Ongoing maintenance break. Please wait a while.'
  } else if (error instanceof NotFound) {
    return "Whatever you were looking for wasn't there."
  } else if (error instanceof TokenInvalid) {
    return 'Expired or invalid access token. Try refreshing the page.'
  } else if (error instanceof Conflict) {
    return 'Conflict. Resource already exists.'
  } else if (error instanceof Unknown) {
    return 'Oops! Something went wrong.'
  } else if (error instanceof BadRequest) {
    return 'Oops! Something is wrong with the data you provided.'
  } else {
    assertNever(error)
  }
}

export function isApiError(error: unknown): error is ApiError {
  return (
    error instanceof MaintenanceBreak ||
    error instanceof NotFound ||
    error instanceof TokenInvalid ||
    error instanceof Conflict ||
    error instanceof BadRequest ||
    error instanceof Unknown
  )
}

export function unknownErrorToMessage(error: ApiError | unknown): string {
  if (isApiError(error)) {
    return toMessage(error)
  } else {
    return toMessage(new Unknown())
  }
}
