import React, { FC } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { makeStyles } from '@material-ui/core/styles'
import { dateTimeFormat } from '../dateTimeFormat'
import { QueryStatus, useMutation, useQuery, useQueryCache } from 'react-query'
import { Firmware } from '../data/Firmware'
import * as FirmwareFamily from '../data/FirmwareFamily'
import * as ApiError from '../data/ApiError'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddFirmwareDialog from '../components/AddFirmwareDialog'
import DeleteDialog from '../components/DeleteDialog'
import * as api from '../api'
import StateLabel from '../components/StateLabel'
import * as color from '../color'
import CancelIcon from '@material-ui/icons/Cancel'
import PublishIcon from '@material-ui/icons/Publish'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ConfirmDialog from '../components/ConfirmDialog'
import PopupState from 'material-ui-popup-state'
import { bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import EditFirmwareDialog from '../components/EditFirmwareDialog'
import UpdateFirmwareRegExprsDialog from '../components/UpdateFirmwareRegExprsDialog'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  buttonAddFirmware: {
    marginLeft: 8,
  },
}))

const publishableVersionRegex = /^\d+\.\d+\.\d+$/

type Props = {
  jwtToken: () => Promise<string>
}

const FirmwareComponent: FC<Props> = ({ jwtToken }) => {
  const classes = useStyles()
  const cache = useQueryCache()
  const [addFirmwareDialogOpen, setAddFirmwareDialogOpen] =
    React.useState(false)

  const [updateRegExprsDialogOpen, setUpdateRegExprsDialogOpen] =
    React.useState(false)

  const [firmwareToDelete, setFirmwareToDelete] = React.useState<
    Firmware | undefined
  >(undefined)

  const [firmwareToPublish, setFirmwareToPublish] = React.useState<
    Firmware | undefined
  >(undefined)

  const [firmwareToUnpublish, setFirmwareToUnpublish] = React.useState<
    Firmware | undefined
  >(undefined)

  const [firmwareToEdit, setFirmwareToEdit] = React.useState<
    Firmware | undefined
  >(undefined)

  const firmwareQuery = useQuery(
    ['firmware'],
    () => api.fetchFirmware(jwtToken),
    {
      retry: false,
      enabled: true,
      staleTime: 1000 * 30,
    },
  )

  const { status, data, error } = firmwareQuery

  const [mutateAddFirmware, mutateAddFirmwareResult] = useMutation(
    (request: api.AddFirmwareRequest) => api.addFirmware(jwtToken, request),
    {
      onSuccess: () => {
        setAddFirmwareDialogOpen(false)
      },
      onSettled: () => {
        cache.invalidateQueries('firmware')
      },
    },
  )

  const [mutateDeleteFirmware, mutateDeleteFirmwareResult] = useMutation(
    (firmware: Firmware) => api.deleteFirmware(jwtToken, firmware.id),
    {
      onSuccess: () => {
        setFirmwareToDelete(undefined)
      },
      onSettled: () => {
        cache.invalidateQueries('firmware')
        cache.invalidateQueries('otaUpdate')
      },
    },
  )

  const [mutateUpdateFirmware, mutateUpdateFirmwareResult] = useMutation(
    (firmware: Pick<Firmware, 'id' | 'serialNumberRegexes'>) =>
      api.updateFirmware(jwtToken, firmware.id, firmware.serialNumberRegexes),
    {
      onSuccess: () => {
        setFirmwareToEdit(undefined)
      },
      onSettled: () => {
        cache.invalidateQueries('firmware')
        cache.invalidateQueries('otaUpdate')
      },
    },
  )

  const [mutatePublishFirmware, mutatePublishFirmwareResult] = useMutation(
    (firmware: Firmware) => api.publishFirmware(jwtToken, firmware.id),
    {
      onSuccess: () => {
        setFirmwareToPublish(undefined)
      },
      onSettled: () => {
        cache.invalidateQueries('firmware')
      },
    },
  )

  const [mutateUnpublishFirmware, mutateUnpublishFirmwareResult] = useMutation(
    (firmware: Firmware) => api.unpublishFirmware(jwtToken, firmware.id),
    {
      onSuccess: () => {
        setFirmwareToUnpublish(undefined)
      },
      onSettled: () => {
        cache.invalidateQueries('firmware')
      },
    },
  )

  const header = (
    <React.Fragment>
      <div className={classes.header}>
        <Button
          onClick={() => setUpdateRegExprsDialogOpen(true)}
          color="default"
        >
          Update RegExprs
        </Button>
        <Button
          className={classes.buttonAddFirmware}
          onClick={() => setAddFirmwareDialogOpen(true)}
          variant="contained"
          color="primary"
        >
          Add firmware
        </Button>
      </div>

      <Divider variant="fullWidth" orientation="horizontal" />
    </React.Fragment>
  )

  let content: JSX.Element
  if (status === QueryStatus.Idle || status === QueryStatus.Loading) {
    content = <CircularProgress />
  } else if (status === QueryStatus.Error) {
    content = <div>{ApiError.unknownErrorToMessage(error)}</div>
  } else {
    content = (
      <TableContainer component={Paper}>
        <Table aria-label="Firmware packages">
          <TableHead>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell>RegExprs</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Published</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((firmware) => (
              <TableRow key={firmware.id}>
                <TableCell>{firmware.version}</TableCell>
                <TableCell>
                  {firmware.serialNumberRegexes.map((regex) => (
                    <p key={regex}>{regex}</p>
                  ))}
                </TableCell>

                <TableCell>
                  {(firmware.fileSizeBytes / 1000).toFixed(3)} kB
                </TableCell>
                <TableCell>
                  <StateLabel
                    label={firmware.published ? 'Yes' : 'No'}
                    circleColor={
                      firmware.published ? color.swegonGreen : color.gray
                    }
                  />
                </TableCell>
                <TableCell>
                  {dateTimeFormat.format(Date.parse(firmware.createdAt))}
                </TableCell>
                <TableCell align="right">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <IconButton {...bindTrigger(popupState)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                          {!firmware.published && (
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                setFirmwareToPublish(firmware)
                              }}
                              disabled={
                                !publishableVersionRegex.test(firmware.version)
                              }
                            >
                              <ListItemIcon>
                                <PublishIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary="Publish"
                                secondary={
                                  !publishableVersionRegex.test(
                                    firmware.version,
                                  )
                                    ? 'Only versions conforming to format x.y.z can be published.'
                                    : undefined
                                }
                              />
                            </MenuItem>
                          )}
                          {firmware.published && (
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                setFirmwareToUnpublish(firmware)
                              }}
                            >
                              <ListItemIcon>
                                <CancelIcon />
                              </ListItemIcon>
                              <ListItemText primary="Unpublish" />
                            </MenuItem>
                          )}
                          {!firmware.published && (
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                setFirmwareToDelete(firmware)
                              }}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText>Delete</ListItemText>
                            </MenuItem>
                          )}

                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              setFirmwareToEdit(firmware)
                            }}
                          >
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText>Update RegExprs</ListItemText>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              api
                                .fetchFirmwareDownloadUrl(jwtToken, firmware.id)
                                .then((url) => {
                                  const a = document.createElement('a')
                                  a.style.display = 'none'
                                  a.href = url
                                  a.download = `${firmware.family}-${firmware.version}`
                                  document.body.appendChild(a)
                                  a.click()
                                  document.body.removeChild(a)
                                })
                            }}
                          >
                            <ListItemIcon>
                              <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText>Download</ListItemText>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div>
      {header}
      {content}

      <UpdateFirmwareRegExprsDialog
        open={updateRegExprsDialogOpen}
        onClose={() => setUpdateRegExprsDialogOpen(false)}
        jwtToken={jwtToken}
      />

      <AddFirmwareDialog
        onAdd={(request) => {
          mutateAddFirmware(request)
        }}
        open={addFirmwareDialogOpen}
        addResult={mutateAddFirmwareResult}
        onClose={() => setAddFirmwareDialogOpen(false)}
        existingVersions={new Set(data?.map((x) => x.version) ?? [])}
      />

      {firmwareToEdit && (
        <EditFirmwareDialog
          firmware={firmwareToEdit}
          onSave={(request) =>
            firmwareToEdit &&
            mutateUpdateFirmware({
              id: firmwareToEdit.id,
              serialNumberRegexes: request.serialNumberRegexes,
            })
          }
          open={firmwareToEdit !== undefined}
          onClose={() => setFirmwareToEdit(undefined)}
          saveResult={mutateUpdateFirmwareResult}
        />
      )}

      <DeleteDialog
        title={`Delete firmware ${
          firmwareToDelete
            ? FirmwareFamily.translate(firmwareToDelete.family)
            : ''
        } version ${firmwareToDelete?.version}?`}
        description={
          'The firmware file will be deleted permanently. Deleting firmware with active (Idle / In progress) OTA sessions is not possible. Please wait until all OTA sessions with this firmware version are finished before deleting.'
        }
        open={firmwareToDelete !== undefined}
        deleteResult={mutateDeleteFirmwareResult}
        onDelete={() =>
          firmwareToDelete && mutateDeleteFirmware(firmwareToDelete)
        }
        onClose={() => setFirmwareToDelete(undefined)}
      />

      <ConfirmDialog
        title={`Publish firmware ${
          firmwareToPublish
            ? `${FirmwareFamily.translate(firmwareToPublish.family)} ${
                firmwareToPublish.version
              }?`
            : ''
        }`}
        description={
          'Mobile application users will be able to install the newest version of all published firmware.'
        }
        confirmText="Publish"
        open={firmwareToPublish !== undefined}
        confirmResult={mutatePublishFirmwareResult}
        onConfirm={() =>
          firmwareToPublish && mutatePublishFirmware(firmwareToPublish)
        }
        onClose={() => setFirmwareToPublish(undefined)}
      />

      <ConfirmDialog
        title={`Unpublish firmware ${
          firmwareToUnpublish
            ? `${FirmwareFamily.translate(firmwareToUnpublish.family)} ${
                firmwareToUnpublish.version
              }?`
            : ''
        }`}
        description={
          'Please note that if this is the newest version, mobile application users will be prompted to install the newest published version before this version.'
        }
        confirmText="Unpublish"
        open={firmwareToUnpublish !== undefined}
        confirmResult={mutateUnpublishFirmwareResult}
        onConfirm={() =>
          firmwareToUnpublish && mutateUnpublishFirmware(firmwareToUnpublish)
        }
        onClose={() => setFirmwareToUnpublish(undefined)}
      />
    </div>
  )
}

export default FirmwareComponent
