import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Add from '@material-ui/icons/Add'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, InputAdornment } from '@material-ui/core'

type Props = {
  autoFocus?: boolean
  value: string[]
  onChange: (value: string[]) => void
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginBottom: theme.spacing(2),
    width: '350px',
  },
  regexInputField: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    maxWidth: '350px',
  },
  inputContainer: {
    flexDirection: 'column',
    display: 'flex',
  },
}))

function RegExprsInputList({ autoFocus, value, onChange }: Props): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.inputContainer}>
      {value.map((regex, index) => (
        <TextField
          key={index}
          className={`${classes.inputField} ${classes.regexInputField}`}
          autoFocus={autoFocus}
          id={`regex-${index}`}
          label="RegExpr"
          value={regex}
          onChange={(e) =>
            onChange(
              value.map((val, valIndex) =>
                valIndex === index ? e.target.value : val,
              ),
            )
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={index === 0 && value.length === 1}
                  onClick={() =>
                    onChange(
                      value.filter((_item, itemIndex) => itemIndex !== index),
                    )
                  }
                >
                  <RemoveCircle />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ))}

      <Button
        className={classes.addButton}
        color="default"
        variant="outlined"
        onClick={() => {
          onChange([...value, ''])
        }}
      >
        <Add />
        Add RegExpr
      </Button>
    </div>
  )
}

export default RegExprsInputList
